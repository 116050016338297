import { cn } from '@utils/cn';

// common css classes for chips
// *******************************************************************************************************
const commonClasses = 'inline-flex flex-1 items-center justify-center whitespace-nowrap gap-x-2 px-4 py-1.5 outline-none rounded-full border transition-all duration-200 bg-surface text-sm';
const defaultClasses = 'border-input text-ink';
const focusedClasses = 'focus:outline-none focus:ring-1 focus:ring-primary/40';
const clickableClasses = 'cursor-pointer hover:enabled:shadow-md hover:enabled:border-primary hover:enabled:text-primary';
const disabledClasses = 'disabled:opacity-60 disabled:shadow-none disabled:cursor-not-allowed disabled:text-input';
const highlightedClasses = 'border-primary text-primary';
const selectedClasses = 'bg-surface-100 border-transparent focus:ring-0';

/**
 * @description Composer for final classes to be passed to component based on color, size, styling and tailwind classes (className)
 * passed to component as props.
 * @param clickable
 * @param disabled
 * @param highlighted
 * @param selected
 * @param hasDelete,
 * @param className
 */
export const composeChipClasses = (
  clickable: boolean,
  disabled: boolean,
  highlighted: boolean,
  selected: boolean,
  hasDelete: boolean,
  className: string,
): string => {
  let chipClasses = `${commonClasses} ${focusedClasses}`;
  chipClasses += ` ${clickable && !selected ? `${clickableClasses} ${focusedClasses}` : ''}`;
  chipClasses += ` ${highlighted ? highlightedClasses : defaultClasses}`;
  chipClasses += ` ${disabled ? disabledClasses : ''}`;
  chipClasses += ` ${selected ? selectedClasses : ''}`;

  return cn(chipClasses, hasDelete && 'pr-9', className);
};

