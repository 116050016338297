import { useCombobox, UseComboboxState, UseComboboxStateChangeOptions } from 'downshift';


/** Type for stateReducerSelectMultiple function */
type StateReducerSelect = <Item>(
  state: UseComboboxState<Item>,
  actionAndChanges: UseComboboxStateChangeOptions<Item>,
) => Partial<UseComboboxState<Item>>;

/**
 * @description Combobox state reducer for keeping open the dropdown so we can select multiple items.
 */
export const stateReducerSelect: StateReducerSelect = (state, actionAndChanges) => {
  const { type, changes } = actionAndChanges;

  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: state.isOpen,
        highlightedIndex: state.highlightedIndex,
      };
    default:
      return changes;
  }
};
