import { ApiSubscriptionPackagesParams } from '@type/v1-api-types';
import { useState } from 'react';
import { useSwrUpgradePackages } from '@hooks/useSwrApi/endpoints/subscription';
import { Spinner } from '@components/common';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { UpgradeList } from '../cards/UpgradeList';


/**
 * @description Renders the subscription upgrade packages list (extra credits or subscriptions).
 */
export const StepSelectUpgradePackage = () => {
  // Router
  const { locale } = useExtendedRouter();


  // 1. GET UPGRADE PACKAGES
  // ***********************************************************************************************
  // State for useSwrUpgradePackages search params (we need to set the section param for the month/year)
  const [
    packagesSearchParams,
  ] = useState<string | ApiSubscriptionPackagesParams>('');

  // Fetch upgrade packages with SWR
  const {
    data: packagesData,
    isLoading: packagesLoading,
  } = useSwrUpgradePackages(locale, packagesSearchParams);

  // Render component
  // *****************************************
  return (
    <div className="py-8">
      { /* Spinner */}
      { !packagesData && packagesLoading && <div className="flex h-72 items-center justify-center"><Spinner /></div> }

      { /* Packages */}
      { packagesData && <UpgradeList packagesData={packagesData} /> }
    </div>
  );
};
