import { LinkStyled } from '@components/common/Anchor/LinkStyled';
import {
  ListIcon, ListSize, iconList, elementClasses, getFlatKeyValue,
} from './list-styles';


// Interfaces
// *****************************************************
interface ListObjectProps {
  items?: object[],
  icon?: ListIcon,
  size?: ListSize,
  className?: string,
  classLi?: string,
  classIcon?: string,
  classText?: string,
  classLink?: string,
  textKey?: string,
  urlKey?: string,
}


/**
 * @description Renders a list of items. Makes the design consistent across the app.
 *
 * * **items** - the list of items to render; default is an empty array
 * * **icon** - the icon to render for each item; default is 'check'
 * * **size** - the size of the icon & text; default is 'base'
 * * **className** - extra classes for the list; default is an empty string
 * * **classIcon** - extra classes for the icon; default is an empty string
 * * **classText** - extra classes for the text; default is an empty string
 * * **classLink** - extra classes for the link; default is an empty string
 * * **textKey** - the object key to use for the text; use flattened; default is an empty string
 * * **urlKey** - the object key to use for the url; use flattened; default is an empty string
 *
 */
export const ListObject = (props: ListObjectProps) => {
  // Props destructuring
  const {
    items = [],
    size = 'base',
    icon = 'check',
    className = '',
    classLi = '',
    classIcon = '',
    classText = '',
    classLink = '',
    textKey = '',
    urlKey = '',
  } = props;


  // Get the icon from the list
  const IconName = iconList[icon as keyof typeof iconList] || iconList.check;


  // Render component
  // *****************************************
  return (
    <ul className={`flex flex-col gap-1.5 ${className}`}>
      {items?.length && items.map((item) => {
        // Get the text and url from the item
        const text = getFlatKeyValue(item, textKey);
        const url = getFlatKeyValue(item, urlKey);

        // Elements classes
        const textClasses = elementClasses('text', size, classText);
        const linkClasses = elementClasses('text', size, classLink);

        // Don't render the item if there is no text
        if (!text) return null;

        // Render the item
        return (
          <li key={text} className={`flex items-center gap-1.5 ${classLi}`}>
            <IconName className={elementClasses('icon', size, classIcon)} />

            { // Render normal text if there is no url
              !url && <span className={textClasses}>{text}</span>
            }

            { // Render a link if there is an url
              url && <LinkStyled href={url} styling="none" className={linkClasses}>{text}</LinkStyled>
            }
          </li>
        );
      })}
    </ul>
  );
};
