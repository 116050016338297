import { flattenObj, expandObj } from '@utils/flatten-expand-object/flatten-expand-object';


/**
 * @description Sort the keys of an object. It will only sort the first level of keys.
 * @param obj - The object to sort.
 * @returns The sorted object.
 *
 * @example
 * const obj = {
 *  d: { f: 4, e: 5 },
 *  b: 1,
 *  a: 2,
 *  c: 3,
 *  g: { i: 6, h: 7 },
 * };
 *
 * const sortedObj = sortObjectKeys(obj);
 * // Output: { a: 2, b: 1, c: 3, d: { f: 4, e: 5 }, g: { i: 6, h: 7 } }
 */
export const sortObjectKeys = <DataType extends object, Key extends keyof DataType>(obj: DataType) => {
  if (!obj || typeof obj !== 'object') return obj;

  // New object holder
  const ordered = {} as DataType;

  // Sort the keys and add them to the new object
  Object.keys(obj).sort().forEach((key) => {
    ordered[key as Key] = obj[key as Key];
  });

  // Return the ordered object
  return ordered;
};


/**
 * @description Sort the keys of an object. It will sort all the keys of the object, including the nested ones.
 * @param obj - The object to sort.
 * @returns The sorted object.
 *
 * @example
 * const obj = {
 *  d: { f: 4, e: 5 },
 *  b: 1,
 *  a: 2,
 *  c: 3,
 *  g: { i: 6, h: 7 },
 * };
 *
 * const sortedObj = sortNestedObjectKeys(obj);
 * // Output: { a: 2, b: 1, c: 3, d: { e: 4, f: 5 }, g: { h: 6, i: 7 } }
 */
export const sortNestedObjectKeys = <DataType extends object, Key extends keyof DataType>(obj: DataType) => {
  if (!obj || typeof obj !== 'object') return obj;

  // New object holder
  const ordered = {} as DataType;

  // Sort the keys and add them to the new object
  Object.keys(flattenObj(obj)).sort().forEach((key) => {
    ordered[key as Key] = obj[key as Key];
  });

  // Return the ordered object
  return expandObj(ordered);
};
