// SWITCH LANG ************************************************************************************
// pathname: /v1/user/switch-lang
// ************************************************************************************************
import {
  useSwrMutationApi,
  ApiUserSwitchLangPost,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: POST switch lang. Used when the user switches the language to save
 * the preference in the database.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 */
export const useSwrSwitchLang = (locale = 'ro') => {
  // SWR mutation: fetch order
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, ApiUserSwitchLangPost>({
    url: '/v1/user/switch-lang',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
