import { StaticImageData } from 'next/image';
import { ImageBj } from '@components/common';
import visaLogo from '@images/svg/payment/visa.svg';
import mastercardLogo from '@images/svg/payment/mastercard.svg';
import maestroLogo from '@images/svg/payment/maestro.svg';


// Interface
interface CreditCardListProps {
  logoBorder?: boolean,
  logoSize?: string,
  className?: string,
}


// Credit card logos
const logoProviders = [visaLogo, mastercardLogo, maestroLogo];


/**
 * @description Renders a list of credit card logos.
 * * **logoBorder** is a boolean to show or hide the border around the logos. default is true.
 * * **logoSize** is the size of the logos. use the tailwind classes. default is 'w-8'.
 * * **className** prop for extra classes you might want to pass on the card.
 */
export const CreditCardList = (props: CreditCardListProps) => {
  // Props destructuring
  const {
    logoBorder = true,
    logoSize = 'w-8',
    className = '',
  } = props;


  // Render the credit card list
  // *****************************************
  return (
    <span className={`flex gap-1 ${className}`}>
      {
        logoProviders.map((logo: StaticImageData) => (
          <span key={logo.src} className={`flex items-center justify-center rounded p-1${logoBorder ? ' border' : ''}`}>
            <ImageBj
              src={logo}
              width={20}
              height={20}
              alt="Credit card logo"
              className={`h-auto ${logoSize}`}
            />
          </span>
        ))
      }
    </span>
  );
};
