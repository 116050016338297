import { useEffect } from 'react';
import { useAppDispatch } from 'src/app';
import {
  fetcher, fetchAbort, isHeaderInfoOk, ApiHeaderInfo,
} from '@utils/data-fetching';


/**
 * @description Check if user is authenticated
 * @param locale
 */
export const CheckAuthUser = (locale = 'ro') => {
  const appDispatch = useAppDispatch();

  useEffect(() => {
    // external AbortController & timeoutId,
    const { controller, timeoutId } = fetchAbort();

    // fetch data
    fetcher<ApiHeaderInfo>('/v1/header-info', locale, { externalAbort: { extController: controller, extTimeout: timeoutId } })
      .then((response) => {
        if (isHeaderInfoOk(response)) {
          appDispatch({ type: 'user/saveHeaderInfo', payload: response });
        }
      })
      .catch(() => {});

    // useEffect cleanup
    return () => controller?.abort();
  }, [appDispatch, locale]);
};
