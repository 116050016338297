import { useAppSelector } from '@store/index';
import { useTranslation } from 'next-i18next';
import { Avatar, bjToast, Button } from '@components/common';
import {
  EnvelopeIcon, PaperAirplaneIcon, PhoneIcon,
} from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Label, Textarea } from '@components/common/Forms';
import { useSwrResumeCheckUnlockMessage } from '@hooks/useSwrApi/endpoints/resume-operations';
import { useRouter } from 'next/router';
import { showUpgradeSubscriptionModal } from '@components/features/modals/employer/subscription/UpgradeSubscriptionModal';
import { useStaticToasts } from '@hooks/useStaticToasts';
import { useSwrMessageSend } from '@hooks/useSwrApi/endpoints/messages';
import { getLocaleFromQuery } from '@utils/gssp/gsspGetLocale';

interface IProps {
  onPressCloseButton: () => void;
  trackingId?: string;
}

/**
 * @description - Component for sending a message to a talent
 * * It handles resume operations for unlock sending message
 * * It handles sending the message
 * @param onPressCloseButton
 * @param trackingId - optional. The tracking id for the event.
 */
export const SendMessage = ({ onPressCloseButton, trackingId }: IProps) => {
  const router = useRouter();
  const locale = getLocaleFromQuery(router.query);

  const { t } = useTranslation('common');

  const { talentParticipant } = useAppSelector((state) => state.socketOnline);

  const [message, setMessage] = useState('');

  // Toasts
  const { showStatusToast } = useStaticToasts();

  // SWR: Send message
  const { trigger: triggerMessage, isMutating: isMutatingMessage } = useSwrMessageSend({
    slug: talentParticipant?.slug as string,
    locale,
    trackingId,
    successCallback: () => {
      bjToast.success(t('messages-send-success'));
      onPressCloseButton();
    },
  });

  // SWR: Check unlock message
  const { trigger: triggerCheckUnlock, isMutating: isMutatingCheckUnlock } = useSwrResumeCheckUnlockMessage({
    userId: talentParticipant?.userId as number,
    locale,
    successCallback: () => {
      // On success - send the message
      void triggerMessage({
        body: message,
      });
    },
    errorCallback: (errors) => {
      // Handle errors
      if (!errors.errors.length) {
        bjToast.error(t('global.something.went.wrong'));
        return;
      }
      const { code } = errors.errors[0];
      if (code === 601) {
        showUpgradeSubscriptionModal();
      } else {
        showStatusToast(String(`${code}`));
      }
    },
  });

  // on press submit button
  const onSubmit = () => {
    void triggerCheckUnlock({});
  };

  return (
    <div className="mb-2 mt-4 flex flex-col gap-4">

      {/* talent details */}
      <div className="flex flex-col items-center gap-4 sm:flex-row">
        {
            !!talentParticipant?.imgUrl && (
              <Avatar
                imgPath={talentParticipant.imgUrl}
                imgFolder="resume_image"
                extractImageName
                alt="Profile picture"
              />
            )
          }
        <div className="flex flex-col gap-2">
          <p className="text-lg font-bold text-ink">
            {talentParticipant?.userName}
          </p>
          {
              talentParticipant?.phone !== undefined && (
                <div className="flex items-center">
                  <PhoneIcon className="mr-2 size-4" />
                  <span className="text-sm text-ink">
                    {talentParticipant.phone}
                  </span>
                </div>
              )
            }
          {
              talentParticipant?.email !== undefined && (
                <div className="flex items-center">
                  <EnvelopeIcon className="mr-2 size-4" />
                  <span className="text-sm text-ink">
                    {talentParticipant.email}
                  </span>
                </div>
              )
            }
        </div>
      </div>

      {/* input for message */}
      <Label htmlFor="message" description={t('call_live.participant.no_answer.send_label', { participantName: talentParticipant?.userName })} required>
        <Textarea id="message" value={message} onChange={(e) => setMessage(e.target.value)} />
      </Label>

      {/* button for sending message */}
      <div className="text-center">
        <Button onClick={onSubmit} disabled={message.length < 3} rounding="full" color="secondary" isLoading={isMutatingMessage || isMutatingCheckUnlock}>
          <PaperAirplaneIcon className="mr-2 size-5" />
          {t('call_live.participant.no_answer.send_button')}
        </Button>
      </div>
    </div>
  );
};
