// to avoid path errors in phpstorm, mark the public folder as Resource Root
// https://stackoverflow.com/questions/63653319/how-to-make-intellij-understand-the-next-js-public-directory-structure
const assetsPrefix = process.env.ASSETS_PREFIX_FRONTEND || '';
const cdnUrl = process.env.NODE_ENV === 'production' ? assetsPrefix : '';

// Favicon component
export const Favicon = () => (
  <>
    <link rel="apple-touch-icon" sizes="180x180" href={`${cdnUrl}/favicon/apple-touch-icon.png`} />
    <link rel="icon" type="image/png" sizes="32x32" href={`${cdnUrl}/favicon/favicon-32x32.png`} />
    <link rel="icon" type="image/png" sizes="16x16" href={`${cdnUrl}/favicon/favicon-16x16.png`} />
    <link rel="manifest" href={`${cdnUrl}/favicon/site.webmanifest`} crossOrigin="" />
    <link rel="mask-icon" href={`${cdnUrl}/favicon/safari-pinned-tab.svg`} color="#5FBB46" />
    <link rel="shortcut icon" href={`${cdnUrl}/favicon/favicon.ico`} />
    <meta name="msapplication-TileColor" content="#00a300" />
    <meta name="msapplication-config" content={`${cdnUrl}/favicon/browserconfig.xml`} />
    <meta name="theme-color" content="#ffffff" />
  </>
);
