import { useState } from 'react';
import { useSwrPackages } from '@hooks/useSwrApi/endpoints/subscription';
import { Spinner } from '@components/common';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { SubscriptionsList } from '../cards/SubscriptionsList';
import { SubscriptionsListAction } from '../cards/SubscriptionsListAction';


/**
 * @description Renders the Premium Subscription packages. It renders the list of subscriptions,
 * the common benefits and the action button. On the action button click, it redirects to the
 * payment step.
 */
export const StepSelectSubscription = () => {
  // Router
  const { locale } = useExtendedRouter();


  // 1. Fetch subscriptions with SWR
  // *****************************************
  const {
    data: subscriptionsData, isLoading: subscriptionsLoading,
  } = useSwrPackages(locale);


  // 2. Get the user selected subscription (for the action button)
  // *****************************************
  const [
    userSelectedSubscription, setUserSelectedSubscription,
  ] = useState<ApiSchema<'SubscriptionPackageModel'> | undefined>(undefined);


  // Render component
  // *****************************************
  return (
    <>
      { /* Spinner */
        !subscriptionsData && subscriptionsLoading
        && <div className="flex h-96 items-center justify-center"><Spinner /></div>
      }

      { /* Subscriptions list */
        subscriptionsData
        && (
          <SubscriptionsList
            subscriptionsData={subscriptionsData?.subscriptionPackages}
            shareSelectedSubscription={setUserSelectedSubscription}
          />
        )
      }

      { /* Action button */
        subscriptionsData && (
          <SubscriptionsListAction
            subscriptionsData={subscriptionsData?.subscriptionPackages}
            userSelectedSubscription={userSelectedSubscription}
            locale={locale}
          />
        )
      }
    </>
  );
};
