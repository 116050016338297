import {
  ElementType, Fragment, ReactNode, forwardRef, ForwardedRef,
} from 'react';
import {
  Listbox as HeadlessListbox, Transition, ListboxOptionsProps,
} from '@headlessui/react';
import { composePopoverPanelClasses, composePopoverPanelAnimation } from 'src/components/common/Popover/popoverStyles';


// Interfaces
// Important: Listbox.Options types are a tuple of any, we need to some Generic magic to
// extract className separately
// *******************************************************************************************
type IListboxRootOptions = ListboxOptionsProps<ElementType<JSX.Element>> & {
  children: ReactNode,
  className?: string,
  animation?: 'fade' | 'slideUp' | 'slideDown',
  position?: 'left' | 'center' | 'right' | 'float',
  ui?: 'none' | 'default' | 'float', // 'none' will reset all the classes from the component
};


// Customized Listbox.Options component
// *******************************************************************************************
export const ListboxStyledRootOptions = forwardRef((props: IListboxRootOptions, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    children,
    className = '',
    animation = 'fade',
    position = 'center',
    ui = 'default',
    ...rest
  } = props;

  // component classes
  const componentClasses = composePopoverPanelClasses(position, ui, className);

  // component animation
  const componentAnimation = composePopoverPanelAnimation(animation);

  // get the unmount prop
  const { unmount = true } = rest;


  // Render component
  // **************************
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom={componentAnimation.hiddenState}
      enterTo={componentAnimation.visibleState}
      leave="transition ease-in duration-150"
      leaveFrom={componentAnimation.visibleState}
      leaveTo={componentAnimation.hiddenState}
      unmount={unmount}
    >
      <HeadlessListbox.Options ref={ref} className={componentClasses} {...rest}>
        { children }
      </HeadlessListbox.Options>
    </Transition>
  );
});
