'use client';

import Link from 'next/link';
import { forwardRef } from 'react';
import { useRouter as useRouterPages } from 'next/compat/router';
import { LinkStyledProps, AnchorRef } from 'src/types/anchors-buttons';
import { getI18nLinkHref } from '@services/i18nPaths';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { Anchor } from './Anchor';
import { composeButtonClasses } from './anchorButtonStyles';

/**
 * @description Styled Next.js Link component. **To be used for internal links. It automatically
 * translates the i18n paths.** In addition to the normal properties of the Link component, for
 * styling you need to pass the following props:
 *
 * * **styling** - the styling of the button: text, outline, solid, none; default: solid
 * * **color** - the color of the button; default: primary
 * * **size** - the size of the button: xs, sm, md, lg; default: md
 * * **rounding** - the rounding of the button: none, normal, full; default: normal *
 *
 * -----------------
 * @description If styling is **none**, no classes will be pre-applied, the component will have no
 * styling applied to it.
 *
 * -----------------
 *
 * @param props
 *
 * @example
 * <LinkStyled href="/" styling="outline" color="secondary" size="sm" rounding="full">
 *   Link text
 * </LinkStyled>
 *
 * // force a locale on an i18n path (switches language on destination to en)
 * <LinkStyled href="/temp/translate-url/localized-url" locale="en">
 *   Localized link
 * </LinkStyled>
 */
export const LinkStyled = forwardRef<AnchorRef, LinkStyledProps>((props, ref) => {
  const {
    href = '',
    color = 'primary',
    styling = 'solid',
    size = 'md',
    rounding = 'normal',
    className = '',
    children = 'Button text',
    locale: localeProps,
    ...restProps
  } = props;

  const finalClasses = styling === 'none' ? className : composeButtonClasses(color, size, styling, rounding, className);

  // Get the current page locale
  const { locale } = useExtendedRouter();

  // Check if we're passing a locale prop to component. Otherwise, use the current page locale.
  const usedLocale = localeProps || locale;

  // Check the href for translated i18n paths; return translated or original path.
  // The function also adds the locale prefix to non i18n path.
  const finalHref = getI18nLinkHref(href, usedLocale);

  // Determine whether to render an Anchor instead of a Link.
  //
  // This is applicable because:
  // - When using route interception (e.g., modals with "intercepted" routes - Job detail, CV detail),
  // all navigation to matching routes will automatically render the intercepted UI.
  // - Next.js (with the App Router) lacks a built-in mechanism to skip route interception.
  //
  // To prevent the interception for specific routes, we manually check if the provided `href`
  // matches one of the predefined intercepted routes (defined in `interceptedPathnames`).
  //
  // If the route is intercepted and we’re in an App Router context (`pagesRouter === null`),
  // the component renders an `Anchor` to avoid triggering the route interception mechanism.
  // This ensures the navigation bypasses the interception logic and behaves as a full page reload.
  //
  const pagesRouter = useRouterPages();
  const interceptedPathnames = ['/job/list', '/cv/search'];
  const shouldRenderAnchor = pagesRouter === null && interceptedPathnames.some((path) => href.startsWith(path));

  /**
   * Render an Anchor for intercepted routes in the App Router.
   */
  if (shouldRenderAnchor) {
    return (
      <Anchor
        href={finalHref}
        color={color}
        styling={styling}
        size={size}
        rounding={rounding}
        className={finalClasses}
        {...restProps}
        ref={ref}
      >
        { children }
      </Anchor>
    );
  }

  /**
   * Render the component.
   */
  return (
    <Link href={finalHref} className={finalClasses} {...restProps} ref={ref}>
      { children }
    </Link>
  );
});
