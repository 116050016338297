'use client';

import {
  Component, ErrorInfo, ReactNode, isValidElement, cloneElement,
} from 'react';
import { logErrorBoundary } from '@utils/logger/logger';


// Interfaces & types
// *******************************
interface ErrorBoundaryProps {
  fallback?: ReactNode,
  fallbackComponent?: ReactNode,
  children?: ReactNode,
}

interface ErrorBoundaryState {
  hasError: boolean,
  error?: Error,
  errorInfo?: ErrorInfo
}


// Initial component state
// *******************************
const initialState: ErrorBoundaryState = {
  hasError: false,
  error: undefined,
  errorInfo: undefined,
};


/**
 * @description Component to handle errors in the application. For more information, check the
 * {@link https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary React documentation}
 *
 * ---------------
 * @description IMPORTANT: ErrorBoundary is a client component. You can only pass props to it that
 * are serializable or use it in files that have a 'use client'; directive.
 *
 * ---------------
 * @example
 * 'use client';
 * import { ErrorBoundary } from '@components/common';
 *
 * // with inline fallback
 * <ErrorBoundary fallback={<div>Something went wrong</div>}>
 *   <ExampleApplication />
 * </ErrorBoundary>
 *
 * // with inline fallback, component without props
 * <ErrorBoundary fallback={<SomeComponent />}>
 *   <ExampleApplication />
 * </ErrorBoundary>
 *
 * // with component fallback, component with props: error & reset
 * // (these are passed by the ErrorBoundary component)
 * <ErrorBoundary fallbackComponent={<SomeComponentWithReset />}>
 *   <ExampleApplication />
 * </ErrorBoundary>
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    // Define a state variable to track whether is an error or not
    this.state = initialState;
  }

  // Update state so the next render will show the fallback UI
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  // Log the error
  // You can extend the component to use an error logging service here (with a prop like `errorLogger = true`)
  componentDidCatch(error:Error, errorInfo:ErrorInfo) {
    console.error({ error, errorInfo });
    this.setState({ errorInfo });
    logErrorBoundary(error, errorInfo);
  }

  // Reset the state to the initial state
  resetErrorBoundary = () => {
    this.setState(initialState);
  };

  // Render the component
  render() {
    const { fallback, fallbackComponent, children } = this.props;
    const { hasError, error, errorInfo } = this.state;
    // Define the props to pass to the fallbackComponent
    const fallbackProps = {
      error,
      errorInfo,
      reset: this.resetErrorBoundary,
    };

    // Check if the error is thrown
    if (hasError) {
      if (isValidElement(fallback)) {
        // You can render any inline fallback UI
        return fallback;
      } if (isValidElement(fallbackComponent)) {
        // Render a component if provided, and also pass the error object & reset state function to that component
        return cloneElement(fallbackComponent, { ...fallbackProps });
      }

      // if no fallback is provided, throw an error, we require at least one of the two
      throw new Error('ErrorBoundary requires a fallback or fallbackComponent prop');
    }

    // Return children components in case of no error
    return children;
  }
}
