import { v4 as uuidv4 } from 'uuid';

/**
 * @description Generate SHA256 hash in client side
 * @param message
 */
export const generateSHA256HashClientSide = async (message: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  return hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
};

/**
 * @description Generate random uuid
 */
export const generateUuid = (): string => uuidv4();
