// PAYMENT TYPE ***********************************************************************************
// pathname: /v1/subscription/payment-type
// ************************************************************************************************
import { useAppDispatch } from 'src/app';
import { savePaymentData } from '@store/reducers/employer/subscription/subscriptionReducer';
import {
  useSwrMutationApi,
  ApiSubscriptionPackagesPost,
} from 'src/hooks/useSwrApi';
import { bjToast } from '@components/common';


/**
 * @description SWR mutation: fetch subscription payment type after posting the packageId. Used when
 * upgrading the subscription package (click on package card CTA). We save the response to Redux, so
 * we can use it in the payment section.
 * @param locale - The locale to use for the request.
 * @param openPaymentModal - Callback to open the payment modal after the request is successful.
 *
 * @example
 * const { trigger, ... } = useSwrMutationPaymentType(locale);
 *
 * const triggerNoParam = () => { void trigger(); };
 * const triggerWithParams = () => { void trigger({ packageId: 123 }); };
 */
export const useSwrMutationPaymentType = (locale = 'ro', openPaymentModal?: () => void) => {
  // Redux dispatch
  const appDispatch = useAppDispatch();

  // SWR mutation: fetch subscription payment type
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiSchema<'SubscriptionPayment'>, ApiSubscriptionPackagesPost>({
    url: '/v1/subscription/payment-type',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      populateCache: true,
      onSuccess: (newData) => {
        // Save the response to Redux
        appDispatch(savePaymentData(newData));
        // Success callback if any. You can use it to open the payment modal.
        if (openPaymentModal) openPaymentModal();
      },
      onError: (newError) => {
        if (newError.errors[0].message) {
          bjToast.error(newError.errors[0].message);
        }
      },
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
