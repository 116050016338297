// IMPORTS ****************************************************************************************
// ************************************************************************************************
import { GenericV1ErrorsArray } from './_global';
import { EscoSkill } from '../esco-skill';
import { IGenericApiStrings } from '../generic-api-strings';

// Resume Skill List ************************************************************************************
// pathname: /v1/resume/skill/list
// ************************************************************************************************

export type ApiSkillListOk = EscoSkill[];
export type ApiSkillList = ApiSkillListOk | GenericV1ErrorsArray;


// Resume Skill Add ************************************************************************************
// pathname: /v1/resume/skill/add/{id}
// ************************************************************************************************

export type ApiSkillAddOk = Record<string, never>;
export type ApiSkillAdd = ApiSkillAddOk | GenericV1ErrorsArray;


// Resume Skill Remove ************************************************************************************
// pathname: /v1/resume/skill/remove/{id}
// ************************************************************************************************

export type ApiSkillRemoveOk = Record<string, never>;
export type ApiSkillRemove = ApiSkillRemoveOk | GenericV1ErrorsArray;


// Resume Create Spoken language ************************************************************************************
// pathname: /v1/resume/spoken-language
// ************************************************************************************************

export interface SpokenLanguage {
  shortName: string;
  level: string;
}

export type ApiLanguageAddPayload = SpokenLanguage;
export type ApiLanguageAddOK = Record<string, never>;
export type ApiLanguageAdd = ApiLanguageAddOK | GenericV1ErrorsArray;
export type ApiLanguageRemoveOK = Record<string, never>;
export type ApiLanguageRemove = ApiLanguageRemoveOK | GenericV1ErrorsArray;


// Resume Create Experience ************************************************************************************
// pathname: /v1/resume/work-experience
// ************************************************************************************************

export interface Experience {
  title: string,
  location?: string,
  salary?: {
    amount: number,
    currency: string,
    confidential: boolean
  },
  predictedGroup?: string | null,
  company: string,
  rating?: number,
  start: string,
  end?: string | null,
  description?: string,
}


export type ApiExperienceAddOK = Record<string, never>;
export type ApiExperienceAdd = ApiExperienceAddOK | GenericV1ErrorsArray;


// EXPERIENCE ID **********************************************************************************
// pathname: /v1/resume/experience/{experienceId}
// ************************************************************************************************
export interface ApiExperienceIdOk {
  data: {
    title: string,
    location?: string,
    salary?: {
      amount?: number,
      currency?: string,
      confidential?: boolean,
    }
    predictedGroup?: string,
    company:string,
    rating?: number,
    start: string,
    end: string,
    description?: string,
  }
}

export type ApiExperienceId = ApiExperienceIdOk | GenericV1ErrorsArray;


// LAST WORK EXPERIENCE ***************************************************************************
// pathname: /v1/resume/last-work-experience
// ************************************************************************************************
export interface Salary {
  amount?: number,
  currency: string,
  confidential?: boolean,
}

export interface ApiLastWorkExperienceOk {
  title: string,
  location?: string,
  salary?: Salary;
  predictedGroup?: string,
  company: string,
  rating?: number,
  start: string,
  end: string,
  description?: string,
}

export type ApiLastWorkExperience = ApiLastWorkExperienceOk | GenericV1ErrorsArray;


// USER RESUME PERSONAL DATA **********************************************************************
// pathname: /v1/resume/personal-data
// ************************************************************************************************
export interface ApiResumePersonalDataOk {
  firstName?: string;
  lastName? : string;
  resumeLocation?: string;
  email?: string;
  birthDate?: string;
  gender?: string;
  socialLinks?: { [key: string]: string }[];
  privacyLevel?: number;
}

export type ApiResumePersonalData = ApiResumePersonalDataOk | GenericV1ErrorsArray;
export type ApiResumePersonalDataSave = object | GenericV1ErrorsArray;

// USER RESUME ABOUT ME DATA **********************************************************************
// pathname: /v1/resume/about-me
// ************************************************************************************************
export interface ApiResumeAboutMeDataOk {
  details: string;
  selfInterviewItems: { questionId: string; answer: string }[];
}

export type ApiUserAboutMeData = ApiResumeAboutMeDataOk | GenericV1ErrorsArray;

// USER RESUME JOB TYPE PREFERENCES DATA **********************************************************
// pathname: /v1/resume/preferences
// ************************************************************************************************
export interface ApiUserJobTypePreferencesOk {
  salary?: {
    amount?: number;
    currency?: string;
    confidential?: boolean;
  };
  fullTime?: boolean;
  partTime?: boolean;
  onCompanyPremises?: boolean;
  relocation?: boolean;
  travel?: boolean;
  withRemote?: boolean;
  recruitmentProjects?: boolean;
}

export type ApiUserJobTypePreferences = ApiUserJobTypePreferencesOk | GenericV1ErrorsArray;
export type ApiUserJobTypePreferencesSave = object | GenericV1ErrorsArray;

export interface ProfileExperienceRating {
  generalRating: number;
  benefitsRating: number;
  environmentRating: number;
  managementRating: number;
  careerRating: number;
  proObservation?: string;
  consObservation?: string;
}

export interface ProfileTalentRating {
  rating: number;
  message: string;
  success: boolean;
}

// GET USER RESUME EXPERIENCE DATA **********************************************************************
// pathname: /v1/resume/work-experience/{id}
// ************************************************************************************************
export interface ApiProfileExperienceOk {
  id: number,
  title: string,
  location?: string,
  salary?: {
    amount?: number,
    currency?: string,
    confidential?: boolean,
  }
  employerSlug?:string;
  predictedGroup?: string,
  company:string,
  ratedEmployer: ProfileExperienceRating,
  rating?: number,
  start: string,
  end: string,
  description?: string,
}

export type ApiProfileExperience = ApiProfileExperienceOk | GenericV1ErrorsArray;


// DELETE USER RESUME EXPERIENCE DATA **********************************************************************
// pathname: /v1/resume/work-experience/{id}
// ************************************************************************************************
export type ApiDeleteProfileExperienceOk = object;
export type ApiDeleteProfileExperience = ApiDeleteProfileExperienceOk | GenericV1ErrorsArray;


// USER RESUME STUDY DATA **********************************************************************
// pathname: /v1/resume/study/{id}
// ************************************************************************************************
export interface ApiGetResumeStudyOk {
  id: number;
  institution: string;
  specialization?: string;
  studyLevel: number;
  admissionYear?: number;
  graduationYear?: number;
  location?: string;
  description?: string;
}

export type ApiGetResumeStudy = ApiGetResumeStudyOk | GenericV1ErrorsArray;

// USER RESUME STUDY DATA **********************************************************************
// pathname: /v1/resume/study/{id}
// ************************************************************************************************
export type ApiDeleteResumeStudyOk = object;
export type ApiDeleteResumeStudy = ApiDeleteResumeStudyOk | GenericV1ErrorsArray;


// USER RESUME STUDY SPECIALIZATION **********************************************************************
// pathname: /v1/resume/study/specialization/{id}
// ************************************************************************************************
export interface ApiGetResumeStudySpecializationOk {
  id: number;
  text: string;
}

export type ApiGetResumeStudySpecialization = ApiGetResumeStudySpecializationOk | GenericV1ErrorsArray;


// USER RESUME GET VIDEO CV  **********************************************************************
// pathname: /v1/resume/video
// ************************************************************************************************

export enum VideoCVStatus {
  STATUS_NEW = 0,
  STATUS_TRANSCODE = 1,
  STATUS_APPROVED = 2,
  STATUS_REJECTED = 3,
}

export interface ApiResumeVideoCVOk {
  name: string;
  status: VideoCVStatus;
}

export type ApiResumeVideoCV = ApiResumeVideoCVOk | GenericV1ErrorsArray;

// USER RESUME SET VIDEO NOTIFICATION STATUS  **********************************************************************
// pathname: /v1/resume/video/notification/{status}
// ************************************************************************************************
export type ApiResumeSetVideoNotificationStatusOk = object;
export type ApiResumeSetVideoNotificationStatus = ApiResumeSetVideoNotificationStatusOk | GenericV1ErrorsArray;


// USER RESUME SKILL LIST       *******************************************************************
// pathname: /v1/resume/skill/list
// ************************************************************************************************

export type ApiResumeSkillList = IGenericApiStrings[] | GenericV1ErrorsArray;


// USER RESUME UPLOAD VIDEO CV  *******************************************************************
// pathname: /v1/resume/video
// ************************************************************************************************

export type ApiResumeUploadVideo = object | GenericV1ErrorsArray;

// USER RESUME DELETE VIDEO CV  *******************************************************************
// pathname: /v1/resume/video
// ************************************************************************************************

export type ApiResumeDeleteVideo = object | GenericV1ErrorsArray;

// USER RESUME ADD PICTURE ************************************************************************
// pathname: /v1/resume/picture
// ************************************************************************************************

export type ApiResumePicture = object | GenericV1ErrorsArray;

// USER NEXT JOBS SKILL LIST       *******************************************************************
// pathname: /v1/resume/next-jobs/list
// ************************************************************************************************

export type ApiResumeNextJobslList = IGenericApiStrings[] | GenericV1ErrorsArray;


// UPLOAD ATTACHMENTS  *****************************************************************************
// pathname: /v1/resume/attachment/upload
// ************************************************************************************************

interface ApiResumeAttachmentsUploadItem {
  id: string;
  name: string;
  originalName: string;
  mimeType: string;
}

export type ApiResumeAttachmentsUploadOk = ApiResumeAttachmentsUploadItem[];
export type ApiResumeAttachmentsUpload = ApiResumeAttachmentsUploadOk | GenericV1ErrorsArray;

// DELETE ATTACHMENT  *****************************************************************************
// pathname: /v1/resume/attachment/remove/{id}
// ************************************************************************************************
export type ApiResumeAttachmentRemoveOk = undefined | object;
export type ApiResumeAttachmentRemove = ApiResumeAttachmentRemoveOk | GenericV1ErrorsArray;
