import {
  Fragment, ReactNode, MutableRefObject, ElementType, forwardRef, ForwardedRef,
} from 'react';
import { Popover as HeadlessPopover, Transition, PopoverPanelProps } from '@headlessui/react';
import { composePopoverPanelClasses, composePopoverPanelAnimation } from './popoverStyles';


// Interfaces
// Important: Popover.Panel types are a tuple of any, we need to some Generic magic to
// extract className separately
// *******************************************************************************************
type IRootPopoverPanel = PopoverPanelProps<ElementType<JSX.Element>> & {
  children: ReactNode,
  className?: string,
  animation?: 'fade' | 'slideUp' | 'slideDown',
  position?: 'left' | 'center' | 'right' | 'float',
  ui?: 'none' | 'default' | 'float', // 'none' will reset all the classes from the component
  close?: (focusableElement?: (HTMLElement | MutableRefObject<HTMLElement | null> | undefined)) => void,
};


// Customized Popover.Panel component
// *******************************************************************************************
export const PopoverStyledRootPanel = forwardRef((props: IRootPopoverPanel, ref:ForwardedRef<HTMLDivElement>) => {
  const {
    children,
    className = '',
    animation = 'fade',
    position = 'center',
    ui = 'default',
    ...rest
  } = props;

  // component classes
  const componentClasses = composePopoverPanelClasses(position, ui, className);

  // component animation
  const componentAnimation = composePopoverPanelAnimation(animation);

  // get the unmount prop
  const { unmount = true } = rest;


  // Render component
  // **************************
  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom={componentAnimation.hiddenState}
      enterTo={componentAnimation.visibleState}
      leave="transition ease-in duration-150"
      leaveFrom={componentAnimation.visibleState}
      leaveTo={componentAnimation.hiddenState}
      unmount={unmount}
    >
      <HeadlessPopover.Panel ref={ref} className={componentClasses} {...rest}>
        { children }
      </HeadlessPopover.Panel>
    </Transition>
  );
});
