// PHONE COUNTRIES **************************************************************************************
// pathname: /v1/static-lists/phone-countries
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';


// SWR: fetch static phone countries
// usage: const { data, error, ... } = useSwrStaticPhoneCountries(locale);
// ********************************************
export const useSwrStaticPhoneCountries = (locale: string) => {
  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiSchema<'OptionItem2'>[]>({
    url: '/v1/static-lists/phone-countries',
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 60 * 24, // 24 hours, the data is not supposed to change
    },
  });

  // Return data
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
