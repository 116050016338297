import { SubscriptionOrderStatus } from '@type/redux';
import { StatusPayment } from '@components/features/payment';
import { useAppDispatch } from '@store/index';
import { clearOrderStatus } from '@store/reducers/employer/subscription/subscriptionReducer';
import { SuccessMessage } from '../utils/utils';


// Interface
// *****************************************
interface StatusUpgradePackageProps {
  packageName?: string,
  orderStatus?: SubscriptionOrderStatus,
  closeModal?: () => void,
}


/**
 * @description Renders the payment status for the upgrade package when done inside the page (no
 * redirect to other pages).
 * * **orderStatus** - The order status from Redux.
 * * **packageName** - The name of the package that was upgraded.
 * * **closeModal** - The function to close the modal.
 */
export const StepPaymentStatus = (props: StatusUpgradePackageProps) => {
  // Props destructuring
  const {
    orderStatus,
    packageName,
    closeModal,
  } = props;

  // Custom success message for Upgrade Packages Payment Status.
  const message = SuccessMessage(packageName || '');

  // Clear the order status from Redux (for error)
  const appDispatch = useAppDispatch();
  const actionError = () => appDispatch(clearOrderStatus());

  // Render component
  // *****************************************
  return (
    <StatusPayment
      isActivated
      orderStatus={orderStatus}
      successCustomMessage={message}
      actionError={actionError}
      actionSuccess={closeModal}
    />
  );
};
