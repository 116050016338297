// RESUME SEARCH ALERT - SAVE ****************************************************************************
// pathname: /v1/resume-search-alert/save/{id}
// *******************************************************************************************************
import { useSwrMutationApi } from '@hooks/useSwrApi';


/**
 * @description SWR: save resume search alert.
 * @param locale - string, the locale
 * @param id - string, the id of the resume search alert
 * @param successCallback - function, the success callback
 */
export const useSwrSaveResumeSearchAlert = (locale = 'ro', id = '', successCallback?: () => void) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, object>({
    url: '/v1/resume-search-alert/save/',
    urlParams: id,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
