import {
  fetcher, isSearchGeneratorOk, FetcherConfig, SearchGeneratorInterface,
} from '@utils/data-fetching';
import {
  JobSearchGroup, JobSearchData, JobSearchTwoLines, JobSearchTwoLinesGroup,
} from '@type/job-search';


/**
 * @description Fetch search data from the API. It returns an array of objects with the following structure:
 * ```ts
 * [
 *  { category: 'Jobs' },
 *  { suggestion: 'job title 1' },
 *  { suggestion: 'job title 2' },
 *  { category: 'Employers' },
 *  { suggestion: 'employer name 1' },
 * ]
 * ```
 * @param keyword - search keyword: job title or employer name (string)
 * @param titleJobs - title for the job titles category (string)
 * @param titleEmployers - title for the employer names category (string)
 */
export const fetchSearchData = async (keyword: string, titleJobs: string, titleEmployers: string): Promise<JobSearchData> => {
  // url for fetching
  const url = 'https://www.bestjobs.eu/complete/api/as/v1/engines/bestjobs/query_suggestion';

  // Build query for fetching
  const buildQuery = (size: number, keyType: 'title' | 'employer_name') => ({
    query: keyword,
    size,
    types: { documents: { fields: [keyType] } },
  });

  // Common config options for fetching
  const commonConfig: FetcherConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    stringifyPayload: true,
  };

  // Fetcher for job titles
  const fetchJobTitles = fetcher<SearchGeneratorInterface>(url, 'ro', {
    ...commonConfig,
    payload: buildQuery(6, 'title'),
  });

  // Fetcher for employer names
  const fetchEmployerNames = fetcher<SearchGeneratorInterface>(url, 'ro', {
    ...commonConfig,
    payload: buildQuery(1, 'employer_name'),
  });

  // Fetch both job titles and employer names
  const [jobTitles, employerNames] = await Promise.all([fetchJobTitles, fetchEmployerNames]);


  // Handle response data
  // ***************************************************
  let jobTitlesGroup: JobSearchGroup = [];
  let employerNamesGroup: JobSearchGroup = [];

  let jobsTwoLines: JobSearchTwoLines[] = [];
  let employersTwoLines: JobSearchTwoLines[] = [];

  // Build job titles group
  if (isSearchGeneratorOk(jobTitles) && jobTitles.results.documents.length > 0) {
    jobTitlesGroup = [
      { category: titleJobs },
      ...jobTitles.results.documents,
    ];

    jobsTwoLines = (jobTitles.results.documents).map((item) => ({
      title: item.suggestion,
      subtitle: titleJobs,
      type: 'job',
    }));
  }

  // Build employer names group
  if (isSearchGeneratorOk(employerNames) && employerNames.results.documents.length > 0) {
    employerNamesGroup = [
      { category: titleEmployers },
      ...employerNames.results.documents,
    ];

    employersTwoLines = (employerNames.results.documents).map((item) => ({
      title: item.suggestion,
      subtitle: titleEmployers,
      type: 'employer',
    }));
  }

  // Return both job titles and employer names
  return {
    keyword,
    // to render this list use the Combobox 'Categorii' example from combobox.page.tsx
    jobSearchGroup: [...jobTitlesGroup, ...employerNamesGroup],
    // to render this list use the Combobox 'Fără filtrare / Objects list' example from combobox.page.tsx
    jobSearchTwoLines: [...jobsTwoLines, ...employersTwoLines],
  };
};


// Export types
// ***************************************************
export type {
  JobSearchGroup, JobSearchData, JobSearchTwoLines, JobSearchTwoLinesGroup,
};
