import { RecordInterface } from '@type/form-types';
import { useTranslation } from 'next-i18next';
import { clsx } from 'clsx';
import { GlobalFilterObject } from '@type/filters';
import { Input } from '../Input/Input';
import { Label } from '../Label/Label';
import { RadioText } from '../RadioText/RadioText';


// Interface
// ************************************************************************************
interface GenerateFieldsProps {
  recordFunc: RecordInterface,
  submitFunc?: () => void,
  filterObject: GlobalFilterObject,
  spacing?: string,
  customApiParameter?: string,
  idPrefix?: string,
  labelStyles?: string;
  inputStyles?: string;
}


/**
 * @description Generates the fields for the filter object or some other place.
 * * **recordFunc** - the 'record' function from useBjForm.
 * * **submitFunc** - the function to trigger the form submit. You might want to trigger it on click. Defaults to undefined.
 * * **filterObject** - the filter object to use. Take a look at the filterObjects.ts file for more information.
 * * **spacing** - the vertical spacing between the items. Defaults to 'my-1.5'.
 * * **customApiParameter** - the api parameter name to use for the field name. Sometimes the endpoints have different names
 * for the same thing, so this might help... Defaults to the field.name or filterObject.apiParameter.
 * * **idPrefix** - the prefix for the form elements ids. When using multiple forms on the same page,
 * this is needed to avoid duplicate ids.
 * * **labelStyles** - custom classes to be added to the label element.
 * * **inputStyles** - custom classes to be added to the input element.
 */
export const GenerateFields = (props: GenerateFieldsProps) => {
  // Destructure props
  const {
    recordFunc,
    submitFunc,
    filterObject,
    spacing = 'my-1.5',
    customApiParameter,
    idPrefix,
    labelStyles = '',
    inputStyles = '',
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Figure the field label style by type
  const fieldStyle = filterObject.groupType === 'radio' || filterObject.groupType === 'checkbox' ? 'radioType' : 'textType';

  // Render components
  return (
    <>
      {
        filterObject.fields.map((field, index) => {
          // Field name and id
          const fieldName = customApiParameter || field?.name || filterObject.apiParameter;
          const fieldBackupId = `${idPrefix || ''}${fieldName}-${index}`;
          const fieldId = field?.recordOptions?.id ? `${idPrefix || ''}${field?.recordOptions?.id}` : fieldBackupId;
          const fieldType = field?.type || filterObject.groupType;

          // Mutate the recordOptions object to add the id field
          const mutatedRecordOptions = field?.recordOptions?.id
            ? field.recordOptions
            : { ...field.recordOptions, id: fieldId };

          // Render the field
          return (
            <Label
              key={fieldId}
              htmlFor={fieldId}
              defaultMargin={false}
              className={clsx('flex cursor-pointer items-center', spacing, labelStyles)}
              {...fieldStyle !== 'radioType' && field?.label ? { description: t(field.label) } : {}}
              {...fieldStyle !== 'radioType' && field?.labelHelper ? { description: t(field.labelHelper) } : {}}
            >
              <Input
                className={inputStyles}
                type={fieldType}
                {...(submitFunc ? { onClick: submitFunc } : {})}
                {...recordFunc(fieldName, mutatedRecordOptions)}
              />
              {fieldStyle === 'radioType' && (
                <RadioText className="pt-px">{t(field.label)}</RadioText>
              )}
            </Label>
          );
        })
      }
    </>
  );
};
