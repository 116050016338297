// Imports
// ********************************************
import { hasCookie, getCookie } from 'cookies-next';
import { store } from 'src/app';


// Get the rm_pbl cookie name from .env
// ********************************************
export const rmCookie = process.env.NEXT_PUBLIC_RM_COOKIE || '';
export const rmCookieLegacy = process.env.NEXT_PUBLIC_RM_COOKIE_LEGACY || '';


/**
 * @description Check if we have to revalidate (re-fetch) AUTH on tab refocus. We directly check
 * for the cookie here instead of relying on hooks în order to avoid stale data on tab refocus checks.
 */
export const revalidateAuthUser = (): boolean => {
  const isAuth = hasCookie(rmCookie) || hasCookie(rmCookieLegacy);
  const getAuthDate = (getCookie(rmCookie) || getCookie(rmCookieLegacy)) as string;

  // Redux states
  // this is the way to get the redux data inside a function (not a hook or component)
  const reduxAuthDate = store.getState().user.authDate;
  const reduxHeaderInfo = store.getState().user.headerInfo;

  // Conditions for headerInfo revalidation
  // 1. isAuth - check if we have the rm_pbl cookie
  // 2. cookieAuthDate !== reduxAuthDate - check if the cookie auth date is different from the redux auth date
  // 3. !reduxHeaderInfo - check if we already have the header info in redux state
  return isAuth && (getAuthDate !== reduxAuthDate || !reduxHeaderInfo);
};
