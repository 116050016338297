import { StarIcon } from '@heroicons/react/24/solid';
import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';
import { StartHalfIconSolidIcon } from '@components/common/Icons';
import { cn } from '@utils/cn';

interface IRatingScore {
  score: number;
  showScore: boolean;
  className?: string;
  scoreClassName?: string;
  numberOfRatings?: number;
}

/**
 * @description Shows rating with stars
 *
 * e.g. <RatingScore score={4.5} showScore={true} className="text-warning w-4-h-4" />
 *
 * @param score
 * @param showScore - show score number near stars
 * @param numberOfRatings - show score number near stars
 * @param className - custom class for stars - set color, width, height, etc.
 * @param scoreClassName - custom class for score - set color, font-size etc.
 */
export const RatingScore = ({
  score, showScore, className, scoreClassName, numberOfRatings,
}: IRatingScore) => {
  // Stars icon list based on score
  const stars = useMemo(() => [...Array(5).keys()].map((item) => {
    const safeClassName = className || 'w-4 h-4 text-ink-light';

    if ((item + 1) <= Math.round(score)) {
      return <StarIcon key={item} className={safeClassName} />;
    }
    if ((item + 1) > Math.ceil(score)) {
      return <StarOutlineIcon key={item} className={safeClassName} />;
    }
    return <StartHalfIconSolidIcon key={item} className={`${safeClassName}`} />;
  }), [score, className]);

  return (
    <div className="flex items-center">
      {stars}
      {
        showScore && (
          <span className={cn('ml-2 text-sm text-ink-medium', scoreClassName)}>
            {score.toFixed(2)}
            {numberOfRatings && (
            <>
              {' /'}
              {numberOfRatings}
            </>
            )}
          </span>
        )
      }
    </div>
  );
};
