import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  isValidElement,
  cloneElement,
  ElementType,
  MouseEvent,
  useCallback, ReactNode, PropsWithChildren,
} from 'react';
import { XMarkIcon, CheckIcon } from '@heroicons/react/16/solid';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { cn } from '@utils/cn';
import { composeChipClasses } from './chipStyles';


// Interface
// *****************************************
export interface ChipBaseProps {
  as?: ElementType,
  disabled?: boolean,
  highlighted?: boolean,
  selected?: boolean,
  icon?: ReactElement<{ className?: string }>,
  onClick?: (event: MouseEvent) => void,
  onDelete?: (event: MouseEvent) => void,
  className?: string,
  children?: ReactNode,
  withDropdownArrow?: boolean,
  isDropdownOpen?: boolean,
  dropdownArrowPosition?: 'left' | 'right',
}

// Chip component props type, extending the base props with native element attributes.
// **************************************
export type ChipProps = PropsWithChildren<ChipBaseProps> & ComponentPropsWithoutRef<keyof JSX.IntrinsicElements>;

/**
 * @description Chip component that can display text, an optional icon, and an optional delete button.
 * It can be rendered as any HTML element or custom component.
 *
 * - **as** - ElementType. The component or HTML element to be used as the root node. Can be an HTML element name like 'div' or 'button', or a custom component.
 * - **disabled** - boolean. If `true`, the chip is disabled and cannot be interacted with.
 * - **highlighted** - boolean. If `true`, the chip will appear as if it were hovered. Useful for visual feedback.
 * - **selected** - boolean. If `true`, the chip will appear as selected.
 * - **icon** - ReactElement<{ className?: string }>. An optional icon element to be displayed inside the chip.
 * - **onClick** - (event: MouseEvent) => void>. Callback function fired when the chip is clicked. If provided, the chip will be styles as a clickable element.
 * - **onDelete** - (event: MouseEvent) => void>. Callback function fired when the delete button is clicked. If provided, a delete button will be shown inside the chip.
 * - **className** - string. Additional class names to apply to the chip. These classes will be merged with the default classes.
 * - **children** - string. Additional class names to apply to the chip. These classes will be merged with the default classes.
 * - **withDropdownArrow** - boolean. If `true`, displays a ChevronDownIcon inside the chip to indicate that Chip is a trigger for a dropdown element.
 * - **isDropdownOpen** - boolean. boolean. If `true`, rotates the ChevronDownIcon to indicate a dropdown or expandable state.
 *
 * @example
 * // Simple usage
 * <Chip>Chip text</Chip>
 *
 * @example
 * // Chip with an icon and delete button
 * <Chip icon={<SomeIcon />} onDelete={() => console.log('Deleted!')}>
 *   Chip with icon and delete
 * </Chip>
 */

export const Chip = forwardRef<HTMLElement, ChipProps>((props, ref) => {
  // Destructure props
  const {
    as,
    disabled = false,
    highlighted = false,
    selected = false,
    icon: iconProp,
    onClick,
    onDelete,
    className = '',
    children = 'Chip text',
    withDropdownArrow,
    isDropdownOpen,
    dropdownArrowPosition = 'right',
    ...restProps
  } = props;

  // Helper variables
  // **************************************
  const isClickable = !!onClick;
  const finalClasses = composeChipClasses(isClickable, disabled, highlighted, selected, !!onDelete, className);
  const isButton = as === 'button' || (isClickable && !onDelete);

  // Conditionally choose the Root component: if it’s not a button, and clickable, use a focusable div
  const Component = as || (isButton ? 'button' : 'div');

  // The icon element, ensure it receives the proper classes.
  let icon = null;
  if (iconProp && isValidElement(iconProp)) {
    icon = cloneElement(iconProp, {
      className: cn('size-4', iconProp.props.className),
    });
  }

  // Handle delete button click
  // **************************************
  const handleDeleteButtonClick = useCallback((event: MouseEvent) => {
    // Stop the event from bubbling up to the `Chip`
    event.stopPropagation();
    if (onDelete) {
      onDelete(event);
    }
  }, [onDelete]);

  // Render component
  // **************************************
  return (
    <div className="relative">
      <Component
        ref={ref}
        className={finalClasses}
        tabIndex={!isButton && isClickable ? 0 : undefined} // Makes the element focusable if clickable
        onClick={onClick}
        {...(isButton ? { type: 'button' } : {})} // Adds type="button" if it's a button
        {...(disabled ? { disabled: true } : {})}
        {...restProps}
      >
        {selected && (
          <CheckIcon className="-ml-1.5 size-4 text-secondary" />
        )}
        { icon }
        <span className={cn('flex items-center', { 'order-1': dropdownArrowPosition === 'left' })}>
          { children }
        </span>

        {/* Conditionally render ChevronDownIcon if `withDropdownArrow` is true */}
        {withDropdownArrow && (
          <ChevronDownIcon
            className={cn(
              'size-5 transition-transform ease-in-out',
              { 'rotate-180': isDropdownOpen },
              { 'order-0 -ml-2 -mr-1': dropdownArrowPosition === 'left' },
              { '-ml-1 -mr-2': dropdownArrowPosition === 'right' },
            )}
            aria-hidden="true"
          />
        )}
      </Component>
      {onDelete && (
        <button
          type="button"
          className="absolute right-2 top-1/2 flex size-5.5 -translate-y-1/2 items-center justify-center rounded-full bg-surface-100 text-ink hover:bg-surface-200 focus:outline-none focus:ring-2 focus:ring-primary/20"
          onClick={handleDeleteButtonClick}
          aria-label="Remove"
        >
          <XMarkIcon className="size-4" />
        </button>
      )}
    </div>
  );
});
