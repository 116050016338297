type Timer = ReturnType<typeof setTimeout>;

/**
 * @description Debounces a function with a specific delay
 *
 * @param func
 * @param delay
 */
export const debounce = <Func extends (...args: Parameters<Func>) => ReturnType<Func>>(
  func: Func,
  delay = 500,
) => {
  let timer: Timer;

  return (...args: Parameters<Func>) => {
    const newTimer = setTimeout(() => {
      void func(...args);
    }, delay);
    clearTimeout(timer);
    timer = newTimer;
  };
};

