import { FilterMainLinks, GetSwitchLink } from '@type/header-types';
import { removeDocumentBodyOverflow, setDocumentBodyOverflow } from '@utils/document/document';


/**
 * @description This function is used to set the top of the mobile menu. The height of the mobile menu is calculated
 * by subtracting the offset from viewport height. The TailwindCSS class is 'h-mobile-menu'.
 *
 * **IMPORTANT!** Considering that we're never going to have more than one header and one mobile menu, in this
 * particular case we can safely use getElementById instead of React refs.
 *
 * In this way, for example, we can use the function when closing a banner in another component with the mobile
 * menu opened, without getting into a ref's hell.
 */
export const setMenuTopOffset = () => {
  const $header = document.getElementById('header');
  const $root = document.documentElement;
  const $mainMenuNav = document.getElementById('menuOffsetSentinel');

  const headerOffset = $header?.getBoundingClientRect().top || 0;
  const mobileMenuOffset = $mainMenuNav?.offsetTop || 0;
  const totalOffset = headerOffset + mobileMenuOffset;

  // add the css variable to the header; it will be used to calculate the height of the mobile menu in the 'h-mobile-menu' class
  $root?.style.setProperty('--bj-mobile-menu-offset', `${String(totalOffset)}px`);
};


/**
 * @description Filter main links based on user authentication and role.
 * @param role - user role if authenticated (company or user)
 */
export const filterMainLinks: FilterMainLinks = (role) => ((link) => {
  const { forRole } = link;

  // if user is not authenticated
  if (!role && forRole?.includes('NONE')) return true;

  // if user is authenticated
  if (role) {
    // if role is company
    if (role === 'COMPANY' && forRole?.includes('COMPANY')) return true;
    // if role is user
    if (role !== 'COMPANY' && forRole?.includes('USER')) return true;
  }

  return false;
});


/**
 * @description Get the account type switch link based on user authentication and role.
 * @param obj - switch link object with links for not authenticated, authenticated user and authenticated company
 * @param role - user role if authenticated (company or user)
 */
export const getSwitchLink: GetSwitchLink = (obj, role) => {
  const { notAuth, authUser, authCompany } = obj;

  // if user is authenticated
  if (role) {
    if (role === 'COMPANY') return authCompany;
    if (role === 'USER') return authUser;
  }

  return notAuth;
};


/**
 * @description Remove the overflow classes from the body.
 */
export const removeBodyOverflow = () => {
  removeDocumentBodyOverflow('mobile-menu');
};

/**
 * @description Remove the overflow classes from the body on menu close.
 */
export const closeMenu = () => {
  removeBodyOverflow();
};


/**
 * @description Toggle the mobile menu body styles.
 * @param status
 */
export const toggleMenu = (status = false) => {
  if (status) {
    setMenuTopOffset();
    setDocumentBodyOverflow('mobile-menu');
  } else {
    closeMenu();
  }
};
