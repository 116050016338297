import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useCallback, useState } from 'react';
import { fetcher } from '@utils/data-fetching';

const AI_URL = process.env.NEXT_PUBLIC_AI_URL;

export interface Message {
  id: string;
  type: string;
  content: string;
  name?: string;
}

export interface MessageMetadata {
  run_id?: string;
}

export interface MessageHistoryResponse {
  messages: Message[];
  created_at: string;
}

export interface StreamState {
  status: 'inflight' | 'error' | 'done';
  messages?: Message[];
  run_id?: string;
}

export interface StreamStateProps {
  stream: StreamState | null;
  startStream: (
    input: Message,
    thread_id: string,
    config?: Record<string, unknown>,
  ) => Promise<void>;
  stopStream?: (clear?: boolean) => void;
  initializeWithHistory: (
    name: string,
    job?: string,
  ) => Promise<MessageHistoryResponse>;
}

export function mergeMessagesById(
  left: Message[] | Record<string, Message[]> | null | undefined,
  right: Message[] | Record<string, Message[]> | null | undefined,
): Message[] {
  const leftMessages = Array.isArray(left) ? left : left?.messages;
  const rightMessages = Array.isArray(right) ? right : right?.messages;

  const merged = (leftMessages ?? [])?.slice();
  (rightMessages || []).forEach((msg) => {
    const foundIdx = merged.findIndex((m) => m.id === msg.id);
    if (foundIdx === -1) {
      merged.push(msg);
    } else {
      merged[foundIdx] = msg;
    }
  });

  return merged;
}

export function useStreamState(api: string): StreamStateProps {
  const [current, setCurrent] = useState<StreamState | null>(null);
  const [controller, setController] = useState<AbortController | null>(null);

  const fetchHistory = useCallback(async (name: string, job?: string) => {
    try {
      const response = await fetcher<MessageHistoryResponse>(
        `${AI_URL}/history/v2/${name}${job ? `?job=${job}` : ''}`,
        undefined,
      );
      if (!response) {
        throw new Error('Failed to fetch history');
      }

      const historyMessages: Message[] = response.messages;
      setCurrent((prevCurrent) => ({
        status: prevCurrent?.status || 'done',
        messages: mergeMessagesById(prevCurrent?.messages, historyMessages),
        run_id: prevCurrent?.run_id,
      }));

      return response;
    } catch (error) {
      console.error('Error fetching history', error);
      throw error;
    }
  }, []);

  const startStream = useCallback(
    async (
      input: Message,
      name: string,
      config?: {
        headers?: Record<string, string>;
        files?: File[];
        configurations?: Record<string, string>;
      },
    ) => {
      const abortController = new AbortController();
      setController(abortController);

      const inputMessages: Message[] = [];
      if (input) {
        if (Array.isArray(input)) {
          inputMessages.push(...input);
        } else {
          inputMessages.push(input);
        }
      }

      setCurrent((prevCurrent) => ({
        status: 'inflight',
        messages: [...(prevCurrent?.messages || []), ...inputMessages],
        run_id: prevCurrent?.run_id,
      }));

      const formData = new FormData();
      formData.append('input', input.content);

      if (config?.files) {
        config.files.forEach((file) => {
          formData.append('files', file);
        });
      }
      if (config?.configurations) {
        formData.append(
          'configurations',
          JSON.stringify(config.configurations),
        );
      }

      await fetchEventSource(`${api}/${name}`, {
        signal: abortController.signal,
        method: 'POST',
        credentials: 'include',
        body: formData,
        openWhenHidden: true,
        onopen: async (response) => {
          if (
            response.ok
            && response.headers.get('content-type') === 'text/event-stream'
          ) {
            // Connection established
          } else if (response.status === 402) {
            throw new Error('Subscription required');
          } else if (
            response.status >= 400
            && response.status < 500
            && response.status !== 429
          ) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        },
        onmessage(msg) {
          if (msg.event === 'data') {
            const messages: Message[] | Record<string, Message[]> = JSON.parse(
              msg.data,
            ) as Message[] | { messages: Message[] };

            setCurrent((prevCurrent) => ({
              status: 'inflight',
              messages: mergeMessagesById(prevCurrent?.messages, messages),
              run_id: prevCurrent?.run_id,
            }));
          } else if (msg.event === 'metadata') {
            const metadata: MessageMetadata = JSON.parse(
              msg.data,
            ) as MessageMetadata;

            setCurrent((prevCurrent) => ({
              status: 'inflight',
              messages: prevCurrent?.messages,
              run_id: metadata.run_id || prevCurrent?.run_id,
            }));
          } else if (msg.event === 'error') {
            setCurrent((prevCurrent) => ({
              status: 'error',
              messages: prevCurrent?.messages,
              run_id: prevCurrent?.run_id,
            }));
          }
        },
        onclose() {
          setCurrent((prevCurrent) => ({
            status:
              prevCurrent?.status === 'error' ? prevCurrent.status : 'done',
            messages: prevCurrent?.messages,
            run_id: prevCurrent?.run_id,
          }));
          setController(null);
        },
        onerror(error) {
          setCurrent((prevCurrent) => ({
            status: 'error',
            messages: prevCurrent?.messages,
            run_id: prevCurrent?.run_id,
          }));
          setController(null);
          throw error;
        },
      });
    },
    [api],
  );

  const stopStream = useCallback(
    (clear: boolean = false) => {
      controller?.abort();
      setController(null);
      if (clear) {
        setCurrent((prevCurrent) => ({
          status: 'done',
          run_id: prevCurrent?.run_id,
        }));
      } else {
        setCurrent((prevCurrent) => ({
          status: 'done',
          messages: prevCurrent?.messages,
          run_id: prevCurrent?.run_id,
        }));
      }
    },
    [controller],
  );

  return {
    startStream,
    stopStream,
    stream: current,
    initializeWithHistory: fetchHistory, // Include the new method
  };
}
