import { Listbox as HeadlessListbox, ListboxProps } from '@headlessui/react';
import { ListboxStyledRootOptions } from './ListboxOptions';


// ROOT COMPONENT
// Change the default component tag from "Fragment" to "div".
// *******************************************************************************************
const ListboxStyledRoot = <TType, TActualType>(props: ListboxProps<'div', TType, TActualType>) => (
  <HeadlessListbox as="div" className="relative" {...props} />
);


// EXPORT COMPONENT WITH DOT NOTATION
// look at the types in library here: node_modules/@headlessui/react/dist/components/listbox/listbox.d.ts
// *******************************************************************************************
/**
 * @description Customized Listbox component. It has a default Options component that is customized
 * with animations, positions and UI styles. This component is extended from the HeadlessUI Listbox,
 * so it has all the same props and types (and some extras as previously said).
 *
 * If you need to show the listbox in a parent with overflow: hidden, or you want to detect the screen margins and flip
 * the listbox panel position, you can use the HeadlessFloat component. Refer to Headless UI Float documentation for more
 * info here: https://headlessui-float.vercel.app/react/quick-start.html.
 *
 * @example
 * // object with the data for the listbox
 * const people = [
 *   { name: 'Wade Cooper' },
 *   { name: 'Arlene Mccoy' },
 *   { name: 'Devon Webb' },
 *   { name: 'Tom Cook' },
 *   { name: 'Tanya Fox' },
 *   { name: 'Hellen Schmidt' },
 * ];
 *
 * // state for the selected item
 * const [selected, setSelected] = useState(people[0]);
 *
 * // Listbox component
 * <ListboxStyled value={selected} onChange={setSelected}>
 *
 *   // Toggle: model for ListboxStyled.Button using the prop "as" to render our own component
 *   <ListboxStyled.Button as={Button} styling="text" color="primary" className="outline-0">
 *      {selected.name}
 *   </ListboxStyled.Button>
 *
 *   // Options: this is the customized ListboxStyled.Options component. Extra props (from the original
 *   // HeadlessUI ListboxStyled.Options component) are: "animation", "position" and "ui".
 *   // Using ui="none" will reset all the classes from the component, you will have to add your own.
 *   // we inferred the types from original-component
 *   // we use the "value" prop to set the selected item
 *   <ListboxStyled.Options className="min-w-48" animation="slideDown">
 *      {people.map((person) => (
 *         <ListboxStyled.Option key={person.name} value={person}>
 *            {({ active }) => (
 *              <div className={`py-2 px-3 ${active ? 'bg-surface-100' : ''}`}>
 *                 {person.name}
 *              </div>
 *            )}
 *         </ListboxStyled.Option>
 *       )}
 *  </ListboxStyled.Options>
 *
 * </ListboxStyled>
 *
 *
 * // If you need to show the listbox in a parent with overflow: hidden, or you want to detect the screen margins and flip
 * // the listbox panel position, you can use the HeadlessFloat component.
 * <ListboxStyled value={selected} onChange={setSelected}>
 *   <HeadlessFloat animation="slideDown" strategy="fixed">
 *
 *     <ListboxStyled.Button as={Button} styling="text" color="primary" className="outline-0">
 *       {selected.name}
 *     </ListboxStyled.Button>
 *
 *     <ListboxStyled.Options position="float" ui="float" className="min-w-48">
 *       {people.map((person) => (
 *         <ListboxStyled.Option key={person.name} value={person}>
 *            {({ active }) => (
 *              <div className={`py-2 px-3 ${active ? 'bg-surface-100' : ''}`}>
 *                 {person.name}
 *              </div>
 *            )}
 *         </ListboxStyled.Option>
 *       )}
 *     </ListboxStyled.Options>
 *
 *   </HeadlessFloat>
 * </ListboxStyled>
 */
export const ListboxStyled = Object.assign(ListboxStyledRoot, {
  Button: HeadlessListbox.Button,
  Label: HeadlessListbox.Label,
  Options: ListboxStyledRootOptions,
  Option: HeadlessListbox.Option,
});
