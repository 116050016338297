// RESUME DELETE COMMENT ******************************************************************************
// pathname: /v1/resume-operation/${commentId}/comment
// ****************************************************************************************************
import { useSwrMutationApi, ApiResumeDeleteCommentPayload } from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: DELETE comment from resume. Response status ok: 200.
 * @param locale - string. The locale to use for the request.
 * @param commentId - number. The comment id to delete.
 * @param successCallback - function. The callback to call on success. For example, re-fetch comments (mutate function).
 */
export const useSwrResumeDeleteComment = (locale: string, commentId: number, successCallback?: () => void) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, ApiResumeDeleteCommentPayload>({
    url: `/v1/resume-operation/${commentId}/comment`,
    locale,
    fetcherConfig: {
      method: 'DELETE',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
