// DELTE SUBSCRIPTION CLOSE (delete request) ******************************************************
// pathname: /v1/subscription/close-request
// ************************************************************************************************
import {
  useSwrMutationApi,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: delete subscription close request. Used to discard a close request for
 * premium subscription. Since we're not sending any payload, we're using undefined as the type for
 * the payload.
 * @param locale
 * @param successCallback
 */
export const useSwrDeleteCloseSubscription = (locale = 'ro', successCallback?: () => void) => {
// SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, undefined>({
    url: '/v1/subscription/close-request',
    locale,
    fetcherConfig: {
      method: 'DELETE',
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) {
          void successCallback();
        }
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
