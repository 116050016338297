import { MutateSiblingsOptions } from '@type/swr';
import {
  Middleware, unstable_serialize, useSWRConfig, Cache,
} from 'swr';
import { useCallback } from 'react';
import { arrayRemoveItems } from '@utils/arrays/arrayRemoveItems';


/**
 * @description Helper function used to return the array of cache keys that match the current endpoint regardless of the
 * query parameters or locale.
 *
 * @param cache - the SWR cache Map.
 * @param cacheKey - the cache key to search for. Use unstable_serialize to serialize the key, before passing it
 *
 * @example
 * const { cache } = useSWRConfig();
 * const serializedKey = unstable_serialize(key);
 * const endpointKeys = findEndpointCacheKeys(cache, serializedKey);
 *
 * // if the serialized key is '@"/v3/job-name/applicants?page=NaN&limit=24","ro",#,' the function will return
 * // ['@"/v3//job-name/applicants?limit=24","ro",#', '@"/v3/job-name/applicants?sort=true","ro",#'] etc.
 */
const findEndpointCacheKeys = (cache: Cache, cacheKey: string) => {
  const apiURL = cacheKey.split('?')[0];
  return [...cache.keys()].filter((key) => key.startsWith(apiURL));
};


/**
 * @description Helper function used to return the array of matching cache keys. You provide the current cache and the
 * cache key, and it will return all matching cache keys. For infinite lists, it will also return all cache keys that match
 * the key but have a different page number.
 *
 * @param cache - the SWR cache Map.
 * @param cacheKey - the cache key to search for. Use unstable_serialize to serialize the key, before passing it
 * to this function.
 *
 * @example
 * const { cache } = useSWRConfig();
 * const serializedKey = unstable_serialize(key);
 * const matchingKeys = findMatchingCacheKeys(cache, serializedKey);
 *
 * // if the serialized key is '@"/v3/job-name/applicants?page=NaN&limit=24","ro",#,' the function will return
 * // ['@"/v3//job-name/applicants?page=1&limit=24","ro",#', '@"/v3/job-name/applicants?page=2&limit=24","ro",#'] etc.
 * // but something like '@"/v3/job-name/applicants?page=1&limit=24&sort=true","ro",#' will not.
 */
const findMatchingCacheKeys = (cache: Cache, cacheKey: string) => {
  const escapedSearchString = cacheKey.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // If for whatever reason, you pass a key with the actual page number (page=1) use replace(/page=\d+/, 'page=\\d+') instead
  const pattern = new RegExp(escapedSearchString.replace('NaN', '\\d+'));
  return [...cache.keys()].filter((key) => pattern.test(key));
};


/**
 * @description SWR Middleware that exports a function that mutates the sibling queries from SWR cache. It will mutate
 * all sibling queries that do not match the current query. We define siblings as all cache keys that match the current
 * API URL but have different query parameters.
 *
 * For infinite lists, it will not clear the cache keys that match the current query but have a different page number.
 */
export const mutateEndpointSiblings = (): Middleware => (useSWRNext) => (key, fetcher, config) => {
  // Actual SWR hook.
  const swr = useSWRNext(key, fetcher, config);
  const { data: swrData } = swr;

  // Access the SWR cache.
  const { cache, mutate } = useSWRConfig();


  /**
   * @description Mutate the sibling queries from the SWR cache. We define siblings as all cache keys that match the
   * current API URL but have different query parameters. Keep in mind that the revalidation of the siblings data
   * (network request) will happen only when that sibling is accessed again.
   *
   * **Important: For infinite lists, it will not clear the cache keys that match the current query but have a different
   * page number.**
   *
   * Props:
   * - **paramsCompareFn** - function. Compare function to filter the siblings by query params; optional.
   * - **dataCompareFn** - function. Compare function to filter the siblings by sibling key data; optional.
   * - **revalidate** - boolean. If you want to revalidate the siblings after mutating the cache. Default false.
   *
   * @example
   * // mutate all siblings
   * mutateSiblings();
   *
   * // Usage with a compare function. It will mutate the siblings that match the compare function. Same rules for infinite lists apply.
   * mutateSiblings({
   *  paramsCompareFn: (key) => key.includes('sort=true'), // => /v2/job-name?page=1&sort=true, /v2/job-name?page=2&sort=true
   * });
   *
   * // Usage with a compare function and a data compare function. It will mutate the siblings that match the compare function and
   * // the data compare function. Same rules for infinite lists apply. In the example below, it will mutate only the sibling with the
   * // page number 2 and the sort query parameter.
   * mutateSiblings({
   *   paramsCompareFn: (key) => key.includes('sort=true'), // => /v2/job-name?page=1&sort=true, /v2/job-name?page=2&sort=true
   *   dataCompareFn: (data) => data?.page === '2', => /v2/job-name?page=2&sort=true
   *   revalidate: true,
   * });
   */
  const mutateSiblings = useCallback((props?: MutateSiblingsOptions<typeof swrData>) => {
    // Destructure the props.
    const {
      paramsCompareFn,
      dataCompareFn,
      revalidate = false,
    } = props || {};

    // Serialize the current key.
    const serializedKey = unstable_serialize(key);

    // Find all required cache keys.
    const endpointKeys = findEndpointCacheKeys(cache, serializedKey) || [];
    const matchingKeys = findMatchingCacheKeys(cache, serializedKey) || [];
    let siblingKeys: string[] = [];

    // Filter the sibling keys by URL params. If no compare function is provided, return all siblings.
    if (endpointKeys.length > 0 && matchingKeys.length > 0) {
      if (paramsCompareFn) {
        siblingKeys = endpointKeys.filter(paramsCompareFn);
      } else {
        siblingKeys = arrayRemoveItems(endpointKeys, matchingKeys);
      }
    }

    // Filter the remaining sibling keys by data contained in each of the siblings.
    // This allows you to filter the siblings with finer granularity.
    if (dataCompareFn && siblingKeys.length > 0) {
      const filteredSiblings: string[] = [];

      siblingKeys.forEach((siblingKey) => {
        const keyData = cache.get(siblingKey)?.data as typeof swrData | undefined;
        if (keyData && dataCompareFn(keyData)) filteredSiblings.push(siblingKey);
      });

      siblingKeys = filteredSiblings;
    }

    // Mutate the matching keys.
    if (siblingKeys.length > 0) {
      siblingKeys.forEach((siblingKey) => {
        void mutate(siblingKey, undefined, { revalidate });
      });
    }
  }, [cache, key, mutate]);


  // Return data
  // ********************************************
  return {
    ...swr,
    mutateSiblings,
  };
};
