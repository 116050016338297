import { ApiSubscriptionPackageOk } from '@type/v1-api-types';
import { Trans } from 'next-i18next';


/**
 * @description Function that returns the section param for the upgrade packages request (useSwrUpgradePackages),
 * ie: 'freemium_year' or 'all-inclusive_year' to be used together with the month/year switcher.
 * @param currentPackage
 */
export const sectionParams = (currentPackage?: ApiSubscriptionPackageOk) => {
  if (!currentPackage || currentPackage.period === 'year') return '';
  return (currentPackage.type === 'freemium' || currentPackage.type === 'all-inclusive') ? `${currentPackage.type}_year` : '';
};


/**
 * @description Custom success message for Upgrade Packages Payment Status.
 * @param packageName - The name of the purchased package.
 */
export const SuccessMessage = (packageName: string) => (
  <Trans
    i18nKey="payment.success.upgrade-package"
    values={{ service: packageName }}
    components={{ tag: <strong className="whitespace-nowrap" /> }}
  />
);


/**
 * @description Get the subscription status for the subscription card. Used for the subscription card styles.
 * @param subscriptionData - The subscription data object.
 */
export const getSubscriptionStatus = (subscriptionData: ApiSchema<'SubscriptionPackageModel'> | undefined): 'isCurrent' | 'isUpgrade' | 'isNotAvailable' => {
  if (subscriptionData?.current) return 'isCurrent';
  if (subscriptionData?.available && !subscriptionData?.current) return 'isUpgrade';
  return 'isNotAvailable';
};


/**
 * @description Select a subscription from the list of subscriptions based on the following criteria:
 * current, selected, recommended, available with price 300, available.
 *
 * @param subscriptions - The array of subscriptions.
 */
export const getInitialSelectedSubscription = (subscriptions: ApiSchema<'SubscriptionPackageModel'>[] | undefined) => {
  // If no subscriptions, early return
  if (!subscriptions) return undefined;

  // Try to find a subscription that is current, selected or recommended (marked)
  const markedSubscription = subscriptions.find((subscription) => subscription.current
    || subscription?.selected
    || subscription?.recommended);

  // If not found, try to find a subscription that is available and has price 300
  const priceAvailableSubscription = subscriptions.find((subscription) => subscription?.price === 300 && subscription?.available);

  // If not found, try to find a subscription that is available
  const fallbackSubscription = subscriptions.find((subscription) => subscription?.available);

  // Return the first truthy value
  return markedSubscription || priceAvailableSubscription || fallbackSubscription;
};


/**
 * @description Get the subscriptions context for the subscriptions modal. Used for getting the correct
 * action button label. The context is 'default' for the default case, 'upgrade' for the upgrade case
 * and 'freeTrial' for the free trial case.
 * @param subscription
 */
export const getSubscriptionsContext = (subscription: ApiSubscriptionPackageOk | undefined): 'default' | 'upgrade' | 'freeTrial' => {
  if (subscription?.availableFreeTrialPackage) return 'freeTrial';
  if (subscription?.available && !subscription?.current) return 'upgrade';
  return 'default';
};
