'use client';

import { useEffect, useState } from 'react';
import { StarIcon } from '@heroicons/react/24/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import { generateNumbersArray } from '@utils/arrays/generateNumbersArray';
import { cn } from '@utils/cn';

// Interface
// ***********************************
interface RatingWidgetProps {
  maxRating?: number,
  defaultValue?: number,
  size?: string,
  disabled?: boolean,
  className?: string,
  starClassName?: string,
  emptyStarClassName?: string,
  onChange?: (newValue: number) => void,
}

/**
 * @description  Rating widget component.
 * It supports customization for star count, size, styling, and provides a callback function for changes in rating value.
 *
 * - **maxRating** - number. The maximum possible rating value, representing the total number of stars in the widget (default 5).
 * - **defaultValue** - number. The default selected rating value.
 * - **size** - string. Size of the star icons (e.g., `size-6`, `size-8`).
 * - **disabled** - boolean. If true, disables interaction with the widget.
 * - **className** - string. Additional class names for the root container.
 * - **starClassName** - string. Additional class names for filled stars.
 * - **emptyStarClassName** - string. Additional class names for empty stars.
 * - **onChange** - function. Callback function invoked when the rating changes.
 *
 * @example
 * <RatingWidget
 *   defaultValue={talentRating}
 *   className="justify-between border border-dashed bg-surface p-3"
 *   onChange={(newValue) => onChangeStars(newValue)}
 *   disabled={isMutating}
 * />
 */
export const RatingWidget = (props: RatingWidgetProps) => {
  // Destructure props
  const {
    maxRating = 5,
    defaultValue,
    disabled,
    className,
    size = 'size-6',
    starClassName,
    emptyStarClassName,
    onChange,
  } = props;

  const ratingsArray = generateNumbersArray(1, maxRating);

  // State
  const [currentRating, setCurrentRating] = useState<number | undefined>(undefined);
  const [hoveredRating, setHoveredRating] = useState<number | undefined>(undefined);

  /**
   * Synchronizes the internal state `currentRating` with the `defaultValue` prop.
   *
   * This effect ensures that whenever the `defaultValue` prop changes,
   * the component updates its internal state (`currentRating`) accordingly.
   */
  useEffect(() => {
    setCurrentRating(defaultValue);
  }, [defaultValue]);

  // Render component
  // *****************************************************
  return (
    <div className={cn('flex items-center w-full gap-2', className)}>
      {ratingsArray.map((rating) => {
        const isActive = hoveredRating ? rating <= hoveredRating : rating <= (currentRating ?? 0);
        const Icon = isActive ? StarIcon : StarIconOutline;

        return (
          <span
            key={rating}
            role="presentation"
            className={cn(
              'cursor-pointer transition',
              disabled && 'pointer-events-none opacity-50',
              hoveredRating === rating && 'scale-[1.15]',
            )}
            onClick={() => {
              setCurrentRating(rating);
              if (onChange) onChange(rating);
            }}
            onMouseEnter={() => setHoveredRating(rating)}
            onMouseLeave={() => setHoveredRating(undefined)}
          >
            <Icon
              className={cn(
                size,
                isActive ? ['fill-warning', starClassName] : ['text-warning', emptyStarClassName],
              )}
            />
          </span>
        );
      })}
    </div>
  );
};
