import { unstable_serialize, Middleware } from 'swr';
import { useEffect, useRef } from 'react';


/**
 * @description SWR Middleware that populates the cache with initial data from a server-side request. This means that we
 * can prevent the initial request.
 *
 * @param keyOnMount - the initial key that the hook uses on mounting.
 * @returns the SWR hook with the server side data if available.
 *
 * @example
 * // Usage in middlewares for SWR:
 * serverData(keyOnMount),
 */
export const serverData = (keyOnMount: string): Middleware => (useSWRNext) => (key, fetcher, config) => {
  // Actual SWR hook.
  const swr = useSWRNext(key, fetcher, config);

  // Data from the server
  const serverSideData = config?.fallbackData;

  // Get the cache key
  const currentSeriesKey = unstable_serialize(key);

  // Store status if we saved the data to cache. We use a simple boolean as long as we can get away without comparing the data.
  const savedData = useRef<boolean>(false);


  // Write the server data in SWR cache for current key if we didn't already.
  // ****************************************
  // If in some cases we will discover that we're not able to use different server side data in multiple instances of the same
  // hook, we will have to compare the data. We can use the 'compare' function from SWR internal (just replace with the
  // following code in the proper places):
  //
  // import { compare } from 'swr/_internal';
  // const savedData = useRef<Data | undefined>(undefined);
  // ... && (!savedData?.current || compare(savedData, serverSideData)) {... savedData.current = serverSideData;}
  // ****************************************
  useEffect(() => {
    if (serverSideData && currentSeriesKey.includes(keyOnMount) && !savedData?.current) {
      // Flag that we saved the data to cache
      savedData.current = true;

      // Write the data in the cache without triggering a revalidation (no extra fetch)
      void swr.mutate(serverSideData, {
        populateCache: true,
        revalidate: false,
      });
    }
  }, [serverSideData, swr, currentSeriesKey]);


  // Return data
  // ********************************************
  return useSWRNext(key, fetcher, {
    ...config,
    // if the data is already saved in the cache, we reset the fallback data
    fallbackData: savedData.current ? undefined : serverSideData,
  });
};
