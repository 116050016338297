// IMPORTS ****************************************************************************************
// ************************************************************************************************
import {
  AgentData, CvDetailApplicationDetails, CvDetailAvailableOperations, CvDetailInterest,
  CvDetailLeadExperience, CvDetailStudies, IVideoUrl,
  PrivateInformation,
} from '@components/templates/cv/interface/CvDetailInterface';
import { JobMatch } from '@type/v1-api-types/talent-search';
import { GenericV1ErrorsArray } from './_global';

// SUGGESTED SALARY *******************************************************************************
// pathname: /v1/resume-operation/resume/suggested-salary
// ************************************************************************************************

/**
 * @description Interfaces for
 * /v1/resume-operation/resume/suggested-salary
 */


// interface for /v1/resume-operation/resume/suggested-salary response
export interface ApiSuggestedSalaryOk {
  salarySuggestion: {
    salary: number;
    min: number;
    max: number;
    minSalaryLimit: number;
    occupation: string | null;
    location: string | null;
  },
  suggestionStatistic: {
    items: {
      locationLabel: string;
      jobNumber: number;
      isRemote: boolean;
      salarySuggestion: {
        salary: number;
        min: number;
        max: number;
        minSalaryLimit: number;
      }
    }[];
    salaryIntervalList: {
      min: number;
      max: number;
      value: number;
    }[];
    salaryEvolutionPercentage: number;
  };
  benefits: {
    id: number;
    typeId: number;
    name: string;
    type: string;
  }[];
  result?: {
    id: string;
    input: ISuggestedSalaryPayload;
    url: string;
    privateUrl: string;
    ownerNameLabel?: string;
  }
}

// interface for /v1/resume-operation/resume/suggested-salary payload
export interface ISuggestedSalaryPayload {
  experienceSave: boolean;
  experienceWrite: {
    title: string;
    location: string;
    predictedGroup: string | null;
    salary: {
      data: {
        amount: number;
        currency: string;
        confidential: boolean;
      }
    }
  }
}

export type ApiSuggestedSalary = ApiSuggestedSalaryOk | GenericV1ErrorsArray;


// CHECK UNLOCK CV *******************************************************************************
// pathname: /v1/resume-operation/{userId}/check-unlock/{status}
// pathname: /v1/resume-operation/{userId}/{type}
// ************************************************************************************************

export interface ApiCheckUnlockOk {
  jobId: number,
  jobSlug: string,
  title: string,
  meta?: {
    consumptionInfoSummary?: string,
  }
}

export interface ApiCheckUnlockPriceInfoOk {
  is_unlock: boolean,
}

export type ApiCheckUnlock = ApiCheckUnlockOk | GenericV1ErrorsArray;

// CHECK UNLOCK CV *******************************************************************************
// pathname: /v1/resume-operation/{userId}/check-unlock/{status}
// pathname: /v1/resume-operation/{userId}/{type}
// ************************************************************************************************

export interface ApiCheckUnlockPayOk {
  id: string;
}

export type ApiCheckUnlockPay = ApiCheckUnlockPayOk | GenericV1ErrorsArray;


// RESUME DETAIL *******************************************************************************
// pathname: /v2/job/${jobSlug}/user-resume-detail/${slug}
// pathname: /v2/user-resume-detail/${slug}
// ************************************************************************************************

export interface ApiResumeDetailOk {
  shortDescriptionTranslated: boolean;
  accessToPrivateInformation:boolean;
  isOwnCV:boolean;
  blurred?: boolean;
  errors:[];
  agent: AgentData;
  id?:number;
  online:boolean;
  hasUnreadMessage:boolean;
  translatedDescription:boolean;
  imgUrl?: string;
  privateInformation?: PrivateInformation;
  location?: string;
  age?: number;
  expectedSalary?: number;
  salaryCurrency?: string;
  gender?: string;
  skills?: [];
  skillsWithHighlight: [];
  cvCompletion?: number;
  experiences?: [];
  preferences?: [];
  leadExperience?: CvDetailLeadExperience;
  studies?: [];
  availableOperations: CvDetailAvailableOperations[];
  shortDescription?:string;
  selfInterview?:[];
  recruitmentDomains?:[];
  coachingAreas?:[];
  applications?:[];
  comments?:[];
  locked?:boolean;
  application?: CvDetailApplicationDetails;
  activityStatus?: string;
  interest?: CvDetailInterest;
  completionPercentagePerLocale?:[],
  slug:string,
  hiredConfirm:boolean,
  jobMatches?: JobMatch[],
  citiesOfInterest?: string[]
}

export type ApiResumeDetail = ApiResumeDetailOk | GenericV1ErrorsArray;


// RESUME SIMILAR CANDIDATES *******************************************************************************
// pathname: /v1/${slug}/similar-users
// ************************************************************************************************

export interface SimilarCandidate {
  leadExperience: CvDetailLeadExperience;
  studies: CvDetailStudies[];
  id :number;
  activityStatus :string;
  imgUrl:string;
  gender:string;
  age:string;
  location:string;
  slug:string;
  cvCompletion:number;
  privateInformation:IVideoUrl;
}
export type ApiSimilarCandidateOk = SimilarCandidate[];
export type ApiSimilarCandidate = ApiSimilarCandidateOk | GenericV1ErrorsArray;


// GET RESUME VIDEO URL *******************************************************************************
// pathname: /v1/resume-operation/{userId}/video-url
// ****************************************************************************************************
export type ApiResumeVideoUrlOk = string;
export type ApiResumeVideoUrl = ApiResumeVideoUrlOk | GenericV1ErrorsArray;


// POST RESUME ADD COMMENT ****************************************************************************
// pathname: /v1/resume-operation/${userId}/add-comment
// ****************************************************************************************************
export interface ApiResumeAddEditCommentPayload {
  jobId?: number;
  jobSlug?: string;
  comment: string;
}

export interface ApiResumeDeleteCommentPayload {
  data?: number;
}

// POST RESUME EVALUATE ****************************************************************************
// pathname: /v1/resume-operation/${userId}/evaluate
// ****************************************************************************************************
export interface ApiResumeEvaluatePayload {
  jobId?: number;
  jobSlug?: string;
}

// RESUME OPERATION JOB *******************************************************************************
// ****************************************************************************************************
export interface ApiResumeOperationJobPayload {
  jobId?: number;
  jobSlug?: string;
  title?: string;
}

// RESUME CHECK UNLOCK MESSAGE ************************************************************************
// pathname: /v1/resume-operation/{userId}/check-unlock/message
// ****************************************************************************************************
export type ApiResumeCheckUnlockPayload = ApiResumeOperationJobPayload;

export enum ApplicantStatusEnum {
  NEW = 1,
  VIEWED,
  INTERVIEW,
  REMOVED,
  SHORTLIST = 21,
  HIRED = 22,
}

// GET OPERATION JOB **********************************************************************************
// pathname: /v1/resume-operation/get-operation-job
// ****************************************************************************************************
export interface ApiGetOperationJobOk {
  jobId: number,
  jobSlug: string,
  title: string,
}
