import {
  useState, useEffect, useMemo, createRef, useCallback,
} from 'react';
import {
  FormElementRefImperative,
  StoredElementsInterface,
  GenericStoredValuesInterface,
  ElementsRefsInterface, RecordInterface,
} from 'src/types/form-types';


// form record
/**
 * @description Core custom hook for {@link useBjForm}. It creates two state objects witch allows us to handle the form.
 *
 * * {@link storedElements} state stores all the data of the **'recorded'** form fields, ie: name, id, defaultValue, etc.,
 * which we can access at our convenience.
 * * {@link elementsRefs} state stores all the form fields **refs based on ID**, which allows us to use the internal methods of
 * the components build using the {@link useSingleFormElement} custom hook
 * * **record** - A core function that builds an object to be spread as Element's props.
 * ***updateFormElements** - A trigger to force the update the stored form elements. Use it with Popovers, Dropdowns, etc. when you need to update the form fields
 * on panel mounting.
 *
 * @param storedDefaultValues - defaultValues as object only (ie: getServerSideProps), not values from API call (ie: fetcher get)
 */
export const useRecordForm = (storedDefaultValues: GenericStoredValuesInterface | null) => {
  // We want a value that doesn't change between renders
  const storedElementsBase: StoredElementsInterface[] = useMemo(() => [], []);

  /**
   * @description Create a Record with all the 'recorded' form fields
   */
  const [storedElements, setStoredElements] = useState<StoredElementsInterface[]>([]);

  // Create a trigger to FORCE the update the storedElements array
  // Use it with Popovers, Dropdowns, etc. when you need to update the form fields.
  const [updateStoredElements, setUpdateStoredElements] = useState<boolean>(false);
  const updateFormElements = useCallback(() => setUpdateStoredElements((current) => !current), []);

  /**
   * @description Create refs as objects using field's id as key
   */
  const elementsRefs: ElementsRefsInterface = useMemo(() => Object.fromEntries(storedElements.map(
    (entry) => [entry.id, createRef<FormElementRefImperative>()],
  )), [storedElements]);


  // Update the form Elements names array
  // storedElementsBase.length is needed to update the storedElements state when we mount new form fields
  useEffect(() => {
    const newNames = [...storedElementsBase];
    setStoredElements(newNames);
  }, [storedElementsBase, storedElementsBase.length, updateStoredElements]);


  /**
   * @description Build an object to be spread as Element's props. A core function of the {@link useBjForm} Library.
   *
   * * **name** - first parameter - the form field's name (string). The field's **id** will also be created using the name, unless you pass it as an option.
   * **Important:** Radio groups need to have different id's, even if they share the same name
   * * **props** - second parameter - the form field's options (object).
   *
   * ---------------------------
   *
   * @description **Props** (all are optional):
   * * **id** - string - usually the id is the same as the name, but sometimes you might need something else
   * * **dataTestId** - string - id for testing purposes
   * * **valueAsArray** - boolean - if we want to send the value as an array (useful for checkboxes)
   * * **convertValue** - 'toString' | 'toBoolean' | 'toNumber' | 'commaStringToArray' - in case the API expects a specific type for value
   * * **convertEmptyValue** - 'toString' | 'toBoolean' | 'toNumber' | 'toNull' - in case the API expects a specific type for 'empty-sh' value
   * * **nullable** - boolean - if we want to delete the 'empty-sh' key/value pair from the FormData object. Sometimes this is the only way for the
   * server to not record a value on an optional field (ie: salary on experience v1). Use it on optional fields only.
   * * **removeFieldsIfThisEmpty** - string[] - when the field is empty, we also delete the fields from the dependents array, no matter if they are empty or not.
   * Good for conditional fields that are shown/hidden.
   * * **removeFieldsIfThisUnchecked** - string[] - (for radio and checkboxes only) when the field is unchecked, we also delete the fields from the dependents
   * array, no matter if they are empty or not. Good for conditional fields that are shown/hidden.
   * * **removeFromSubmit** - boolean - if we want to delete the field from the submitted data (use it on optional fields only). Good when you have helper
   * form fields that you don't want them to be added to the formData.
   * * **dontHardReset** - boolean - if we don't want to hard reset the field on form submit but instead keep the default value. Good when we have a mix of controlled
   * and uncontrolled fields in the same form, and we only want to reset the controlled fields (or some of the all fields).
   * * **filtersLabel** - string - the label of the field. Good for radio and checkboxes when you want to use readable labels when building filters for example.
   * * **defaultValue** - string | number | readonly string[] | undefined (HTML5 API standard) - React way to pass value to an uncontrolled field
   * * **defaultChecked** - boolean - React way set checked uncontrolled checkbox or radio fields
   *
   * ------------------------------
   *
   * @example
   * record('fieldName', {
   *    id: 'fieldId', // this is mostly for radio groups where you need different id's for elements sharing the same name
   *    convertValue: 'toString', // one of the options if BJ API needs a specific type to be sent
   *    convertEmptyValue: 'toString', // one of the options if BJ API needs a specific 'empty-sh' type to be sent
   *    nullable: true, // we delete the field from submitted data if empty (use it on optional fields only)
   *    removeFieldsIfThisEmpty: ['salary.currency', 'salary.confidential'], // we delete other dependency fields if the field from submitted data if empty (not required ofc)
   *    defaultValue: 'card', // pass a default value respecting HTML5 API types; BJ API doesn't follow the rules though
   *    defaultChecked?: true, // pass a default checked (because we're dealing with uncontrolled elements, we need to use defaultValue & defaultChecked - React)
   * });
   *
   * // Usage without extra props (you won't really need them very often):
   * <Input type="text" {...record('username')} />
   *
   * // Usage for radio groups
   * <Input type="radio" {...record('fruits', {id: 'fruitsBanana', defaultValue: 'banana', defaultChecked: true})} />
   * <Input type="radio" {...record('fruits', {id: 'fruitsApple', defaultValue: 'apple'})} />
   * <Input type="radio" {...record('fruits', {id: 'fruitsCherry', defaultValue: 'cherry'})} />
   *
   * // Usage for single checkbox on an Edit form that gets the default values via API Get (useGetFormValues custom hook)
   * <Input type="checkbox" {...record('confidentiality', { convertValue: 'toBoolean' })} />
   *
   * // Single checkbox that gets the defaultValue & defaultChecked via object (direct or getServerSideProps)
   * // Expected return values: true / false. For default value true it will auto-toggle checked
   * <Input type="checkbox" {...record('confidential', { convertValue: 'toBoolean' })} />
   *
   * // Deleting empty fields & dependent siblings option
   * <Input type="text" {...record('companyName')} />
   * <Input type="number" {...record('salary.amount', { nullable: true, removeFieldsIfThisEmpty: ['salary.currency', 'salary.confidential'] })} />
   * <Select {...record('salary.currency')} />
   *    <option ... />
   * </Select>
   * <Input type="checkbox" {...record('salary.confidential')} />
   *
   * // Output form data; we don't longer have {salary: {amount: ..., currency: ..., confidential: ...}}
   * // {
   * //   companyName: 'BestJobs'
   * // }
   *
   */
  const record: RecordInterface = useCallback((name, options = {}) => {
    // check if we already passed an id; if not create one
    const elemId = Object.hasOwn(options, 'id') ? options['id' as keyof typeof options] as string : name;

    // props to be spread on the element
    const recordedOptions: StoredElementsInterface = {
      name,
      id: elemId,
      ref: elementsRefs[elemId],
      ...options,
    };

    // store the Element; we don't want to store the same element twice, so we check if it's already there
    if (!storedElementsBase.find((elem) => elem.id === recordedOptions.id)) {
      storedElementsBase.push(recordedOptions);
    }

    // check if we're dealing with a checkbox multiple and if it needs to be turned as checked
    let checkedMultipleCheckbox = false;
    if (storedDefaultValues !== null && storedDefaultValues !== undefined && Array.isArray(storedDefaultValues?.[name])) {
      const arrayValues = storedDefaultValues?.[name] as [];
      arrayValues.forEach((elem) => {
        if (elem === options?.defaultValue) {
          checkedMultipleCheckbox = true;
        }
      });
    }

    // props to be spread on the element
    return {
      name: recordedOptions.name,
      id: recordedOptions.id,
      ref: recordedOptions.ref,

      // add the data-test-id for testing purposes; if not defined, use the id
      'data-test-id': recordedOptions.dataTestId ?? recordedOptions.id,

      // Add default values to be passed to the form elements using **withDefaultValues** in {@link useBjForm}.
      // This is the nicest way to pass the defaultValues as an object, either directly (dev mode test) or obtained using Next.js
      // getServerSideProps for example.

      // add the defaultValue if we have one in the record function in JSX
      ...(Object.hasOwn(options, 'defaultValue') && { defaultValue: options?.defaultValue }),

      // add default values if we have them from server (GET), and they are not defined in JSX
      // we add them here only if they are a custom build object (dev testing) or got them with getServerSideProps()
      // we don't overwrite the values for radio / checkboxes (set earlier in code)
      ...(!Object.hasOwn(options, 'defaultValue') && storedDefaultValues !== null && { defaultValue: storedDefaultValues?.[name] }),

      // check if the radio or checkbox are default checked in JSX
      // uncheck if we pass defaultChecked in record options, but we get defaultValue: false from server
      ...(Object.hasOwn(options, 'defaultChecked') && { defaultChecked: options?.defaultChecked && String(storedDefaultValues?.[name]) !== 'false' }),

      // add checked / unchecked if not defined in JSX, and we have values from server (GET)
      // first check: check the radio or checkbox if set from the server (by name) - ie: <input type="radio" defaultValue="card">
      // second check: if it is of boolean type, use that as a factor to toggle checked (we need to convert the value to string)
      // third check: for checkboxes, if we have a value that is equal to 'true' toggle checked
      // forth check: for checkboxes, if we have a value from an array (checkbox multiple)
      ...(
        !Object.hasOwn(options, 'defaultChecked')
        && storedDefaultValues !== null
        && {
          defaultChecked:
            options?.defaultValue === storedDefaultValues?.[name]
            || options?.defaultValue === String(storedDefaultValues?.[name])
            || ((options?.convertValue === 'toBoolean') && String(storedDefaultValues?.[name]) === 'true')
            || (Array.isArray(storedDefaultValues?.[name]) && checkedMultipleCheckbox),
        }
      ),
    };
  }, [elementsRefs, storedDefaultValues, storedElementsBase]);

  return {
    record, storedElements, elementsRefs, updateFormElements,
  };
};
