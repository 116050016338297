'use client';

import {
  ReactNode,
  useState,
  useEffect,
  createElement,
} from 'react';
import { createPortal } from 'react-dom';


export interface PortalInterface {
  children: ReactNode,
  wrapperId: string,
}


/**
 * @description Helper function to create an empty div with a given ID,
 * append it to the body, and return the element
 * @param wrapperId
 */
export const createAndAppendWrapper = (wrapperId: string): Element => {
  const wrapper = document.createElement('div');
  wrapper.setAttribute('id', wrapperId);
  document.body.appendChild(wrapper);
  return wrapper;
};


/**
 * @description Wrapper component that creates a Portal and renders content
 * in the provided container outside the default hierarchy
 * @param children
 * @param wrapperId
 */
export const Portal = ({ children, wrapperId = 'bj-portal' }: PortalInterface) => {
  const [wrapper, setWrapper] = useState<null | Element>(null);

  useEffect(() => {
    let element = document.getElementById(wrapperId) as Element;
    let elemCreated = false;

    // if  no element is found with wrapperId, create one and append it to body
    if (!element) {
      elemCreated = true;
      element = createAndAppendWrapper(wrapperId);
    }

    setWrapper(element);

    return () => {
      // delete the programmatically created element
      if (elemCreated && element.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  // wrapperElement state will be null on the very first render.
  if (wrapper === null) return null;

  return createPortal(createElement('div', null, children), wrapper);
};
