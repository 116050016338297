import { useRouter as useRouterPages } from 'next/compat/router';
import { useRouter as useRouterApp, useParams, usePathname } from 'next/navigation';
import { getLocaleFromQuery } from '@utils/gssp/gsspGetLocale';
import { i18nConfig } from 'i18n-config';
import { omitFromObject } from '@utils/objects';
import { setLocalePrefix } from '@services/i18nPaths/helpers_all';
import { useCallback, useMemo } from 'react';

/**
 * @description Extended Next.js useRouter options for push, replace and prefetch.
 */
interface ExtendedMethodOptions {
  withLocale?: boolean;
  forcedLocale?: Locale;
}

/**
 * @description Extended Next.js router hook for both `/pages` and `/app`. It extends the base app useRouter with
 * some of the props we need for quicker development, props that where present in the pages useRouter hook.
 *
 * **Return values**
 *
 * - `router`: object, the router object (from the [useRouter]{@link https://nextjs.org/docs/app/api-reference/functions/use-router}).
 * Check the 'router functions' section for a detailed list of functions.
 *
 * - `pathname`: string, the current pathname (from the [usePathname]{@link https://nextjs.org/docs/api-reference/next/router#usepathname})
 * - `params`: object, the dynamic route params (from the [useParams]{@link https://nextjs.org/docs/api-reference/next/router#useparams}).
 * Keep in mind that this includes the locale key, which might be unwanted in most use cases.
 * - `paramsNoLocale`: object, the dynamic route params from above but without the locale key
 *
 * - `locale`: string, the current locale ('ro', 'en' or 'hu')
 * - `locales`: string[], the available locales
 * - `defaultLocale`: string, the default locale ('ro')
 * - `withLocale`: function, a function to add the locale prefix to a path. It will add the locale prefix only if the
 * locale is not the default one. It will also handle the case when the default locale is prefixed or not.
 *
 * **router functions**
 * - `router.push`: function, navigate to a new page, adding an entry to the history stack
 * - `router.replace`: function, replace the current entry on the history stack
 * - `router.refresh`: function, reload the current page (equivalent to 'reload' from pages router)
 * - `router.back`: function, move back one entry in the history stack
 * - `router.forward`: function, move forward one entry in the history stack
 * - `router.prefetch`: function, prefetch the given page
 *
 * -----------
 * **Important:** after we migrate all the pages to '/app' folder we can safely remove the 'useRouter' hook needed for navigation
 * inside the '/pages' folder (`useRouterPages` import) since we would no longer need it. Also, do not include the
 * [useSearchParams]{@link https://nextjs.org/docs/app/api-reference/functions/use-search-params} hook, as we don't want
 * to wrap everything in a [Suspense]{@link https://nextjs.org/docs/app/api-reference/functions/use-search-params#static-rendering}
 * boundary in case we're using the hook in places that might use static rendering.
 *
 * @example
 * // Import the hook
 * import { useExtendedRouter } from '@hooks/next-routing-wrappers';
 *
 * // It is recommended to destructure the props for better readability, otherwise if you assign the hook
 * // to a variable, you might do something awkward like `router.router.push()`.
 * const {
 *    router,
 *    pathname,
 *    params,
 *    paramsNoLocale,
 *    locale,
 *    locales,
 *    defaultLocale,
 *    withLocale,
 * } = useExtendedRouter();
 *
 * // Use the router functions
 * router.push('/app/some-page');
 *
 *
 * // How to get the equivalent of 'asPath' variable from the pages router as there is
 * // no direct equivalent in the app router hook. For this ypu will also have to import
 * // the useExtendedSearchParams hook. Don't forget to wrap the consumer component in a
 * // Suspense boundary to be safe for static rendering (no need for dynamic rendering though).
 * // *********************************************************************
 * import { useExtendedRouter, useExtendedSearchParams } from '@hooks/next-routing-wrappers';
 *
 * // Destructure the hooks
 * const { pathname, withLocale } = useExtendedRouter();
 * const { searchParamsAsString } = useExtendedSearchParams();
 *
 * // Combine the pathname with the search params
 * const asPath = `${pathname}${searchParamsAsString}`;
 */
export const useExtendedRouter = () => {
  // Get the props from the old pages router. Account for null in /app
  const {
    query,
  } = useRouterPages() || {};

  // Get the props from the app router
  const routerApp = useRouterApp();

  // Get the params in /app
  const params = useParams();

  // Get the params without the locale
  const paramsNoLocale = omitFromObject(params || {}, ['locale']);

  // Get the pathname in /app
  const pathname = usePathname();

  // Locale for both /pages and /app
  const locale = getLocaleFromQuery(params || query);

  /**
   * @description Function to add the locale prefix to a path.
   * - `path`: string, the path to which we want to add the locale prefix
   * - `forcedLocale`: string, the locale to be used instead of the current one. Default is the current locale.
   */
  const withLocale = useCallback((path: string, forcedLocale?: Locale) => {
    const homepageAdjustedPath = path === '/' ? '' : path;
    return `${setLocalePrefix(forcedLocale || locale, homepageAdjustedPath)}${homepageAdjustedPath}`;
  }, [locale]);

  /**
   * @description Localized router. It will add the locale prefix to the path if needed. Forced locale is optional for
   * cases when we want to also change the locale. The *ExtendedMethodOptions* options for push, replace and prefetch are:
   * - `withLocale`: boolean, if false, the locale prefix will not be added to the path. Default is true.
   * - `forcedLocale`: string, the locale to be used instead of the current one. Default is the current locale.
   *
   * @example
   * // Push to a new page with the current locale
   * router.push('/app/some-page');
   *
   * // Push to a new page with a different locale
   * router.push('/app/some-page', { forcedLocale: 'en' });
   *
   * // Push to a new page without the locale prefix
   * router.push('/app/some-page', { withLocale: false });
   */
  const localizedRouter = useMemo(() => {
    const wrapLocale = <NavOptions, Fn extends (href: string, options?: NavOptions) => void>
      (func: Fn) => (href: string, options?: NavOptions & ExtendedMethodOptions) => {
        const transformedHref = options?.withLocale === false ? href : withLocale(href, options?.forcedLocale || locale);
        return func(transformedHref, options);
      };

    return {
      ...routerApp,
      push: wrapLocale<Parameters<typeof routerApp.push>[1], typeof routerApp.push>(routerApp.push.bind(routerApp)),
      replace: wrapLocale<Parameters<typeof routerApp.replace>[1], typeof routerApp.replace>(routerApp.replace.bind(routerApp)),
      prefetch: wrapLocale<Parameters<typeof routerApp.prefetch>[1], typeof routerApp.prefetch>(routerApp.prefetch.bind(routerApp)),
    };
  }, [routerApp, withLocale, locale]);


  // Return compatible in both '/app' and '/pages'
  // ***************************************
  return {
    router: localizedRouter,
    pathname,
    params,
    paramsNoLocale,
    locale,
    locales: i18nConfig.locales,
    defaultLocale: i18nConfig.defaultLocale,
    withLocale,
  };
};
