// Base i18n paths
export { i18nBasePaths } from './paths';
// Middleware
export { checkI18nPath, checkDefaultLocaleRedirect, checkDefaultLocaleRewrite } from './helpers_middleware';
// Switcher
export { getSwitcherPaths } from './helpers_switcher';
// Links
export { getI18nLinkHref } from './helpers_links';
// Canonical URL
export { getCanonicalUrl } from './helpers_canonical';
