/**
 * Determines whether a given pathname belongs to the "hire" section of the website.
 * The "hire" section contains routes related to company.
 *
 * @param {string | null} pathname - The full pathname to check.
 * @returns {boolean} - `true` if the pathname matches a valid "hire" route; otherwise, `false`.
 */
export const isHireRoute = (pathname: string | null): boolean => {
  // Return false if pathname is null or empty
  if (!pathname) {
    return false;
  }

  // Define static routes
  const staticRoutes = ['/company/offer', '/cv/search', '/company/edit', '/offer/configurator', '/premium', 'landing/matching'];

  // Define dynamic route pattern for job-related hire pages
  const jobHirePattern = /^\/job\/[^/]+\/(applicants|candidates|viewers|users-who-favorite-my-job)$/;

  const jobFolderCvPattern = /^\/job\/[^/]+\/cv\/[^/]+$/;
  const landingMatchingPattern = /^\/landing\/matching(\/[a-f0-9-]+)?$/;

  // Check if pathname contains any of the staticRoutes as a substring
  const isInStaticRoutes = staticRoutes.some((route) => pathname.includes(route));

  return isInStaticRoutes || jobHirePattern.test(pathname) || jobFolderCvPattern.test(pathname) || landingMatchingPattern.test(pathname);
};
