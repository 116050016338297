import { IncomingHttpHeaders } from 'http';
import { isApiV1Errors } from 'src/type-predicates/v1-api-predicates';
import { GenericV1ErrorsArray } from '@type/v1-api-types';
import {
  baseURL, fetchAbort, fetcherErrorHandler, globalTimeout, isFullUrl,
} from './utils';


// INTERFACES *************************************************************************************
// ************************************************************************************************
export type FetcherMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';
type FetcherResponseTypes = 'blob' | 'json';

// Interface for the fetcher's config object
// Add extra types @payload if Eslint is complaining about it
export interface FetcherConfig {
  method?: FetcherMethods,
  payload?: BodyInit | object | [],
  // for SSR only, all headers from the browser request
  ssrClientHeaders?: IncomingHttpHeaders,
  stringifyPayload?: boolean,
  timeout?: number,
  responseType?: FetcherResponseTypes,
  headers?: Record<string, string>,
  externalAbort?: {
    extController: AbortController,
    extTimeout: NodeJS.Timeout,
  },
  withResponseHeaders?: boolean,
  cache?: RequestCache,
  next?: NextFetchRequestConfig,
}

// Interface for the fetcher's swr config object
export type FetcherSwrConfig = Omit<FetcherConfig, 'ssrClientHeaders' | 'externalAbort' | 'withResponseHeaders'>;

// Interface for fetcher's options
interface FetcherOptions extends RequestInit {
  credentials: RequestCredentials | undefined,
  method: FetcherMethods,
  signal: AbortSignal,
  headers: HeadersInit | undefined,
  body?: BodyInit,
}

// Interface for the fetcher's response with headers
export interface ResponseWithHeaders<T> {
  response: T,
  responseHeaders: Headers,
  status: number,
  ok: boolean,
  statusText: string,
}


// FETCHER ****************************************************************************************
// ************************************************************************************************
/**
 * @description Generic fetcher function for **client side** and **server side** requests. Can pass an interface for
 * expected response (the generic <T>), but you really should use type predicates.
 * Keep in mind that the base url (ex: https://example.com) is already included.
 *
 * ---------------------------
 * @description The config options are:
 * * **method** - the fetching method; default 'GET'.
 * * **payload** - the data to be sent to the server when doing a 'POST', 'PUT' or 'DELETE'.
 * * **stringifyPayload** - if we want to stringify the payload (JSON.stringify); default 'false'.
 * * **externalAbort** - when using inside useEffect we need to use an external AbortController in
 * order to do a proper cleanup (check examples).
 * * **headers** - extra headers to be sent with the request.
 * * **withResponseHeaders** - if we want to get the response headers as well; default 'false'.
 * * **ssrClientHeaders** - for SSR only, all headers from the browser request.
 * * **timeout** - customize the timeout for the request; default 1 minute.
 * * **responseType** - the response type 'blob' or 'json'; default 'json'.
 * * **cache** - the [cache option]{@link https://developer.mozilla.org/en-US/docs/Web/API/Request/cache} for the request; default 'default'.
 * * **next** - the Next.js options for the request [revalidate & tags]{@link https://nextjs.org/docs/app/api-reference/functions/fetch#optionsnextrevalidate};
 * default 'undefined'. Use it for SSR only. Keep in mind that the caching is done per endpoint not per user, so different users will get the same cached data.
 * Use this option for data that can be used globally, not user specific data.
 *
 * @param URL *string* - pathname without base url
 * @param locale *string* - the locale
 * @param config *object* - extra configs for fetcher, if needed
 *
 * @example
 * // Basic usage; remember that the base url (ex: https://example.com) is already included
 * fetcher('/v1/header-info', locale)
 *   .then((response) => {
 *     // whatever you want to do
 *     console.log(response); // JSON data parsed by `response.json()` call
 *   }
 * );
 *
 * // With TS interface for response (replacing the generic <T>)
 * fetcher<MyResponseInterface>('https:... )
 *
 * // Using a type predicate for handling the response (create one for your valid API response)
 * // as we already have one for response with errors (_global.ts).
 * .then((response) => {
 *    if (isApiV1Errors(response)) {
 *      const status = response.errors[0].code;
 *      showStatusToast(status.toString());
 *    }
 * })
 *
 * // Usage inside useEffect hook
 * useEffect(() => {
 *    // create the abort controller & timeoutId
 *    const { controller, timeoutId } = fetchAbort();
 *
 *    // do your stuff, pass the controller & timeoutId to some function or w/e
 *    fetcher(URL, locale, {externalAbort: {extController: controller, extTimeout: timeoutId}})
 *      .then((response) => {...});
 *
 *    // cleanup fetcher (fetcher will no longer get called twice in strict mode)
 *    return () => controller?.abort();
 * }, []);
 *
 * // Sending a POST request
 * fetcher('/v1/domain/detect', locale, {
 *   method: 'POST',
 *   payload: {
 *     title: 'Lorem ipsum',
 *     description: 'Sit dolor amet',
 *   },
 *   stringifyPayload: true, // check it without this first, just in case
 * });
 *
 *
 * // When using with the withResponseHeaders option you need to also import
 * // the ResponseWithHeaders interface to avoid any TS errors
 * // *************************************************************
 * fetcher<unknown>(apiURL, locale, { withResponseHeaders: true, responseType: 'blob' })
 *    .then((res) => {
 *      const { response, responseHeaders }: { response: BlobPart, responseHeaders: Headers } = res as ResponseWithHeaders<BlobPart>;
 *    })
 */
export const fetcher = async <T>(URL: string, locale: string | undefined, config: FetcherConfig = {}): Promise<T | void> => {
  // check if we have a custom timeout in config
  const timeout = config?.timeout || globalTimeout;

  // create internal AbortController
  // this is how we are able to pass a timeout to the Fetch API
  const { controller, timeoutId } = fetchAbort(timeout);

  // External (used for cleaning up in useEffect) or internal AbortController; default internal
  const signal = config?.externalAbort?.extController.signal || controller.signal;

  // method to be used; default 'GET'
  const method = config?.method || 'GET';

  // default headers
  const headers = {
    'Accept-Language': locale as string,
  } as Record<string, string>;

  // add SSR headers if we have them
  if (config.ssrClientHeaders) {
    // add user agent from client request
    if (config.ssrClientHeaders['user-agent']) {
      headers['user-agent'] = config.ssrClientHeaders['user-agent'];
    }
    // add cookie from client request
    headers.Cookie = config.ssrClientHeaders.cookie ?? '';
  }

  // fetch options
  // for content-type we let the request/response to intelligently determine the content type (doc)
  const fetcherOptions: FetcherOptions = {
    credentials: 'include',
    method,
    signal,
    headers: {
      ...headers,
      // add extra headers
      ...config.headers,
    },
    ...(config.cache && { cache: config.cache }),
    ...(config.next && { next: config.next }),
  };

  // Add payload (on demand)
  // stringify payload if stringifyPayload option true
  // default: do not stringify
  if (Object.hasOwn(config, 'payload')) {
    if (Object.hasOwn(config, 'stringifyPayload') && config.stringifyPayload === true) {
      fetcherOptions.body = JSON.stringify(config.payload);
    } else {
      fetcherOptions.body = config.payload as BodyInit;
    }
  }

  // the fetching code
  try {
    // handle a result; returns as generic <T>.
    const url = isFullUrl(URL) ? URL : `${baseURL}${URL}`;
    const response = await fetch(url, fetcherOptions);

    // if we want to also get the response headers
    if (config?.withResponseHeaders) {
      return {
        response: await response[config?.responseType ?? 'json']() as T,
        responseHeaders: response.headers,
        status: response.status,
        ok: response.ok,
        statusText: response.statusText,
      } as T;
    }

    return await response[config?.responseType ?? 'json']() as T;
  } catch (error) {
    // handle fetch errors (not response errors from server); returns as void.
    return fetcherErrorHandler(signal, error, URL);
  } finally {
    // clean up timeout
    clearTimeout(config?.externalAbort?.extTimeout || timeoutId);
  }
};


/**
 * @description Extended fetcher function with split response. Use it just like the {@link fetcher} function.
 * The difference is that this function returns an object with the data and the error, both correctly typed.
 *
 * **Important: Unlike the fetcher function, the interface should be the one for OK response.**
 *
 * @param URL - the URL to be fetched
 * @param locale - the locale
 * @param config - the fetcher config object
 *
 * @returns an object with the data (T) and the error (GenericV1ErrorsArray)
 *
 * @example
 * // Data will have the ApiV1UserOk interface
 * // Error will have the GenericV1ErrorsArray interface.
 * const { data, error } = await fetcherSplit<ApiV1UserOk>('/v1/user', locale);
 */
export const fetcherSplit = async <T = unknown>(URL: string, locale: string | undefined, config: FetcherConfig = {})
: Promise<{ data: T | undefined, error: GenericV1ErrorsArray | undefined }> => {
  const response = await fetcher<T>(URL, locale, config);
  const isDataOk = isApiV1Errors(response);

  return {
    data: isDataOk ? undefined : response as T,
    error: isDataOk ? response : undefined,
  };
};


/**
 * @description Base fetcher function for SWR library. This function is just a boilerplate, for
 * everyday use consider fetcherSWR. With SWR we can simplify the code a lot. We also use SWR to get data
 * only from our API, so no full url option.
 * @param url
 * @param locale
 * @param config
 * @param arg - used for useSWRMutation hook; it's the payload, but we need to treat it differently
 * from the payload in fetcherConfig in this case.
 */
export const fetcherSwrBase = async <T>(url: string, locale = 'ro', config: FetcherSwrConfig = {}, { arg }: { arg: unknown } = { arg: undefined }): Promise<T> => {
  // create internal AbortController
  const { controller, timeoutId } = fetchAbort(globalTimeout);

  // abort signal
  const { signal } = controller;

  // url for data fetching
  const fetchUrl = isFullUrl(url) ? url : `${baseURL}${url}`;

  // post data holder
  let postDataHolder: BodyInit | null | undefined;

  // fetching method; don't add body for GET requests
  const fetchMethod = config?.method || 'GET';

  // Add payload (on demand)
  // stringify payload if stringifyPayload option true
  // default: do not stringify
  if (Object.hasOwn(config, 'payload') || arg !== undefined) {
    if (Object.hasOwn(config, 'stringifyPayload') && config.stringifyPayload === true) {
      postDataHolder = JSON.stringify(config.payload || arg);
    } else {
      postDataHolder = (config.payload || arg) as BodyInit;
    }
  }

  // The catch phase will be handled by SWR, so we don't need to add a catch block here.
  try {
    const res = await fetch(fetchUrl, {
      method: fetchMethod,
      credentials: 'include',
      signal,
      headers: {
        'Accept-Language': locale,
        ...(config.headers && config.headers),
      },
      ...(postDataHolder && fetchMethod !== 'GET' && { body: postDataHolder }),
    });

    // The response is ok, so we return the data (the 'data' state)
    if (res.ok) {
      if (res.status === 204) return {} as T;
      return await res[config?.responseType ?? 'json']() as T;
    }

    // This is where SWR catches the error and sends it to the 'error' state.
    // This is the Bj error object, not the fetch error.
    throw await res[config?.responseType ?? 'json']();
  } finally {
    clearTimeout(timeoutId);
  }
};


/**
 * @description Main fetcher function for SWR library. This is the way we can pass locale to SWR,
 * since the fetcher only accepts one argument. For more info check
 * [SWR docs]{@link https://swr.vercel.app/docs/arguments#multiple-arguments}.
 * @param url - the url to be fetched (without base url)
 * @param locale - the locale in the format 'ro', 'en' or 'hu'
 * @param config - the fetcherSwrBase config object
 *
 * @example
 * // Usage with useSWR hook; don't forget to pass a default locale to fetcherSWR
 * const { data } = useSWR(['/api/user', locale || 'ro', fetcherConfig || {}], fetcherSWR, options);
 */
export const fetcherSWR = <T>([url, locale, fetcherConfig]: [string, string, FetcherSwrConfig]): Promise<T> | T => fetcherSwrBase(url, locale, fetcherConfig);


/**
 * @description Secondary fetcher function for SWR library. Use it with the useSWRMutation hook.
 * @param url - the url to be fetched (without base url)
 * @param locale - the locale in the format 'ro', 'en' or 'hu'
 * @param config - the fetcherSwrBase config object
 * @param arg - used for useSWRMutation hook; it's the payload, but we need to treat it differently
 *
 * @example
 * // Usage with useSWRMutation hook
 * const { mutate } = useSWRMutation<DataType, Error, SwrKey, ArgType>(() => ([fetchUrl, locale || 'ro', fetcherConfig || {}]), fetcherSWRMutation, apiOptions);
 */
export const fetcherSWRMutation = <T>(
  [url, locale, fetcherConfig]: [string, string, FetcherSwrConfig],
  { arg }: { arg: unknown },
): Promise<T> | T => fetcherSwrBase(url, locale, fetcherConfig, { arg });
