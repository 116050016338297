// SUBSCRIPTION ORDER *****************************************************************************
// pathname: /v1/subscription/order
// ************************************************************************************************
import {
  useSwrMutationApi,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: POST subscription order. Used when clicking on the 'Order subscription' button.
 * @param locale
 */
export const useSwrMutationSubscriptionOrder = (locale = 'ro') => {
  // SWR mutation: fetch order
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiSchema<'CartOrderResponse2'>, ApiSchema<'SubscriptionOrderRequest'>>({
    url: '/v1/subscription/order',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
