// MESSAGE SEND ************************************************************************
// pathname: /v1/messages/{slug}/send
// ****************************************************************************************************
import { useSwrMutationApi } from '@hooks/useSwrApi';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';

interface SwrMessageSendProps {
  slug: string;
  locale: string;
  successCallback?: () => void;
  errorCallback?: (errors: ApiSchema<'ErrorResponse'>) => void;
  trackingId?: string;
}

/**
 * @description SWR mutation: POST send a message in a conversation. Response status ok: 201.
 *
 * - **slug** (string): The slug of the conversation to which the message will be sent.
 * - **locale** (string, optional): The locale to use for the request. Defaults to `'ro'`.
 * - **successCallback** (function, optional): A callback function that is executed on success.
 * - **errorCallback** (function, optional): A callback function that is executed when an error occurs.
 * - **trackingId** (string, optional): The tracking id for the event.
 */
export const useSwrMessageSend = (props: SwrMessageSendProps) => {
  // Props destructuring
  // ********************************************
  const {
    slug,
    locale = 'ro',
    successCallback,
    errorCallback,
    trackingId,
  } = props;

  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, ApiSchema<'MessageWrite'>>({
    url: `/v1/messages/${slug}/send`,
    urlParams: trackingId ? `?rid=${trackingId}` : '',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        // Send Google Tag Manager event
        sendGoogleTagEvent('employer_cv_message_sent', 'Custom');
        // Callback
        if (successCallback) successCallback();
      },
      onError: (errors) => {
        // Callback
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
