import { useTranslation } from 'next-i18next';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FormErrorProps } from 'src/types/form-types';

/**
 * @description Shows form field error
 * @remarks Uses headless ui animations
 * @param errorMessage
 * @param isShowing
 */

export const FormError = ({ errorMessage, isShowing }: FormErrorProps) => {
  const { t } = useTranslation('common');

  return (
    <Transition
      as={Fragment}
      appear
      show={isShowing}
      enter="transition-all duration-300"
      enterFrom="opacity-0 -translate-y-1.5"
      enterTo="opacity-100 translate-y-0"
      leave="transition-all duration-300"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-1.5"
    >
      <span className="mt-1.5 flex text-error text-sm items-start order-last w-full">
        <span className="inline-block text-pico uppercase bg-error text-surface rounded leading-snug align-middle px-1 pt-px mr-1.5 mt-0.5">
          {t('msg-error')}
        </span>
        {errorMessage}
      </span>
    </Transition>
  );
};
