'use client';

import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import useResizeObserver from 'use-resize-observer';
import { Button } from '../Forms/Button/Button';

// Interface
// ***********************************
export interface AnimatedCollapsibleProps {
  renderContent: () => ReactNode,
  collapsedHeight: number,
  renderToggle?: (isExpanded: boolean, handleToggle: () => void) => ReactNode;
  duration?: number,
  easing?: string,
  isDefaultExpanded?: boolean,
  className?: string,
}

/**
 * @description Animated Collapsible component. It is designed to handle expand / collapse of the content with a smooth animation.
 * Note that the expand / collapse state is based on height (not characters).
 * Uses a ResizeObserver to show / hide the ShowMore button only if the content is overflowing the container.
 *
 * - **renderContent** - Render prop function. The content to be displayed, which will be animated between collapsed and expanded states. Required.
 * - **collapsedHeight** - number. The height (in pixels) of the content when collapsed.
 * - **renderToggle** - Render prop function. A custom render function for the toggle button. Receives `isExpanded` and `handleToggle` as arguments.
 * If not provided, a default button will be rendered.
 * - **duration** - number. The transition duration in milliseconds. Defaults to `300`.
 * - **easing** - string. The easing function for the transition. Defaults to 'cubic-bezier(0.4, 0, 0.2, 1)'.
 * - **isDefaultExpanded** - boolean. Determines if the content should be expanded by default. Defaults to `false`.
 * - **className** - string. Additional CSS class names to apply to the content container.
 *
 *
 * @example
 * <AnimatedCollapsible
 *    collapsedHeight={48}
 *    renderContent={() => (
 *      <div>
 *        <p>This is some expandable content. Click the button to see more or less.</p>
 *        <p>Additional content can go here, and will be shown or hidden based on the toggle state.</p>
 *      </div>
 *    )}
 * />
 *
 * // With custom toggle
 * // ************************************************************************************
 * <AnimatedCollapsible
 *    collapsedHeight={78}
 *    renderContent={() => (
 *        <SanitizeHTML
 *          as="div"
 *          htmlContent={employer.description}
 *          className="prose"
 *        />
 *    )}
 *    renderToggle={(isExpanded, handleToggle) => (
 *        <Button
 *          type="button"
 *          onClick={handleToggle}
 *        >
 *          {isExpanded ? (
 *            <>
 *              <ChevronUpIcon className="w-5 h-5 mr-1" />
 *              {t('global.less.about.company-name.react', { company_name: employer.employerName })}
 *            </>
 *          ) : (
 *            <>
 *              <ChevronDownIcon className="w-5 h-5 mr-1" />
 *              {t('global.more.about.company-name.react', { company_name: employer.employerName })}
 *            </>
 *          )}
 *       </Button>
 *    )}
 * />
 *
 */
export const AnimatedCollapsible = (props: AnimatedCollapsibleProps) => {
  // Destructure props
  const {
    renderContent,
    collapsedHeight,
    duration = 300,
    easing = 'cubic-bezier(0.4, 0, 0.2, 1)',
    isDefaultExpanded = false,
    className,
    renderToggle,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Use `useResizeObserver` hook to get a reference to the `div` element that wraps the content and the actual height of the content.
  // ************************************************************************************
  const { ref, height: contentHeight = 0 } = useResizeObserver<HTMLDivElement>();

  // State
  // ************************************************************************************
  // The currentHeight. It is actually the active state between the contentHeight and collapsedHeight
  const [currentHeight, setCurrentHeight] = useState(collapsedHeight);

  // Determines whether the content exceeds the maximum height.
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  // Controls whether the content is expanded.
  const [isExpanded, setIsExpanded] = useState<boolean>(isDefaultExpanded);

  // Watch content height changes
  // The useEffect hook keeps track of the content's height and sync it with the current height.
  // Also check whether content is overflowing the collapsed height.
  // ************************************************************************************
  useEffect(() => {
    if (isExpanded) {
      setCurrentHeight(contentHeight);
    }

    setIsOverflowing(contentHeight > collapsedHeight);
  }, [contentHeight, currentHeight, collapsedHeight, isExpanded]);

  // Handle Toggle
  // *****************************************************
  const handleToggle = () => {
    if (currentHeight === collapsedHeight) {
      setCurrentHeight(contentHeight);
      setIsExpanded(true);
    } else {
      setCurrentHeight(collapsedHeight);
      setIsExpanded(false);
    }
  };

  // Render component
  // *****************************************************
  return (
    <>
      <div
        className={className}
        style={{
          overflow: 'hidden',
          maxHeight: `${currentHeight}px`,
          transition: `max-height ${duration}ms ${easing}`,
        }}
      >
        <div ref={ref}>
          {/* The actual content that is collapsed / expanded */}
          {renderContent()}
        </div>
      </div>
      {isOverflowing && (
        renderToggle ? renderToggle(isExpanded, handleToggle) : (
          <Button styling="text" onClick={handleToggle}>
            {isExpanded ? t('global.label.see_less') : t('global.label.see_more')}
          </Button>
        )
      )}
    </>
  );
};
