import { useRef, useEffect } from 'react';
import { FiltersHubHookProps } from '@type/filters';
import { useBjForm } from '@hooks/useBjForm';
import { pickFromObject } from '@utils/objects/pickFromObject';
import { objectRemoveEmptyKeys } from '@utils/object-remove-empty-keys/object-remove-empty-keys';
import { sortObjectKeys } from '@utils/objects/sortObjectKeys';


/**
 * @description The filters hub hook. Used to handle the filters forms and mutate the query params accordingly. Can be
 * used for multiple forms when you want to compose different options that are placed in different parts of the UI.
 *
 * Props:
 * - **queryParams** - the query params for the filters.
 * - **setQueryParams** - the function to set the query params.
 * - **sortQueryParams** - if true, the query params will be sorted alphabetically. Default true. Needed to maintain the
 * order of the query params for SWR access keys.
 * - **externalFieldsList** - *string array* - the external fields to keep untouched by current form submit. Sometimes
 * we might have queryParams with more fields than the current form, and we want them to be kept when setting the query
 * params. For example, the **sort** field, that is part of the TopBar form but not of the Sidebar form. Usage: `externalFieldsList: ['sort', ...]`.
 * Take care to not dynamically change the fields, as they will not be updated in the hook for performance reasons.
 * - **internalFieldsList** - *string array* - when we want to mutate/handle only the fields from the current form and keep the rest
 * of the queryParams unchanged, because on form submit the params will be overridden, and also we want to avoid passing too many names
 * to the *externalFieldsList*. Usage: `internalFieldsList: ['keyword']`. Take care to not dynamically change the fields, as they
 * will not be updated in the hook for performance reasons.
 * - **protectFieldsOnMount** - *string array* - the fields that should be protected from the form submit only once. This is useful
 * when we want to keep the fields unchanged when the component mounts. Usage: `protectFieldsOnMount: ['similarSearch']`. For example,
 * when the sidebar filters are mounted, the form automatically submits for synchronization reasons.
 *
 * Returns:
 * - **formRef** - the form reference.
 * - **formStatus** - the form status. Contains the submitted form values and labels.
 * - **handleStep** - the function to handle the form steps; to be used in the form onSubmit.
 * - **record** - the BjForms record function.
 * - **setFormValues** - the function to set the form values.
 * - **updateFormElements** - the function to force the record on newly mounted form elements.
 * - **submitForm** - the function to submit the form.
 * - **hardResetForm** - the function to hard reset the form (even the default values).
 * - **talentSearchLabels** - the labels for the talent search filters.
 * - **resetAllFilters** - the function to reset all the filters.
 *
 * @example
 * const {
 *  formRef,
 *  formStatus,
 *  handleStep,
 *  record,
 *  setFormValues,
 * } = useFiltersHub({
 *  queryParams,
 *  setQueryParams,
 *  externalFieldsList: ['keyword', 'sort'],
 *  protectFieldsOnMount: ['similarSearch'],
 * });
 *
 * // Use the formRef in the form tag
 * <form noValidate onSubmit={handleStep} ref={formRef}>
 *   // Use the record function in the form elements
 *   <input {...record('name')} />
 * </form>
 *
 * // How to clear filters
 * <button type="button" onClick={
 *   () => {
 *     // this only clears the elements that exist in one of the filters forms (ie: the fields from sidebar filters - location, careerLevels, etc.)
 *     hardResetForm(formRef);
 *     setTimeout(() => submitForm(), 50);
 *     // add this if you want to clear all the filters from all the forms (ie: clears sidebar form, top bar form, keyword search, etc.)
 *     // this is useful when using the hook for multiple different forms that compose the filters
 *     setQueryParams({});
 *   }}
 * >Clear all filters</button>
 */
export const useFiltersHub = (props: FiltersHubHookProps) => {
  // Destructure props
  const {
    queryParams,
    setQueryParams,
    sortQueryParams = true,
    externalFieldsList,
    internalFieldsList,
    protectFieldsOnMount,
  } = props || {};


  // Helper refs & state
  // ***********************************************
  const formRef = useRef<HTMLFormElement | null>(null);
  const formIsUpdating = useRef(false);
  const settingFormValues = useRef(false);
  // We use a ref for two reasons: they should not change and prevent re-renders when updating the query params in useEffect
  const externalQueryFields = useRef(externalFieldsList);
  const externalMutateFields = useRef(internalFieldsList);
  const protectedFieldsOnMount = useRef(protectFieldsOnMount);


  // BJ FORM hook
  // ***********************************************
  const {
    handleStep,
    record,
    setFormValues,
    formStatus,
    submitTrigger,
    updateFormElements,
    hardResetForm,
    resetForm,
  } = useBjForm({ withFilterLabels: true });


  // Submit trigger helper
  // ***********************************************
  const submitForm = () => setTimeout(() => {
    submitTrigger(formRef);
  }, 50);


  // Talent Search forms active filters labels
  // ***********************************************
  const { filterLabels } = formStatus;

  const talentSearchLabels = {
    location: [filterLabels?.location, filterLabels?.radiusInKm],
    searchIn: [
      filterLabels?.nameSearch,
      filterLabels?.queryFields,
      filterLabels?.presentExperience,
    ],
    confineResults: [
      filterLabels?.searchInApplicants,
      filterLabels?.searchInSavedApplicants,
      filterLabels?.driversLicence,
      filterLabels?.recommendedByAgent,
      filterLabels?.excludeContacted,
      filterLabels?.passedThroughTheInterview,
    ],
    lastActivity: [filterLabels?.lastActivity],
    spokenLanguages: [filterLabels?.spokenLanguages],
    careerLevels: [filterLabels?.careerLevels],
    domains: [filterLabels?.domains],
    studyLevels: [
      filterLabels?.studyLevels,
      filterLabels?.studySpecialization,
      filterLabels?.graduateStart,
      filterLabels?.graduateEnd,
    ],
  };


  // 1. Set the form initially depending on the query params
  // ***********************************************
  useEffect(() => {
    // Update the form values depending on the query params when the component mounts (page load, etc.)
    // We need a bit of timeout in order to synchronize the HeaderSearchContext value with the query params (it will be stale otherwise).
    const formUpdater = setTimeout(() => {
      if (queryParams && !formIsUpdating.current && Object.keys(queryParams).length > 0) {
        formIsUpdating.current = true;
        setFormValues(queryParams);
        settingFormValues.current = true;
      }
    }, 50);

    // Because setState is delayed, we need to delay the form submit as well. We need to submit the form in order
    // to update the labels and the formStatus state.
    const formSubmitter = setTimeout(() => {
      if (settingFormValues.current) {
        submitTrigger(formRef);
        settingFormValues.current = false;
      }
    }, 100);

    // Hard reset the form if we have no query params and hardReset is true. Good for controlled inputs like the ones used
    // in the ListboxWrapper component.
    if (queryParams && !formIsUpdating.current && Object.keys(queryParams).length === 0) {
      hardResetForm(formRef);
    }

    // Cleanup
    const cleanupUpdater = setTimeout(() => {
      formIsUpdating.current = false;
    }, 150);

    return () => {
      clearTimeout(formUpdater);
      clearTimeout(formSubmitter);
      clearTimeout(cleanupUpdater);
    };
  }, [queryParams, setFormValues, submitTrigger, hardResetForm]);


  // 2. Update the query params depending on the form
  // ***********************************************
  useEffect(() => {
    if (!formIsUpdating.current && formStatus.form) {
      formIsUpdating.current = true;

      if (setQueryParams) {
        setQueryParams((prev) => {
          let externalParams = {};
          const newQueryParams = { ...formStatus.form };
          const onlyMutateFields = externalMutateFields?.current;

          // Keep the fields that are protected on mount. That means they are protected from the form submit only once.
          if (protectedFieldsOnMount?.current) {
            externalParams = {
              ...externalParams,
              ...objectRemoveEmptyKeys(pickFromObject(prev, protectedFieldsOnMount.current as never[])),
            };
            protectedFieldsOnMount.current = undefined;
          }

          // Keep the fields that are part of the keepFields prop
          if (externalQueryFields?.current) {
            externalParams = {
              ...externalParams,
              ...objectRemoveEmptyKeys(pickFromObject(prev, externalQueryFields.current as never[])),
            };
          }

          // Mutate the current form fields but keep the rest unchanged (internalFieldsList prop)
          if (onlyMutateFields) {
            Object.keys(prev).forEach((key) => {
              if (onlyMutateFields.includes(key)) {
                delete prev[key as keyof typeof prev];
              }
            });
            externalParams = { ...prev, ...externalParams };
          }

          // We sort the keys to have a consistent order, because SWR uses the keys to cache the data and if the order
          // is different it will be considered a different request. Same thing is done inside useFiltersQueryParams
          // or when applying saved filters (ResumeSearchAlerts component).
          const finalQueryParams = { ...externalParams, ...newQueryParams };
          return (sortQueryParams ? sortObjectKeys(finalQueryParams) : finalQueryParams);
        });
      }
    }

    const timer = setTimeout(() => {
      formIsUpdating.current = false;
    }, 100);

    return () => clearTimeout(timer);
  }, [formStatus.form, setQueryParams, sortQueryParams]);


  // Function to reset all the filters
  // ***********************************************
  const resetAllFilters = () => {
    hardResetForm(formRef);
    setTimeout(() => submitForm(), 50);
    if (setQueryParams) setQueryParams({});
  };


  // Return
  // ***********************************************
  return {
    formRef,
    formStatus,
    handleStep,
    record,
    setFormValues,
    updateFormElements,
    submitForm,
    resetForm,
    hardResetForm,
    talentSearchLabels,
    resetAllFilters,
  };
};
