// STUDY LEVELS **************************************************************************************
// pathname: /v1/static-lists/study-levels
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';
import { ApiGetStudyLevelsOk } from '@utils/data-fetching';


// SWR: fetch static study levels
// usage: const { data, error, ... } = useSwrStaticStudyLevels(locale);
// ********************************************
export const useSwrStaticStudyLevels = (locale: string) => {
  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiGetStudyLevelsOk>({
    url: '/v1/static-lists/study-levels',
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 60 * 24, // 24 hours, the data is not supposed to change
    },
  });

  // Return data
  return {
    data: data !== undefined ? data : [],
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
