import { MagnifyingGlassIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useFiltersHub } from '@hooks/filters/useFiltersHub';
import { useResumeSearchAlerts } from '@hooks/filters/useResumeSearchAlerts';
import {
  ComboBox, DecorativeGroup, ExternalGetQueryFunction, Button,
} from '@components/common/Forms';
import { useRouter } from 'next/navigation';
import { useRouter as usePagesRouter } from 'next/compat/router';
import { FormEvent, useState, useContext } from 'react';
import { fetchSearchData, JobSearchGroup } from '@components/templates/job/search/searchFetcher';
import { useDebounce } from '@hooks/useDebounce/useDebounce';
import { IGenericApiStrings } from '@type/generic-api-strings';
import { useTranslation } from 'next-i18next';
import { DocumentInterface } from '@type/v1-api-types';
import { HeaderSearchContext } from '@components/contexts/HeaderSearchContext';

/**
 * @description Renders the talent search section in the navbar.
 * * **locale** The locale of the app
 */
export const SearchSection = ({ locale }: { locale: Locale }) => {
  // Next router
  const router = useRouter();

  // Pages router
  const pagesRouter = usePagesRouter();

  // Translation
  const { t } = useTranslation('common');

  // Helper state for the input focus length
  const [isFocused, setIsFocused] = useState<boolean>(false);

  // Dropdown list for the search suggestions. Used for the autocomplete.
  const [dropdownList, setDropdownList] = useState<JobSearchGroup | undefined>(undefined);


  // Get the query params from the context
  // ***********************************************
  const {
    queryParams,
    setQueryParams,
    inputPlaceholder,
  } = useContext(HeaderSearchContext) || {};


  // SWR: fetch search alerts;
  // Filter the keywords and use them as initial values in the search input
  // ******************************
  const {
    mutateSearchAlerts,
    alertsKeywords,
  } = useResumeSearchAlerts({ locale });


  // FILTERS HUB HOOK
  // ***********************************************
  const {
    formRef,
    handleStep,
    record,
    submitForm,
  } = useFiltersHub({
    queryParams, setQueryParams, internalFieldsList: ['keyword', 'similarSearch'], protectFieldsOnMount: ['similarSearch'],
  });


  // Handle search results
  // ***********************************************
  // Debounce the external search query
  const externalSearchQuery = useDebounce<ExternalGetQueryFunction>((keyword: string) => fetchSearchData(keyword, '', '').then((res) => {
    setDropdownList(res.jobSearchGroup.filter((item) => !!(item as DocumentInterface)?.suggestion));
  }), 350);

  // Previous keyword. Used to update the resume search alerts if the previous keyword is different from the current one.
  const [previousKeyword, setPreviousKeyword] = useState<string>('');


  // Handle the form submit and navigate to the search page if needed
  // ***********************************************
  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const submitButton = event?.currentTarget?.keywordSubmit as HTMLButtonElement;
    const formInput = event?.currentTarget?.keyword as HTMLInputElement;
    const keyword = formInput?.value?.trim() || '';

    // submit the form
    handleStep(event);
    setIsFocused(false);
    submitButton.blur();
    formInput.blur();

    // navigate to search page if we're not already there when not having query params from the context
    if (!queryParams && keyword) {
      const url = `/${locale}/cv/search?keyword=${keyword}`;

      // Temporary fix: Using window.location.href as a fallback to force a full page reload
      // when pagesRouter is unavailable. This bypasses Next.js client-side navigation
      // and should be replaced with a proper router solution when possible.
      if (!pagesRouter) {
        window.location.href = url;
      } else {
        void router.push(url);
      }
    }

    // update the resume search alerts
    if (queryParams && keyword && keyword !== previousKeyword) {
      setPreviousKeyword(keyword);
      setTimeout(() => {
        void mutateSearchAlerts();
      }, 1000);
    }
  };


  // Common button classes
  const commonButtonClasses = 'transition-all duration-300 delay-300';

  const triggerInputFocus = () => {
    setIsFocused(true);
    // Ensure the formRef exists
    if (formRef.current) {
      // Use querySelector to safely access the input within the form
      const input = formRef.current.querySelector<HTMLInputElement>('input[name="keyword"]');

      // Check if the input exists and log its value
      if (input) {
        input.focus();
      }
    }
  };

  // Render component
  // *****************************************************
  return (
    <>
      <div className="relative z-1 flex w-full items-center md:absolute md:left-10 md:w-auto">
        {/* Form */}
        <form
          ref={formRef}
          onSubmit={handleSearchSubmit}
          onFocus={() => setIsFocused(true)}
          className={`${isFocused ? 'md:w-92' : 'md:w-52'} relative w-full !rounded-md bg-surface-100 transition-all duration-300`}
        >

          {/* Decorative group */}
          <DecorativeGroup groupParentClasses="w-full" className="border-0">

            {/* Icon */}
            <Button
              styling="none"
              className="flex shrink-0 items-center pl-3 outline-none"
              onClick={triggerInputFocus}
            >
              <MagnifyingGlassIcon className="size-5 stroke-2" />
            </Button>

            {/* Input */}
            <ComboBox
              placeholder={inputPlaceholder ? t(inputPlaceholder) : t('header.autocomplete.placeholder.talents')}
              className="rounded-md border-0 bg-transparent pl-2 text-md md:text-sm"
              buttonsContainerClass={`items-center top-0 bottom-0 right-0 ${commonButtonClasses} ${isFocused ? 'opacity-100' : 'opacity-0'}`}
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)}
              onInputSubmit={() => {
                submitForm();
              }}
              options={{
                showDropdownButton: false,
                valueFromKey: 'suggestion',
                // we use the search alerts keywords as the initial list
                staticList: dropdownList && dropdownList.length > 0 ? dropdownList : alertsKeywords,
                externalGetQuery: externalSearchQuery,
              }}
              onSelectedItemChange={(item) => { if (item) submitForm(); }}
              onClear={submitForm}
              renderList={(item, index, listKey, getItemProps, listStyling) => {
                const suggestion = item as IGenericApiStrings;
                return (
                  <li key={listKey} className={`${listStyling} !cursor-pointer border-dashed !py-1 text-sm`} {...getItemProps({ item, index })}>
                    {suggestion.suggestion}
                  </li>
                );
              }}
              {...record('keyword', { id: 'navbar_keyword' })}
            />

            {/* Submit button; tabindex for Safari */}
            <div className="relative z-10 flex shrink-0 items-center pr-1.5">
              <Button
                type="submit"
                size="sm"
                rounding="full"
                color="secondary"
                name="keywordSubmit"
                tabIndex={-1}
                className={`${isFocused ? 'size-9 scale-100' : 'size-0 scale-0'} ${commonButtonClasses} items-center justify-center !p-0`}
              >
                <ArrowRightIcon className="mr-px size-4 stroke-3" />
              </Button>
            </div>
          </DecorativeGroup>

        </form>
      </div>
    </>
  );
};
