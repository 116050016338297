import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SocketLiveInterviewStoreState } from '@type/redux';

// Define the initial state
const initialState: SocketLiveInterviewStoreState = {
  isSubscriberAvailable: false,
};

// Socket live interview reducers and actions
export const socketLiveInterviewSlice = createSlice({
  name: 'socketLiveInterview',
  initialState,
  reducers: {
    // set if subscriber is available
    setIsSubscriberAvailable: (state, action: PayloadAction<boolean>) => {
      state.isSubscriberAvailable = action.payload;
    },
  },
});

export default socketLiveInterviewSlice.reducer;
