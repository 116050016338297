// We need to sign the path using the salt and secret key to prevent denial of service attacks.
// Find out more info at: https://docs.imgproxy.net/signing_the_url
import createHmac from 'create-hmac';
import { ImageFoldersObject, ImageFolders, ImageAspectRatios } from '@type/image-bj';


// Environment variables
// *************************************
// Turns out that we need env variables to render on the client too... Bummer, that means we need to expose them.
// If we don't do that, we'll get a hydration mismatch error, between the server and the client.
const imgproxySalt = 'c06c74af353251b4083dfc06b4e5d17acfc7f256e49c985f1855e31c0291b40f';
const imgproxyKey = '25bce022049f2f8d306490cf50b7b0c7e4074695bc95ba60ed2b0d53ac5ece37';
const imgproxyURL = process.env.NEXT_PUBLIC_IMAGE_PREFIX_FRONTEND ?? '';
const gcsPrefix = process.env.NEXT_PUBLIC_GCS_PREFIX ?? '';
const gsBucket = process.env.NEXT_PUBLIC_IMAGES_GS_BUCKET ?? '';
const publicFolder = process.env.NEXT_PUBLIC_FOLDER_PREFIX ?? '';


/**
 * @description ImgProxy paths depending on the folder of the image. Pe local și test environment.
 * For **next** folder we use absolute path when we are in local environment. Otherwise, we leverage the ASSETS_PREFIX_FRONTEND
 * variable from next.config.js.
 *
 * - **none:** no url preset;
 * - **next:** static images from the _next folder (imported images with 'import xxx from "@images/xxx.jpg"');
 * - **public:** images from the Next.js public folder; this references the root public folder, so you will need to add the rest of the path, ie: images/avatars/;
 * - **resume_image:** resume images from the Google Storage bucket;
 * - **resume_attachment:** resume attachments from the Google Storage bucket;
 * - **employer_logo:** employer logos from the Google Storage bucket.
 * - **employer_gallery:** employer galleries from the Google Storage bucket.
 * - **feed_image:** feed images from the Google Storage bucket.
 */
const imgProxyPaths: ImageFoldersObject = {
  none: '',
  next: gcsPrefix && gcsPrefix !== 'staging/' ? `/plain/${imgproxyURL}` : '/plain/',
  public: `/plain/${publicFolder}`,
  resume_image: `/plain/${gsBucket}${gcsPrefix}resume_image/`,
  resume_attachment: `/plain/${gsBucket}${gcsPrefix}resume_attachment/`,
  employer_logo: `/plain/${gsBucket}${gcsPrefix}employer_logo/`,
  employer_gallery: `/plain/${gsBucket}${gcsPrefix}employer_gallery/`,
  feed_image: `/plain/${gsBucket}${gcsPrefix}feed_image/`,
};


/**
 * @description Function for extracting the file name from an url string.
 * @param src - The url string
 *
 * @example
 * getFileNameFromUrl('https://example.com/resume_image/image.jpg') => 'resume_image'
 */
export const getFolderFromSrc = (src: string): ImageFolders => {
  const keyName = Object.keys(imgProxyPaths).find((key) => src.includes(key));
  return keyName as ImageFolders;
};


/**
 * @description Function for processing the image depending on the folder and image width.
 * @param width - The width of the image (default 0)
 * @param aspectRatio - The aspect ratio of the image (default 'default').
 *
 * Aspect ratios:
 * - **default:** default aspect ratio;
 * - **square-fill:** square aspect ratio with fill;
 * - **square-fit:** square aspect ratio with fit;
 * - **landscape:** 7:3 aspect ratio.
 */
const imgProxyProcessing = (width = 0, aspectRatio: ImageAspectRatios = 'default') => {
  let processing: string;

  switch (aspectRatio) {
    case 'square-fill':
      processing = `/rs:fill-down:${width}:${width}/g:ce`;
      break;
    case 'square-fit':
      processing = `/rs:fit:${width}:${width}`;
      break;
    case 'landscape':
      // 7:3 aspect ratio
      processing = `/rs:fit:${width}:${Math.floor((width * 3) / 7)}`;
      break;
    case '4/3':
      // 4:3 aspect ratio
      processing = `/rs:fill-down:${width}:${Math.floor((width * 3) / 4)}/g:ce`;
      break;
    default:
      processing = `/rs:fit:${width}`;
  }

  return processing;
};


/**
 * @description Function for decoding a hex string to a buffer.
 * @param hex
 */
const hexDecode = (hex: string): Buffer => Buffer.from(hex, 'hex');


/**
 * @description Function for encoding a buffer to an url-safe base64 string.
 * @param arrayBuffer - The buffer to encode
 */
const urlSafeBase64 = (arrayBuffer: ArrayBuffer): string => Buffer.from(arrayBuffer)
  .toString('base64')
  .replace(/=/g, '')
  .replace(/\+/g, '-')
  .replace(/\//g, '_');


/**
 * @description Function for signing the path using the salt and secret key to prevent denial of service attacks.
 * @param imgPath - The path of the image
 * @param key - The secret key
 * @param salt - The salt
 */
const sign = (imgPath: string, key: string, salt: string): string => {
  const hmac = createHmac('sha256', hexDecode(key || ''));
  hmac.update(hexDecode(salt || ''));
  hmac.update(imgPath);
  return urlSafeBase64(hmac.digest() as unknown as ArrayBuffer);
};


/**
 * @description Function for getting imgProxy url for an image. To be used for global Next.js loader.
 * @param src - The source of the image
 * @param width - The width of the image
 * @param quality - The quality of the image
 * @param imageFolder - The folder of the image (ImageFolders type)
 * @param aspectRatio - The aspect ratio of the image (ImageAspectRatios type)
 */
export const getImgProxyUrl = (src: string, width?: number, quality?: number, imageFolder?: ImageFolders, aspectRatio?: ImageAspectRatios): string => {
  // Short circuit for images that are already processed by imgproxy.
  // We still return the src, because the function is expected to return an url string.
  if ((imageFolder === undefined || imageFolder === 'none') && src.includes('imgcdn.')) return src;

  // Set the folder
  let folder = !imageFolder || imageFolder === 'none' ? '/plain/' : imgProxyPaths[imageFolder];

  // Adjust the folder for Placeholder Avatar images in lists (ie: applicants list) because they are not stored on Google Storage.
  if (src.startsWith('img-profile-pic-placeholder-') && imageFolder === 'resume_image') {
    folder = `${imgProxyPaths.public}images/avatars/`;
  }

  // Build path for imgproxy
  // encodeURI is needed because sometimes the url contains special characters that are not safe for an url
  // (spaces in the filename, slashes or math signs for example)
  // We need to remove the domain (cdn.bestjobs.eu) part from the url to work if they are from the frontend bucket;
  const path = `${imgProxyProcessing(width, aspectRatio)}${quality ? `/q:${quality}` : '/q:85'}${folder}${
    encodeURI(
      src
        // we replace the path for static assets with the path for frontend assets
        .replace('https://cdn.bestjobs.eu/static/', 'https://cdn.bestjobs.eu/frontend/')
        // needed for default human avatars
        .replace('img/cv/human/', 'images/avatars/')
        // we need a relative path for imgproxy to work in this case.
        .replace('https://cdn.bestjobs.eu/', '')
        .replace(imgproxyURL, '')
        // remove the /assets from the path, to correlate with the bucket path
        .replace('/assets/', 'frontend/')
        // replace img with images
        .replace('/img/', '/images/'),
    )}`;

  // Sign path
  const signature = sign(path, imgproxyKey, imgproxySalt);
  // Build signed path
  const result = `/${signature}${path}`;

  // Return imgproxy url
  return `${imgproxyURL}${result}`;
};
