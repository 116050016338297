import { createContext } from 'react';
import { ApiSpokenLanguagesArr, ApiSpokenLanguageLevelsObj } from '@type/v1-api-types/static';


// Context interface
interface LanguagesListAndLevelsContextInterface {
  spokenLanguagesList?: ApiSpokenLanguagesArr,
  spokenLanguagesLevels?: ApiSpokenLanguageLevelsObj,
}


/**
 * @description Context for spoken languages list and levels.
 */
export const LanguagesListAndLevelsContext = createContext<LanguagesListAndLevelsContextInterface>({
  spokenLanguagesList: undefined,
  spokenLanguagesLevels: undefined,
});
