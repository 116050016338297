import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  ComboBox, DecorativeGroup, ExternalGetQueryFunction, Button,
} from '@components/common/Forms';
import {
  FormEvent, useCallback, useEffect, useState, useContext,
} from 'react';
import { fetchSearchData } from '@components/templates/job/search/searchFetcher';
import { useDebounce } from '@hooks/useDebounce/useDebounce';
import { route } from '@services/symfony';
import { useTranslation } from 'next-i18next';
import { DocumentInterface } from '@type/v1-api-types';
import { fetcher, isApiV1Errors } from '@utils/data-fetching';
import { bjToast } from '@components/common';
import { UserJobSearchQuery, UserSearch } from '@type/v1-api-types/user-search';
import { ArrayFilterItem } from '@utils/arrays/filter-strings';
import { HeaderSearchContext } from '@components/contexts/HeaderSearchContext';
import { useFiltersHub } from '@hooks/filters/useFiltersHub';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { getI18nLinkHref } from '@services/i18nPaths';
import { useRouter as usePagesRouter } from 'next/compat/router';
import { isApiUserSearchOk } from '../../../../../type-predicates/v1-api-predicates/user-search';

/**
 * @description Renders the job search section in the navbar.
 * * **locale** The locale of the app
 */

interface SearchResult {
  category?: string;
  recent?: string;
  suggestion?: string;
  legacyLink?: string;
  jobSearch?: UserJobSearchQuery;
}

export const SearchJobSection = ({ locale = 'ro' }: { locale: Locale }) => {
  // Next router
  const { router } = useExtendedRouter();
  const pagesRouter = usePagesRouter();

  // Get the query params from the context
  // ***********************************************
  const {
    queryParams,
    setQueryParams,
  } = useContext(HeaderSearchContext) || {};

  // Filters Hub
  // ***********************************************
  const {
    formRef,
    handleStep,
    record,
    submitForm,
  } = useFiltersHub({
    queryParams, setQueryParams, internalFieldsList: ['keyword'],
  });

  // Main component props with default values
  const { t } = useTranslation('common');

  // ***********************************************
  // Search results holder
  const [dropdownList, setDropdownList] = useState<SearchResult[]>([]);
  const [currentValue, setCurrentValue] = useState<string>();
  const [searchStatus, setSearchStatus] = useState<'recent' | 'suggestion'>('recent');
  const [recentSearch, setRecentSearch] = useState<SearchResult[]>([]);

  const externalSearchQuery = useDebounce<ExternalGetQueryFunction>((keyword: string) => fetchSearchData(keyword, '', '').then((res) => {
    setDropdownList(res.jobSearchGroup.filter((item) => !!(item as DocumentInterface)?.suggestion));
    setSearchStatus('suggestion');
  }), 350);


  const [isFocused, setIsFocused] = useState<boolean>(false);

  // Handle the form submit and navigate to the search page if needed
  // ***********************************************
  const handleSearchSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formInput = event?.currentTarget?.keyword as HTMLInputElement;
    const keyword = formInput?.value?.trim() || '';

    // submit the form
    handleStep(event);
    setIsFocused(false);
    formInput.blur();

    // navigate to search page if we're not already there when not having query params from the context
    if (!queryParams && keyword) {
      const href = getI18nLinkHref(`/job/list/${keyword}`, locale);
      void router.push(href, { withLocale: false });
    }
  };

  const getRecentSearchesInformations = useCallback(() => {
    const popularSearches = [
      { category: t('user.popular.searches'), legacyLink: route('app_job_canonical', { keyword: t('user.popular.searches') }) },
      { recent: t('popular.search.filters.driver'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.driver') }) },
      { recent: t('popular.search.filters.engineer'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.engineer') }) },
      { recent: t('popular.search.filters.manager'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.manager') }) },
      { recent: t('popular.search.filters.part.time'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.part.time') }) },
      { recent: t('popular.search.filters.sales'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.sales') }) },
      { recent: t('popular.search.filters.remote'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.remote') }) },
      { recent: t('popular.search.filters.marketing'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.marketing') }) },
      { recent: t('popular.search.filters.accountant'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.accountant') }) },
      { recent: t('popular.search.filters.abroad'), legacyLink: route('app_job_canonical', { keyword: t('popular.search.filters.abroad') }) },
    ];

    fetcher<UserSearch>('/v1/job-search-tooltip', locale, { method: 'GET' })
      .then((response) => {
        if (isApiUserSearchOk(response)) {
          const historySuggestion = [];

          if (response.recentSearches && response.recentSearches.length > 0) {
            const recent: SearchResult[] = [
              { category: t('user.recent.searches') },
              ...response.recentSearches.map((search) => ({
                recent: (search.keyword || '') + (search.location ? ` in ${search.location}` : ''),
              })),
            ];
            historySuggestion.push(recent);
          }
          if (response.savedSearches && response.savedSearches.length > 0) {
            const savedSearch: SearchResult[] = [
              { category: t('user.saved.searches') }];

            const savedObject = response.savedSearches;
            savedObject.forEach((item) => {
              const searchData = item.jobSearch;
              if (searchData.keyword && searchData.location[0]) {
                savedSearch.push({
                  recent: `${searchData.keyword} in ${searchData.location[0]}`,
                  jobSearch: searchData,
                });
                return;
              }

              if (searchData.keyword) {
                savedSearch.push({ recent: `${searchData.keyword}`, jobSearch: searchData });
                return;
              }

              if (searchData.location[0]) {
                savedSearch.push({ recent: `${searchData.location[0]}`, jobSearch: searchData });
              }
            });
            historySuggestion.push(savedSearch);
          }

          if (response.savedSearches.length === 0 && response.recentSearches.length === 0) {
            setDropdownList(popularSearches);
            setRecentSearch(popularSearches);
          } else {
            setDropdownList(historySuggestion.flat());
            setRecentSearch(historySuggestion.flat());
          }
        }

        if (isApiV1Errors(response)) {
          bjToast.error(response.errors[0].message);
        }
      }).catch(() => {});
  }, [locale, t]);

  useEffect(() => {
    getRecentSearchesInformations();
  }, [getRecentSearchesInformations]);


  useEffect(() => {
    if (!currentValue) {
      setSearchStatus('recent');
      setDropdownList(recentSearch);
    }
  }, [searchStatus, currentValue, dropdownList, recentSearch]);

  const triggerInputFocus = () => {
    setIsFocused(true);
    // Ensure the formRef exists
    if (formRef.current) {
      // Use querySelector to safely access the input within the form
      const input = formRef.current.querySelector<HTMLInputElement>('input[name="keyword"]');

      // Check if the input exists and log its value
      if (input) {
        input.focus();
      }
    }
  };

  // Render component
  // *****************************************************
  return (
    <>
      <div className="relative z-1 flex w-full items-center md:absolute md:left-14 md:w-auto">
        { /* Dropdown */ }
        <div className={`${isFocused ? 'md:w-92' : 'md:w-52'} relative flex w-full items-center rounded-md border-0 bg-surface-100 transition-all duration-300`}>

          <DecorativeGroup groupParentClasses="w-full" className="border-0">
            <Button styling="none" onClick={triggerInputFocus} className="flex shrink-0 items-center pl-3 outline-none">
              <MagnifyingGlassIcon className="size-5 stroke-2" />
            </Button>
            <form ref={formRef} onSubmit={handleSearchSubmit} className="w-full">
              <ComboBox
                placeholder={t('header.autocomplete.placeholder.employers')}
                className="rounded-md border-0 bg-transparent text-md md:text-sm"
                onFocus={() => {
                  setIsFocused(true);
                }}
                type="text"
                onBlur={() => setIsFocused(false)}
                onChange={(e) => setCurrentValue(e.target.value)}
                options={{
                  showDropdownButton: false,
                  showResetButton: false,
                  valueFromKey: searchStatus,
                  staticList: dropdownList,
                  externalGetQuery: externalSearchQuery,
                }}
                onInputSubmit={(value) => {
                  if (value) {
                    submitForm();
                  }
                }}
                onSelectedItemChange={(item) => {
                  if (item && typeof item === 'object' && 'recent' in item) {
                    const searchResultItem = item as SearchResult;
                    if (pagesRouter?.pathname === '/[locale]/job/list/[[...slug]]' && setQueryParams) {
                      setQueryParams({
                        keyword: searchResultItem.jobSearch?.keyword ?? searchResultItem.recent,
                        location: searchResultItem.jobSearch?.location ?? [],
                        domain: [],
                      });
                    } else {
                      const href = getI18nLinkHref(`/job/list/${searchResultItem.recent}`, locale);
                      void router.push(href, { withLocale: false });
                    }
                  }

                  if (item) submitForm();
                }}
                renderList={(item, index, listKey, getItemProps, listStyling) => {
                  // a type predicate to distinguish object types
                  const isCategoryTitle = (obj: ArrayFilterItem) => obj['category' as keyof typeof obj] !== undefined;
                  if (isCategoryTitle(item)) {
                    return (
                      <li key={listKey} className="py-1.5 pl-5 text-xs font-semibold text-ink-light">
                        {item['category' as keyof typeof item]}
                      </li>
                    );
                  }
                  return (
                    <li
                      key={listKey}
                      className={`${listStyling} !cursor-pointer !border-0 !py-1 !pl-5 text-sm`}
                      {...getItemProps({
                        item,
                        index,
                      })}
                    >
                      {item[searchStatus as keyof typeof item]}
                    </li>
                  );
                }}
                {...record('keyword', { id: 'navbar_keyword' })}
              />
            </form>

          </DecorativeGroup>

        </div>
      </div>
    </>
  );
};
