import {
  ChangeEvent, useImperativeHandle, useRef, useState, useCallback, useEffect,
} from 'react';
import { convertEmptyValue } from 'src/utils';
import {
  UseFormElementInterface,
  FormElementTypes,
  GlobalValuesTypes,
} from 'src/types/form-types';
import { useCheckValidity } from './useCheckValidity';


/**
 * @description Custom hook to share common functionality among form SINGLE elements
 *
 * @description **Exposed methods** that can be accessed via refs (exported by {@link useImperativeHandle})
 * * **checkElementValidity** - Check validity helper function and helps build an error object similar to the one we receive from the server.
 * * **getElement** - Returns the current node.
 * * **getElementValue** - Get the value of the element
 * * **getCheckedValue** - Get the checked value of the element. If the element is not a checkbox or radio, return undefined.
 * * **setElementValue** - Set a value to Element (ie: from API get); used to populate the form with data from server
 * * **setCheckInvalid** - Set the Element as invalid; if we get a response from server that the element is invalid we use this method to trigger it
 * * **setInvalidFromServer** - Set the error message received from server
 * * **resetField** - Resets all fields states & value. Reset all element's states
 * * **scrollToElement** - Scroll to the element
 * * **setCheckboxChecked** - Set the checkbox as checked or unchecked
 *
 * --------
 * * @param ref { RefObject } - where we pass the functions exposed with the 'useImperativeHandle' hook
 * * @param patternMismatchMessage { string } - pattern mismatch error message (ie: credit card numbers not match pattern)
 * * @param customValidityMessages { FormElementCustomValidityMessages } - custom error messages for validation type. See {@link FormElementCustomValidityMessages} for more details
 * * @param className { string } - element's className (for nicely formatted error and empty classes)
 * * @param hasCustomError { boolean } - a boolean to trigger a custom error message; default undefined
 * * @param getValidityStates { Function } - the getValidityStates function callback. Use a state setter function to get the validity states.
 * Returns an object with isInvalid and errorMSG properties.
 *
 * @returns object with keys
 * * {@link handleInteraction} { ChangeEvent } - both for onChange and onBlur events; checks validity
 * * {@link checkEmpty} { ChangeEvent } - for onChange; checks if the element's value or default value is empty
 * * {@link setElementValue} { Action } - set a value to Element (ie: from API get); also used to populate the form with data from server
 * * {@link elementRef} { RefObject } - the element's ref, makes it easy to target it
 * * {@link isInvalid} { boolean } - state for validity
 * * {@link hasEmptyValue} { boolean } - state for the element's value or default value
 * * {@link useCheckValidity} { string } - the error message that will be passed to the Error component; got from 'useCheckValidity' custom hook
 * * {@link errorClass} { string } - nicely formatted hasError class
 * * {@link emptyClass} { string } - nicely formatted emptyValue class
 *
 * @remarks Used for generating form field elements
 *
 * @version 1.0
 */
export const useSingleFormElement = ({
  ref, patternMismatchMessage, customValidityMessages, className, hasCustomError, getValidityStates,
}: UseFormElementInterface) => {
  // validity custom hook
  const { errorMSG, checkValidation } = useCheckValidity();

  // element's ref
  const elementRef = useRef(null);

  // other states
  const [isInvalid, setIsInvalid] = useState(false);
  const [serverInvalid, setServerInvalid] = useState(false);

  // if the user has interacted with the element
  const [interacted, hasInteracted] = useState(false);

  // the default value for a Select element (unselected if not populated with get data);
  // we use it to add the 'emptyValue' class
  const [hasEmptyValue, setEmptyValue] = useState(true);

  // the saved Element's value, in case we have errors from server;
  // we use this and if the value does not change we don't validate the element
  const [savedValue, setSavedValue] = useState('');

  // we save the error message we got from the server
  const [serverErrorMessage, setServerErrorMessage] = useState('');


  /**
   * @description Check validity helper function and helps build an error object similar to the one we receive from the server.
   * @description If the element is VALID, sets the {@link isInvalid} state as false
   * @description If the element is Invalid, sets the {@link isInvalid} state as true
   * and triggers the {@link checkValidation} function from the {@link useCheckValidity} custom hook
   * @param elem
   *
   * @remarks Method stored in ref and passed using the {@link https://reactjs.org/docs/hooks-reference.html#useimperativehandle useImperativeHandle} hook.
   */
  const checkElementValidity = useCallback((elem = elementRef.current as unknown as FormElementTypes): string => {
    if (elem.validity.valid) {
      setIsInvalid(false);
      return '';
    }
    setIsInvalid(true);
    checkValidation(elem, serverErrorMessage, patternMismatchMessage, customValidityMessages);
    return elem.id;
  }, [checkValidation, customValidityMessages, patternMismatchMessage, serverErrorMessage]);


  // Element's methods -------------------------------------------

  /**
   * @description Returns the current node.
   * @remarks Method stored in ref and passed using the {@link https://reactjs.org/docs/hooks-reference.html#useimperativehandle useImperativeHandle} hook.
   */
  const getElement = () => elementRef.current as unknown as FormElementTypes;

  /**
   * @description Set the Element as invalid
   * @remarks Method stored in ref and passed using the {@link https://reactjs.org/docs/hooks-reference.html#useimperativehandle useImperativeHandle} hook.
   */
  const setCheckInvalid = (): void => {
    hasInteracted(true);
    checkElementValidity();
  };


  /**
   * @description Scroll to the element.
   */
  const scrollToElement = () => {
    const element = getElement();

    // if input hidden - scroll to hidden field's parent
    if (element.hidden && element.parentElement) {
      element.parentElement.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    element.scrollIntoView({ behavior: 'smooth' });
  };


  /**
   * @description Set a value to Element (ie: from API get);
   * used to populate the form with data from server
   * @description For Select elements we need to remove the emptyValue class if the element has a value
   * @description For radio and checkboxes match the default value to toggle 'checked'
   * @description For checkbox only: if the value is 'true' a single checkbox will be triggered as checked (ie: confidentiality)
   * @param newValue
   */
  const setElementValue = useCallback((newValue: string): void => {
    const currElement = getElement();

    // remove the emptyValue class from element (selects usually)
    if (newValue) setEmptyValue(false);

    // cleanup the validation states
    setIsInvalid(false);
    setServerInvalid(false);

    // check if the Element is of type checkbox or radio
    if (currElement.getAttribute('type') === 'checkbox' || currElement.getAttribute('type') === 'radio') {
      // assignment for typescript
      const radioOrCheckbox = <HTMLInputElement> currElement;
      const convertedValue = convertEmptyValue('toString', newValue);

      // First handle checkbox multiple
      // Then toggle checked if the value is not 'empty' or if the value is equal with the default value
      // Remember that checkbox & radio must have string value, you need to convert whatever the API is sending
      if (Array.isArray(newValue)) {
        const valuesAsStrings = newValue.map((item) => (typeof item !== 'string' ? String(item) : item));
        radioOrCheckbox.checked = valuesAsStrings.includes(currElement.value);
      } else if (currElement.value === newValue || currElement.value === String(newValue)) {
        radioOrCheckbox.checked = true;
      } else {
        radioOrCheckbox.checked = !!(currElement.getAttribute('type') === 'checkbox' && convertedValue);
      }
    } else {
      // set the value for not radio/checkbox form elements
      currElement.value = newValue;
    }

    // Trigger onInput event maybe for some custom logic. Cleanups the isTriggeringEvent flag
    // It might help you to know the user value change (ie: manual) is trusted (event.isTrusted === true) and
    // programatic value change is not (event.isTrusted === false).
    currElement.dispatchEvent(new Event('input', { bubbles: true }));
  }, []);


  /**
   * @description Get the value of the element
   */
  const getElementValue = (): GlobalValuesTypes => {
    const currElement = getElement();
    return currElement ? currElement.value : '';
  };


  /**
   * @description Get the checked value of the element. If the element is not a checkbox or radio, return undefined.
   */
  const getCheckedValue = (): boolean | undefined => {
    const currElement = getElement();
    if (currElement.getAttribute('type') === 'checkbox' || currElement.getAttribute('type') === 'radio') {
      const radioOrCheckbox = <HTMLInputElement> currElement;
      return radioOrCheckbox.checked;
    }

    return undefined;
  };


  /**
   * @description Set the checkbox as checked or unchecked
   * @param isChecked - boolean - the checked value
   */
  const setCheckboxChecked = (isChecked: boolean): void => {
    const currElement = getElement();
    if (currElement.getAttribute('type') === 'checkbox') {
      const checkbox = <HTMLInputElement> currElement;
      checkbox.checked = isChecked;
    }
  };


  /**
   * @description Set the error message received from server
   * @param errorMessage
   */
  const setInvalidFromServer = (errorMessage: string): void => {
    const currElement = getElement();
    setServerErrorMessage(errorMessage);
    hasInteracted(true);
    setIsInvalid(true);
    setServerInvalid(true);

    // save the field's current value to for comparison when triggering customError
    setSavedValue(currElement.value);
    currElement.setCustomValidity(errorMessage);

    checkValidation(currElement, errorMessage, patternMismatchMessage, customValidityMessages);
  };


  /**
   * @description Trigger a custom error message
   * @param errorMessage - the custom error message
   */
  const triggerCustomError = useCallback((errorMessage: string): void => {
    const currElement = getElement();
    hasInteracted(true);
    setIsInvalid(true);
    currElement.setCustomValidity(errorMessage);

    checkValidation(currElement, errorMessage, patternMismatchMessage, customValidityMessages);
  }, [checkValidation, patternMismatchMessage, customValidityMessages]);

  /**
   * @description Clear the custom error message
   */
  const clearCustomError = useCallback((): void => {
    const currElement = getElement();
    if (interacted) checkElementValidity(currElement);
  }, [interacted, checkElementValidity]);


  /**
   * @description When we receive an error from server, we need to set the
   * validity's customError using the setCustomValidity() method
   * @param elem
   */
  const customErrorCheck = (elem: FormElementTypes): void => {
    if (savedValue !== elem.value) {
      elem.setCustomValidity('');
      checkElementValidity(elem);
    } else {
      elem.setCustomValidity(serverErrorMessage);
      checkElementValidity(elem);
    }
  };


  /**
   * @description Events handling for form single elements.
   * @description It triggers the {@link checkElementValidity} helper function
   * @param event
   */
  const handleInteraction = (event: ChangeEvent<FormElementTypes>): void => {
    const element = event.target;

    // trigger show errors for the first time, only after losing focus on field
    if (!isInvalid && !interacted && event.type === 'blur') {
      hasInteracted(true);
      checkElementValidity(element);
    }

    // if the user has interacted at least once with the form field
    if (interacted) checkElementValidity(element);

    // if we receive an error from server after submit
    if (serverInvalid) customErrorCheck(element);
  };


  /**
   * @description Add remove class if the element's value or default value is empty (ie: for styling required Select fields)
   * @note This is specially useful for required selects, because all required elements start as :isInvalid when required
   * (HTML5 validation API)
   * @param event
   */
  const checkEmpty = (event: ChangeEvent<FormElementTypes>): void => {
    if (event.target.value) {
      setEmptyValue(false);
    } else {
      setEmptyValue(true);
    }
  };


  /**
   * @description Resets all fields states & value. Reset all element's states
   * @remarks - setIsInvalid(false) and setServerInvalid(false) are handled inside {@link setElementValue}
   */
  const resetField = (): void => {
    hasInteracted(false);
    setEmptyValue(true);
    setSavedValue('');
    setServerErrorMessage('');
    setElementValue('');
  };


  /**
   * @description Expose the methods.
   * Be able to externally call the element's methods like [refName].current.showInvalid();
   */
  useImperativeHandle(ref, () => ({
    checkElementValidity,
    getElement,
    getElementValue,
    getCheckedValue,
    setElementValue,
    setCheckInvalid,
    setInvalidFromServer,
    resetField,
    scrollToElement,
    setCheckboxChecked,
  }));


  /**
   * @description Pass the validity states of the element externally
   */
  useEffect(() => {
    if (getValidityStates) {
      getValidityStates({ isInvalid, errorMSG });
    }
    // Cleanup on unmount
    return () => {
      if (getValidityStates) {
        getValidityStates({ isInvalid: false, errorMSG: '' });
      }
    };
  }, [isInvalid, errorMSG, getValidityStates]);


  /**
   * @description Trigger the custom error message
   */
  useEffect(() => {
    if (hasCustomError) {
      triggerCustomError(customValidityMessages?.customError || '');
    } else if (hasCustomError === false) {
      clearCustomError();
    }
  }, [hasCustomError, triggerCustomError, clearCustomError, customValidityMessages]);


  /* Error class nicely formatted */
  const errorClass = className ? ' hasError' : 'hasError';
  /* Empty class nicely formatted */
  const emptyClass = className || isInvalid ? ' emptyValue' : 'emptyValue';


  return {
    checkElementValidity,
    setCheckInvalid,
    handleInteraction,
    checkEmpty,
    setElementValue,
    getElementValue,
    elementRef,
    isInvalid,
    errorMSG,
    hasEmptyValue,
    errorClass,
    emptyClass,
  };
};
