// Interfaces
// *******************************************************************************************
interface ProfileTextElements {
  name: string,
  email: string,
}

interface ProfileSize {
  sm: ProfileTextElements,
  md: ProfileTextElements,
  base: ProfileTextElements,
}


// Classes
// *******************************************************************************************
export const textElements: ProfileSize = {
  sm: {
    name: 'font-semibold text-sm leading-snug max-w-35 truncate',
    email: 'hidden',
  },
  md: {
    name: 'text-base font-semibold leading-snug',
    email: 'text-sm text-ink-medium leading-snug',
  },
  base: {
    name: 'text-xl font-semibold leading-snug',
    email: 'text-base text-ink-medium leading-snug',
  },
};


/**
 * @description Compose profile text classes
 * @param size
 * @param textElement
 */
export const composeProfileTextClasses = (size: string, textElement: string)
: string => textElements[size as keyof ProfileSize][textElement as keyof ProfileTextElements];
