import { createContext } from 'react';
import { FiltersQueryParamsStates, JobSearchQueryParams, TalentSearchQueryParams } from '@type/filters';


// Interface
interface HeaderSearchContextType extends FiltersQueryParamsStates<TalentSearchQueryParams | JobSearchQueryParams> {
  inputPlaceholder?: string;
}


/**
 * @description The context for the header search. It contains the query params and a state setter to update them.
 * * **queryParams** - the query params (ie: for the filters). Default is undefined.
 * * **setQueryParams** - the function to set the query params. Default is undefined.
 * * **inputPlaceholder** - the placeholder for the search input. Default is empty string.
 *
 * With this, you won't need to use route conditionals to see if you're in the right Page, you will only need to check
 * if the *queryParams* or *setQueryParams* params are not undefined.
 *
 * @example
 * Page file, the place where you're declaring the context value.
 * // **************************************
 * import { HeaderSearchContext, HeaderSearchContextType } from '@components/contexts/HeaderSearchContext';
 *
 * // Declare a variable outside the Page component to pass the query params to the search input component
 * let headerSearchContextValue: HeaderSearchContextType;
 *
 * // Inside the Page component
 * const MyPage = (props: MyPageProps) => {
 *   ...
 *
 *   // Example of getting the query params and the function to set them from useFiltersQueryParams hook
 *   const {
 *     queryParams,
 *     setQueryParams,
 *     setCurrentPage,
 *   } = useFiltersQueryParams({
 *     serverQueryParams: objFilterParams,
 *     initialPage: page,
 *     keepAsCommaSeparatedValues: ['spokenLanguages'],
 *   });
 *
 *   ...
 *
 *   // Mutate the HeaderSearchContext value with the new query params. Use useMemo to avoid unnecessary re-renders.
 *   headerSearchContextValue = useMemo(() => ({
 *     queryParams,
 *     setQueryParams,
 *   }), [queryParams, setQueryParams]);
 *
 *   return (...);
 * };
 *
 * // Page layout (outside Page component); We wrap the page component with the HeaderSearchContext.Provider in order
 * // to pass the query params to the search input component.
 * TalentSearch.getLayout = function getLayout(page: ReactNode) {
 *   return (
 *     <HeaderSearchContext.Provider value={headerSearchContextValue}>
 *       <LayoutDefault navbarTheme="transparent">
 *         {page}
 *       </LayoutDefault>
 *     </HeaderSearchContext.Provider>
 *   );
 * };
 *
 *
 * // Search input component file
 * // **************************************
 * export const SearchSection = () => {
 *   ...
 *   // Get the query params from the context
 *   const { queryParams, setQueryParams } = useContext(HeaderSearchContext);
 *
 *   if (!queryParams || !setQueryParams) {
 *     // you're not in the MyPage page
 *   };
 * };
 */
export const HeaderSearchContext = createContext<HeaderSearchContextType | undefined>(
  { queryParams: {}, setQueryParams: () => {}, inputPlaceholder: '' },
);
