// TYPES *************************************************
type JsonItem = string | boolean | undefined | null;
export type IsJson = (json: JsonItem) => boolean;


/**
 * @description Check if a value is valid JSON
 * @param json
 */
export const isJson: IsJson = (json) => {
  if (typeof json !== 'string') return false;

  try {
    JSON.parse(json);
  } catch (error) {
    console.error('Not a proper JSON!', error);
    return false;
  }

  return true;
};


/**
 * @description Safely parse a JSON object. If the parsed item is not an object, the function
 * returns an empty object.
 * @param json
 */
export const safeParseJsonObject = <T extends object>(json: JsonItem): T => {
  let holder = {} as T;
  if (typeof json !== 'string') return holder;

  if (isJson(json)) {
    const parsedJson = JSON.parse(json) as T;
    holder = typeof parsedJson === 'object' && parsedJson !== null && !Array.isArray(parsedJson) ? parsedJson : {} as T;
  }

  return holder;
};
