import { useSwrStaticLiveInterval } from '@hooks/useSwrApi/endpoints/static/useSwrStaticLiveInterval';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { getLocaleFromQuery } from '@utils/gssp/gsspGetLocale';
import { isDateInsideLiveInterval } from './utils';

interface IProps {
  autoRefresh?: boolean;
}

/**
 * @description Hook for getting live interval. It returns the live interval, the start and end time of the live interval
 * @param autoRefresh - if true, the current date will be refreshed every 30 seconds
 */
export const useLiveInterval = ({ autoRefresh = true }: IProps) => {
  const router = useRouter();
  const locale = getLocaleFromQuery(router.query);

  const { data: liveInterval } = useSwrStaticLiveInterval(locale);

  // current date time for showing the interval
  const [currentDateTime, setCurrentDateTime] = useState<Date>(new Date());

  const startTime = liveInterval?.startAt?.substring(11, 16) ?? '';
  const endTime = liveInterval?.endAt?.substring(11, 16) ?? '';

  // refresh the current date every 30 seconds
  useEffect(() => {
    if (!autoRefresh) {
      return () => {};
    }

    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 30 * 1000); // 30 seconds

    return () => {
      clearInterval(interval);
    };
  }, [autoRefresh]);

  // check if current time is inside the live interval
  const checkCurrentTimeInsideInterval = useCallback(() => isDateInsideLiveInterval(liveInterval, currentDateTime), [liveInterval, currentDateTime]);

  return {
    checkCurrentTimeInsideInterval,
    liveInterval,
    startTime,
    endTime,
  };
};
