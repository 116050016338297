import { Router } from './symfony.router';
import jsRoutes from './routes/fos_js_routes.json';
import {logErrorEvent} from "@utils/logger/logger";

const { routes, ...context } = jsRoutes;

const SymfonyRouter = new Router({ ...context, locale: 'ro' }, routes);

// documentation here: https://github.com/FriendsOfSymfony/FOSJsRoutingBundle/blob/master/Resources/doc/usage.rst#generating-uris
// if you need to generate a relative path or pass parameters to the link, use something like this
// route('sitemap_jobs', {'type': 'user'}, false)
export const route = (name, params, absolute) => {
    try {
        return SymfonyRouter.generate(name, params, absolute);
    } catch (e) {
        logErrorEvent(e);
    }
    return '';
}

export default SymfonyRouter;
