// Drawer base classes
const positionLeft = 'inset-y-0 left-0 max-w-full pr-13';
const positionRight = 'inset-y-0 right-0 max-w-full pl-13';
const positionTop = 'inset-x-0 top-0 max-h-full pb-13';
const positionBottom = 'inset-x-0 bottom-0 max-h-full pt-13';


// Drawer sizes X
const drawerSizesX = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
};

// Drawer sizes Y
const drawerSizesY = {
  xs: 'max-h-20',
  sm: 'max-h-32',
  md: 'max-h-44',
  lg: 'max-h-64',
  xl: 'max-h-80',
  '2xl': 'max-h-96',
};

// Animation right
const animationRight = {
  enterFrom: 'translate-x-full',
  enterTo: 'translate-x-0',
  leaveFrom: 'translate-x-0',
  leaveTo: 'translate-x-full',
};

// Animation left
const animationLeft = {
  enterFrom: '-translate-x-full',
  enterTo: 'translate-x-0',
  leaveFrom: 'translate-x-0',
  leaveTo: '-translate-x-full',
};

// Animation top
const animationTop = {
  enterFrom: '-translate-y-full',
  enterTo: 'translate-y-0',
  leaveFrom: 'translate-y-0',
  leaveTo: '-translate-y-full',
};

// Animation bottom
const animationBottom = {
  enterFrom: 'translate-y-full',
  enterTo: 'translate-y-0',
  leaveFrom: 'translate-y-0',
  leaveTo: 'translate-y-full',
};


// Export drawer styles
export const drawerStyles = {
  drawerSizesX,
  drawerSizesY,
  drawerPosition: {
    left: positionLeft,
    right: positionRight,
    top: positionTop,
    bottom: positionBottom,
  },
  drawerAnimation: {
    right: animationRight,
    left: animationLeft,
    top: animationTop,
    bottom: animationBottom,
  },
};
