/**
 * @description Extracts the first letter from the first two words
 *
 * @example
 * getNameInitials("John Doe John") -> "JD"
 * getNameInitials("John") -> "J"
 *
 * @param name
 */
export const getNameInitials = (name: string): string => {
  const tokens = name.split(' ');
  if (!tokens.length) {
    return '';
  }
  let initials = tokens[0].slice(0, 1);

  if (tokens.length > 1) {
    initials += tokens[1].slice(0, 1);
  }

  return initials.toUpperCase();
};
