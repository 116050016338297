// Imports
// *****************************************************
import { NavbarTheme } from '@type/header-types';


// Classes for navbar themes
// *****************************************************
const themeCommon = 'relative transition-all duration-300';
const themeDefault = 'bg-surface shadow-header';

// transparent navbar (when not pinned)
const themeTransparent = 'bg-surface/30 backdrop-blur border-b border-surface/30';


// Get the main navbar theme
// 1. theme - add new themes by editing NavbarTheme type and adding your new switch case
// 2. notPinned - the navbar is pinned and is triggered after user has scrolled down. Always false when page
// is scrolled to top. It's used to add the transparent theme when navbar is not pinned.
// *****************************************************
export const getNavbarTheme = (theme: NavbarTheme, notPinned: boolean) => {
  switch (theme) {
    case 'transparent':
      return `${themeCommon} ${notPinned ? themeTransparent : themeDefault}`;
    default:
      return `${themeCommon} ${themeDefault}`;
  }
};
