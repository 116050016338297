import { cn } from '@utils/cn';

// Interface
// ***********************************
interface ChatBubbleSkeletonProps {
  isSender?: boolean; // true if the message is sent by the user, false otherwise
  withTimestamp?: boolean; // optional timestamp
  messageClassName?: string
}

/**
 * @description  Chat bubble Skeleton component.
 * A reusable skeleton component for simulating a chat bubble during loading states..
 *
 * - **isSender**: A boolean that determines if the chat bubble represents the user (sender) or the other party (receiver).
 * - **withTimestamp**: Whether to include a timestamp skeleton.
 * - **messageClassName**: Additional classes for styling the message bubble.
 *
 * @example
 * // Sender bubble with timestamp
 * <ChatBubbleSkeleton isSender={true} withTimestamp={true} />
 *
 * @example
 * // Receiver bubble without timestamp and custom width
 * <ChatBubbleSkeleton isSender={false} messageClassName="max-w-32" />
 */
export const ChatBubbleSkeleton = (props: ChatBubbleSkeletonProps) => {
  // Destructure props
  const {
    isSender = false,
    withTimestamp = false,
    messageClassName,
  } = props;

  // Render component
  // *****************************************************
  return (
    <div
      className={cn(
        'flex w-full flex-col',
        isSender ? 'items-end' : 'items-start',
      )}
    >
      {withTimestamp && (
        <div className="mb-2 h-2.5 w-8 animate-pulse rounded-md bg-surface-200" />
      )}
      <div className={cn(
        'rounded-b-2xl px-4 w-full max-w-64 h-10 py-2 bg-surface-200 animate-pulse',
        isSender ? 'rounded-tl-2xl' : 'rounded-tr-2xl',
        messageClassName,
      )}
      />
    </div>
  );
};
