import { forwardRef } from 'react';
import { CardProps, CardRef } from '@type/card';
import { CardHeader } from './CardHeader';
import { CardActions } from './CardActions';
import { CardBody } from './CardBody';
import { CardFooter } from './CardFooter';
import { getCardAnimation, getCardColor } from './cardStyles';


/**
 * @description Card component
 * @param props
 */
const Card = forwardRef<CardRef, CardProps>((props, ref) => {
  // destructure props
  const {
    className = '',
    children = 'Card contend',
    animation = 'none',
    color = 'none',
    ...restProps
  } = props;

  const cardColor = color && color !== 'none' ? ` ${getCardColor(color)}` : '';
  const cardClasses = `flex flex-col ${getCardAnimation(animation)}${cardColor} ${className}`;

  // Render component
  return (
    <div ref={ref} className={cardClasses} {...restProps}>
      { children }
    </div>
  );
});


// Add dot notation for sub-components
// *************************************************************************************************
const CardStyled = Object.assign(Card, {
  Header: CardHeader,
  Body: CardBody,
  Footer: CardFooter,
  Actions: CardActions,
});

export { CardStyled as Card };
