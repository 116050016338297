// RESUME GET OPERATION JOB ***************************************************************************
// pathname: /v1/resume-operation/get-operation-job
// ****************************************************************************************************
import {
  ApiGetOperationJobOk, GenericV1ErrorsArray,
  useSwrMutationApi,
} from '@hooks/useSwrApi';
import { SwrToastsProps } from '@type/swr';

// Interface
// ********************************************
interface SwrResumeGetOperationJob {
  jobId?: number,
  locale: string,
  successCallback?: (response: ApiGetOperationJobOk) => void,
  errorCallback?: (errors: GenericV1ErrorsArray) => void,
  toastsOptions?: SwrToastsProps,
}

/**
 * @description SWR mutation: Get last job, based on job id for operation (status or unlock).
 *
 * * **jobId**: number. Job ID.
 * * **locale**: string. Locale to use for the request. Default 'ro'.
 * * **successCallback**: function, optional.
 * * **errorCallback**: function, optional.
 * * **toastsOptions**: SWR toasts options, optional.
 */
export const useSwrResumeGetOperationJob = (props: SwrResumeGetOperationJob) => {
  // Props destructuring
  // ********************************************
  const {
    jobId,
    locale = 'ro',
    successCallback,
    errorCallback,
    toastsOptions,
  } = props;

  // Build url
  // ********************************************
  const url = `/v1/resume-operation/get-operation-job${jobId ? `?jobId=${jobId}` : ''}`;

  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiGetOperationJobOk, number | undefined>({
    url,
    locale,
    apiOptions: {
      onSuccess: (response) => {
        // Callback
        if (successCallback) successCallback(response);
      },
      onError: (errors) => {
        // Callback
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showErrorToast: true,
      ...toastsOptions,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
