// Hooks to fetch data from API
// ************************************************************************************************
export { useSwrApi } from './useSwrApi';
export { useSwrMutationApi } from './useSwrMutationApi';
export { useSwrInfiniteApi } from './useSwrInfiniteApi';
export { useSwrCache } from './useSwrCache';
export { useSwrGlobal } from './useSwrGlobal';
export { useRevalidateOnRefocus } from './revalidators/useRevalidateOnRefocus';


// Export Re-validators for easier use
// ************************************************************************************************
export * from './revalidators/revalidateAuthUser';


// Export API types & type-predicates for easier use
// ************************************************************************************************
export { baseURL, globalTimeout } from 'src/utils/data-fetching/utils';
export * from 'src/types/v1-api-types';
export * from 'src/type-predicates/v1-api-predicates';


// Export utils for easier use
// ************************************************************************************************
export { useOnTabRefocus } from '../common/tabFocus/useOnTabRefocus';
