/**
 * @description Picks key/value pairs from an object and returns a new object. Works similar to
 * TypeScript's Pick utility type.
 * @param obj - the object to pick from
 * @param keys - the keys to pick (string array)
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * pickFromObject(obj, ['a', 'b']);
 * // => { a: 1, b: 2 }
 */
export const pickFromObject = <T extends object, K extends keyof T>(obj: T, keys: K[]) => {
  // holder for new object
  const newObj = {} as T;

  // loop through keys and assign them to new object
  keys.forEach((key) => {
    newObj[key] = obj[key];
  });

  return newObj;
};
