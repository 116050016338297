import { hasCookie, getCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { route } from '@services/symfony';
import { useState, useEffect } from 'react';


// Use the non https only cookie mirror to check if logged. We have access to cookie's existence
// this way. This does not count as truly logged, it's just a shallow check though and help us avoid
// making unneeded API calls since we don't have access to https only cookies on the client side in js.
const rmCookie = process.env.NEXT_PUBLIC_RM_COOKIE || '';
const rmCookieLegacy = process.env.NEXT_PUBLIC_RM_COOKIE_LEGACY || '';

/**
 * @description Simple hook to check if the user is authenticated
 * **isAuth**: boolean - check if we have the rm_pbl cookie
 * **updateHeaderState**: redux state - if you want to update the header state
 */
export const useAuthClient = () => {
  // router
  const router = useRouter();


  // Check if the user is authenticated
  // 1. Depending on where you check you might also need this condition to prevent mismatch between server & client:
  // 2. typeof window !== 'undefined' (ie: if(typeof window !== 'undefined' && isAuth) { ... })
  // 3. Is stale when called on tab refocus; in these cases check with cookie function like below
  // ***********************************************
  const isAuth = hasCookie(rmCookie) || hasCookie(rmCookieLegacy);


  // Safe auth state
  // 1. If you get a hydrate mismatch error, you can use this state to check if the user is authenticated
  // 2. State is updated inside useEffect (this runs on client side only)
  // 3. Is stale when called on tab refocus; in these cases check with cookie function like isAuth
  // ***********************************************
  const [isSafeAuth, setIsSafeAuth] = useState<boolean>(false);

  // Update safe auth state
  useEffect(() => {
    if (isAuth) setIsSafeAuth(true);
  }, [isAuth]);


  // Check if the user is authenticated
  const checkAuthCookie = () => hasCookie(rmCookie) || hasCookie(rmCookieLegacy);


  // Get the auth date from cookie
  // ***********************************************
  const getAuthCookieDate = () => (getCookie(rmCookie) || getCookie(rmCookieLegacy)) as string;


  // Redirect to login page if not authenticated
  // ***********************************************
  const redirectToLogin = (afterLoginRedirectPath?: string) => {
    if (!isAuth) {
      let options = {};
      if (afterLoginRedirectPath) {
        options = { _target_path: afterLoginRedirectPath };
      }
      router.push(route('login', options));
    }
  };


  return {
    isAuth,
    isSafeAuth,
    checkAuthCookie,
    getAuthCookieDate,
    redirectToLogin,
  };
};
