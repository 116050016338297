// TYPES
export type ConvertValueTo = 'toString' | 'toBoolean' | 'toNumber' | 'commaStringToArray' | undefined;
export type ConvertEmptyTo = 'toString' | 'toBoolean' | 'toNumber' | 'toNull' | undefined;
export type Keys = keyof typeof Object;
export type ObjValues = typeof Object[Keys];
export type ConvertFrom = string | number | boolean | null | object | ObjValues | [] | undefined;


/**
 * @description Helper function to check id a value can be considered empty
 * @param value
 *
 * @example
 * // the 'empty-sh' values
 * // emptyValues =
 * //   value === ''
 * //   || value === false
 * //   || value === 0
 * //   || value === null
 * //   || value === '0'
 * //   || value === 'false'
 * //   || value === undefined
 * //   || (Array.isArray(value) && value.length === 0);
 */
export const checkEmptyValue = (value: ConvertFrom) => value === ''
    || value === false
    || value === 0
    || value === null
    || value === '0'
    || value === 'false'
    || value === undefined
    || (Array.isArray(value) && value.length === 0);


/**
 * @description Helper function to convert 'empty' form field values, like - empty string, false, 0, null - to values required by the server.
 * @description If the value does not qualify for conversion is returned untouched.
 * @param type
 * @param value
 * @example
 *
 * // the 'empty-sh' values
 * // emptyValues =
 * //   value === ''
 * //   || value === false
 * //   || value === 0
 * //   || value === null
 * //   || value === '0'
 * //   || value === 'false'
 * //   || value === undefined
 * //   || (Array.isArray(value) && value.length === 0);
 *
 * const emptyString = '';
 * const someNumber = 123;
 *
 * const convertedString = convertEmptyValue('toNumber', emptyString);
 * const convertedNumber = convertEmptyValue('toString', someNumber);
 *
 * // result
 * // convertedString = 0;
 * // convertedNumber = 123; // it does nothing to it, it's not 'empty-sh'
 *
 * // Example use-case: in the add experience form API, the location is an optional text input,
 * // but needs 'null' if empty instead of the normal empty string value. Otherwise, the server will return the field as invalid.
 * <Input type="text" value {...record('location', { convertEmptyValue: 'toNull' })} />
 */

export const convertEmptyValue = (type: ConvertEmptyTo, value: ConvertFrom) => {
  // toString: convert 'empty' value to string
  if (type === 'toString') {
    if (checkEmptyValue(value)) return '';
  }

  // toBoolean: convert 'empty' value to boolean
  if (type === 'toBoolean') {
    if (checkEmptyValue(value)) return false;
  }

  // toNumber: convert 'empty' value to number
  if (type === 'toNumber') {
    if (checkEmptyValue(value)) return 0;
  }

  // toNull: convert 'empty' value to null
  if (type === 'toNull') {
    if (checkEmptyValue(value)) return null;
  }

  return value;
};


/**
 * @description Helper function to convert a value to boolean
 * @param value
 *
 * @example
 * // these values will return false
 * // emptyValues =
 * //   value === ''
 * //   || value === false
 * //   || value === 0
 * //   || value === null
 * //   || value === '0'
 * //   || value === 'false'
 * //   || value === undefined
 * //   || (Array.isArray(value) && value.length === 0);
 */
export const convertToBoolean = (value: ConvertFrom) => !checkEmptyValue(value);


/**
 * @description Convert strings & booleans to number. Can be used for arrays with the map method.
 * Can be used together with {@link convertEmptyValue} if you want to also convert 'null' & 'undefined'.
 * @param value
 *
 * @example
 * // simple convert
 * convertToNumber('22');
 *
 * // convert an array
 * array.map(convertToNumber);
 *
 * // also convert null & undefined
 * convertEmptyValue('toNumber', convertToNumber(value));
 */
export const convertToNumber = (value: ConvertFrom) => {
  // convert strings
  if (typeof value === 'string') {
    // take care of boolean strings or empty string
    if (value === 'false' || value === '') return 0;
    if (value === 'true') return 1;

    // take care of numbers as strings
    if (!Number.isNaN(value) && !Number.isNaN(Number(value))) {
      return Number(value);
    }

    // return the string if it's not actually a numerical value
    return value;
  }

  // convert boolean
  if (typeof value === 'boolean') {
    if (!value) return 0;
    return 1;
  }

  return value;
};


/**
 * @description Converts numbers & booleans to string. Can be used for arrays with the map method.
 * Can be used together with {@link convertEmptyValue} if you want to also convert 'null' & 'undefined'.
 * @param value
 *
 * @example
 * // simple convert
 * convertToString(22);
 *
 * // convert an array
 * array.map(convertToString);
 *
 * // also convert null & undefined
 * convertEmptyValue('toString', convertToString(value));
 */
export const convertToString = (value: ConvertFrom) => {
  // convert numbers
  if (typeof value === 'number' || typeof value === 'boolean') {
    return String(value);
  }

  return value;
};


/**
 * @description Convert a value to desired type.
 * @param type - 'toString' | 'toBoolean' | 'toNumber' | 'commaStringToArray'
 * @param value - the value to be converted
 *
 * @example
 * // simple convert
 * convertValue('toNumber', value);
 *
 * // convert values inside array
 * array.map((item) => convertValue('toString', item));
 *
 * // also convert null & undefined
 * convertEmptyValue('toString', convertValue('toString', value));
 */
export const convertValue = (type: ConvertValueTo, value: ConvertFrom) => {
  // toBoolean: convert value to boolean
  if (type === 'toBoolean') {
    return convertToBoolean(value);
  }

  // toNumber: convert value to number
  if (type === 'toNumber') {
    return convertToNumber(value);
  }

  // toString: convert value to string
  if (type === 'toString') {
    return convertToString(value);
  }

  // commaStringToArray: convert value to a string array (if not empty)
  if (type === 'commaStringToArray') {
    const valueString = convertToString(value);
    if (value && typeof valueString === 'string') {
      return valueString.split(',').map((item) => item.trim());
    }
    return valueString;
  }

  return value;
};
