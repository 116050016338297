import { CardAnimations, CardColors } from '@type/card';

// Common styles for transitions
const animationCommon = 'transition-all duration-200 ease-in-out';

// Variants for transitions
const animationVariants = {
  none: '',
  rise: `${animationCommon} xl:hover:shadow-cardHover`,
  riseUp: `${animationCommon} xl:hover:shadow-cardHover xl:hover:-translate-y-1`,
  zoom: `${animationCommon} xl:hover:shadow-cardHover xl:hover:scale-105`,
};

// Variants for colors
const colorVariants = {
  none: '',
  primary: 'bg-primary text-surface',
  'primary-light': 'bg-primary-light text-surface',
  secondary: 'bg-secondary text-surface',
  'secondary-light': 'bg-secondary-light text-dark',
  warning: 'bg-warning text-dark',
  error: 'bg-error text-surface text-white',
  light: 'bg-light',
  ink: 'bg-ink text-surface',
  'ink-medium': 'bg-ink-medium text-surface',
  white: 'bg-white text-dark',
  info: 'bg-info text-surface',
  dark: 'bg-dark text-white',
  pink: 'bg-pink text-white',
};


// Function to get the animation variant
// *******************************************************************************
export const getCardAnimation = (animation: CardAnimations): string => animationVariants[animation];


// Function to get the color variant
// *******************************************************************************
export const getCardColor = (color: CardColors): string => colorVariants[color];
