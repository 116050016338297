import { Middleware } from 'swr';
import { useEffect } from 'react';
import { GenericV1ErrorsArray } from 'src/types/v1-api-types/_global';
import { useStaticToasts } from 'src/hooks/useStaticToasts/useStaticToasts';
import { SwrToastsProps } from '@type/swr';


/**
 * @description SWR middleware: show toasts on data change. It will show only for props that are in the config object.
 * * **showSuccessToast** - boolean - show success toast on data change; default undefined
 * * **showErrorToast** - boolean - show error toast on data change; default undefined
 * * **successToastText** - string - custom success toast text; default undefined. If not provided, it will show a default success toast.
 * * **errorToastText** - string - custom error toast text; default undefined. If not provided, it will show a generic error toast.
 * * **useGenericErrorToast** - boolean - use generic error toast with the default text; default undefined.
 * * **byErrorCodes** - ErrorByCode[] - show error toast based on error codes; default undefined.
 */
export const withToasts = (props: SwrToastsProps): Middleware => (useSWRNext) => (key, fetcher, config) => {
  // Destructure props
  const {
    showSuccessToast,
    showErrorToast,
    successToastText,
    errorToastText,
    genericErrorToast,
    byErrorCodes,
  } = props;

  // Actual SWR hook.
  const swr = useSWRNext(key, fetcher, config);

  // Static toasts
  const {
    status200Toast, statusGenericErrorToast, showStatusToast,
  } = useStaticToasts();

  // Show success toasts
  // ********************************************
  useEffect(() => {
    if (swr?.data !== undefined) {
      // Toasts
      if (showSuccessToast) {
        if (successToastText) {
          status200Toast(successToastText);
        } else {
          status200Toast();
        }
      }
    }
  }, [
    swr?.data,
    status200Toast,
    successToastText,
    showSuccessToast,
  ]);


  // Show error toasts
  // ********************************************
  useEffect(() => {
    if (swr?.error !== undefined) {
      const errorObj = swr?.error as GenericV1ErrorsArray;
      const firstError = errorObj?.errors?.[0];
      const firstCode = firstError?.code;
      const firstMessage = firstError?.message;

      if (showErrorToast) {
        switch (true) {
          case !!errorToastText:
            statusGenericErrorToast(errorToastText);
            break;
          case !!genericErrorToast:
            statusGenericErrorToast();
            break;
          case Array.isArray(byErrorCodes) && byErrorCodes.length > 0: {
            const errorText = byErrorCodes.find((item) => item.code === String(firstCode))?.text;
            statusGenericErrorToast(errorText || undefined);
            break;
          }
          case firstCode !== undefined: {
            if (firstMessage) {
              showStatusToast(firstCode, { [`message_${firstCode}`]: firstMessage });
            } else {
              showStatusToast(firstCode);
            }
            break;
          }
          case firstMessage !== undefined: {
            statusGenericErrorToast(firstMessage);
            break;
          }
          default:
            statusGenericErrorToast();
            break;
        }
      }
    }
  }, [
    swr?.error,
    statusGenericErrorToast,
    showStatusToast,
    errorToastText,
    showErrorToast,
    genericErrorToast,
    byErrorCodes,
  ]);


  // Return data
  // ********************************************
  return { ...swr };
};
