import { useAppSelector } from 'src/app';
import { getUserHeaderInfo } from '@store/reducers/userSelector';


/**
 * @description Convenience hook to get different user states from the redux store. Add more as needed but don't forget
 * to add to description.
 *
 * Returns:
 * - **headerInfo:** *object* - the header info data. Use it to get the users name, email, etc., or check if the user is
 * authenticated.
 */
export const useReduxUser = () => {
  // Get the header info.
  const headerInfo = useAppSelector(getUserHeaderInfo);

  return {
    headerInfo,
  };
};
