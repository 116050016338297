import { GlobalValuesTypes } from '@type/form-types';

/**
 * @description Helper function that trim the string empty spaces at both ends and removes all
 * multiple consecutive whitespaces. Used to clean up the submitted form data, because sometimes the API
 * might accept a long whitespaces string and fail silently (ie: add an experience, use a long whitespaces string for job title)
 *
 * @description Since the form values can be of multiple types, you can pass them, but it will transform only strings.
 *
 * @param value - string | number | boolean | [] | object | null | undefined
 * @param condition - - useful if you trigger this function as an option in {@link useBjForm} (default `true`)
 *
 * @example
 * let myString = "  So     many     spaces   ";
 * console.log(trimStringEmptySpaces(myString));
 * // output: "So many spaces"
 */
export const trimStringEmptySpaces = (value: GlobalValuesTypes, condition = true) => {
  let val = value;
  if (typeof value === 'string' && condition) {
    val = value.replace(/\s\s+/g, ' ').trim();
  }
  return val;
};
