import { GetI18nLinkHref, RegexPaths, I18nLangPaths } from 'src/types/i18n-paths';
import { pathToRegexp } from 'path-to-regexp';
import { compileToPath, getRegexPathParams } from '@utils/path-to-regexp';
import { checkEmptyObject } from '@utils/objects/checkEmptyObject';
import { i18nBasePaths } from './paths';
import { setLocalePrefix } from './helpers_all';


/**
 * @description Bi-dimensional array with the i18n base paths transformed into a usable form for
 * comparison.
 *
 * @example
 * // output
 * [
 *  [
 *    {pattern: 'Regex string', source: 'Source path'},
 *    {ro: 'path', en: 'path', hu: 'path'},
 *  ]
 * ]
 */
const linksToRegex: [RegexPaths, I18nLangPaths][] = Object.entries(i18nBasePaths).map((entry) => [
  {
    pattern: pathToRegexp(entry[0]).regexp,
    source: entry[0],
  },
  entry[1],
]);


/**
 * @description Check if the passed href is in i18nBasePaths. If it is, return the i18n one,
 * otherwise return the provided url.
 * @param href string: normal Next folder based path, not the translated one
 * @param locale string: the target locale (if you need, pass any locale)
 * @param withLocale boolean: whether to include the locale in the url, in case you really, really want the raw path (with false).
 */
export const getI18nLinkHref: GetI18nLinkHref = (href, locale, withLocale = true) => {
  let translatedUrl: string | undefined;
  const normalUrl = withLocale ? `${setLocalePrefix(locale)}${href}` : href;

  linksToRegex.forEach((entry) => {
    // get the search params from the asPath
    const [cleanHref, rawSearchParams] = href.split('?');
    const searchParams = rawSearchParams ? `?${rawSearchParams}` : '';

    if (entry[0].pattern.test(cleanHref)) {
      // get the dynamic parameters (if any) from path (ie: {id: "confidentiality"})
      const dynamicParams = getRegexPathParams(cleanHref, entry[0]);

      // return the translated URL
      if (translatedUrl === undefined) {
        translatedUrl = `${compileToPath(entry[1][locale as Locale], dynamicParams)}${searchParams}`;
      }

      // This check is important because we might have a link that looks like a dynamic translated url
      // but, it's actually a normal translated url. Consider having these links in paths.ts:
      // '/temp/translate-url/localized-url' and '/temp/translate-url/:id' => for regex-param they
      // both match. The trick is that for a non-dynamic url, if it matches the params object is empty.
      // So, we override a previously matched url only if it is a non-dynamic translated url.
      if (translatedUrl !== undefined && checkEmptyObject(dynamicParams)) {
        compileToPath(entry[1][locale as Locale], dynamicParams);
      }
    }
  });

  // Prepend locale to the translated URL
  // Check if the url is not an external one. You should use a simple a tag or Anchor component for that,
  // but, you know, just in case you forget.
  if (translatedUrl && withLocale && !href.startsWith('http://') && !href.startsWith('https://')) {
    translatedUrl = `${setLocalePrefix(locale)}${translatedUrl}`;
  }

  return translatedUrl || normalUrl;
};
