import { useEffect } from 'react';
import { useSWRConfig } from 'swr';
import { revalidateAuthUser } from './revalidateAuthUser';


/**
 * @description Hook that revalidates the SWR cache when the tab is refocused if the user is
 * different.
 *
 */
export const useRevalidateOnRefocus = () => {
  const { mutate: globalMutate } = useSWRConfig();

  useEffect(() => {
    // when tab is refocused, run the callback
    const tabFocusCallback = () => {
      if (document.visibilityState === 'visible' && revalidateAuthUser()) {
        void globalMutate(
          () => true, // which cache keys are updated => all of them
          undefined, // update cache data to `undefined`
        );
      }
    };

    // Add event listener
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', tabFocusCallback, false);
    }

    // Cleanup the effect and remove the event listener
    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('visibilitychange', tabFocusCallback, false);
      }
    };
  }, [globalMutate]);
};
