import { Middleware } from 'swr';
import { unstable_serialize, SWRInfiniteKeyLoader } from 'swr/infinite';
import { useEffect, useRef } from 'react';


/**
 * @description SWR Middleware that populates the cache with initial data from a server-side request. We prevent the
 * initial request by resolving a Promise with the server-side data instead of fetching it.
 *
 * 1. The process only happens at hook mount, we do NOT prevent further mutations even if they have the same cache key
 * (we use useRef, we don't trigger effects).
 * 2. The process is carried out only for the key that the hook writes to the mount cache. As an example, we prevent the
 * request and write directly into the cache only the existing key at the time the page is accessed,
 * ie: /v3/talent-search&page=2 but not for /v3/talent-search&page=2&limit=12 or /v3/talent -search&page=3.
 * As the middleware runs at each SWR request, a way of unambiguous identification must be found, a key is always the
 * first as long as it has not been written in the return cache (key, fetcher, config). It remains to be seen how SWR
 * writes a series of keys for infinity (in the cache), if it has another identifier.
 * 3. When *{revalidateOnMount: true}*, we only revalidate the sibling keys (ie same URL & params only the page is different),
 * not the original key which gets its data from the server-side request.
 *
 * **Parameters:**
 * - **keyOnMount** - the initial key that the hook uses on mounting.
 *
 * **Returns:**
 * - the SWR hook with the server side data if available.
 *
 * @example
 * // Usage in middlewares for SWR infinite:
 * serverDataInfinite(keyOnMount)
 */
export const serverDataInfinite = (keyOnMount: string): Middleware => (useSWRNext) => (key, fetcher, config) => {
  // Data from the server
  const baseServerData = config?.fallbackData;

  // Because in SWR infinite we need to put the server data into an array, we need to extract it.
  // The data is always an array, so we take the first element.
  const serverData = baseServerData && Array.isArray(baseServerData) ? baseServerData[0] as typeof baseServerData : baseServerData;

  // Store hook mount status
  const alreadyMounted = useRef(false);

  // Get the cache key (for SWR Infinite is the series key '@inf', not the page key '@')
  const currentSeriesKey = unstable_serialize(key as SWRInfiniteKeyLoader);

  // Check if we should use the server data
  const useServerData = serverData && !alreadyMounted?.current && currentSeriesKey.includes(keyOnMount);


  // Handle hook mount status
  // ********************************************
  useEffect(() => {
    alreadyMounted.current = true;
    return () => {
      alreadyMounted.current = false;
    };
  }, []);


  // If we have server data, we use it, otherwise we fetch the data.
  // Unlike the normal SWR, we save the server data in the cache by settling a promise, because SWR Infinite always
  // fetches the first page, no matter what.
  // ********************************************
  return useSWRNext(key, useServerData ? () => Promise.resolve(serverData) : fetcher, {
    ...config,
    // if the data is already saved in the cache, we reset the fallback data
    fallbackData: alreadyMounted.current ? undefined : baseServerData,
  });
};
