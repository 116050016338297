import { useTranslation } from 'next-i18next';
import { useAppDispatch } from '@store/index';
import { saveSelectedUpgradePackage } from '@store/reducers/employer/subscription/subscriptionReducer';
import { Button, Card } from '@components/common';
import { useSwrMutationPaymentType } from '@hooks/useSwrApi/endpoints/subscription';
import { extractNumber } from '@utils/strings/extractNumber';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';


// Interface
interface CardUpgradeProps {
  packageData: ApiSchema<'SubscriptionPackageModel'>,
  className?: string,
}


/**
 * @description Renders the upgrade card for Subscription Upgrades. Might render a package or an
 * extra credits upgrade. Use it in the Subscription Upgrade List.
 * * **packageData** is the data for the package or extra credits upgrade.
 * * **className** extra classes you might want to pass on the card.
 */
export const UpgradeCard = (props: CardUpgradeProps) => {
  // Props destructuring
  const {
    packageData,
    className = '',
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Router
  const { locale } = useExtendedRouter();

  // Redux dispatch
  const appDispatch = useAppDispatch();


  // 1. SWR - POST data with the packageId to get the payment options
  // We save the response in Redux state from inside this hook
  // *****************************************
  const {
    trigger: paymentTypeTrigger,
    isMutating: paymentTypeMutating,
  } = useSwrMutationPaymentType(locale);


  // 2. Handle click on order button
  // *****************************************
  const handleOrder = () => {
    // the packageId is a string, but the API expects a number
    const packageId = extractNumber(packageData?.packageId);
    if (packageId) {
      // Save the selected package in Redux state
      appDispatch(saveSelectedUpgradePackage(packageData));
      // Trigger SWR post request
      void paymentTypeTrigger({ packageId });
    }
  };


  // Styles / text
  // *****************************************
  // Package type
  const packageType = {
    label: packageData?.type === 'extra' ? t('extra-credits.package.type.label') : t('subscription.package.available'),
    color: packageData?.type === 'extra' ? 'bg-primary' : 'bg-secondary',
  };

  // Card classes
  const cardHoverColor = packageData?.type === 'extra' ? 'hover:border-primary/40' : 'hover:border-secondary/60';
  const cardClasses = `relative text-center rounded-md bg-surface border w-full xl:max-w-80 ${cardHoverColor} ${className || ''}`;

  // Render component
  // *****************************************
  return (
    <Card animation="riseUp" className={cardClasses}>
      <Card.Header className="px-5 pt-5">
        <div className="flex items-center justify-between">
          <h6 className="text-base font-bold">
            {packageData.mainTitle}
          </h6>
          <h6
            className={`rounded px-2 text-sm font-semibold text-surface ${packageType.color}`}
          >
            {packageType.label}
          </h6>
        </div>
      </Card.Header>

      <Card.Body className="flex flex-col px-5 pb-5 pt-0">
        <div className="mb-5 flex items-center justify-between">
          <span className="text-sm lowercase text-ink-medium">
            {t('subscriptions.packages.free_activation')}
          </span>
        </div>
        <ul className="flex flex-col divide-y divide-dashed ">
          {packageData?.benefits?.map((benefit, index) => (
            <li
              className="flex gap-2 py-2 text-start capitalize leading-tight"
              key={`benefit-${packageData.packageId}-${index + 1}`}
            >
              <CheckCircleIcon className="size-5 shrink-0 text-secondary" />
              <span>{benefit}</span>
            </li>
          ))}
        </ul>
      </Card.Body>

      <Card.Footer className="px-5 pb-5 md:pt-2">
        <Button
          size="sm"
          className="w-full"
          rounding="full"
          color="primary"
          styling="outline"
          isLoading={paymentTypeMutating}
          onClick={handleOrder}
        >
          {packageData.ctaTitle}
        </Button>
      </Card.Footer>
    </Card>
  );
};
