import { useAppDispatch } from '@store/index';
import { useCallback } from 'react';
import { ISocketCallParticipantData } from '@type/socket-online';

/**
 * @description This hook is used for a company to start the online socket call
 */
export const useOpenTokCallStart = () => {
  const dispatch = useAppDispatch();

  // show video options after unlocking the cv
  const onShowVideoOptions = useCallback((participant: ISocketCallParticipantData) => {
    dispatch({ type: 'socketOnline/saveTalentParticipant', payload: participant });
  }, [dispatch]);

  return {
    // show video options after unlocking the cv
    onShowVideoOptions,
  };
};
