/**
 * @description Global timeout -> 5 sec more than min required by the back-end colleagues.
 * It's a lot, I know...
 */
export const globalTimeout = 65000;


/**
 * @description The base URL for API calls.
 */
export const baseURL = process.env.NEXT_PUBLIC_API_URL as string;

/**
 * @description The base URL for Open API calls.
 */
export const openAPIURL = process.env.NEXT_PUBLIC_OPEN_API_URL as string;

/**
 * @description Error handler for generic data-fetching **catch** block. This is not for response
 * errors from server, you will handle those inside a *.then()* block probably using the
 * 'isApiV1Errors' type predicate.
 * @param signal *AbortSignal*
 * @param error *object*
 * @param URL *string*
 */
export const fetcherErrorHandler = (signal: AbortSignal, error: unknown, URL: string) => {
  if (error instanceof Error) {
    const { aborted, reason } = <{ aborted: boolean, reason: string | undefined }>signal;

    if (aborted && reason === 'TimeoutError') {
      // Might add a toast in the future
      console.error(`Connection timeout when fetching ${URL}`);
    } else if (!aborted) {
      // A network error, or some other problem.
      console.error(`Error: type: ${error.name}, message: ${error.message}, fetching: ${URL}`);
    }
  } else {
    // A network error, or some other problem.
    console.error(`Error trying to fetch: ${URL}`, error);
  }
};


/**
 * @description Function that creates and returns a new AbortController (**controller**) and a
 * timed-out abort call with a defined period (**timeoutId**). Default timeout: globalTimeout.
 * @param timeout *ms*: number
 *
 * @example
 * // usage inside useEffect
 * useEffect(() => {
 *    // create the abort controller & timeoutId
 *    const { controller, timeoutId } = fetchAbort();
 *
 *    // do your stuff, pass the controller & timeoutId to some function or w/e
 *    fetcher(URL, locale, {externalAbort: {extController: controller, extTimeout: timeoutId}})
 *      .then((response) => {...});
 *
 *    // cleanup fetcher (fetcher will no longer get called twice in strict mode)
 *    return () => controller?.abort();
 * }, []);
 */
export const fetchAbort = (timeout = globalTimeout) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => {
    controller.abort('TimeoutError');
  }, timeout);

  return {
    controller,
    timeoutId,
  };
};

export const isFullUrl = (url: string): boolean => url.startsWith('http://') || url.startsWith('https://');
