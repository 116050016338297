import { ParsedUrlQuery } from 'querystring';
import { i18nConfig } from '../../../i18n-config';

type ParamsWithLocale = ParsedUrlQuery & { locale?: string };

/**
 * Get the locale from the NextJs `GetServerSideProps` context params.
 *
 * @param {ParsedUrlQuery | undefined} params - The parameters from the Next.js `GetServerSideProps` context. Can be undefined.
 * @returns {Locale} - The resolved locale. If the `locale` parameter is a valid string, it is returned as the locale.
 * Otherwise, the default locale from the i18n configuration is returned.
 */
export const gsspGetLocale = (params: ParamsWithLocale | undefined) => (
  typeof params?.locale === 'string' ? params.locale as Locale : i18nConfig.defaultLocale
);

export const getLocaleFromQuery = (query: ParamsWithLocale | undefined) => (
  typeof query?.locale === 'string' ? query.locale as Locale : i18nConfig.defaultLocale
);
