import { useState } from 'react';
import { bjToast } from '@components/common';
import { useTranslation } from 'next-i18next';


/**
 * @description Hook to copy text to the clipboard. This can be used to copy a text from anywhere
 * (ie: form elements, divs, paragraphs, etc) to the clipboard. A success toast will be shown on copy.
 *
 * @param copyText required: text to be copied to the clipboard
 * @param showToast optional: show toast on copy success
 * @param toastText optional: text to be shown in the toast; directly use a translation key
 *
 * @example
 * // some text to be copied
 * const someValue = 'some text';
 *
 * // init the hook
 * const { handleCopyText } = useCopyToClipboard(someValue);
 *
 * // inside render, this is the button that will trigger the copy action
 * <button onClick={handleCopyText}>Copy to clipboard</button>
 *
 */
export const useCopyToClipboard = (copyText: string, showToast = true, toastText = 'global.clipboard.link-copied') => {
  // Translation
  const { t } = useTranslation('common');

  // State to keep track of whether the text has been copied or not.
  const [isCopied, setIsCopied] = useState(false);

  // We're using the javascript Clipboard API to copy the text to the clipboard.
  const copyToClipboard = async (text: string) => navigator.clipboard.writeText(text);

  // When the copy action is triggered, we copy the text to the clipboard and set the isCopied state
  // to true.
  const handleCopyText = () => {
    copyToClipboard(copyText)
      .then(() => {
        if (!isCopied) {
          setIsCopied(true);
          // Show toast
          if (showToast) {
            bjToast.success(t(toastText));
          }
          // reset the state after interval
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        }
      })
      .catch((error) => {
        console.error('Failed to copy to clipboard!', error);
      });
  };

  return {
    handleCopyText,
    copyToClipboard,
    isCopied,
    setIsCopied,
  };
};
