/**
 * @description Get the canonical url for the current page. Adds lang prefix for non default
 * languages. Remove query parameters from the end of url if any (.split...).
 * It should be enough to use in _app.tsx (for pages folder) or layout (for app folder)
 * @param locale string: the page locale (if you need, pass any locale)
 * @param asPath string: the current page path
 * @param domain string: the domain for canonical url; default *https://www.bestjobs.eu*
 */
export const getCanonicalUrl = (asPath: string, domain = 'https://www.bestjobs.eu'): string => (
  `${domain}${asPath}`
).split('?')[0];
