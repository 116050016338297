/**
 * @description Removes multiple items from an array.
 * @param arr - Array to remove items from.
 * @param toRemove - Another array with the items to remove from the main array.
 *
 * @returns The array without the items to remove.
 *
 * @example
 * const arr = [1, 2, 3, 4, 5];
 * const toRemove = [2, 4];
 * // arr = [1, 3, 5]
 */
export const arrayRemoveItems = <DataType>(arr: DataType[], toRemove: DataType[]) => arr.filter((item) => !toRemove.includes(item));


/**
 * @description Removes multiple objects from an array.
 * @param arr - Array to remove objects from.
 * @param toRemove - Another array with the objects to remove from the main array.
 * @param key - Key to compare the objects.
 *
 * @returns The array without the objects to remove.
 *
 * @example
 * const arr = [{ id: 1, name: 'John' }, { id: 2, name: 'Mary' }, { id: 3, name: 'Peter' }];
 * const toRemove = [{ id: 2, name: 'Mary' }, { id: 3, name: 'Peter' }];
 * const key = 'id';
 * // arr = [{ id: 1, name: 'John' }]
 */
export const arrayRemoveObjects = <DataType extends object, Key extends keyof DataType>(arr: DataType[], toRemove: DataType[], key: string) => arr
  .filter((item) => !toRemove.find((itemToRemove) => item[key as Key] === itemToRemove[key as Key]));
