'use client';

import { useTranslation } from 'next-i18next';
import { ChatMessageBubble } from '@components/templates/bia/chat/ChatMessageBubble';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Message, useStreamState } from '@components/templates/bia/hooks/useStreamState';
import { v4 as uuidv4 } from 'uuid';
import {
  bjToast, Button, ChatTypingDots, Tooltip,
} from '@components/common';
import { PaperAirplaneIcon, PlusIcon } from '@heroicons/react/24/solid';

import { fetcher } from '@utils/data-fetching';
import { ChatAgent } from '@components/templates/bia/chat/ChatAgent';
import { ChatTextarea } from '@components/common/Chat/ChatTextarea';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import { BiaDropdownInterface } from '@type/header-types';

const CHAT_API = `${process.env.NEXT_PUBLIC_AI_URL}/chat`;

/**
 * @description Renders the bia chat dropdown.
 * @param agent
 * @param job
 * @constructor
 */
export const BiaDropdown = ({ agent, job }: BiaDropdownInterface) => {
  // translation
  const { t } = useTranslation('common');
  const { router } = useExtendedRouter();

  const { stream, startStream, initializeWithHistory } = useStreamState(CHAT_API);
  const [input, setInput] = useState('');
  const wasInitialised = useRef(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const showSmallAgent = useRef(false);
  const hasCheckedMessages = useRef(false);
  const historyMessagesCount = useRef(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messageContainerRef = useRef<HTMLDivElement | null>(null);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const initialMessageFromUrlSent = useRef(false);
  const [previousSentMessagesIndex, setPreviousSentMessagesIndex] = useState<number | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const suggestions = [
    {
      name: t('landing.bia.cta1'),
      url: `?q=${t('landing.bia.cta1')}`,
      event: 'bia_conversation_starter_1',
    },
    {
      name: t('landing.bia.cta2'),
      url: `?q=${t('landing.bia.cta2')}`,
      event: 'bia_conversation_starter_2',
    },
    {
      name: t('landing.bia.cta3'),
      url: `?q=${t('landing.bia.cta3')}`,
      event: 'bia_conversation_starter_3',
    },
    {
      name: t('landing.bia.cta4'),
      url: `?q=${t('landing.bia.cta4')}`,
      event: 'bia_conversation_starter_4',
    },
  ];

  const showWelcomeBack = useCallback(
    (initialMessages: Message[], lastMessageDate: Date) => {
      if (!hasCheckedMessages.current) {
        const oneDayAgo = new Date();
        oneDayAgo.setDate(oneDayAgo.getDate() - 1);
        if (
          initialMessages
          && initialMessages.length > 1
          && lastMessageDate < oneDayAgo
        ) {
          showSmallAgent.current = true;
        }

        hasCheckedMessages.current = true;
        historyMessagesCount.current = initialMessages.length || 0;
      }
    },
    [hasCheckedMessages],
  );

  useEffect(() => {
    if (!initialMessageFromUrlSent.current) {
      const searchParams = new URLSearchParams(window.location.search);
      const initialQuery = searchParams.get('q');
      if (initialQuery) {
        const message = {
          id: uuidv4(),
          content: initialQuery,
          type: 'human',
        };

        void startStream(message, agent.id || '', {
          configurations: { job },
        });

        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.delete('q');
        if (typeof window !== 'undefined') {
          router.replace('/bia', { scroll: false });
        }
        initialMessageFromUrlSent.current = true;
      }
    }
  }, [router, startStream, agent.id, job]);

  useEffect(() => {
    if (!wasInitialised.current) {
      void initializeWithHistory(agent.id, job).then((response) => {
        showWelcomeBack(response.messages, new Date(response.created_at));
      });

      wasInitialised.current = true;
    }
  }, [agent.id, initializeWithHistory, showWelcomeBack, job]);

  const handleSuggestionClick = (suggestion: {
    name: string;
    url: string;
    event: string;
  }) => {
    const message = {
      id: uuidv4(),
      content: suggestion.name,
      type: 'human',
    };
    sendGoogleTagEvent(suggestion.event, 'Custom');

    void startStream(message, agent.id || '', { configurations: { job } });
  };

  const handleSubmit = async (event?: { preventDefault?: () => void }) => {
    event?.preventDefault?.();

    if (!input) return;

    const message = {
      id: uuidv4(),
      content: input,
      type: 'human',
    };
    setInput('');
    try {
      await startStream(message, agent.id || '', {
        configurations: { job },
      });
    } catch (error) {
      console.error('Error sending message', error);
    }
  };

  const handleChatInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setInput(e.target.value);
  };

  // When the user presses enter, submit the message
  const handleChatInputEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    void handleSubmit();
    setPreviousSentMessagesIndex(null); // reset the index
  };

  // When the user presses shift + enter, add a new line in the textarea
  const handleChatInputShiftEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setInput(`${input}\n`);
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };

  // When the user presses arrow up, search up through the previous sent messages
  const handleChatInputArrowUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    // get all human messages - previous sent messages
    const humanMessages = stream?.messages?.filter((msg) => msg.type === 'human') || [];
    if (previousSentMessagesIndex === null && humanMessages.length) {
      // index is null, set it to the last message
      setPreviousSentMessagesIndex(humanMessages.length - 1);
      setInput(humanMessages[humanMessages.length - 1].content);
    } else if (previousSentMessagesIndex !== null && previousSentMessagesIndex > 0) {
      // index is not null, decrement it
      setPreviousSentMessagesIndex(previousSentMessagesIndex - 1);
      setInput(humanMessages[previousSentMessagesIndex - 1].content);
    }
  };

  // When the user presses arrow down, search down through the previous sent messages
  const handleChatInputArrowDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    // get all human messages - previous sent messages
    const humanMessages = stream?.messages?.filter((msg) => msg.type === 'human') || [];
    if (previousSentMessagesIndex !== null && previousSentMessagesIndex < humanMessages.length - 1) {
      // index is not null, increment it
      setPreviousSentMessagesIndex(previousSentMessagesIndex + 1);
      setInput(humanMessages[previousSentMessagesIndex + 1].content);
    } else {
      // index is the last message, reset it
      setPreviousSentMessagesIndex(null);
      setInput('');
    }
  };

  // Handle keydown events
  const handleChatInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleChatInputEnter(e);
    } else if (e.key === 'Enter' && e.shiftKey) {
      handleChatInputShiftEnter(e);
    } else if (e.key === 'ArrowUp') {
      handleChatInputArrowUp(e);
    } else if (e.key === 'ArrowDown') {
      handleChatInputArrowDown(e);
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target;
    if (!files || files.length === 0) return;
    setIsUploading(true);
    const formData = new FormData();
    let filename = '';
    const message = t('landing.bia.upload_document_message');
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
      filename += `<br/>${files[i].name}`;
    }
    formData.append('input', message + filename);
    try {
      const agentName = agent.id;
      const response = await fetcher(`${CHAT_API}/${agentName}`, undefined, {
        method: 'POST',
        payload: formData,
      });

      if (!response) {
        throw new Error('Failed to upload file');
      }

      await initializeWithHistory(agent.id, job);
    } catch (error) {
      bjToast.error(t('landing.bia.upload_document_error'));

      console.error('Error uploading file:', error);
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
        fileInputRef.current?.blur();
      }
      setIsUploading(false);
    }
  };

  return (
    <div className="flex h-full grow rounded bg-surface-100 shadow-lg">
      <div
        className="container mx-auto flex h-full flex-col items-center overflow-y-hidden rounded bg-surface p-4"
      >
        <div
          className="mb-2 flex size-full flex-col-reverse overflow-y-scroll"
          ref={messageContainerRef}
        >
          {stream?.status === 'inflight' && <ChatTypingDots />}

          {stream?.messages
            && [...new Map(stream.messages.map((m) => [m.id, m])).values()]
              .reverse()
              .map((m, index) => (
                <div key={`message-container-${m.id}`}>
                  <ChatMessageBubble
                    key={`message-${m.id}`}
                    message={{ ...m }}
                    ref={index === 0 ? lastMessageRef : null}
                  />
                  {showSmallAgent.current
                    && stream.messages
                    && stream.messages.length
                    && index
                    === stream.messages.length - historyMessagesCount.current && (
                      <div className="w-full">
                        <ChatAgent
                          description={agent.description}
                        />
                        <ChatMessageBubble
                          key="welcome-message-2" // Ensure the welcome message has a unique 'key' prop.
                          message={{
                            id: 'welcome-message-2', // Ensure each message has a unique 'id' prop when it needs to be unique.
                            content: t('landing.bia.greeting-2'),
                            type: 'ai',
                          }}
                        />
                        <div className="-mx-2 mb-5 w-full">
                          {suggestions.map((suggestion) => (
                            <button
                              type="button"
                              key={suggestion.name}
                              className=" mx-2 my-1 rounded-full border border-surface-300 px-4 py-2 text-sm"
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion.name}
                            </button>
                          ))}
                        </div>
                      </div>
                  )}
                </div>
              ))}

          <div className="-mx-2 mb-5 w-full">
            {suggestions.map((suggestion) => (
              <button
                type="button"
                key={suggestion.name}
                className=" mx-2 my-1 rounded-full border border-surface-300 px-4 py-2 text-sm"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.name}
              </button>
            ))}
          </div>

          <ChatMessageBubble
            key="welcome-message" // Ensure the welcome message has a unique 'key' prop.
            message={{
              id: 'welcome-message', // Ensure each message has a unique 'id' prop when it needs to be unique.
              content: t('landing.bia.greeting'),
              type: 'ai',
            }}
          />

          <ChatAgent description={agent.description} />
        </div>

        <form onSubmit={() => handleSubmit} className="w-full">
          <div
            className="relative my-2 flex min-h-18 flex-row items-center justify-center rounded-full border border-input px-2"
          >
            <Tooltip
              floating
              useFloatingPortal
              tooltipText={t('landing.bia.upload_document_hint')}
              position="top"
              forceShowTooltip={isTooltipOpen}
              forceHideTooltip={!isTooltipOpen}
            >
              <Button
                aria-label={t('landing.bia.upload_document_hint')}
                className="size-10 !px-2 !py-0"
                color="light"
                rounding="full"
                size="md"
                isLoading={isUploading}
                disabled={isUploading}
                spinnerClass="m-0"
                onClick={() => fileInputRef.current?.click()}
                onMouseEnter={() => setIsTooltipOpen(true)}
                onMouseLeave={() => setIsTooltipOpen(false)}
              >
                {!isUploading && <PlusIcon className="my-2 size-6" />}
              </Button>
            </Tooltip>

            <ChatTextarea
              textareaRef={textareaRef}
              input={input}
              handleInputChange={handleChatInputChange}
              handleKeyDown={handleChatInputKeyDown}
              placeholder={t('landing.bia.input')}
            />

            <input
              ref={fileInputRef}
              type="file"
              accept=".doc,.docx,.pdf,.xls,.xlsx,.csv"
              onChange={(event) => {
                void handleFileUpload(event);
              }}
              style={{ display: 'none' }}
              multiple
            />

            <Button
              type="button"
              color="secondary"
              rounding="full"
              size="md"
              className="!p-4"
              onClick={() => {
                void handleSubmit();
              }}
            >
              <PaperAirplaneIcon className="size-5 -rotate-45 text-white" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
