import { compile, match } from 'path-to-regexp';

// TYPES
// ************************************************************************************************
type ObjectAlias = object;

export interface PathToRegexpParseResult {
  pattern: RegExp,
  source: string,
}

export interface RegexPathParams extends ObjectAlias {
  [p: string]: string | undefined,
}

interface GetRegexPathParams {
  (pathname: string, parsedDynamicRoute: PathToRegexpParseResult): RegexPathParams,
}

/**
 * Extracts dynamic parameters from a given pathname based on a parsed dynamic route.
 * If the pathname matches, the function returns an object containing the extracted parameters.
 *
 * @param {string} pathname - The actual path from which to extract dynamic parameters.
 * @param {PathToRegexpParseResult} parsedDynamicRoute - The route object containing a regex source
 *                                                  to match against the `pathname`.
 * @returns {RegexPathParams} - An object containing the extracted dynamic parameters
 *                              from the `pathname`. If no match is found, an empty object is returned.
 *
 * @example
 * const pathname = "/user/123/profile/settings";
 * const parsedDynamicRoute = { regex: "^/user/(?<id>[^/]+)/profile/(?<section>[^/]+)$", source: "/user/:id/profile/:section" };
 * const params = getRegexPathParams(pathname, parsedDynamicRoute);
 * // params: { id: "123", section: "settings" }
 */
export const getRegexPathParams: GetRegexPathParams = (pathname, parsedDynamicRoute) => {
  let out = {} as RegexPathParams;
  const matcherFn = match<RegexPathParams>(parsedDynamicRoute.source, {
    decode: decodeURIComponent,
  });

  const matcherResult = matcherFn(pathname);

  if (matcherResult) {
    out = { ...matcherResult.params };
  }

  return out;
};

/**
 * Compiles a given pathname with the specified parameters to generate a valid path string.
 *
 * @param {string} pathname - The path template, which may include parameters in the form of `:paramName`.
 * @param {RegexPathParams} params - An object where the keys correspond to the parameter names
 *                                   in the `pathname`, and the values are the values to substitute.
 * @returns {string} - The generated path with the parameters injected and encoded.
 *
 * @example
 * const pathname = "/user/:id/profile/:section";
 * const params = { id: "123", section: "settings" };
 *
 * const result = compileToPath(pathname, params);
 * // result: "/user/123/profile/settings"
 */
export const compileToPath: (pathname: string, params: RegexPathParams) => string = (pathname, params) => {
  const toPathFn = compile(pathname, { encode: encodeURIComponent });
  return toPathFn(params);
};
