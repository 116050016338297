// RESUME SEARCH ALERTS ***************************************************************************
// pathname: /v2/resume-search-alerts
// ************************************************************************************************
import { useSwrApi, ApiResumeSearchAlert } from 'src/hooks/useSwrApi';


/**
 * @description SWR: fetch resume search alerts.
 * @param locale - string, the locale. Default 'ro'.
 * @param shouldFetch - boolean, if true, the data will be fetched. Default true.
 */
export const useSwrResumeSearchAlerts = (locale = 'ro', shouldFetch = true) => {
  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiResumeSearchAlert[]>({
    url: '/v2/resume-search-alerts',
    locale,
    shouldFetch,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 60, // 1 hour
      keepPreviousData: true,
    },
  });

  // Return data
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
