/**
 * @description get camera media stream if has permission
 * @returns MediaStream
 */
export const getCameraMediaStream = async (facingMode: 'user' | 'environment' = 'user'): Promise<MediaStream> => {
  const isChrome = typeof window !== 'undefined' && /Chrome/.test(navigator.userAgent);

  const audioConstraints = { audio: true };
  const videoConstraints = {
    audio: false,
    video: {
      facingMode,
    },
  };

  // create audio and video streams separately
  const audioStream = await navigator.mediaDevices.getUserMedia(
    audioConstraints,
  );
  const videoStream = await navigator.mediaDevices.getUserMedia(
    videoConstraints,
  );

  // combine both audio and video streams
  const combinedStream = new MediaStream([
    ...videoStream.getVideoTracks(),
    ...audioStream.getAudioTracks(),
  ]);

  const audioTracks = combinedStream.getAudioTracks();

  audioTracks.forEach((track) => {
    track.enabled = false; // Mute the audio track
  });

  if (isChrome) {
    const cameraPermissionStatus = await navigator.permissions.query({ name: 'camera' as PermissionName });
    const audioPermissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });
    if (cameraPermissionStatus.state === 'granted' && audioPermissionStatus.state === 'granted') {
      return combinedStream;
    }
    throw Error('Chrome - No permission for camera or microphone');
  }

  return combinedStream;
};


/**
 * @description request video permission without streaming
 */
export const requestVideoPermissionWithoutStreaming = async (): Promise<boolean> => {
  const stream = await getCameraMediaStream();
  if (!stream) {
    return false;
  }

  stream.getTracks().forEach((track) => {
    track.stop();
  });

  return true;
};
