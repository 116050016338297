import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicantTalentList, EmployerLists } from '@type/redux';


// Define the initial state
// *******************************************************************
const initialState: EmployerLists = {
  applicantTalentList: undefined,
};


// Define reducers and actions
// *******************************************************************
export const employerListsSlice = createSlice({
  name: 'employerLists',
  initialState,
  reducers: {

    // Update the applicant/talent list states. With ths we will watch if:
    // - The CV detail modal is open.
    // - The applicant/talent status has changed (interview, shortlist, etc.).
    // - The applicant/talent has been contacted (message, show contact details).
    updateApplicantTalentList: (state, action: PayloadAction<ApplicantTalentList>) => {
      state.applicantTalentList = action.payload;
    },

    // Reset the applicant talent list states.
    resetApplicantTalentList: (state) => {
      state.applicantTalentList = initialState.applicantTalentList;
    },

  },
});


// Export actions
// When writing to store use like this (you will need to import them though):
// appDispatch(saveSubscriptionUpgradePayment(newData));

// If you can't use the action directly, or don't want to import the actions, use this form instead:
// appDispatch({ type: 'employer/saveSubscriptionUpgradePayment', payload: newData });
// *******************************************************************
export const {
  updateApplicantTalentList,
  resetApplicantTalentList,
} = employerListsSlice.actions;


// Export reducer
// *******************************************************************
export const employerListsReducer = employerListsSlice.reducer;
