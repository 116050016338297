import { useSearchParams } from 'next/navigation';
import { buildObjectFromSearchParams } from '@utils/data-fetching';


// Props for the useExtendedSearchParams hook
// ***************************************
interface ExtendedSearchParamsProps {
  paramsAsStringPrefix?: string;
}


/**
 * @description Extended Next.js router hook for both `/pages` and `/app`. It returns a search params interface,
 * a search params object and a search params string.
 * It is used to get the search params from the URL and build an object from them.
 *
 * **Props:** (optional)
 * - `paramsAsStringPrefix`: string, the prefix for the returned searchParamsAsString (default is '?')
 *
 * **Return values:**
 * - `searchParams`: readonly URLSearchParams interface (from the [useSearchParams]{@link https://nextjs.org/docs/app/api-reference/functions/use-search-params})
 * - `searchParamsAsObject`: object, the search params converted into an object (for convenience, i.e. in filters).
 * If there are no search params, it will return an empty object.
 * - `searchParamsAsString`: string, the search params converted into a string with '?' prefix (for convenience, i.e. in links).
 * If there are no search params, it will return an empty string.
 *
 * -----------
 * **Important:** when using this hook, make sure to wrap the consumer component in a `Suspense` boundary as recommended
 * in the [official docs]{@link https://nextjs.org/docs/app/api-reference/functions/use-search-params#static-rendering} hook.
 * Keep in mind that this is needed only for static rendering, but not for dynamic rendering.
 *
 * @example
 * // Import the hook
 * import { useExtendedSearchParams } from '@hooks/next-routing-wrappers';
 *
 * // It is recommended to destructure the props for better readability, otherwise if you assign the hook
 * // to a variable, you might do something awkward like `searchParams.searchParams.toString()`.
 * const {
 *    searchParams,
 *    searchParamsAsObject,
 *    searchParamsAsString,
 * } = useExtendedSearchParams();
 *
 * // Use the search params
 * const hasTacos = searchParams.has('tacos');
 *
 * // Get the search params string without the '?' prefix (or with a custom prefix)
 * const { searchParamsAsString } = useExtendedSearchParams({ paramsAsStringPrefix: ''});
 *
 * // The same as above, but using the searchParams interface. Keep in mind that unlike the 'searchParamsAsString'
 * // that returns an empty string if there are no search params, this will return undefined if there are no search params.
 * const { searchParams } = useExtendedSearchParams();
 * const myParams = searchParams?.toString();
 */
export const useExtendedSearchParams = (props?: ExtendedSearchParamsProps) => {
  // Destructure the props
  const {
    paramsAsStringPrefix = '?',
  } = props || {};

  // Get the search params
  const searchParams = useSearchParams();

  // Build an object from the search params
  const searchParamsAsObject = buildObjectFromSearchParams(searchParams?.toString() || '');

  // Get the search params string
  const searchParamsAsString = searchParams?.toString() ? `${paramsAsStringPrefix}${searchParams?.toString()}` : '';

  // Return compatible in both '/app' and '/pages'
  // ***************************************
  return {
    searchParams,
    searchParamsAsObject,
    searchParamsAsString,
  };
};
