// IMPORTS ****************************************************************************************
// ************************************************************************************************
import { ApiNotificationDelete, ApiNotificationList, ApiNotificationsOk } from 'src/types/v1-api-types/notifications';


// NOTIFICATIONS **********************************************************************************
// pathname: /v1/notifications
// ************************************************************************************************
export const isNotificationListOk = (res: ApiNotificationList | void)
: res is ApiNotificationsOk[] => res !== null && res !== undefined && (res as ApiNotificationsOk[])?.length !== undefined;

export const isNotificationInfiniteOk = (res: ApiNotificationList[] | void): res is ApiNotificationsOk[][] => res !== null
  && res !== undefined
  && (res as ApiNotificationsOk[][])?.length !== undefined
  // all items should be arrays, error is an object
  && res.every((item) => Array.isArray(item));


// NOTIFICATIONS DELETE ***************************************************************************
// pathname: /v1/notifications/{id}/delete
// pathname: /v1/notifications/delete-all
// ************************************************************************************************
export const isNotificationDeleteOk = (res: ApiNotificationDelete | void)
: res is object => res !== null && res !== undefined && Object.keys(res as object)?.length === 0;
