import { flattenObj } from '../flatten-expand-object/flatten-expand-object';


/**
 * @description Remove all numbers within square brackets from a string. Helper function for {@link buildSearchParams}.
 * We need this because the API doesn't accept array indexes within square brackets.
 * @param key
 *
 * @example
 * // if there are no numbers within square brackets, the key will be returned as it is
 * const key1 = 'key1[keyA]'; // returns 'key1[keyA]'
 *
 * // if there are numbers within square brackets, the numbers will be removed
 * const key2 = 'key2[1]'; // returns 'key2[]'
 *
 */
const clearArrayNumbers = (key: string): string => {
  let cleanedKey = key;

  // Regex helper to remove all numbers within square brackets
  const regex = /\[\d+\]/g;

  // Remove all numbers within square brackets
  if (regex.test(key)) {
    cleanedKey = key.replace(regex, '[]');
  }

  return cleanedKey;
};


/**
 * @description Clean the keys of an object. It removes all the array square brackets. This is useful when we take the
 * search params from the URL and we want to clean them before using them.
 *
 * @param data - the object to be cleaned
 *
 * @example
 * const myObject = {
 *   'key1[keyA]': 'value',
 *   'key2[]': 'text',
 *   'key3[a][b][c]': 2,
 *   'key4': 'some value',
 * };
 *
 * const cleanedObject = cleanSearchObjectKeys(myObject);
 * // returns {
 *   key1keyA: 'value',
 *   key2: 'text',
 *   key3abc: 2,
 *   key4: 'some value',
 * }
 */
export const cleanSearchObjectKeys = <T extends object>(data: T | undefined): T | undefined => {
  // short circuit for no data, empty object, or array
  if (!data || Object.keys(data).length === 0) return {} as T;

  // clean the keys of the object; remove all the array square brackets
  const cleanedData = Object.fromEntries(Object.entries(data).map(([key, value]) => [key.replace(']', '').replace('[', ''), value]));
  return cleanedData as T;
};


/**
 * @description Build search params from an object. It converts nested objects and arrays into usable search params.
 * @param data (string, object) - the data to be transformed into search params
 * @param prefix - the prefix for the search params (default[?])
 * @param arrayToString - if true, it will convert arrays into comma separated strings (default[false])
 * @param convertKeysValuesToArray - an array of keys whose value must be converted from string to array (default[]). This is
 * useful because forms multiple, when they have a single value, it is returned as string.
 *
 * @example
 * const myObject = {
 *    key1: { keyA: 'value' },
 *    key2: ['keyB', 'text']
 *    key3: { a: { b: { c: 2 } } },
 *    key4: 'someValue',
 * };
 *
 * // 1. Standard usage
 * const searchParams = buildSearchParams(myObject);
 * // returns (square brackets will be escaped, but we leave them here for readability)
 * // searchParams = '?key1[keyA]=value&key2[]=keyB&key2[]=text&key3[a][b][c]=2'
 *
 * // it also works with strings
 * const someParams = buildSearchParams('limit=4&sort=new'); // returns '?limit=4&sort=new' *
 *
 * // 2. We want to convert arrays into comma separated strings
 * const someParams = buildSearchParams(myObject, '?', true);
 * // returns (notice the comma separated values in array from '&key2[]=keyB&key2[]=text' to '&key2=keyB,text')
 * // searchParams = '?key1[keyA]=value&key2=keyB,text&key3[a][b][c]=2'
 *
 * // 3. We want to convert some keys values to arrays
 * const someParams = buildSearchParams(myObject, '?', false, ['key4']);
 * // returns (notice the key4 value is now an array)
 * // searchParams = '?key1[keyA]=value&key2[]=keyB&key2[]=text&key3[a][b][c]=2&key4[]=someValue'
 */
export const buildSearchParams = <T extends object>(data: T | string | null | undefined, prefix = '?', arrayToString = false, convertKeysValuesToArray: string[] = []): string => {
  // short circuit for no data, empty object, or array
  if (!data || Object.keys(data).length === 0 || Array.isArray(data)) return '';

  // short circuit for string
  if (typeof data === 'string') return `${prefix}${data}`;

  // convert keys values to array if needed
  if (convertKeysValuesToArray.length > 0) {
    convertKeysValuesToArray.forEach((key) => {
      if (data[key as keyof typeof data] && typeof data[key as keyof typeof data] === 'string') {
        data[key as keyof typeof data] = [data[key as keyof typeof data]] as T[keyof typeof data];
      }
    });
  }

  // flatten the data object using the bracket notation;
  // 'preserveArray' parameter: flatten arrays if we don't want to convert them to strings (false)
  const flattenedData = flattenObj(data, '[]', arrayToString);

  // search params constructor
  const params = new URLSearchParams();

  // parse flattenedData object and append each key/value pair to the search params
  // sometimes instead of array, API wants comma separated values (from array)
  Object.entries(flattenedData).forEach(([key, value]: [key: string, value: string | number | unknown[]]) => {
    const mutatedValue = Array.isArray(value) ? value.join(',') : value;
    if (mutatedValue?.toString()) {
      params.append(clearArrayNumbers(key), mutatedValue.toString());
    }
  });

  // Try to avoid adding a prefix if the search params are empty or undefined: { someKey: undefined }
  return params.toString().length > 0 ? `${prefix}${params.toString()}` : '';
};


/**
 * @description Build an object from search params. It converts the search params into an object. This is the first
 * quick basic iteration of the function (no nested objects). It will be improved in the future.
 *
 * @param searchParams - the search params to be transformed into an object
 *
 * @example
 * const searchParams = '?key1=value&key2[]=value1&key2[]=value2&key3=value3';
 * const obj = buildObjectFromSearchParams(searchParams);
 * // returns {
 * //   key1: 'value',
 * //   key2: ['value1', 'value2'],
 * //   key3: 'value3',
 * // }
 */
export const buildObjectFromSearchParams = <T extends object, K extends keyof T>(searchParams: string): T | undefined => {
  const params = new URLSearchParams(searchParams);
  const obj = {} as T;

  Array.from(params.keys())?.forEach((key) => {
    if (params.getAll(key).length > 1) {
      obj[key as K] = params.getAll(key) as T[K];
    } else {
      obj[key as K] = params.get(key) as T[K];
    }
  });

  // clean the keys of the object; remove all the array square brackets
  return cleanSearchObjectKeys(obj);
};
