import { StripeNotificationOk } from '@type/v1-api-types';
import { useState, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { Button, Anchor } from '@components/common';
import { useTranslation } from 'next-i18next';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { sanitizeHtml } from '@utils/strings/sanitizeHtml';
import { setMenuTopOffset } from '@components/layout/Header/MainNavbar/config/helper-functions';


// Interface
// ****************************************
interface SalesCampaignStripeProps {
  data?: StripeNotificationOk,
  hasCloseButton?: boolean,
}


/**
 * @description SalesCampaignStripe component for displaying the sales campaign stripe above the navbar.
 *
 * * **data** is the data from the API. Don't display the stripe if there's no data.
 * * **hasCloseButton** - boolean: show/hide the close button
 */
export const SalesCampaignStripe = (props: SalesCampaignStripeProps) => {
  // Props destructuring
  const {
    data,
    hasCloseButton = true,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // State for showing/closing the stripe
  const [showStripe, setShowStripe] = useState(true);

  // Close the stripe. It will trigger the hide transition.
  const closeStripe = () => {
    setShowStripe(false);
    // recalculate the offset css variable and account for the transition duration (duration-300)
    setTimeout(() => {
      setMenuTopOffset();
    }, 300);
  };

  // Save the state in session storage so that it doesn't show up again.
  // Will do this after the transition finishes.
  const saveToSessionStorage = () => {
    sessionStorage.setItem('salesCampaignStripe', 'closed');
  };

  // If there's no data or the stripe was closed, don't display the stripe
  if (!data || sessionStorage.getItem('salesCampaignStripe') === 'closed') return null;


  // Render component
  // ****************************************
  return (
    <Transition
      as={Fragment}
      show={showStripe}
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={saveToSessionStorage}
    >
      <div className="bg-gradient-to-r from-secondary-dark to-secondary-light text-white z-2">
        <div className="container flex flex-wrap justify-between py-3 sm:flex-nowrap sm:py-1.5 items-center sm:gap-3">

          {/* CTA text */}
          <p
            className="font-medium grow w-full pr-7 sm:pr-2 sm:w-auto"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(data?.body) }}
          />

          { /* CTA button. We're not using LinkStyled because the target might be a symfony page */
            data?.url && data?.cta && (
              <Anchor
                href={data?.url}
                size="sm"
                color="white"
                rounding="full"
                styling="outline"
                className="!py-2 mb-1 mt-3 sm:my-0"
              >
                {data?.cta}
              </Anchor>
            )
          }

          { /* Close button */
            hasCloseButton && (
              <Button
                styling="none"
                className="absolute top-1 right-1 p-2 sm:relative sm:pr-0 sm:top-auto sm:right-auto"
                aria-label={t('global.btn.close')}
                title={t('global.btn.close')}
                onClick={closeStripe}
              >
                <XMarkIcon className="w-4.5 h-4.5 text-white stroke-3" />
              </Button>
            )
          }

        </div>
      </div>
    </Transition>
  );
};
