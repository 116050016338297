export const i18nConfig = {
  defaultLocale: 'ro',
  locales: ['ro', 'en', 'hu', 'de', 'fr', 'it', 'es'],
  // If true, the default locale will be prefixed to the path, just like the other locales,
  // e.g. '/ro/some-page'. Else, the default locale will be the root path, e.g. '/some-page'.
  prefixedDefaultLocale: false,
} as const;

export type Locales = (typeof i18nConfig)['locales'];
export type Locale = Locales[number];
