// Imports
// ********************************************
import { useSwrApi } from '@hooks/useSwrApi';
import { useRouterLocale } from '@hooks/common/useRouterLocale';

/**
 * @description Fetch burger menu data. We also check on tab refocus if we need to revalidate the data.
 * We check for SWR data revalidation on tab focus inside the useHeaderInfoData hook, because this
 * component is not mounted normally when that occurs, unless the user leaves the popover opened.
 */
export const useBurgerMenuData = () => {
  // Get locale from router
  const locale = useRouterLocale();


  // SWR: fetch data
  // IMPORTANT: we trigger a re-fetch on tab refocus inside the useHeaderInfoData hook. We do it there
  // because this component is not mounted normally when that occurs, unless the user leaves the popover
  // opened.
  // ********************************************
  const {
    data, error, isLoading, isValidating,
  } = useSwrApi<ApiSchema<'BurgerMenuItem'>[]>({
    url: '/v1/burger-menu',
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 36000000, // 10 hours
    },
  });


  // Return data
  // ********************************************
  return {
    burgerMenuData: data,
    burgerMenuError: error,
    burgerMenuLoading: isLoading,
    burgerMenuValidating: isValidating,
  };
};
