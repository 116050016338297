import dynamic from 'next/dynamic';
import { Modal } from '@components/common';
import { SendMessage } from '@components/features/onlineSocket/videoSession/SendMessage';
import { useTranslation } from 'next-i18next';
import { socketOnlineCompanyEndCallType } from '@type/socket-online';
import { useOpenTokCall } from '@components/features/openTokCall/useOpenTokCall';
import { CompanyCallOptionsModal } from '../onlineSocket/company';

// dynamic import used only on client side due to opentok package restrictions
const VideoCallSession = dynamic(() => import('../onlineSocket/videoSession/VideoCallSession'), {
  ssr: false,
});

/**
 * @description - Main component for handling open tok call 1-1 / company (web) to talent (mobile app)
 * * - It shows the UI for the video call, missed call, receiving call, and company call options
 */
export const OpenTokCall = () => {
  const { t } = useTranslation('common');

  // get online socket data for calls and notifications
  const {
    endOfCallReason,
    companyEndOfCallMessage,
    showCompanyCallOptions,
    sessionData,
    onStartVideoCall,
    isVideoCall,
    onOpenTokConnect,
    onCloseConnection,
    isLoadingUserCall,
    trackingId,
  } = useOpenTokCall();

  return (
    <>
      {/* shows calling options (video / audio) for company */}
      {showCompanyCallOptions && (
        <CompanyCallOptionsModal
          isLoadingUserCall={isLoadingUserCall}
          forceOpen={showCompanyCallOptions}
          onStartInterview={onStartVideoCall}
          onClose={() => onCloseConnection(false, socketOnlineCompanyEndCallType.END_SEND_MESSAGE)}
        />
      )}

      {/* video session */}
      { sessionData !== null && endOfCallReason === null && companyEndOfCallMessage === null && (
        <Modal
          isOpen
          size="2xl"
          centered
          onClose={() => {}}
          className="rounded"
        >
          <VideoCallSession
            isSocketReady
            sessionData={sessionData}
            isVideoCall={isVideoCall}
            companyParticipant={null}
            onOpenTokConnect={onOpenTokConnect}
            onCloseConnection={onCloseConnection}
            waitingCounterDescendingCallback={() => onCloseConnection(false, socketOnlineCompanyEndCallType.END_NO_ANSWER)}
          />
        </Modal>
      )}

      {/* for company - after ending the call */}
      {
        companyEndOfCallMessage !== null && (
          <Modal
            isOpen
            size="lg"
            centered
            onClose={() => onCloseConnection(true)}
            className="rounded"
          >
            <Modal.Header onClose={() => onCloseConnection(true)}>
              {t(companyEndOfCallMessage)}
            </Modal.Header>
            <Modal.Body>
              {/* interview send message if no answer */}
              <SendMessage trackingId={trackingId} onPressCloseButton={() => onCloseConnection(true)} />
            </Modal.Body>
          </Modal>
        )
      }
    </>
  );
};
