import { TooltipColorScheme, TooltipPosition } from '@type/tooltip';

// Tooltip position classes
const positions: { [key in TooltipPosition]: string } = {
  top: 'bottom-full left-1/2 transform -translate-x-1/2 justify-center mb-2',
  right: 'left-full top-1/2 transform -translate-y-1/2 ml-2 justify-start mr-auto',
  bottom: 'top-full left-1/2 transform -translate-x-1/2 justify-center mt-2',
  left: 'right-full top-1/2 transform -translate-y-1/2 ml-auto justify-end mr-2',
};

// Tooltip color scheme classes
const colorSchemes: { [key in TooltipColorScheme]: string } = {
  primary: 'bg-primary text-white',
  secondary: 'bg-secondary text-white',
  default: 'bg-surface text-ink',
  error: 'bg-error text-white',
  black: 'bg-black text-white',
  gray: 'bg-gray-700 text-white',
  pink: 'bg-pink text-white',
};

// Function to get the position classes
// *******************************************************************************
export const getTooltipPosition = (position: TooltipPosition): string => positions[position];

// Function to get the color scheme classes
// *******************************************************************************
export const getTooltipColorScheme = (color: TooltipColorScheme): string => colorSchemes[color];
