// ROUTING UTILS, KEEP THEM AT THE TOP
export * from './path-to-regexp';

// ARRAYS
export * from '@utils/arrays/filter-strings';
export * from '@utils/arrays/getRandomArrayIndex';
export * from '@utils/arrays/shuffle-array';


// BARREL FILE FOR UTILITY FUNCTIONS
export * from './flatten-expand-object/flatten-expand-object';
export * from './convert-empty-value/convert-empty-value';
export * from './trim-empty-spaces/trim-empty-spaces';
export { objectRemoveEmptyKeys } from './object-remove-empty-keys/object-remove-empty-keys';
export * from './json-helpers/json-helpers';
export * from './form-helpers/cookies';
export * from './form-helpers/session-storage';
export * from '@utils/date-time/date-time';
export { slugify } from '@utils/strings/slugify';
export { getNameInitials } from '@utils/name-initials/name-initials';
export { getCameraMediaStream, requestVideoPermissionWithoutStreaming } from '@utils/camera/camera';
export * from './local-storage/localStorage';
export { buildCircularSliderValues, CIRCULAR_SLIDER_STEP_VALUE } from '@utils/circular-slider/circular-slider';
export { getFileExtension, ACCEPTED_FILE_EXTENSIONS, ACCEPTED_MIME_TYPES } from '@utils/file/file-types';
export * from './document/document';

// RESUME
export {
  areJobPreferencesEmpty, countJobPreferencesElements, convertJobTypePreferencesToUserResumeFormat,
} from '@utils/resume/job-type-preferences';
export { areSkillsEmpty, countSkillsElements } from '@utils/resume/skills';
export { arePersonalDataEmpty } from '@utils/resume/personal-data';
export { isUserWithoutResume } from '@utils/resume/user-resume';

// DATA FETCHING
export * from './data-fetching/fetcher';
export * from './data-fetching/utils';
export * from 'src/type-predicates/v1-api-predicates';
export * from 'src/types/v1-api-types';
export * from './data-fetching/searchParams';


// MIDDLEWARE HELPERS
export * from './middleware/utils';

// LOGGER
export * from './logger/logger';

// DATALAYER
export * from './send-data-layer-event/send-data-layer-event';

// ENCRYPTION
export * from './encryption/encryption';

// REF
export * from './refs/merge-refs';

// Numbers
export { getLastDigit } from './numbers/getLastDigit';
export { calculatePercentage } from './numbers/calculatePercentage';

// STRINGS
export * from './strings/extractNumber';

// URL
export * from './url/url';

// DEBOUNCE
export * from './debounce/debounce';
