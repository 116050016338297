import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { JobStoreStateInterface } from '@type/redux';


// Define the initial state
// *******************************************************************
const initialState: JobStoreStateInterface = {
  bulkApplyFeed: undefined, // bulk apply feed
};

// Define reducers and actions
// *******************************************************************
export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {
    // Update bulk apply feed
    updateBulkApplyFeed: (state, action: PayloadAction<ApiSchema<'JobItem'>[]>) => {
      state.bulkApplyFeed = action.payload;
    },

    // Reset bulk apply feed
    resetBulkApplyFeed: (state) => {
      state.bulkApplyFeed = initialState.bulkApplyFeed;
    },
  },
});

// Export actions
// *******************************************************************
export const {
  updateBulkApplyFeed,
  resetBulkApplyFeed,
} = jobSlice.actions;

// Export reducer
// *******************************************************************
export default jobSlice.reducer;

