import { useSWRConfig } from 'swr';
import { SwrApiKey } from '@type/swr';


/**
 * @description SWR: global SWR mutation functions. Use these functions to revalidate (re-fetch) data
 * for a specific API endpoint or multiple API endpoints. You can also clear the cache for a specific
 * API endpoint or multiple API endpoints. You can also clear the cache for all API endpoints.
 *
 * @export **revalidateKey** - revalidate (re-fetch) data for a specific API endpoint.
 * @export **revalidateMultipleKeys** - revalidate (re-fetch) data for multiple API endpoints.
 * @export **clearCacheKey** - clear the cache for a specific API endpoint.
 * @export **clearMultipleCacheKeys** - clear the cache for multiple API endpoints.
 * @export **clearAllCacheKeys** - clear the cache for all API endpoints.
 */
export const useSwrGlobal = () => {
  // Keep in mind that 'mutate' is a filtering function that iterates through all the cache keys
  // and revalidates the ones that match the provided key.
  // From docs: Using global mutator only with the key parameter will not update the cache or trigger
  // revalidation unless there is a mounted SWR hook using the same key.
  // *********************************************************************************
  const { mutate } = useSWRConfig();


  /**
   * @description Revalidate all cache keys that include the provided api url string. It will not
   * update the cache or trigger revalidation unless there is a mounted SWR hook using the same key.
   * Main usage: in some other file/component than the one with the SWR hook for the api url.
   *
   * @param apiUrl { string } - revalidate (re-fetch) data for this the api url
   *
   * @example
   * // it will also revalidate '/v1/some-url?param=some_value'
   * revalidateKey('/v1/some-url');
   */
  const revalidateKey = (apiUrl: string) => {
    const apiKey = (key: SwrApiKey) => Array.isArray(key) && key[0].includes(apiUrl);
    void mutate(apiKey);
  };


  /**
   * @description Revalidate multiple different cache keys that include the provided api url string.
   * It will not update the cache or trigger revalidation unless there is a mounted SWR hook using
   * the same key. Main usage: in some other file/component than the one with the SWR hook for the
   * api urls.
   *
   * @param apiUrls { string[] } - string array with api's that you want to revalidate (re-fetch data)
   *
   * @example
   * // it will also revalidate '/v1/some-url?param=some_value' and '/v1/other-url?param=some_value'
   * revalidateMultipleKeys(['/v1/some-url', '/v1/other-url']);
   */
  const revalidateMultipleKeys = (apiUrls: string[]) => {
    apiUrls.forEach((apiUrl) => {
      revalidateKey(apiUrl);
    });
  };


  /**
   * @description Clear (set data to 'undefined') the SWR cache keys that include the provided
   * api url string. Keep in mind that if you have a long 'dedupingInterval' set on the SWR hook,
   * you might want to set the 'revalidate' parameter to 'true' in order to trigger a revalidation
   * otherwise the data will be 'undefined' until the 'dedupingInterval' expires. Main usage: in some
   * other file/component than the one with the SWR hook for the api url.
   *
   * @param apiUrl - clear the cache for this api url.
   * @param revalidate - boolean to trigger a revalidation (re-fetch) after clearing the cache.
   * If 'false' the data will be 'undefined' until the 'dedupingInterval' expires. Default: false.
   *
   * @example
   * // it will also clear '/v1/some-url?param=some_value'
   * clearCacheKey('/v1/some-url');
   */
  const clearCacheKey = (apiUrl: string, revalidate = false) => {
    const apiKey = (key: SwrApiKey) => Array.isArray(key) && key[0].includes(apiUrl);
    void mutate(
      apiKey, // which key to revalidate
      undefined, // new value
      { revalidate }, // options
    );
  };


  /**
   * @description Clear (set data to 'undefined') multiple different SWR cache keys that include
   * the provided api url string. Keep in mind that if you have a long 'dedupingInterval' set on
   * the SWR hook, you might want to set the 'revalidate' parameter to 'true' in order to trigger
   * a revalidation otherwise the data will be 'undefined' until the 'dedupingInterval' expires.
   * Main usage: in some other file/component than the one with the SWR hook for the api urls.
   *
   * @param apiUrls - string array with api's that you want to clear the cache for.
   * @param revalidate - boolean to trigger a revalidation (re-fetch) after clearing the cache.
   * If 'false' the data will be 'undefined' until the 'dedupingInterval' expires. Default: false.
   *
   * @example
   * // it will also clear '/v1/some-url?param=some_value' and '/v1/other-url?param=some_value'
   * clearMultipleCacheKeys(['/v1/some-url', '/v1/other-url']);
   */
  const clearMultipleCacheKeys = (apiUrls: string[], revalidate = false) => {
    apiUrls.forEach((apiUrl) => {
      clearCacheKey(apiUrl, revalidate);
    });
  };


  /**
   * @description Clear (set data to 'undefined') all SWR cache keys. Good to use when a user logs
   * off. Unlike the other functions, this one doesn't have a 'revalidate' parameter because we
   * certainly don't want to trigger a revalidation (re-fetch) for all API endpoints at the same time.
   *
   * @example
   * // the user logged off, clear all cache keys
   * clearAllCacheKeys();
   */
  const clearAllCacheKeys = () => {
    void mutate(
      () => true, // update all keys
      undefined, // new value
      { revalidate: false }, // options
    );
  };


  return {
    revalidateKey,
    revalidateMultipleKeys,
    clearCacheKey,
    clearMultipleCacheKeys,
    clearAllCacheKeys,
  };
};
