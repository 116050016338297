import { useEffect, useRef, useState } from 'react';

interface IProps {
  durationInSeconds: number;
  onEndCallback?: () => void;
}

/**
 * @description counter hook for descending time
 * @param durationInSeconds - duration of the counter in seconds
 * @param onEndCallback - callback when the counter ends (counterSeconds === 0)
 */
export const useCounterDescending = ({ durationInSeconds, onEndCallback }: IProps) => {
  const [counterSeconds, setCounterSeconds] = useState<number | undefined>();

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  // handles timer
  useEffect(() => {
    // time is up - trigger callback
    if (counterSeconds === 0) {
      if (onEndCallback) {
        onEndCallback();
      }
      setCounterSeconds(undefined);

      // clear timer
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    }
  }, [onEndCallback, counterSeconds]);

  // starting the counter
  useEffect(() => {
    setCounterSeconds(durationInSeconds);

    timerRef.current = setInterval(() => {
      setCounterSeconds((prevState) => (prevState ?? 1) - 1);
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, [durationInSeconds]);

  return {
    counterSeconds,
  };
};
