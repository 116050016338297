import { ApiUserRole } from '@type/v1-api-types';

// #########################################################
// #                  SOCKET NOTIFICATIONS                 #
// #########################################################

export interface ISocketOnlineNotificationBody {
  message: string;
  cta: string;
  url: string;
}

export interface ISocketOnlineNotificationData {
  notification: {
    [key in Locale]: ISocketOnlineNotificationBody;
  };
  from?: string;
  to?: string;
  type: string;
}

export interface ICallbackDataSocketOnline {
  isShowNotification?: boolean;
  payload?: ISocketOnlineNotificationData;
}

export type ICallbackSocketOnline = (data: ICallbackDataSocketOnline) => void;


// #########################################################
// #                       SOCKET CALL                     #
// #########################################################
export interface ISocketCallStartPayload {
  userId: number;
  userName: string;
  companyName: string;
}
export interface ISocketCallParticipantData {
  userId: number;
  userName: string;
  slug: string;
  imgUrl?: string;
  email?: string;
  phone?: string;
  trackingId?: string;
}

export interface ISocketCallStartDataResponse {
  sessionId: string;
  tokenId: string;
}


export const socketOnlineErrCode = {
  ERR_UNAUTHORIZED: 0, // unauthorized
  ERR_INVALID_ARGUMENTS: 1, // invalid arguments
  ERR_COMPANY_ONGOING_LIVE_CONNECTION: 2, // company already has a live interview connection opened
  ERR_COMPANY_ONGOING_DIRECT_CONNECTION: 3, // company already has a live call connection opened
  ERR_TALENT_ONGOING_LIVE_CONNECTION: 4, // talent already has a live interview connection opened
  ERR_TALENT_ONGOING_DIRECT_CONNECTION: 5, // talent already has a live call connection opened
  ERR_VONAGE_SESSION_CREATION: 6, // opentok error for generating a session
  ERR_TALENT_NOT_AVAILABLE: 7, // talent not available
};
export type ISocketCallErrorCode = typeof socketOnlineErrCode[keyof typeof socketOnlineErrCode];

export interface ISocketCallCompanyParticipant {
  temporaryAccountId?: number;
  companyName?: string;
  accountId?: number;
  employerLogo?: string;
  employerRating?: number;
  slug?: string;
}

export interface ISocketCallInviteResponse {
  sessionId: string;
  tokenId: string;
  companyName: string
  accountId: number;
}

export interface ISocketCallCallbackData {
  isSocketConnected?: boolean;
  isInvite?: boolean;
  isInviteResponseAck?: boolean;
  isReady?: boolean;
  isStart?: boolean;
  isError?: boolean;
  isSomethingWrong?: boolean;
  isEnd?: boolean;
  labelMessage?: string;
  data?: ISocketCallInviteResponse | ISocketCallStartDataResponse | ISocketCallExitReason
}

export type ISocketCallCallback = (data: ISocketCallCallbackData) => void;


export const socketOnlineExitReason = {
  COMPANY_ENDED: 1, // company ended call
  TALENT_ENDED: 2, // talent ended call
  TALENT_DECLINED: 3, // talent declined call
  TALENT_UNREACHABLE: 4, // talent is not answering
  COMPANY_DISCONNECTED: 5, // company is disconnected from socket
  TALENT_DISCONNECTED: 6, // talent is disconnected from socket
};
export type ISocketCallExitReason = typeof socketOnlineExitReason[keyof typeof socketOnlineExitReason];

export interface ISocketCallInit {
  type: ApiUserRole;
  onCallback: ISocketCallCallback;
}

export interface ISocketCallStart extends ISocketCallInit {
  data: ISocketCallStartPayload;
}

export const socketOnlineCompanyEndCallType = {
  END_SEND_MESSAGE: 1,
  END_NO_ANSWER: 2,
  END_CALL_ENDED: 3,
  END_CALL_DECLINED: 4,
};
export type ISocketOnlineCompanyEndCallType = typeof socketOnlineCompanyEndCallType[keyof typeof socketOnlineCompanyEndCallType];
