import { generateSHA256HashClientSide } from '@utils/encryption/encryption';
import { ApiDataLayerContent } from '@type/v1-api-types';
import { Job } from '../../types/job';

declare const window: Window & { dataLayer: Record<string, unknown>[]; };

/**
 * @description Send data layer event to Google Tag Manager
 * @param data
 */
export const sendDataLayerEvent = (data: Record<string, unknown>) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

/**
 * @description Send data layer event for job after apply page.
 * ** Used for CRITEO
 * @param job
 * @param email
 */
export const sendAfterApplyJobEvent = async (job: Job, email: string) => {
  if (!job.applicantId) {
    return;
  }

  // make sure we don't send the same application event twice
  const localKey = `job_application_${job.id.toString()}_${job.applicantId.toString()}`;
  if (window.localStorage.getItem(localKey)) {
    return;
  }
  window.localStorage.setItem(localKey, '1');

  // add event in data layer
  sendDataLayerEvent({
    PageType: 'JobApplyPage',
    TransactionID: job.applicantId,
    ProductTransactionProducts: {
      id: job.id,
      quantity: 1,
      price: 1,
    },
    email: await generateSHA256HashClientSide(email.trim().toLowerCase()),
    hash_method: 'sha256',
  });
};

/**
 * @description Send data layer event when user changes his resume
 *
 * @param content
 */
export const sendResumeChangeDataLayerEvent = async (content: ApiDataLayerContent) => {
  sendDataLayerEvent({
    ...content,
    _route: 'cv_edit',
  });
};
