import { useState, Fragment } from 'react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { useTranslation, Trans } from 'next-i18next';
import { Button, Anchor } from '@components/common';
import { setCookie } from 'cookies-next';
import sendGoogleConsent from '@utils/gtm/gtm';
import { dataTestId } from '@services/data-test-id';

interface ICookieConsentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConsent: () => void;
  cookiesBlocked: boolean;
}

export const CookieConsentModal = ({
  isOpen, onClose, onConsent, cookiesBlocked,
}: ICookieConsentModalProps) => {
  const { t } = useTranslation('common');
  const [settingsPanelVisibility, setSettingsPanelVisibility] = useState(false);
  const [functional, setFunctional] = useState(true);
  const [marketing, setMarketing] = useState(true);
  const [analyze, setAnalyze] = useState(true);

  const setToStorage = (key: string, value: string | Date) => (typeof window !== 'undefined' && window.localStorage.setItem(key, value.toString()));

  const onAcceptClick = () => {
    const expiration = new Date(new Date().setFullYear(new Date().getFullYear() + 1)); // +2 years
    setCookie('cc_ts', Math.floor(Date.now() / 1000), { expires: expiration });
    setToStorage('cc_ts', expiration);
    setCookie('cc_ads', marketing ? 'granted' : 'denied', { expires: expiration });
    setToStorage('cc_ads', marketing ? 'granted' : 'denied');
    setCookie('cc_analytics', analyze ? 'granted' : 'denied', { expires: expiration });
    setToStorage('cc_analytics', analyze ? 'granted' : 'denied');

    // Send Google Consent v2 tag updates
    sendGoogleConsent({
      ad_storage: marketing ? 'granted' : 'denied',
      analytics_storage: analyze ? 'granted' : 'denied',
      ad_user_data: marketing ? 'granted' : 'denied',
      ad_personalization: marketing ? 'granted' : 'denied',
    });

    onConsent();
  };

  const onLearnMoreClick = () => {
    const { userAgent } = window.navigator;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i))browserName = 'chrome';
    else if (userAgent.match(/firefox|fxios/i)) browserName = 'firefox';
    else if (userAgent.match(/safari/i)) browserName = 'safari';
    else if (userAgent.match(/opr\//i)) browserName = 'opera';
    else if (userAgent.match(/edg/i)) browserName = 'edge';
    else browserName = '';

    window.open(`https://www.google.com/search?q=enable+cookies ${browserName}`, '_blank');
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md overflow-hidden rounded-xl bg-surface p-6 text-left align-middle shadow-xl transition-all">
                {!cookiesBlocked && (
                  <>
                    <h3 className="relative text-center text-xl font-bold">
                      {t('cookie-disclaimer.title')}
                    </h3>
                    <p className="my-2 ml-4 break-words text-center text-sm">
                      {t('cookie-disclaimer.description-react-new')}
                    </p>

                    <hr className="my-4" />

                    {settingsPanelVisibility && (
                      <div>
                        <div className="mb-6 flex place-content-between items-center opacity-50">
                          <div className="">
                            <h3 className="font-bold">
                              {t('cookie-disclaimer.necessary')}
                            </h3>
                            <p className="text-ink-medium">
                              {t('cookie-disclaimer.necessary.description')}
                            </p>
                          </div>
                          <div>
                            <Switch
                              checked={functional}
                              onChange={() => setFunctional(true)}
                              className={`${
                                functional ? 'bg-secondary' : 'bg-gray-200'
                              } relative inline-flex h-8 w-14 items-center rounded-full`}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  functional
                                    ? 'translate-x-7'
                                    : 'translate-x-0.5'
                                }
                              pointer-events-none inline-block size-[26px] rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div className="mb-6 flex place-content-between items-center">
                          <div className="">
                            <h3 className="font-bold">
                              {t('cookie-disclaimer.marketing')}
                            </h3>
                            <p className="text-ink-medium">
                              {t('cookie-disclaimer.marketing.description')}
                            </p>
                          </div>
                          <div>
                            <Switch
                              checked={marketing}
                              onChange={setMarketing}
                              className={`${
                                marketing ? 'bg-secondary' : 'bg-gray-200'
                              } relative inline-flex h-8 w-14 items-center rounded-full`}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  marketing
                                    ? 'translate-x-7'
                                    : 'translate-x-0.5'
                                }
                              pointer-events-none inline-block size-[26px] rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                        </div>
                        <div className="mb-6 flex place-content-between items-center">
                          <div className="">
                            <h3 className="font-bold">
                              {t('cookie-disclaimer.tracking')}
                            </h3>
                            <p className="text-ink-medium">
                              {t('cookie-disclaimer.tracking.description')}
                            </p>
                          </div>
                          <div>
                            <Switch
                              checked={analyze}
                              onChange={setAnalyze}
                              className={`${
                                analyze ? 'bg-secondary' : 'bg-gray-200'
                              } relative inline-flex h-8 w-14 items-center rounded-full`}
                            >
                              <span className="sr-only">Use setting</span>
                              <span
                                aria-hidden="true"
                                className={`${
                                  analyze ? 'translate-x-7' : 'translate-x-0.5'
                                }
                              pointer-events-none inline-block size-[26px] rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                              />
                            </Switch>
                          </div>
                        </div>

                        <p className="-mt-4 mb-4 text-sm">
                          <Trans
                            i18nKey="cookie-disclaimer.google.info"
                            values={{ here: t('cookie-disclaimer.here') }}
                            components={{
                              customLink: <Anchor
                                styling="text"
                                className="!inline"
                                size="sm"
                                href="https://business.safety.google/privacy/"
                                target="_blank"
                                rel="noopener noreferrer"
                              />,
                            }}
                          />
                        </p>

                        <hr className="my-4" />
                      </div>
                    )}

                    <div className="flex w-full items-center justify-center gap-x-4">
                      {!settingsPanelVisibility && (
                        <Button styling="text" onClick={() => setSettingsPanelVisibility(!settingsPanelVisibility)}>
                          {t('cookie-disclaimer.change')}
                        </Button>
                      )}
                      <Button
                        rounding="full"
                        color="secondary"
                        onClick={onAcceptClick}
                        {...dataTestId('cookiesModal.acceptButton')}
                      >
                        {t('cookie-disclaimer.cta')}
                      </Button>
                    </div>
                  </>
                )}
                {cookiesBlocked && (
                  <>
                    <h3 className="relative text-center text-xl font-bold">
                      {t('cookie-disclaimer.blocked.title')}
                    </h3>
                    <p className="text-center">
                      {t('cookie-disclaimer.blocked.subtitle')}
                      {' '}
                      <Button
                        styling="text"
                        className="outline-none"
                        onClick={onLearnMoreClick}
                      >
                        {t('cookie-disclaimer.blocked.learn-more')}
                      </Button>
                    </p>

                    <hr className="my-4" />

                    <div className="flex w-full items-center justify-center">
                      <Button
                        rounding="full"
                        color="secondary"
                        onClick={() => window.location.reload()}
                      >
                        {t('cookie-disclaimer.blocked.try-again')}
                      </Button>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
