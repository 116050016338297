// RESUME EVALUATE *********************************************************************************
// pathname: /v1/resume-operation/${userId}/evaluate
// ****************************************************************************************************
import { useSwrMutationApi, ApiResumeEvaluatePayload } from 'src/hooks/useSwrApi';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';


/**
 * @description SWR mutation: POST evaluate resume. Response status ok: 201.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 * @param userId - number. The user id to add the comment to.
 * @param successCallback - function. The callback to call on success. For example, re-fetch comments (mutate function).
 */
export const useSwrResumeEvaluate = (locale: string, userId: number, successCallback?: () => void) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, ApiResumeEvaluatePayload>({
    url: `/v1/resume-operation/${userId}/evaluate`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        // Send Google Tag Manager event
        sendGoogleTagEvent('employer_resume_bia_evaluated', 'Custom');
        // Callback
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
