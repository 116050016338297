import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Combines class names into a single string, merging Tailwind CSS classes.
 *
 * This function utilizes:
 * - `clsx`: to handle conditional class names
 * - `tailwind-merge` to ensure that conflicting Tailwind CSS classes are resolved appropriately.
 *
 * **Note:** This function should be used instead of Tailwind's `!important` modifier (e.g., `!p-3`).
 * The `twMerge` utility effectively handles conflicts between Tailwind CSS classes, making the use
 * of `!important` unnecessary. If you encounter situations where `!important` was previously used
 * to enforce a specific style, using this `cn` function should provide
 * the correct merging of classes without needing the `!important` flag.
 *
 *
 * @param {...ClassValue[]} args - The class names to combine. Can include strings,
 *                                  objects, or arrays of class names.
 * @returns {string} - A single string containing the merged class names.
 *
 * @example
 * import { cn } from '@utils/cn';
 *
 * // Basic usage
 * const className = cn('px-2 py-1 bg-primary hover:bg-primary-light', 'p-3 bg-secondary');
 * // returns 'hover:bg-primary-light p-3 bg-secondary'
 *
 *
 * // Inline usage in a component with conditional classes
 * return (
 *   <div className={cn('text-center', isActive && 'bg-green-500', 'p-4', 'hover:bg-green-700')}>
 *     Click me
 *   </div>
 * );
 * // Depending on the value of `isActive`, this may return:
 * // 'text-center bg-green-500 p-4 hover:bg-green-700'
 * // or
 * // 'text-center p-4 hover:bg-green-700'
 *
 */
export const cn = (...args: ClassValue[]) => twMerge(clsx(args));
