/**
 * @description Extracts the file name from a URL
 * @param url - string
 *
 * @example
 * fileNameFromUrl('https://example.com/path/to/file.jpg') // 'file.jpg'
 */
export const fileNameFromUrl = (url: string | undefined): string | undefined => {
  if (!url) return undefined;
  const pathFragments = url?.split('/');
  return pathFragments[pathFragments.length - 1];
};
