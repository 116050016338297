import { BadgePillProps, BadgePillRef } from 'src/types/anchors-buttons';
import { forwardRef } from 'react';
import { composeBadgePillClasses } from './badgePillStyles';

/**
 * @description Badge pill component. For styling you need to pass the following props:
 *
 * * **styling** - the styling of the badge pill: outline, solid, soft, none; default: solid
 * * **color** - the color of the badge pill; default: primary
 * * **size** - the size of the badge pill: pico, xxs xs, sm, md, lg; default: xxs
 * * **rounding** - the rounding of the badge pill: none, normal, full; default: full
 *
 * -----------------
 * @description If styling is **none**, no classes will be pre-applied, the component will have no
 * styling applied to it.
 *
 * -----------------
 *
 * @param props
 *
 * @example
 * <BadgePill color="secondary" size="sm" rounding="full">
 *   Badge text
 * </BadgePill>
 */
export const BadgePill = forwardRef<BadgePillRef, BadgePillProps>((props, ref) => {
  const {
    color = 'primary',
    styling = 'solid',
    size = 'xxs',
    rounding = 'full',
    className = '',
    children = 'Badge pill text',
    ...restProps
  } = props;

  const finalClasses = styling === 'none' ? className : composeBadgePillClasses(color, size, styling, rounding, className);

  return (
    <span ref={ref} className={finalClasses} {...restProps}>
      { children }
    </span>
  );
});
