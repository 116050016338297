import { fetcher, isApiV1Errors, FetcherConfig } from '@utils/data-fetching';
import { stringStartsWith } from '@utils/arrays/filter-strings';
import { useState } from 'react';
import { ComboboxQueryFunction } from 'src/types/form-types';
import { useRouterLocale } from '@hooks/common/useRouterLocale';


// Type for cache
type CacheMapValue = string[] | object[];


/**
 * @description Hook for fetching combobox data for displaying a list.
 * @param fetchUrl
 * @param queryParams
 * @param fetcherConfig
 */
export const useFetchComboboxData = (fetchUrl: string, queryParams?: ComboboxQueryFunction, fetcherConfig?: FetcherConfig) => {
  // LOCALE - Get the locale for fetching the localized data
  const locale = useRouterLocale();

  // CACHE MAP - Setup caching for values from fetch
  // Map is great for building cache, has some optimized methods for it
  const [cacheMap, setCacheMap] = useState(new Map<string, CacheMapValue>());

  // CACHE MAP - Updater helper function
  const updateCacheMap = (key: string, value: CacheMapValue) => {
    setCacheMap(new Map(cacheMap.set(key, value)));
  };

  // EMPTY QUERIES
  // Array where we store the empty result input values. We'll use it to check if request keywords
  // start with a string stored in array. If it is, we know that the result will be also empty and
  // there's no need to do another fetch
  const [emptyResults, setEmptyResults] = useState<string[]>([]);

  // State for status when fetching combobox data
  const [isLoading, setIsLoading] = useState(false);


  // DATA FETCH
  // 1. Before fetch search in cache for exact match (inputValue === Map key)
  // 2. If exact match, return data from cache
  // 3. Check emptyResults if query string starts with one of the values
  // 4. If so, update cache with the new query and return an empty array
  // 5. Go and fetch the data
  // 6. Type check the array, we need to watch for errors array
  const getComboboxData = async (value: string, queryParameters = queryParams, config = fetcherConfig) => {
    // Value holder
    let data: CacheMapValue = [];

    // Get the query parameters for fetching data
    const query = queryParameters ? new URLSearchParams(queryParameters(value)) : '';

    // Check if we have the data in cache and return it if true
    if (cacheMap.has(value.toLowerCase())) {
      return cacheMap.get(value.toLowerCase());
    }

    // Check if we have a key that starts with inputValue && key's value is empty array
    // We directly return an empty array since a request would do the same thing
    const checkStartsWith = emptyResults.find((item) => stringStartsWith(value, item));
    if (checkStartsWith) return [];

    // If we don't have the data in cache, or nothing that matches the emptyResults array, fetch it
    // Start fetching, set the loading status to 'true'
    setIsLoading(true);
    const response = await fetcher(`${fetchUrl}${query ? `?${query.toString()}` : ''}`, locale, config);

    // Fetch ended, set the loading status to 'false'
    setIsLoading(false);

    // Type check the array, assign value & update cache
    if (Array.isArray(response) && !isApiV1Errors(response)) {
      updateCacheMap(value.toLowerCase(), response);
      if (response.length === 0) setEmptyResults([...emptyResults, value.toLowerCase()]);
      data = response;
    }

    // Help for debugging if the API crashes
    if (isApiV1Errors(response)) {
      console.error('API crashing here: ', response);
    }

    return data;
  };

  return {
    getComboboxData,
    cacheMap,
    emptyResults,
    isLoading,
  };
};
