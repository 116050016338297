import { ModalCommonProps } from 'src/types/modal-types';
import { ModalStyles } from './_modalStyles';


/**
 * @description Modal body component.
 * * **className** - additional classes for the body
 * * **content** - body content when not using children (passed as prop)
 * * **children** - body content when using the body with dot notation. It will display nothing
 * if you pass the content prop.
 */
export const ModalBody = (props: ModalCommonProps) => {
  // Destructuring props
  const {
    children,
    content = '',
    className = '',
  } = props;

  // Body content; can be either from content prop or children, but not both
  const bodyContent = content || children;

  // Render component
  // **************************************
  return (
    <div className={`${ModalStyles.body} ${className}`}>
      {bodyContent}
    </div>
  );
};
