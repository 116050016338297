interface IProgressBarProps {
  percentage: number;
}

export const ProgressBar = ({ percentage }: IProgressBarProps) => {
  const progressBarWidth = {
    width: `${percentage}%`,
  };
  return (
    <>
      <div className="w-full bg-gray-200 h-2 mb-6 rounded-full">
        <div className="bg-secondary h-2 rounded-full transition-all duration-500" style={progressBarWidth} />
      </div>
    </>
  );
};

