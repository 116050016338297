import React from 'react';
import { Spinner } from '../Elements/Spinner';

interface IProps {
  isLoading: boolean;
  children: React.ReactNode;
}

/**
 * @description Wrapper to show a loading component while the data is loading
 * @param children
 * @param isLoading
 * @constructor
 */
export const LoadingContainer = ({ children, isLoading }: IProps) => (
  <div className="relative">
    {
      isLoading && (
        <div className="absolute z-50 flex size-full items-center justify-center bg-surface-100/80">
          <Spinner />
        </div>
      )
    }
    <div>
      {children}
    </div>
  </div>

);
