import { createElement } from 'react';
import { Config } from 'isomorphic-dompurify';
import { sanitizeHtml } from '@utils/strings/sanitizeHtml';


// Interface
// ***********************************************
export interface SanitizeHTMLProps {
  htmlContent?: string,
  as?: keyof JSX.IntrinsicElements,
  config?: Config,
  className?: string,
  type?: string,
}


/**
 * @description Sanitizes html content using DomPurify. You can pass a config object to customize the sanitization. For config options, see
 * dompurify's documentation: https://github.com/cure53/DOMPurify?tab=readme-ov-file#can-i-configure-dompurify
 * Basically we replace dangerouslySetInnerHTML with this component.
 *
 * * **htmlContent** - *string* - The html content to sanitize. Defaults to an empty string.
 * * **as** - *keyof JSX.IntrinsicElements* - The element to render the sanitized html as. Defaults to 'div'.
 * * **config** - *Config* - The config object to pass to dompurify. Defaults to undefined.
 * * **className** - *string* - Optional className to pass to the element. Defaults to undefined.
 * * **type** - *string* - Optional type to pass to the element.
 *
 * @example
 * <SanitizeHTML htmlContent="<p>abc<iframe//src=jAva&Tab;script:alert(3)>def</p>" className="mt-1" />
 * // => <div class="mt-1"><p>abc</p></div>
 *
 * // using translation; let's say that 'some.text' = '<strong>Ana</strong> are mere'
 * <SanitizeHTML as="span" htmlContent={t('some.text')} />
 * // => <span><strong>Ana</strong> are mere</span>
 */
export const SanitizeHTML = (props: SanitizeHTMLProps) => {
  // Props destructuring
  const {
    htmlContent = '',
    as = 'div',
    config,
    className,
    type = '',
  } = props;

  // Return nothing if no html
  if (!htmlContent) return null;

  // Sanitize html
  // ************************************************
  return createElement(as, {
    dangerouslySetInnerHTML: { __html: sanitizeHtml(htmlContent, config) },
    className,
    ...(type !== '' && { type }),
  });
};

