import { MessagesCenterInterface } from '@type/header-types';
import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline';
import { PopoverStyled, Button, BadgeNotifications } from '@components/common';
import { dataTestId } from '@services/data-test-id';
import { MessagesDropdown } from './MessagesDropdown';


/**
 * @description Renders the messages section in the navbar.
 * * **className** Extra classes
 * * **locale** The locale of the app
 * * **unseenMessages** The number of unseen messages
 */
export const MessagesSection = (props: MessagesCenterInterface) => {
  // Component props with default values
  const {
    className,
    locale = 'ro',
    unseenMessages = 0,
  } = props;


  // Render component
  // ******************************************
  return (
    <PopoverStyled className={className}>
      {({ close }) => (
        <>
          { /* Navbar icon */ }
          <PopoverStyled.Button as={Button} styling="none" className="relative block px-1 py-2.5 outline-none lg:py-3.5" {...dataTestId('navbar.messagesButton')}>
            <BadgeNotifications count={unseenMessages} showCount={false} className="absolute right-0 top-2.5 -translate-x-1/2 lg:top-3" />
            <ChatBubbleBottomCenterIcon className="size-5 stroke-2" />
          </PopoverStyled.Button>

          { /* Dropdown */ }
          <PopoverStyled.Panel className="top-full w-screen sm:w-auto sm:min-w-87.5 lg:mt-1" position="right">
            <MessagesDropdown closePopover={close} locale={locale} />
          </PopoverStyled.Panel>
        </>
      )}
    </PopoverStyled>
  );
};
