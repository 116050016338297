import { ProfileNavbarInterface } from '@type/header-types';
import { useTranslation } from 'next-i18next';
import { dataTestId } from '@services/data-test-id';
import { composeProfileTextClasses } from './_profileNavbarStyles';
import { ProfileAvatar } from './ProfileAvatar';


/**
 * @description Renders the profile with image, name, email and score. Used in the navbar or
 * somewhere else if needed.
 * * **headerInfo** The header info data
 * * **size** The size of the profile image
 * * **className** Extra classes
 */
export const ProfileInNavbar = (props: ProfileNavbarInterface) => {
  // Component props
  const { headerInfo, size = 'sm', className } = props;

  // translate
  const { t } = useTranslation('common');

  // Classes
  // ******************************************
  const nameClasses = composeProfileTextClasses(size, 'name');

  // Data aliases
  // ******************************************
  const isCompany = headerInfo?.hasBusinessWallet;
  const name = isCompany ? headerInfo?.name || t('account.label.myaccount') : headerInfo?.candidateName || t('account.label.myaccount');

  // Render component
  // Short-circuit if the headerInfo is null
  // ******************************************
  if (!headerInfo) return (<></>);
  return (
    <span
      className={`flex items-center gap-3 ${className || ''}`}
      {...dataTestId('navbar.profile')}
    >
      {/* Avatar */}
      <ProfileAvatar headerInfo={headerInfo} className="md:hidden" />

      {/* User info; accounts created with the phone number don't have an email address initially */}
      <span className="hidden flex-col items-start lg:flex">
        <span className={nameClasses}>{name}</span>
        {(headerInfo?.walletCredits && headerInfo?.walletCredits > 0) ? (
          <span>
            {`${headerInfo?.walletCredits} ${t('account.label.neuro')}`}
          </span>
        ) : ''}
      </span>
    </span>
  );
};
