// Conversation details by ID *********************************************************************
// pathname: /v1/conversations/{id}
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';


/**
 * @description SWR: fetch conversation details by id.
 * @param id - { number } - the conversation ID.
 * @param locale - { string } - the locale to use for the request. Default: 'ro'.
 * @param shouldFetch - { boolean } - a flag indicating whether to start fetching conversation details. Default: true
 */
export const useSwrConversationDetails = (id: number, locale = 'ro', shouldFetch = true) => {
  // Get data from API
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiSchema<'ConversationRead'>>({
    url: `/v1/conversations/${id}`,
    shouldFetch,
    locale,
    apiOptions: {
      dedupingInterval: 60 * 1000, // 60 seconds
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
