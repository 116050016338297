import { i18nConfig } from 'i18n-config';


// Destructure the i18nConfig
const {
  locales,
  defaultLocale,
  prefixedDefaultLocale,
} = i18nConfig;


/**
 * @description Locales regex. This regex is used to match the locale in the URL pathname.
 * Example: /^(ro|en|hu)/
 */
export const localesRegex = new RegExp(`^/(${locales.join('|')})`);


/**
 * @description Function that will return the proper locale prefix for the current locale.
 * It handles the case when the default locale is prefixed or not.
 * @param locale string, the current locale
 * @param asPath string, the current page path, as shown in browser (including search params)
 */
export const setLocalePrefix = (locale: string, asPath?: string) => {
  // Prefix all the locales the same way
  if (prefixedDefaultLocale) {
    return `/${locale}`;
  }

  // Prefix only the non-default locales
  let localePrefix = locale !== defaultLocale ? `/${locale}` : '';

  // Handle the homepage path
  // Add a '/' if as path is empty or contains only search params, and we're on the default locale
  if (asPath !== undefined) {
    localePrefix = (asPath === '' || asPath.startsWith('?')) && locale === defaultLocale ? '/' : localePrefix;
  }

  return localePrefix;
};
