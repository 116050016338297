import { ApiResumeSearchAlertParams } from '@type/v1-api-types';
import { JobSearchGroup } from '@type/job-search';
import {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';
import { useReduxUser } from '@hooks/common/useAuthClient/useReduxUser';
import { useSwrResumeSearchAlerts, useSwrDeleteResumeSearchAlert } from '@hooks/useSwrApi/endpoints/resume-search-alert';
import { objectRemoveEmptyKeys } from '@utils/object-remove-empty-keys/object-remove-empty-keys';
import { sortObjectKeys } from '@utils/objects/sortObjectKeys';
import { arrayRemoveItems } from '@utils/arrays/arrayRemoveItems';
import { buildSearchParams } from '@utils/data-fetching';


// Interface
// ******************************************
interface ResumeSearchAlertsProps {
  locale?: string,
  sortQueryParams?: boolean,
}

interface AlertsMatchingLocations {
  keyword: string,
  location: string,
  radiusInKm: number,
}


/**
 * @description A hook to manage the resume search alerts. It fetches the search alerts and provides functions for deleting them.
 * It updates the search alerts when the query params change after closing the filters sidebar drawer (as long as you pass the query params
 * using the setOnOpenFilters and setOnCloseFilters).
 *
 * Params:
 * - **locale:** *string* - the locale of the app. Default 'ro'. Needed for the SWR fetch.
 * - **sortQueryParams:** *boolean* - if true, the query params will be sorted alphabetically. Default true. Needed to maintain
 * the order of the query params for SWR access keys.
 *
 * Returns:
 * - **searchAlerts:** *object* - the search alerts fetched from the API
 * - **mutateSearchAlerts:** *function* - a function to mutate the search alerts
 * - **deleteAlert:** *function* - a function to delete a search alert
 * - **setSelectedFilters:** *function* - a function to set (apply) the selected filters
 * - **alertsKeywords:** *object* - the keywords from the search alerts. Use them to populate the search input with suggestions
 * - **setOnOpenFilters:** *function* - a function to set the onOpen filters (query params) when the filters sidebar drawer is opened
 * - **setOnCloseFilters:** *function* - a function to set the onClose filters (query params) when the filters sidebar drawer is closed
 * - **matchingLocations:** *object* - the matching locations from the search alerts. Use them to display location suggestions.
 *
 * @example
 * // Usage in a dropdown component
 * // ******************************
 * const {
 *   searchAlerts,
 *   deleteAlert,
 *   setSelectedFilters,
 * } = useResumeSearchAlerts({ locale, sortQueryParams });
 *
 * // Apply the selected filters from the search alerts (ie: on button click) and delete the alert
 * searchAlerts?.map((alert) => (
 *  // Apply the selected filters
 *  <Button onClick={() => setSelectedFilters(alert?.resumeSearch, setQueryParams)}>
 *     <span className="line-clamp-3 text-ink">{alert?.label}</span>
 *  </Button>
 *
 *  // Delete the alert
 *  <Button onClick={() => { if (alert?.id) deleteAlert(alert.id); }}>
 *    <XMarkIcon className="w-4 h-4" />
 *  </Button>
 * ));
 *
 *
 * // Usage in a search component
 * // ******************************
 * const {
 *   mutateSearchAlerts,
 *   alertsKeywords,
 * } = useResumeSearchAlerts({ locale });
 *
 * // Prop for Combobox component
 * staticList: dropdownList || alertsKeywords,
 *
 *
 * // Usage with the filters Drawer component
 * // ******************************
 * const { setOnOpenFilters, setOnCloseFilters } = useResumeSearchAlerts({ locale });
 *
 * <Drawer
 *   afterEnter={() => { setOnOpenFilters(queryParams); }}
 *   beforeLeave={() => { setOnCloseFilters(queryParams); }}
 *   ...
 * >
 *   <SidebarTalentSearchFiltersHub />
 * </Drawer>
 *
 */
export const useResumeSearchAlerts = (props?: ResumeSearchAlertsProps) => {
  // Destructure props
  const {
    locale = 'ro',
    sortQueryParams = true,
  } = props || {};

  // Holder for the alert id.
  const [alertId, setAlertId] = useState('');

  // Holder for keywords from the search alerts
  const [alertsKeywords, setAlertsKeywords] = useState<JobSearchGroup>([]);

  // Holder for the onOpen filters
  const [onOpenFilters, setOnOpenFilters] = useState<object | undefined>(undefined);

  // Holder for the onClose filters
  const [onCloseFilters, setOnCloseFilters] = useState<object | undefined>(undefined);

  // Holder for matching locations
  const [matchingLocations, setMatchingLocations] = useState<AlertsMatchingLocations[]>([]);


  // Redux user. Get the header info to check if the user is logged in
  // ******************************
  const { headerInfo } = useReduxUser();


  // SWR: fetch search alerts
  // ******************************
  const {
    data,
    mutate,
  } = useSwrResumeSearchAlerts(locale, headerInfo !== undefined);

  // Mutate the search alerts
  const getResumeSearchAlerts = () => { void mutate(); };


  // SWR: delete search alert
  // ******************************
  const { trigger } = useSwrDeleteResumeSearchAlert(locale, alertId, getResumeSearchAlerts);

  // Delete the search alert
  const deleteAlert = (id: string) => {
    setAlertId(id);
  };

  // Trigger the delete alert
  useEffect(() => {
    if (alertId) void trigger();
  }, [alertId, trigger]);


  // Set selected filters. Remove empty keys and sort id sort is 'relevance', because they are defaults. Also remove
  // radiusInKm if location is not set.
  // We sort the keys to have a consistent order, because SWR uses the keys to cache the data and if the order
  // is different it will be considered a different request. Same thing is done inside useFiltersHub and useFiltersQueryParams.
  // ******************************
  const setSelectedFilters = (filters?: ApiResumeSearchAlertParams | undefined, setParams?: Dispatch<SetStateAction<object>>) => {
    if (filters && setParams) {
      const newFilters = objectRemoveEmptyKeys(filters) as ApiResumeSearchAlertParams;
      if (newFilters?.sort && filters?.sort === 'relevant') delete newFilters.sort;
      if (newFilters?.radiusInKm && !filters?.location) delete newFilters.radiusInKm;
      setParams(sortQueryParams ? sortObjectKeys(objectRemoveEmptyKeys(filters)) : objectRemoveEmptyKeys(filters));
    }
  };


  // Update the keywords list when the search alerts are fetched
  // ******************************
  useEffect(() => {
    if (data) {
      const keywords = data.map((item) => item.resumeSearch?.keyword);
      const goodKeywords = arrayRemoveItems(keywords, [undefined, '']) as string[];
      setAlertsKeywords(goodKeywords.map((suggestion) => ({ suggestion })));
    }
  }, [data]);


  // Update the search alerts if the query params change after closing the filters sidebar drawer
  // ******************************
  useEffect(() => {
    if (onOpenFilters && onCloseFilters) {
      const initialFilters = buildSearchParams(sortQueryParams ? sortObjectKeys(onOpenFilters) : onOpenFilters, '');
      const finalFilters = buildSearchParams(sortQueryParams ? sortObjectKeys(onCloseFilters) : onCloseFilters, '');

      // If the filters have changed, re-fetch the search alerts
      if (initialFilters !== finalFilters) void mutate();

      // Reset the onOpen and onClose filters
      setOnOpenFilters(undefined);
      setOnCloseFilters(undefined);
    }
  }, [onOpenFilters, onCloseFilters, sortQueryParams, mutate]);


  // Update the matching locations
  // ******************************
  useEffect(() => {
    if (data) {
      const matchingLocationsArray: AlertsMatchingLocations[] = [];

      data.forEach((alert) => {
        if (alert?.resumeSearch?.location) {
          matchingLocationsArray.push({
            keyword: alert?.resumeSearch?.keyword || '',
            location: alert?.resumeSearch?.location || '',
            radiusInKm: alert?.resumeSearch?.radiusInKm || 0,
          });
        }
      });

      setMatchingLocations(matchingLocationsArray);
    }
  }, [data]);


  // Return
  // ******************************
  return {
    searchAlerts: data,
    mutateSearchAlerts: mutate,
    deleteAlert,
    setSelectedFilters,
    alertsKeywords,
    setOnOpenFilters,
    setOnCloseFilters,
    matchingLocations,
  };
};
