import { useState } from 'react';


/**
 * @description Convenience hook to control modal state. It will save you from having to write
 * boilerplate code to control modal state. The returned values are:
 * * **isOpen** - boolean value to control modal state (initial value: false)
 * * **toggleModal** - function to toggle modal state (isOpen: true/false)
 * * **openModal** - function to open modal (isOpen: true)
 * * **closeModal** - function to close modal (isOpen: false)
 *
 * @example
 * const {isOpen, toggleModal, openModal, closeModal} = useBjModal();
 */
export const useBjModal = () => {
  // State to control modal
  const [isOpen, setIsOpen] = useState(false);
  // Flag to prevent rapid toggling of modal
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Transition duration; Taken from Modal Transition.Child
  const transitionDuration = 200;


  // Functions toggled via state
  // ****************************************************************
  // Function to toggle modal via state. We take care of the transition duration here (don't want to
  // reopen modal while it's closing when user clicks too fast).
  const toggleModal = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setIsOpen(!isOpen);

      // Toggle modal state
      setTimeout(() => {
        setIsTransitioning(false);
      }, transitionDuration);
    }
  };

  // Function to open modal via state
  const openModal = () => {
    setIsOpen(true);
  };

  // Function to close modal via state
  const closeModal = () => {
    setIsOpen(false);
  };


  // Return values
  // ****************************************************************
  return {
    isOpen,
    toggleModal,
    openModal,
    closeModal,
  };
};
