import {
  ListIcon, ListSize, iconList, elementClasses,
} from './list-styles';


// Interfaces
// *****************************************************
interface ListProps {
  items?: string[],
  icon?: ListIcon,
  size?: ListSize,
  className?: string,
  classLi?: string,
  classIcon?: string,
  classText?: string,
}


/**
 * @description Renders a list of items. Makes the design consistent across the app.
 * * **items** - the list of items to render; default is an empty array
 * * **icon** - the icon to render for each item; default is 'check'
 * * **size** - the size of the icon & text; default is 'base'
 * * **className** - extra classes for the list; default is an empty string
 * * **classIcon** - extra classes for the icon; default is an empty string
 * * **classText** - extra classes for the text; default is an empty string
 *
 * @example
 * <List items={['item 1', 'item 2', 'item 3']} icon="check-circle" size="lg" />
 */
export const List = (props: ListProps) => {
  // Props destructuring
  const {
    items = [],
    size = 'base',
    icon = 'check',
    className = '',
    classLi = '',
    classIcon = '',
    classText = '',
  } = props;


  // Get the icon from the list
  const IconName = iconList[icon as keyof typeof iconList] || iconList.check;


  // Render component
  // *****************************************
  return (
    <ul className={`flex flex-col gap-1.5 ${className}`}>
      {items?.length && items.map((item) => (
        <li key={item} className={`flex items-center gap-1.5 ${classLi}`}>
          <IconName className={elementClasses('icon', size, classIcon)} />
          <span className={elementClasses('text', size, classText)}>{item}</span>
        </li>
      ))}
    </ul>
  );
};
