// Imports
// ***********************************************
import { flattenObj } from '../flatten-expand-object/flatten-expand-object';
import { safeParseJsonObject } from '../json-helpers/json-helpers';


/**
 * @description Get the object or form values from a JSON object saved in session storage. It safely parses
 * the json and you have the option to delete the key afterward. Be careful to check for the key
 * existence though.
 * @param keyName string
 * @param deleteAfter boolean
 * @param flatten boolean
 */
export const getObjectFromSessionStorage = (keyName: string, deleteAfter = false, flatten = true) => {
  const item = safeParseJsonObject(sessionStorage.getItem(keyName));
  const value = flatten ? flattenObj(item) : item;
  if (deleteAfter) sessionStorage.removeItem(keyName);
  return value;
};

/**
 * @description Get a string from session storage. You have the option to delete the key afterward.
 * @param keyName string
 * @param deleteAfter boolean
 */
export const getStringFromSessionStorage = (keyName: string, deleteAfter = false) => {
  const item = sessionStorage.getItem(keyName);
  if (deleteAfter) sessionStorage.removeItem(keyName);
  return item ?? undefined;
};
