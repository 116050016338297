// RESUME EDIT COMMENT ********************************************************************************
// pathname: /v1/resume-operation/${commentId}/comment
// ****************************************************************************************************
import {
  useSwrMutationApi, ApiResumeAddEditCommentPayload, GenericV1ErrorsArray,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: POST add comment to resume. Response status ok: 201.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 * @param commentId - number. The user id to add the comment to.
 * @param successCallback - function. The callback to call on success. For example, re-fetch comments (mutate function).
 * @param successToastText - string. The text to show in the success toast.
 */
export const useSwrResumeEditComment = (locale: string, commentId: number, successCallback?: () => void, successToastText?: string) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object | GenericV1ErrorsArray, ApiResumeAddEditCommentPayload>({
    url: `/v1/resume-operation/${commentId}/comment`,
    locale,
    fetcherConfig: {
      method: 'PUT',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) successCallback();
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      successToastText,
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
