import { MainMenuLink, SwitchLink } from '@type/header-types';
import {
  CurrencyDollarIcon, Squares2X2Icon, BriefcaseIcon,
} from '@heroicons/react/20/solid';
import { HipStarIcon, HeroIconType } from '@components/common/Icons';

// MAIN MENU LINKS
// forRole:
// NONE - for all, when they are not logged-in
// USER - for authenticated users with role 'USER'
// COMPANY - for authenticated users with role 'COMPANY'

// type:
// symfony - link to symfony route
// react - link to react route
// external - link to external route

// forSection
// TALENT - Represents the talent-related section.
// HIRE - Represents the hiring-related section.
// *******************************************************************
export const mainMenuLinks: MainMenuLink[] = [
  { // job list page
    route: '/job/list',
    type: 'react',
    label: 'header.label.jobs',
    forSection: ['TALENT', 'HIRE'],
    onlyMobile: false,
    icon: Squares2X2Icon,
    dataTestId: 'navbar-job-list',
  },
  { // cv worth page
    route: '/salaries',
    label: 'global.label.salaries',
    type: 'react',
    forSection: ['TALENT', 'HIRE'],
    onlyMobile: false,
    icon: CurrencyDollarIcon,
    dataTestId: 'navbar-salaries',
  },
  {
    route: '/cv/search',
    label: 'hire.menu',
    subTitle: 'header.label.hire_sub',
    type: 'react',
    forSection: ['TALENT', 'HIRE'],
    onlyMobile: false,
    icon: BriefcaseIcon,
    dataTestId: 'navbar-cv-search',
    children: [
      // talent search, only on mobile
      {
        route: '/cv/search',
        label: 'header.label.cv_search',
        type: 'react',
        forSection: ['TALENT', 'HIRE'],
        onlyMobile: false,
        dataTestId: 'navbar-cv-search',
      },
      { // company job list page
        route: 'company_job_list',
        label: 'header.label.jobs.v2',
        type: 'symfony',
        forSection: ['TALENT', 'HIRE'],
        onlyMobile: false,
        dataTestId: 'navbar-my-jobs',
      },
      {
        // add job
        route: 'app_job_content_add',
        label: 'header.label.add_jobs',
        type: 'symfony',
        forSection: ['TALENT', 'HIRE'],
        onlyMobile: false,
        dataTestId: 'navbar-add-job',
      },
      {
        // services page
        route: '/company/offer',
        label: 'header.label.pricing',
        type: 'react',
        forSection: ['TALENT', 'HIRE'],
        onlyMobile: false,
        dataTestId: 'navbar-services',
      },
    ],
  },
  { // Hip Star
    route: 'https://www.hipstar.eu/ro?utm_source=vivre&utm_medium=menu_link',
    label: 'Hip*',
    type: 'external',
    forSection: ['TALENT', 'HIRE'],
    onlyMobile: false,
    icon: HipStarIcon as HeroIconType,
    dataTestId: 'hip-star',
  },
];


// SWITCH LINK - between company and user
// *******************************************************************
export const switchLink: SwitchLink = {
  notAuth: { // to add job
    route: 'job_add',
    label: 'header.label.add_jobs',
  },
  authUser: { // change to company account
    route: 'account_user_type',
    label: 'header.switch.hire',
  },
  authCompany: { // change to user account
    route: 'account_user_type',
    label: 'header.switch.work',
  },
};
