import { forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { Card, List } from '@components/common';
import { CardProps } from '@type/card';
import { getSubscriptionStatus } from '../utils';


// INTERFACES
// *****************************************
interface SubscriptionCardProps extends CardProps {
  subscriptionData: ApiSchema<'SubscriptionPackageModel'>,
}

// Colors type
type ElementsColors = {
  [status: string]: {
    labelColor: string,
    iconColor: string,
  }
};


// STYLE CLASSES
// *****************************************
const elementsColors: ElementsColors = {
  isCurrent: {
    labelColor: 'bg-primary-light',
    iconColor: 'text-primary-light',
  },
  isUpgrade: {
    labelColor: 'bg-secondary',
    iconColor: 'text-secondary',
  },
  isNotAvailable: {
    labelColor: 'bg-secondary/50',
    iconColor: 'text-secondary/50',
  },
};

// card classes
const cardBaseClasses = {
  common: 'rounded-md bg-surface border cursor-pointer md:w-1/2 lg:w-1/3 mb-3',
  selectedCommon: 'ui-checked:ring ui-checked:outline-dotted',
  selectedCurrent: 'ui-checked:border-primary-light/80 ui-checked:ring-primary-light/30 ui-checked:outline-primary-light/30',
  selectedUpgrade: 'ui-checked:border-secondary/80 ui-checked:ring-secondary/30 ui-checked:outline-secondary/30',
  notSelectedCurrent: 'ui-not-checked:hover:border-primary-light/40',
  notSelectedUpgrade: 'ui-not-checked:hover:border-secondary/50',
  disabled: 'ui-disabled:cursor-not-allowed ui-disabled:text-ink-light',
};

const cardClasses = {
  isCurrent: `${cardBaseClasses.common} ${cardBaseClasses.selectedCommon} ${cardBaseClasses.selectedCurrent} ${cardBaseClasses.notSelectedCurrent}`,
  isUpgrade: `${cardBaseClasses.common} ${cardBaseClasses.selectedCommon} ${cardBaseClasses.selectedUpgrade} ${cardBaseClasses.notSelectedUpgrade}`,
  isNotAvailable: `${cardBaseClasses.common} ${cardBaseClasses.disabled}`,
};


/**
 * @description Renders a subscription card.
 * * **subscriptionData** is the subscription package data.
 * * **className** extra classes you might want to pass on the card.
 */
export const SubscriptionCard = forwardRef<HTMLDivElement, SubscriptionCardProps>((props, ref) => {
  // Props destructuring
  const {
    subscriptionData,
    className = '',
    ...restProps
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Get subscription status
  const statusType = getSubscriptionStatus(subscriptionData);


  // Card classes
  // *****************************************
  const mainCardClasses = cardClasses[statusType];
  const { labelColor, iconColor } = elementsColors[statusType];

  // CTA Title fallback (if missing from API)
  const safeCtaTitle = subscriptionData?.ctaTitle ?? t('global.test.free.premium');


  // Render component
  // *****************************************
  return (
    <Card animation={subscriptionData?.available ? 'riseUp' : 'none'} ref={ref} className={`${mainCardClasses} ${className}`} {...restProps}>

      <Card.Header className="px-5 pt-5">
        { /* Title */ }
        <div className="flex justify-between">
          <h4 className="text-sm font-bold">{subscriptionData?.mainTitle}</h4>
          <h6 className={`rounded px-2 text-sm font-semibold text-surface ${labelColor}`}>
            {subscriptionData?.current ? t('global.status.active') : safeCtaTitle}
          </h6>
        </div>

        { /* Price */ }
        <div className="flex items-center justify-between">
          <div className="mt-0.5 flex items-center gap-1.5">
            <strong className="text-5xl">{subscriptionData?.price}</strong>
            <span className="text-ink-medium ui-disabled:text-ink-light">{subscriptionData?.currencyPeriod}</span>
          </div>
          <div className="text-sm font-medium text-error/70 line-through ui-disabled:text-error/50">
            {
              (subscriptionData?.fullPrice && subscriptionData?.fullPrice > 0) && <>{subscriptionData?.fullPrice}</>
            }
          </div>
        </div>
      </Card.Header>

      { /* List */ }
      <Card.Body className="px-5 py-4">
        <List
          items={subscriptionData?.benefits}
          classLi="py-1"
          className="!gap-0 divide-y divide-dashed"
          classIcon={iconColor}
          icon="check-circle"
        />
      </Card.Body>
    </Card>
  );
});
