import { useState, useMemo, ReactElement } from 'react';
import { RecordInterface, RecordOptionsInterface } from '@type/form-types';
import { useTranslation, TFunction } from 'next-i18next';
import { getWithFlatKeys } from '@utils/flatten-expand-object/flatten-expand-object';
import { Input } from '../Input/Input';


// INTERFACES
// **********************************************
interface RenderListboxProps<DataType> {
  selectedLabel: string,
  t: TFunction,
  lsData: DataType[],
  selected: DataType | DataType[],
  setSelected: (value: DataType) => void,
}

interface RenderListboxFunction<DataType> {
  (renderProps: RenderListboxProps<DataType>): ReactElement
}

export interface ListboxWrapperProps<DataType> {
  data: DataType[],
  defaultValueKey: string,
  labelKey?: string,
  initialSelectedIndex?: number[];
  queryParam?: string | number,
  recordFunc?: RecordInterface,
  submitFunc?: () => void,
  fieldName: string,
  idPrefix?: string,
  multiple?: boolean,
  withTranslation?: boolean,
  renderListbox?: RenderListboxFunction<DataType>,
  recordOptions?: RecordOptionsInterface,
  placeholder?: string,
}


/**
 * @description Component that wraps a listbox and a hidden input field that stores the selected value. With this component, we can
 * store the selected value in the input field, and also update the selected value from the input field. This is useful when
 * we want to use the listbox with useBjForm, and we want to store the selected value in the form.
 *
 * Keep in mind that when using the multiple option (checkboxes), and have no value selected, you might need a default selected label,
 * something like selectedLabel || 'choose an option'.
 *
 * Props:
 * * **data**: the listbox data array
 * * **defaultValueKey**: the key that holds the default value in the data object; use dot notation for nested keys
 * * **labelKey**: the key that holds the label in the data object; use dot notation for nested keys
 * * **initialSelectedIndex**: array - the index of the default selected value. If you want to select multiple values, pass an array of indexes or an empty array for no selection.
 * * **queryParam**: the query param that holds the selected value; use it when you have an initial selected value from the query params
 * * **recordFunc**: the record function from useBjForm
 * * **submitFunc**: the submit function from useBjForm
 * * **fieldName**: the name of the field
 * * **idPrefix**: the prefix for the id of the input field
 * * **multiple**: if the listbox is multiple; you will also need to pass multiple to the listbox component
 * * **withTranslation**: if the listbox labels should be translated (you passed the localized keys)
 * * **renderListbox**: a render function that renders the listbox; this is where you can customize the listbox
 * * **recordOptions**: the record options from useBjForm
 *
 *
 * Render options:
 * * **selectedLabel**: the label of the selected value
 * * **t**: the translation function
 * * **lsData**: the listbox data
 * * **selected**: the selected value
 * * **setSelected**: the function that sets the selected value
 *
 * **IMPORTANT:** When you have a listbox with multiple selection, and the values must be an array for BjForm, you need to pass the prop
 * recordOptions={{ convertValue: 'commaStringToArray' }}
 *
 * @example
 * // usage with multiple. Remove multiple if not needed.
 * <ListboxWrapper
 *   fieldName="sort"
 *   data={sortBy.fields}
 *   defaultValueKey="recordOptions.defaultValue"
 *   labelKey="recordOptions.filtersLabel"
 *   withTranslation
 *   multiple
 *   renderListbox={({
 *     selectedLabel, lsData, selected, setSelected,
 *   }) => (
 *     <ListboxStyled className="relative" by="label" value={selected} onChange={setSelected} multiple>
 *       <ListboxStyled.Button
 *         as={Button}
 *         size="sm"
 *         color="ink"
 *         styling="outline"
 *         rounding="full"
 *         className="!py-1.5 !border-ink/25 hover:!bg-primary-light hover:!border-primary-light"
 *       >
 *         <ArrowsUpDownIcon className="w-4 h-4 mr-1.5" />
 *         {selectedLabel}
 *         <ChevronDownIcon className="w-4 h-4 ml-1.5 -mr-2" />
 *       </ListboxStyled.Button>
 *
 *       <ListboxStyled.Options className="min-w-40 text-sm" animation="slideDown" position="left">
 *         <span className="inline-block py-2 px-3">{t('order-by')}</span>
 *         {lsData.map((field) => (
 *
 *           <ListboxStyled.Option key={field.label} value={field} className="py-2 px-3 ui-active:bg-primary ui-active:text-surface ui-not-active:bg-surface ui-not-active:text-ink">
 *             {t(field.label)}
 *           </ListboxStyled.Option>
 *
 *         ))}
 *       </ListboxStyled.Options>
 *     </ListboxStyled>
 *   )}
 * />
 */
export const ListboxWrapper = <DataType extends object>(props: ListboxWrapperProps<DataType>) => {
  // Destructure props
  const {
    data,
    defaultValueKey,
    labelKey,
    initialSelectedIndex = [0],
    queryParam,
    recordFunc,
    submitFunc,
    fieldName,
    idPrefix = '',
    multiple = false,
    withTranslation = false,
    renderListbox,
    recordOptions = {},
  } = props;

  // Translation
  const { t } = useTranslation('common');


  // Get the default selected index
  // **********************************************
  const selectDefault = (check: boolean) => (check ? initialSelectedIndex.map((nr) => data[nr]) : data[initialSelectedIndex[0]]);


  // Get the selected values from a string.
  // We need to be able to update the selected values when triggered from the outside (setFormValues or queryParam)
  // **********************************************
  const getSelectedValuesFromString = (value: string | string[], multi?: boolean) => {
    // Helper variables
    const valueArray = Array.isArray(value) ? value : value.split(',');
    const newSelection = [] as DataType[];

    // Filter elements in data that match each string in the value array
    valueArray.forEach((val) => {
      const found = data.find((elem) => String(getWithFlatKeys(elem, defaultValueKey)) === String(val.trim()));
      if (found) newSelection.push(found);
    });

    // Return the new selection; the empty object is needed when using the single selection as a removable filter
    return (multi ? newSelection : newSelection[0] || {});
  };


  // Helper variables
  // **********************************************
  // The default selected value when no query params are passed
  const defaultSelected = selectDefault(multiple);

  // Get the initial value, with query params if prezent
  const initiallySelected = queryParam ? getSelectedValuesFromString(String(queryParam), multiple) : defaultSelected;

  // Store the currently selected object
  const [selected, setSelectedState] = useState(initiallySelected);


  // Selected string: the value for the input (since we cannot use objects)
  // **********************************************
  const selectedValueString = useMemo(() => {
    // multiple selection
    if (multiple && Array.isArray(selected)) {
      const selectedValues = selected.map((elem) => getWithFlatKeys(elem, defaultValueKey)) as string[];
      return selectedValues.join(',');
    }
    // single selection; if no selected value, return an empty string (removable filter)
    return getWithFlatKeys(selected as DataType, defaultValueKey) as string || '';
  }, [selected, defaultValueKey, multiple]);


  // Selected label: the string to be used for filters
  // **********************************************
  const selectedLabel = useMemo(() => {
    // multiple selection
    if (multiple && Array.isArray(selected) && labelKey) {
      const selectedLabels = selected.map((elem) => getWithFlatKeys(elem, labelKey)) as string[];
      const translatedLabels = selectedLabels.map((label) => t(label));
      return withTranslation ? translatedLabels.join(', ') : selectedLabels.join(', ');
    }
    // single selection
    const singleLabel = (labelKey ? getWithFlatKeys(selected as DataType, labelKey) : '') as string;
    return withTranslation ? t(singleLabel) : singleLabel;
  }, [selected, multiple, labelKey, t, withTranslation]);


  // Handle change. State updates are async, give them some time.
  // **********************************************
  const setSelected = (value: DataType) => {
    setSelectedState(value);
    if (submitFunc) setTimeout(() => submitFunc(), 50);
  };


  // Render component
  // **********************************************
  return (
    <>

      { // Render the listbox if a renderListbox render function is passed
        renderListbox
          ? renderListbox({
            selectedLabel, t, lsData: data, selected, setSelected,
          })
          : null
      }

      {/* Input that collects the listbox selected value; use the 'data-filters-label' attribute when dealing with variable filter labels */}
      <Input
        type="hidden"
        value={selectedValueString}
        data-filters-label={selectedLabel}
        onInput={(e) => {
          // If the event is not trusted (e.isTrusted = false), it means it was triggered programmatically
          // (ie: bjForm's setFormValues() function)
          if (!e.isTrusted) {
            const inputVal = e.currentTarget.value;
            setSelectedState(getSelectedValuesFromString(inputVal, multiple));
          }
        }}
        {...recordFunc ? recordFunc(fieldName, { id: `${idPrefix}${fieldName}`, ...recordOptions }) : {}}
      />

    </>
  );
};
