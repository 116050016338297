import { ReactNode } from 'react';
import { cn } from '@utils/cn';

// Interface
// ***********************************
interface ChatBubbleProps {
  children: ReactNode;
  isSender: boolean; // true if the message is sent by the user, false otherwise
  timestamp?: string; // optional timestamp
  senderStyle?: string; // optional custom styles for sender
  receiverStyle?: string; // optional custom styles for receiver
  className?: string,
}

/**
 * @description  Chat bubble component.
 * A reusable component for displaying chat messages with support for sender/receiver styles and timestamps.
 *
 * - **children**: The content of the chat bubble. Typically, this will be the chat message text or other JSX elements.
 * - **isSender**: A boolean that determines if the chat bubble represents the user (sender) or the other party (receiver).
 * - **timestamp**: An optional string representing the timestamp of the message.
 * - **senderStyle**: Optional custom class names to style the sender's chat bubble.
 * - **receiverStyle**: Optional custom class names to style the receiver's chat bubble.
 * - **className**: Additional class names for the root container of the chat bubble.
 *
 * @example
 * // Basic example with a sender message
 * <ChatBubble
 *   isSender={true}
 *   timestamp="10:45 AM"
 * >
 *   Hello! How are you doing?
 * </ChatBubble>
 *
 * @example
 * // Example with a receiver message
 * <ChatBubble
 *   isSender={false}
 *   timestamp="10:46 AM"
 * >
 *   I'm doing great, thank you! How about you?
 * </ChatBubble>
 *
 */
export const ChatBubble = (props: ChatBubbleProps) => {
  // Destructure props
  const {
    children,
    isSender = false,
    timestamp,
    senderStyle = '',
    receiverStyle = '',
    className,
  } = props;

  // Render component
  // *****************************************************
  return (
    <div
      className={cn(
        'flex w-full flex-col',
        isSender ? 'items-end' : 'items-start',
        className,
      )}
    >
      {timestamp && (
        <p className="mb-1 text-tiny text-ink-medium">{timestamp}</p>
      )}
      <div
        className={cn(
          'rounded-b-2xl px-4 py-2 child-link:text-primary',
          isSender ? 'rounded-tl-2xl bg-ink text-surface' : 'rounded-tr-2xl bg-surface-100 text-ink',
          isSender ? senderStyle : receiverStyle,
        )}
      >
        {children}
      </div>
    </div>
  );
};
