/*
  BRAND ICONS
  You can find brand icons here: https://simpleicons.org/
  Note: To use simpleicons.org with text colour (i.e. text-blue-500 in TW, etc.) or to inherit
  parent text colour you need to add fill="currentColor" to the <svg> tag.

  Current icons list:
  - Facebook
  - LinkedIn
  - Twitter
  - WhatsApp
  - Github
 */

// Default size: 16px
// *********************************
const defaultClasses = 'w-4 h-4';


/**
 * @description Facebook svg icon. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const FacebookIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
  </svg>
);


/**
 * @description LinkedIn svg icon. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const LinkedInIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433a2.062 2.062 0 0 1-2.063-2.065 2.064 2.064 0 1 1 2.063 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" />
  </svg>
);


/**
 * @description Twitter svg icon. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const TwitterIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path d="M23.953 4.57a10 10 0 0 1-2.825.775 4.958 4.958 0 0 0 2.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 0 0-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 0 0-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 0 1-2.228-.616v.06a4.923 4.923 0 0 0 3.946 4.827 4.996 4.996 0 0 1-2.212.085 4.936 4.936 0 0 0 4.604 3.417 9.867 9.867 0 0 1-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 0 0 7.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0 0 24 4.59z" />
  </svg>
);


/**
 * @description WhatsApp svg icon. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const WhatsAppIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51a12.8 12.8 0 0 0-.57-.01c-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 0 1-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 0 1-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 0 1 2.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0 0 12.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 0 0 5.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 0 0-3.48-8.413Z" />
  </svg>
);


/**
 * @description Github svg icon. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const GithubIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
  </svg>
);


/**
 * @description Bestjobs svg icon logo. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const BjGeckoIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3 13.9c0-2.3-.8-4.5-2.2-6.3-1-1.2-2.2-2.2-3.6-2.8-1.4-.7-3-1-4.5-1 0 0-1.6 0-2 .6-.6.8-.3 1.5.5 2.9.4.7.7 1.4 1.1 2.1.1.2.3.4.5.6.6.6 1.1.6 1.7.1.4-.3.8-.7.9-1.3 0-.1.1-.3.2-.4.1-.4.3-.6.6-.7.3-.1.6-.1.9 0 .3.1.6.3.9.6.2.3.3.7.2 1 0 .3-.2.5-.4.8-.3.3-.7.5-1.2.5-1-.1-1.4.5-1.9 1.1-.3.3-.2.9.2 1.1.8.5 1.6.9 2.5 1.3.2.1.4 0 .6 0 .4-.1.8-.1 1.1.1.2.1.4.2.5.4l.3.6c.1.4.1.8-.1 1.1-.2.3-.5.6-.8.8-.2.1-.5.1-.7.1-.3 0-.6-.2-.8-.3-.2-.2-.4-.4-.5-.7-.1-.2-.3-.4-.5-.5-.5-.3-1.1-.6-1.6-.8-.3-.2-.7-.2-1 .1-.3.3-.3.6-.2 1 .3.6.6 1.3.9 1.9.1.1.3.2.4.3.7.2 1.1.7 1.1 1.3 0 .2 0 .5-.1.7-.1.2-.3.4-.5.6-.4.3-.9.4-1.4.2-.3-.1-.5-.3-.7-.5-.3-.4-.3-1-.1-1.4 0-.1.1-.2.1-.3-.4-.8-.7-1.6-1.2-2.3-.3-.4-.8-.4-1.1.1-.3.5-.5.9-.8 1.4-.1.1 0 .4 0 .5.2.6 0 1.2-.4 1.6-.5.4-1.2.5-1.7.2-.3-.2-.6-.5-.7-.8-.1-.4-.1-.8.1-1.2.2-.3.5-.6.9-.7.2-.1.4-.2.5-.4.3-.4.6-.9.8-1.4.2-.5-.1-1.1-.6-1.3-.6-.3-1.2-.2-1.5.3-.4.5-.9.8-1.5.7-.2 0-.5-.1-.7-.3-.3-.2-.6-.6-.6-.9-.1-.4 0-.8.3-1.1.2-.3.5-.5.9-.6.4-.1.9 0 1.2.3.4.3 1.6.2 2.2-.2.5-.3.6-.8.4-1.5-.6-2.1-2-2.4-3-4.3-.2-1.3-.6-3.5-.6-3.5C5.2 1.3 3.8 0 1.7 0v13.9c0 2.7 1.1 5.3 3 7.1 1.9 1.9 4.6 3 7.3 3s5.3-1.1 7.3-3c1.9-1.8 3-4.4 3-7.1z"
    />
  </svg>
);


/**
 * @description Vivre svg icon logo. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const VivreIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path d="M4.1 6.8c.4 1.3.9 2.6 1.4 3.9.5 1.1 1 2.3 1.6 3.4.5.9 1 1.8 1.5 2.6.3.6.7 1.2 1.1 1.7.5.7.9 1.4 1.8 1.6.9.3 1.6-.1 2.2-.7.6-.6 1-1.4 1.5-2 .5-.6.8-1.3 1.2-2 .6-1 1.1-2 1.6-3.1.6-1.3 1.2-2.6 1.6-4 .3-1.1.7-2.1.7-3.3 0-.2 0-.4-.1-.7-.1-.3-.3-.5-.6-.4-1.1 0-2 .4-2.9 1-.9.7-1.6 1.5-2.3 2.4-.2.3-.3.3-.5 0L13 5.7c-.2-.3 0-.5.1-.7.8-1 1.7-1.8 2.8-2.5.7-.4 1.4-.7 2.2-.9.9-.2 1.8-.4 2.7 0 .9.4 1.4 1.1 1.7 2 .3 1 .1 2 0 3-.2 1-.5 2-.8 3-.5 1.6-1.2 3-1.9 4.5-.6 1.2-1.2 2.3-1.8 3.4-.5.8-1 1.6-1.6 2.4-.3.5-.7.9-1.1 1.3-.6.6-1.4.9-2.2 1.1-.8.2-1.6.1-2.4-.2-1-.1-1.7-.6-2.2-1.2-1.6-2-2.8-4.3-3.9-6.6-.9-1.8-1.6-3.6-2.2-5.4-.6-1.6-.9-3.2-.8-4.9 0-1.1 1.3-2.4 2.4-2.4.2 0 1.2.1 1.7.2.6.2 1.3.4 1.9.6 1.5.7 2.7 1.7 3.7 3 .6.7 1.1 1.5 1.5 2.3.4.7.6 1.4.9 2.2.3.8.4 1.6.6 2.3.1.6.1 1.2.2 1.7.2 1.8-1.4 3.1-3 2.8-.9-.2-1.5-.6-1.9-1.4-.3-.5-.2-1.1-.2-1.7.1-.9.2-1.7.4-2.5 0 0 .1-.5.3-.5.2 0 .2.2.3.4.3 1 .6 2 1.1 3 .1.3.3.5.6.4.3-.1.2-.4.2-.7-.3-1.8-.9-3.5-1.8-5.1-.7-1.3-1.6-2.5-2.9-3.4-.8-.6-1.8-1.2-2.9-1.3-.2 0-.5-.1-.7.2-.2.3-.3.7-.2 1 .1.6.1 1.1.3 1.7z" />
  </svg>
);


/**
 * @description HipStar svg icon logo. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const HipStarIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="currentColor" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.733 11.636c.853.655 1.386 1.419 1.706 2.51.427 1.09.64 2.4.534 3.49v8.619c0 .545-.213.872-.534 1.2-.32.327-.64.436-1.066.436-.533 0-.853-.11-1.173-.436-.32-.328-.427-.655-.427-1.2v-8.619c0-.654-.107-1.309-.32-1.963-.213-.655-.533-1.091-1.067-1.527-.426-.328-1.173-.546-2.026-.546-.747 0-1.6.218-2.24.655a5.044 5.044 0 0 0-1.493 1.527 4.154 4.154 0 0 0-.534 1.963v8.619c0 .545-.213.872-.533 1.2A1.51 1.51 0 0 1 8.493 28c-.32 0-.746-.11-1.066-.327-.32-.327-.427-.655-.427-1.2V5.745c0-.545.107-.981.427-1.2.32-.327.64-.545 1.066-.545.534 0 .854.218 1.174.545.32.328.533.655.533 1.2V13.6l.213-.218c.32-.437.747-.982 1.28-1.418.427-.328 1.067-.655 1.813-.873.534-.327 1.28-.436 1.92-.436 1.387 0 2.56.327 3.307.981Zm3.518-.014c.226.252.521.378.886.378.278 0 .521-.072.73-.216.208-.144.356-.36.442-.649l.678-1.649.704 1.65c.086.251.208.45.364.594.174.144.383.234.626.27.434 0 .764-.126.99-.378.243-.253.365-.55.365-.892 0-.397-.183-.739-.547-1.027l-1.303-1.027 1.641-.108c.348-.036.626-.172.834-.406.226-.234.339-.513.339-.838 0-.378-.13-.684-.39-.919-.261-.234-.54-.351-.835-.351-.26 0-.495.081-.703.243l-1.355.946.417-1.594a.825.825 0 0 0 .026-.19.619.619 0 0 0 .026-.189c0-.36-.113-.657-.34-.892A1.104 1.104 0 0 0 24.988 4c-.347 0-.634.126-.86.378-.226.235-.339.532-.339.892 0 .072.009.135.026.19.018.054.035.117.052.189l.391 1.567-1.303-.892a1.762 1.762 0 0 0-.364-.189 1.082 1.082 0 0 0-.391-.08c-.296 0-.574.116-.834.35-.243.217-.365.523-.365.92 0 .324.113.603.339.837.226.234.504.37.834.406l1.615.108-1.303 1.08a.985.985 0 0 0-.417.433c-.087.162-.13.342-.13.54 0 .325.104.622.313.893Z"
    />
  </svg>
);


/**
 * @description Neuro svg icon logo. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const NeuroIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path d="M12 .3C5.4.3 0 5.7 0 12.3v11.4h.1c3 0 5.5-2.5 5.5-5.5v-5.9c0-3.5 2.9-6.4 6.4-6.4s6.4 2.9 6.4 6.4v11.4h.1c3 0 5.5-2.5 5.5-5.5v-5.9c0-6.6-5.4-12-12-12z" />
  </svg>
);
