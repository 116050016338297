import { MessageNavbarInterface } from '@type/header-types';
import { Anchor, Avatar, SanitizeHTML } from '@components/common';
import { memo } from 'react';

/**
 * @description Render a single message in the navbar dropdown.
 * * **message** The message data
 * * **closePopover** The function to close the dropdown popover
 */
export const MessageInNavbar = (props: MessageNavbarInterface) => {
  // Component props
  const { message, closePopover } = props;

  // helper variables
  const routeParams = { id: message.conversationId };
  const wrapperClasses = 'empty:hidden flex border-b p-4 bg-surface-50 hover:bg-surface transition-colors duration-300 ease-in-out place-items-start';


  // Render component
  // we also passed a function to close the mainMenu Messages Popover on click
  // ******************************************
  return (
    <Anchor
      href="app_message_inbox"
      symfonyRoute
      symfonyParams={routeParams}
      styling="none"
      className={wrapperClasses}
      key={message.conversationId}
      onClick={closePopover}
    >

      {/* avatar */}
      <div className="relative mr-3 flex aspect-square w-9 shrink-0">
        <Avatar
          size="sm"
          name={message?.conversationTitle}
          imgPath={message?.senderImgUrl}
          aspectRatio="square-uncertain"
          extractImageName
          className="text-sm"
        />
      </div>

      {/* message body */}
      <span className="grow flex-col">
        <span className="font-bold">
          {message?.conversationTitle}
        </span>
        <SanitizeHTML
          htmlContent={message?.body}
          as="span"
          className="line-clamp-3 grow break-words text-md leading-snug"
        />
      </span>

    </Anchor>
  );
};


// Memoization of the component. As long as the props don't change, the component will not be re-rendered.
// *************************************************************************************************
export const MemoizedMessageInNavbar = memo(MessageInNavbar);
