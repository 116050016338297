import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserStoreState } from '@type/redux';
import { ApiHeaderInfoOk, ApiLastWorkExperienceOk } from '@type/v1-api-types';


// Define the initial state
// *******************************************************************
const initialState: UserStoreState = {
  authDate: undefined,
  headerInfo: undefined,
  lastWorkExperience: undefined,
};


// Define reducers and actions
// *******************************************************************
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    // AUTH DATE - from rm_pbl cookie
    // **********************
    // save the auth date
    saveAuthDate: (state, action: PayloadAction<string>) => {
      state.authDate = action.payload;
    },
    // reset the auth date
    clearAuthDate: (state) => {
      state.authDate = initialState.authDate;
    },

    // HEADER INFO - /v1/header-info
    // **********************
    // save the header info
    saveHeaderInfo: (state, action: PayloadAction<ApiHeaderInfoOk>) => {
      state.headerInfo = action.payload;
    },
    // reset the header info
    clearHeaderInfo: (state) => {
      state.headerInfo = initialState.headerInfo;
    },


    // LAST WORK EXPERIENCE - /v1/resume/last-work-experience
    // **********************
    // save the last work experience
    saveLastWorkExperience: (state, action: PayloadAction<ApiLastWorkExperienceOk>) => {
      state.lastWorkExperience = action.payload;
    },
    // reset the last work experience
    clearLastWorkExperience: (state) => {
      state.lastWorkExperience = initialState.lastWorkExperience;
    },

    // reset the user state
    resetUserState: () => initialState,
  },
});


// Export the reducer, either as a default or named export
// *******************************************************************
export default userSlice.reducer;
