// SALARY CURRENCIES **************************************************************************************
// pathname: /v1/static-lists/salary-currencies
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';
import { ApiGetSalaryCurrenciesOk } from '@utils/data-fetching';


// SWR: fetch static salary currencies
// usage: const { data, error, ... } = useSwrStaticSalaryCurrencies(locale);
// ********************************************
export const useSwrStaticSalaryCurrencies = (locale = 'ro') => {
  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiGetSalaryCurrenciesOk>({
    url: '/v1/static-lists/salary-currencies',
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 60 * 24, // 24 hours, the data is not supposed to change
    },
  });

  // Return data
  return {
    data: data !== undefined ? data : ['RON'],
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
