import { RadioGroup } from '@headlessui/react';
import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { CardPayment } from './CardPayment';


// Interface
interface PaymentTypeProps {
  paymentMethods?: ApiSchema<'PaymentTypeModel'>[],
  creditsAmount?: number,
  className?: string,
  getSelectedMethod?: Dispatch<SetStateAction<ApiSchema<'PaymentTypeModel'> | undefined>>,
  getSelectedCard?: Dispatch<SetStateAction<number>>,
  creditCards?: ApiSchema<'CreditCardRead'>[] | undefined,
  showAvailableCredits?: boolean,
  completedProfile?: boolean,
  priceDescription?: string,
}


/**
 * @description Renders the list of payment types.
 * * **paymentTypes** array of objects: is the list of payment types.
 * * **creditsAmount** is the amount of credits available.
 * * **className** extra classes you might want to pass on the list.
 * * **getSelectedMethod** is the callback to get the selected payment method. It's a state setter.
 */
export const CardPaymentList = (props: PaymentTypeProps) => {
  // Props destructuring
  const {
    paymentMethods,
    creditsAmount = 0,
    className = '',
    getSelectedMethod,
    creditCards,
    getSelectedCard,
    showAvailableCredits = true,
    completedProfile = false,
    priceDescription,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // State helper for the selected payment method; default to the first one of array
  const [selectedMethod, setSelectedMethod] = useState(paymentMethods?.[0]);

  // Handle change of selected payment method
  const handleChange = (method: ApiSchema<'PaymentTypeModel'>) => {
    setSelectedMethod(method);
    if (getSelectedMethod) {
      getSelectedMethod(method);
    }
  };

  // Render the list of payment types
  // *****************************************
  return (
    <>
      {showAvailableCredits && (
      <div className="flex p-5">
        <InformationCircleIcon className="my-auto mr-3 size-7 text-ink-light" />
        <div>
          <p className="mt-1.5 text-sm text-ink-medium">
            <Trans
              i18nKey="payment.prepay-credits.amount"
              values={{ amount: creditsAmount }}
              components={{ tag: <strong className="text-lg text-pink" /> }}
            />
          </p>
          <p className="mt-1.5 text-sm text-ink-medium">
            {priceDescription || `${t('premium-subscription.wallet.info')} ${!completedProfile ? t('payment.wallet.disabled') : ''}`}
          </p>
        </div>
      </div>
      )}
      <RadioGroup value={selectedMethod} onChange={handleChange} className={`grid gap-4 md:grid-cols-2 ${className}`}>
        {
            paymentMethods && paymentMethods.map((paymentMethod) => (
              <RadioGroup.Option
                as={CardPayment}
                value={paymentMethod}
                key={paymentMethod.id}
                paymentMethod={paymentMethod}
                userSavedCreditCards={creditCards}
                getSelectedPaymentCard={getSelectedCard}
                disabled={!paymentMethod?.available}
              />
            ))
          }
      </RadioGroup>

    </>
  );
};
