import { getWithFlatKeys } from '@utils/flatten-expand-object/flatten-expand-object';
import { CheckIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon as CheckCircleSolid } from '@heroicons/react/24/solid';


// Interfaces
// *****************************************************
// Icon names interface; add more icons here
export type ListIcon = 'check'
| 'check-circle'
| 'check-circle-solid';

// List size interface; add more sizes here
export type ListSize = 'none' | 'sm' | 'md' | 'base' | 'lg' | 'xl';

// Size classes interface
interface ListSizeClasses {
  [size: string]: {
    icon: string,
    text: string,
  }
}


// List of icons to render
// *****************************************************
export const iconList = {
  check: CheckIcon,
  'check-circle': CheckCircleIcon,
  'check-circle-solid': CheckCircleSolid,
};


// Size classes
// *****************************************************
const sizeClasses: ListSizeClasses = {
  none: {
    icon: '',
    text: '',
  },
  sm: {
    icon: 'w-4 h-4',
    text: 'text-sm',
  },
  md: {
    icon: 'w-4.5 h-4.5',
    text: 'text-md',
  },
  base: {
    icon: 'w-5 h-5',
    text: '',
  },
  lg: {
    icon: 'w-6 h-6',
    text: 'text-lg',
  },
  xl: {
    icon: 'w-7 h-7',
    text: 'text-xl',
  },
};


// Helper function to compose the classes for the list elements
// *********************************************************************
export const elementClasses = (elem: 'icon' | 'text', size: string, classIcon?: string, classText?: string): string => {
  // Get the size classes
  const { icon, text } = sizeClasses[size];

  // Return the classes
  return elem === 'icon' ? `shrink-0 ${icon} ${classIcon || ''}` : `${text} ${classText || ''}`;
};


// Helper function to get the value from an object key. The key can be a flat key (e.g. 'key1.key2.key3')
// or a simple key (e.g. 'key1')
// *********************************************************************
export const getFlatKeyValue = (obj: object, key: string): string => {
  const valueFromKey = getWithFlatKeys(obj, key);

  // check if the value exists and if it's a string; if so, return it
  if (valueFromKey && typeof valueFromKey === 'string') {
    return valueFromKey;
  }

  // default return
  return '';
};
