// IMPORTS ****************************************************************************************
// ************************************************************************************************
import {
  ApiJobListCards,
  ApiJobDetail,
  ApiJobDetailOk,
  ApiJobUserDetail,
  ApiJobUserDetailOk,
  ApiPrevNextJob,
  ApiPrevNextJobOk,
} from 'src/types/v1-api-types';


// Job-list cards   *******************************************************************************
// pathname: /v1/jobs
// ************************************************************************************************
export const isJobListCardsOk = (res: ApiJobListCards | void)
: res is ApiSchema<'JobResult'> => res !== undefined && res !== null && (res as ApiSchema<'JobResult'>)?.items !== undefined;


// Job detail   ***********************************************************************************
// pathname: /v1/job-operation/{jobSlug}/detail
// ************************************************************************************************
export const isJobDetailOk = (res: ApiJobDetail | void)
: res is ApiJobDetailOk => res !== undefined && res !== null && (res as ApiJobDetailOk).jobId > 0;

// Job user detail   ***********************************************************************************
// pathname: /v1/job-operation/{jobSlug}/user-detail
// ************************************************************************************************
export const isJobUserDetailOk = (res: ApiJobUserDetail | void)
: res is ApiJobUserDetailOk => res !== undefined && res !== null && (res as ApiJobUserDetailOk).applied !== undefined;


// Previous / Next jobs for navigation ************************************************************
// pathname: /v1/job-operation/prev-next-job
// ************************************************************************************************
export const isPrevNextJobOk = (res: ApiPrevNextJob | void)
: res is ApiPrevNextJobOk => res !== undefined && res !== null && (!!(res as ApiPrevNextJobOk)?.prevJob || !!(res as ApiPrevNextJobOk)?.nextJob);
