import pino from 'pino';
import { ErrorInfo } from 'react';

/**
 * @description Logger instance for both client and server side.
 * ** Note: Client side uses browser property to send the request using method `navigator.sendBeacon` **
 * ** Note: Server side don't require any configuration **
 *
 * @example Client side
 * useEffect(() => {
 *  logger.info('client side hello');
 * }, []);
 *
 * @example
 * Server side
 * import { logger } from '@utils/logger/logger';
 * getServerSideProps() {
 * ....
 * logger.info('server side hello');
 * ...
 * }
 */
export const logger = pino({
  name: 'bj-react',
  level: 'info',
  // show severity field as text instead of number
  formatters: {
    level: (label) => ({ severity: label.toUpperCase() }),
  },
  // show timestamp in ISO format
  timestamp: pino.stdTimeFunctions.isoTime,
  // client side logger
  browser: {
    asObject: true,
    transmit: {
      level: 'info',
      send: (level, logEvent) => {
        // max beacon payload size is 2^16, but we need to leave some space for headers and level field
        const msg = (logEvent.messages[0] as string).slice(0, 65536 - 100);

        // ignore error messages from CSP from chrome extensions
        if (
          level === 'error'
          && msg.toLowerCase().includes('is not an allowed source of script in the following content security policy directive')
          && msg.toLowerCase().includes('chrome-extension://')) {
          return;
        }

        // send log to server
        const headers = {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          type: 'application/json',
        };
        const blob = new Blob([JSON.stringify({ msg, level })], headers);
        // use navigator.sendBeacon for sending data because is better for sending analytics data than xhr/fetch
        navigator.sendBeacon('/api/log-client-side', blob);
      },
    },
  },
});

/**
 * @description Logs event detected by the document (client-side).
 * @param errorEvent
 */
export const logErrorEvent = (errorEvent: ErrorEvent) => {
  // default error message
  let errorMessage = errorEvent.message;

  const error = errorEvent?.error as { message: string; stack: string; };
  // logg error message if available
  if (error?.message !== undefined) {
    errorMessage = `${error.message}. `;
  }
  // logg stack if available
  if (error?.stack !== undefined) {
    errorMessage = error.stack;
  }

  if (typeof window !== 'undefined') {
    const { userAgent } = window.navigator;
    logger.error(`[WindowError] in page ${window.location.href}\n User agent: ${userAgent}\n ${errorMessage}`);
  }
};

/**
 * @description Logs error info detected by the ErrorBoundary (client-side).
 * @param error
 * @param errorInfo
 */
export const logErrorBoundary = (error:Error, errorInfo:ErrorInfo) => {
  let message = error?.stack ?? '';
  if (errorInfo?.componentStack?.length) {
    message = `${error.name}: ${error.message} ${errorInfo.componentStack}`;
  }

  const { userAgent } = window.navigator;
  logger.error(`[ErrorBoundary] in page: ${window.location.href}\n User agent: ${userAgent}\n ${message}`);
};
