import { composeButtonClasses } from '@components/common/Anchor/anchorButtonStyles';
import { ButtonProps, ButtonRef } from 'src/types/anchors-buttons';
import { TailwindSpinner } from 'src/components/common/Elements/Spinner';
import { forwardRef } from 'react';

/**
 * @description Styled anchor (<a>) component. **To be used for external links.** In addition to
 * the normal properties of the HTML Anchor, for styling you need to pass the following props:
 *
 * * **styling** - the styling of the button: text, outline, solid, none; default: solid
 * * **color** - the color of the button; default: primary
 * * **size** - the size of the button: xs, sm, md, lg; default: md
 * * **rounding** - the rounding of the button: none, normal, full; default: normal
 * * **isLoading** - boolean: show/hide loading state (spinner, disabled, cursor-not-allowed)
 * * **spinnerClass** - string: additional classes for the spinner
 * * **className** - string: additional classes for the button
 * * **children** - string: the text of the button
 * * **type** - string: the type of the button: button, submit; default: button
 * * **...restProps** - any other props that you would pass to a normal HTML button
 *
 * -----------------
 * @description If styling is **none**, no classes will be pre-applied, the component will have no
 * styling applied to it.
 *
 * -----------------
 *
 * @param props
 *
 * @example
 * <Button type="button" styling="outline" color="secondary" size="sm" rounding="full" isLoading={isPosting}>
 *   Button text
 * </Button>
 */
export const Button = forwardRef<ButtonRef, ButtonProps>((props, ref) => {
  const {
    type = 'button',
    color = 'primary',
    styling = 'solid',
    size = 'md',
    rounding = 'normal',
    className = '',
    children = 'Button text',
    isLoading = false,
    spinnerClass,
    ...restProps
  } = props;

  const finalClasses = styling === 'none' ? className : composeButtonClasses(color, size, styling, rounding, className);

  return (
    <button type={type === 'button' ? 'button' : 'submit'} ref={ref} className={finalClasses} disabled={isLoading} {...restProps}>
      { isLoading && <TailwindSpinner className={spinnerClass} /> }
      { children }
    </button>
  );
});
