import { gtag } from 'ga-gtag';

export default function sendGoogleTagEvent(identifier: string, type:string, payload?: { [key: string]: unknown }) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  gtag('event', identifier, {
    type: `${type}`,
    platfom: 'react',
    ...payload,
  });
}
