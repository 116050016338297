import { NotEnoughCredits } from '@components/features/payment';
import { useAppDispatch } from '@store/index';
import { clearOrderData } from '@store/reducers/employer/subscription/subscriptionReducer';


// Interface
// *****************************************
interface SubscriptionNotEnoughCreditsProps {
  shoppingCartId?: number,
}


export const StepNotEnoughCredits = (props: SubscriptionNotEnoughCreditsProps) => {
  // Props destructuring
  const { shoppingCartId } = props;

  // Dispatch
  const appDispatch = useAppDispatch();

  // Clear order data, so we can go back to payment options step
  const clearOrderDataAction = () => appDispatch(clearOrderData());


  // Render component
  // *****************************************
  return (
    <NotEnoughCredits
      shoppingCartId={shoppingCartId}
      actionBack={clearOrderDataAction}
    />
  );
};
