import { SubscriptionOrderStatus } from '@type/redux';
import { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { Button } from '@components/common';
import { BjThemeColors } from '@type/bj_theme';
import { HeroIconType } from '@type/icons';


// INTERFACES
// *************************************************************************************************
interface StatusPaymentProps {
  orderStatus?: SubscriptionOrderStatus,
  customTitle?: string,
  customMessage?: string,
  customButtonLabel?: string,
  successCustomMessage?: ReactNode,
  withAction?: boolean,
  isActivated?: boolean,
  actionSuccess?: () => void,
  actionError?: () => void,
  className?: string,
}

// Status types
interface StatusTypes {
  [type: string]: {
    icon: HeroIconType,
    title: string,
    message: string,
    buttonLabel: string,
    textColor: string,
    buttonColor: BjThemeColors,
    iconBg: string,
  }
}


// STATUS TYPES OBJECT
// *************************************************************************************************
const statusTypes: StatusTypes = {
  success: {
    icon: CheckCircleIcon,
    title: 'payment.success.title',
    message: 'payment.success.message',
    buttonLabel: 'payment.button.success',
    textColor: 'text-secondary',
    buttonColor: 'secondary',
    iconBg: 'from-surface from-0% via-secondary/30 via-60% to-secondary/30 ring-8 ring-secondary/10',
  },
  error: {
    icon: XCircleIcon,
    title: 'payment.error.title',
    message: 'payment.error.message',
    buttonLabel: 'payment.button.error',
    textColor: 'text-error',
    buttonColor: 'error',
    iconBg: 'from-surface from-0% via-error/25 via-60% to-error/25 ring-8 ring-error/10',
  },
};


/**
 * @description Renders the status of an automatic payment.
 * * **orderStatus** is the status of the payment. Default status: 'success'.
 * * **withAction** is a boolean to show or hide the action button. Default is true.
 * * **customTitle** is a custom title to show on the card. If not passed, the default title will be shown.
 * * **customMessage** is a custom message to show on the card. If not passed, the default message will be shown.
 * * **customButtonLabel** is a custom label for the action button. If not passed, the default label will be shown.
 * * **successCustomMessage** is a custom message to show on success. If not passed, the default
 * message will be shown. Can be a function - t('translation.key') - or a Trans component.
 * * **actionSuccess** is the callback to execute on success button click.
 * * **actionError** is the callback to execute on error button click.
 * * **className** extra classes you might want to pass on the card.
 *
 * @example
 * // Standard model
 * <StatusPayment
 *  orderStatus={orderStatus}
 *  successCustomMessage={successMessage}
 *  withAction={false}
 *  actionSuccess={actionSuccess}
 *  actionError={actionError}
 *  className="mt-10"
 * />
 *
 * // example of successCustomMessage with Trans component
 * const SuccessMessage = (packageName: string) => (
 *   <Trans
 *     i18nKey="payment.success.upgrade-package"
 *     values={{ service: packageName }}
 *     components={{ tag: <strong className="whitespace-nowrap" /> }}
 *   />
 * );
 *
 * // Then to pass it to the StatusPayment component
 * const message = SuccessMessage('Premium 500');
 * return (<StatusPayment successCustomMessage={message} />);
 *
 * // example of successCustomMessage with t() function
 * const message = t('payment.success.upgrade-package', { service: 'Premium 500' });
 * return (<StatusPayment successCustomMessage={message} />);
 */
export const StatusPayment = (props: StatusPaymentProps) => {
  // Props destructuring
  const {
    orderStatus = 'success',
    withAction = true,
    isActivated,
    customTitle,
    customMessage,
    customButtonLabel,
    successCustomMessage,
    actionSuccess,
    actionError,
    className = '',
  } = props;

  // Translation
  const { t } = useTranslation('common');


  // 2. Valid status; convenience variables;
  // *****************************************
  const statusType = orderStatus === 'success' ? statusTypes.success : statusTypes.error;

  const IconName: HeroIconType = statusType.icon;
  const statusAction = orderStatus === 'success' ? actionSuccess : actionError;

  // Succes might have a custom message, so we check if it's passed, otherwise we use the default
  const statusMessage = orderStatus === 'success' && successCustomMessage
    ? successCustomMessage
    : t(statusType.message);


  // Render status
  // *****************************************
  return (
    <div className={`mx-auto max-w-lg px-6 py-8 text-center lg:py-14 ${className}`}>
      {/* Status icon */}
      <IconName className={`mx-auto size-20 rounded-full bg-radial-gradient ${statusType.iconBg} ${statusType.textColor}`} />

      {/* Status title */}
      {!isActivated && (
      <h2 className="mb-1 mt-6 text-xl font-bold">
        {t(customTitle ?? statusType.title)}
      </h2>
      )}


      {/* Status message */}
      <p className={`${isActivated ? 'mt-6' : ''}`}>{customMessage ? t(customMessage) : statusMessage}</p>

      {/* Status button */}
      { withAction && (
        <Button
          size="sm"
          color={statusType.buttonColor}
          className="mt-7 !px-10 first-letter:uppercase"
          rounding="full"
          onClick={statusAction}
        >
          {t(customButtonLabel ?? statusType.buttonLabel)}
        </Button>
      )}
    </div>
  );
};
