import { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Message } from '@components/templates/bia/hooks/useStreamState';
import '@components/templates/bia/chat/markdown.css';

interface ChatMessageBubbleProps {
  message: Message;
}

const formatContent = (content: string): string[] => {
  let formattedContent = content.replace(/<br>/g, '\n\n');
  formattedContent = formattedContent.replace(/(\n)###/g, '\n\n###');
  return [formattedContent];
};

export const ChatMessageBubble = forwardRef<
HTMLDivElement,
ChatMessageBubbleProps
>(({ message: { type, content } }, ref) => {
  const isUserMessage = type === 'human';
  const formattedContent = !isUserMessage ? formatContent(content) : [content];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isUserMessage ? 'flex-end' : 'flex-start',
        paddingBottom: '1.25rem',
        gap: '1.25rem',
      }}
      ref={ref}
    >
      <div
        className={`px-4 py-2 text-sm ${isUserMessage ? 'bg-ink text-surface' : 'markdown bg-surface-100'}`}
        style={{
          borderRadius: '1rem',
          borderTopRightRadius: isUserMessage ? '0.125rem' : '1rem',
          borderTopLeftRadius: isUserMessage ? '1rem' : '0.125rem',
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
          {formattedContent.join('')}
        </ReactMarkdown>
      </div>
    </div>
  );
});
ChatMessageBubble.displayName = 'ChatMessageBubbleAI';
