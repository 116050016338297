import { ButtonsBaseProps } from '@type/anchors-buttons';
import { Button } from 'src/components/common/Forms/Button/Button';
import { Dispatch, SetStateAction } from 'react';


type ButtonsSwitchProps = Pick<ButtonsBaseProps, 'color' | 'size' | 'rounding' | 'className' | 'isLoading'> & {
  isLeftButtonActive?: boolean,
  labels?: string[],
  callback?: Dispatch<SetStateAction<boolean>> | ((arg: boolean) => void),
};


/**
 * @description Renders a switch with two buttons. Use it to switch between month and year.
 * * **isLeftButtonActive** is the state of the switch. If true, the left button is active.
 * * **color** is the color of the buttons.
 * * **size** is the size of the buttons.
 * * **rounding** is the rounding of the buttons.
 * * **className** extra classes you might want to pass on the buttons.
 * * **labels** are the labels for the buttons.
 * * **callback** is the function to call when the user clicks on a button. It should be the state setter
 * function for the isLeftButtonActive state.
 *
 * @example
 * <div className="inline-flex items-center bg-surface rounded-full p-1 border">
 *  <MonthYearSwitch
 *    isLeftButtonActive
 *    color="pink"
 *    size="sm"
 *    rounding="full"
 *    className="!py-1.5 px-4.5 border border-transparent"
 *    labels={['Month', 'Year']}
 *  />
 * </div>
 */
export const ButtonsSwitch = (props: ButtonsSwitchProps) => {
  // Props destructuring
  const {
    isLeftButtonActive = true,
    color = 'pink',
    size = 'sm',
    rounding = 'full',
    className = '',
    labels = ['off', 'on'],
    isLoading,
    callback,
  } = props;


  // Handle click
  const handleClick = (arg: boolean) => {
    if (callback) {
      callback(arg);
    }
  };


  // Render component
  // *****************************************
  return (
    <>
      <Button
        rounding={rounding}
        size={size}
        styling={isLeftButtonActive ? 'solid' : 'text'}
        color={color}
        className={className}
        onClick={() => handleClick(true)}
        isLoading={isLeftButtonActive ? isLoading : undefined}
      >
        {labels[0]}
      </Button>
      <Button
        rounding={rounding}
        size={size}
        styling={!isLeftButtonActive ? 'solid' : 'text'}
        color={color}
        className={className}
        onClick={() => handleClick(false)}
        isLoading={!isLeftButtonActive ? isLoading : undefined}
      >
        {labels[1]}
      </Button>
    </>
  );
};
