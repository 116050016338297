import { Fragment, useState } from 'react';
import {
  Spinner, Anchor, BadgeNotifications, Avatar, Button,
} from '@components/common';
import { UserAccountLinksInterface } from '@type/header-types';
import { useBurgerMenuData } from '@components/layout/Header/MainNavbar/data/useBurgerMenuData';
import { ChevronDownIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useAppSelector } from 'src/app';
import { useTranslation } from 'next-i18next';


/**
 * @description Renders the links in the burger menu
 * @param props
 */
export const BurgerMenuLinks = (props: UserAccountLinksInterface) => {
  // Component props with defaults
  const {
    className = '',
    closePopoverDesktop = () => {},
  } = props;


  // Get the burger menu data with SWR
  // *****************************************
  const { burgerMenuData, burgerMenuLoading } = useBurgerMenuData();


  // Get the headerInfo data from redux
  // *****************************************
  const headerInfo = useAppSelector((state) => state.user.headerInfo);
  const unpaidOrders = headerInfo?.hasUnpaidOrdersAccount;


  // Base anchor classes. Add the color classes to mark special links
  // *****************************************
  const baseClasses = 'flex space-between gap-4 items-center py-2.5 px-3.5 text-base my-1 lg:gap-3 lg:py-1.5 lg:transition-all lg:duration-200 lg:ease-out';
  const defaultColor = 'hover:text-primary hover:bg-surface';

  const totalProfileItems = burgerMenuData?.filter((menu) => menu.type !== undefined).length;
  const [profileItemsLimit, setProfileItemsLimit] = useState(5);

  const { t } = useTranslation('common');

  // Render component
  // *****************************************
  return (
    <div className={`flex flex-col ${className}`}>
      { burgerMenuLoading && <div className="flex items-center justify-center py-10"><Spinner /></div> }

      { // Render links. Use Anchor because there's a mix with symfony links
        (!burgerMenuLoading && burgerMenuData) && (
          <>
            {burgerMenuData.filter((menu) => menu.type !== undefined).slice(0, profileItemsLimit).map((item) => (
              <Fragment key={item.id}>
                {item && item?.id && (
                <Anchor
                  styling="none"
                  href={item.link}
                  onClick={closePopoverDesktop}
                  className={`${baseClasses} ${defaultColor} tr-${item.tracking} flex justify-between`}
                >
                  <span className="truncate">
                    {item.label}
                  </span>
                  {item.icon && (
                    <Avatar
                      size="xs"
                      imgPath={item.icon}
                      imgFolder={item.type === 'employer_profile' ? 'employer_logo' : 'resume_image'}
                      aspectRatio="square-uncertain"
                      extractImageName
                      withRing
                    />
                  )}
                </Anchor>
                )}
              </Fragment>
            ))}
            {Number(totalProfileItems) > profileItemsLimit && (
              <Button
                styling="none"
                onClick={() => setProfileItemsLimit(burgerMenuData.filter((menu) => menu.type !== undefined).length)}
                className={`${baseClasses} ${defaultColor} flex justify-between`}
              >
                {t('global.label.see_all')}
                <ChevronDownIcon
                  className="w-7 rounded-full bg-white"
                />
              </Button>
            )}
            <hr className="my-4" />
            {burgerMenuData.filter((menu) => menu.type === undefined).map((item) => {
            // Check for unpaid orders
              const hasUnpaidOrders = unpaidOrders && item.id === 'cmp-wallet';
              const hiddenDesktopItems = ['messages'];

              return (
                <Fragment key={item.id}>
                  {item && item?.id && (
                    <Anchor
                      styling="none"
                      href={item.link}
                      onClick={closePopoverDesktop}
                      className={`${baseClasses} ${defaultColor}${hasUnpaidOrders ? ' bg-danger/10' : ''} ${hiddenDesktopItems.includes(item.id) ? ' md:hidden' : ''} tr-${item.tracking}`}
                    >
                      {item.label}
                      {item.id === 'messages' && (
                      <BadgeNotifications count={headerInfo?.nrUnseenMessages || 0} className="md:hidden" />
                      )}
                      {['cmp-wallet', 'neuro-wallet'].includes(item.id) && (
                      <BadgeNotifications count={headerInfo?.walletCredits || 0} className="md:hidden" />
                      )}
                      { // Add an exclamation icon to the wallet link if unpaid orders exist
                                                      hasUnpaidOrders && <ExclamationCircleIcon className="ml-auto size-4.5 text-danger" />
                                                    }
                    </Anchor>
                  )}
                </Fragment>
              );
            })}
          </>
        )
}
    </div>
  );
};
