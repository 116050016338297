import { forwardRef } from 'react';
import { useSingleFormElement } from '@hooks/useBjForm';
import { FormElementRefImperative, FormTextareaInterface } from 'src/types/form-types';
import { FormError } from '../FormError/FormError';


/**
 * @description Textarea component for useBjForm. Can be used without the hook though.
 *
 * @description Element's Props:
 * * **className** - pass here your Tailwind classes if you need further customization
 * * **withError** - if you want to show this field's validation error messages; default true
 * * **onBlur** - the onBlur event function
 * * **onChange** - the onChange event function
 * * **getValidityStates** - the getValidityStates function callback. Use a state setter function to get the validity states. Returns an object with isInvalid and errorMSG properties.
 * * **hasCustomError** - a boolean to trigger a custom error message; default undefined
 * * other normal HTML select attributes: required, defaultValue, minLength, maxLength, etc.
 *
 * @remarks When used with **useBjForm** custom hook and the **record** function do not pass a **name** or **id** attribute; these should be handled inside the **record** function
 *
 * @example
 * // without using the 'record' function
 * <Textarea className="mt-2" defaultValue="Description taken from server..." name="description" />
 *
 * // using the 'record function'
 * <Textarea minLength={3} required {...record('description')} />
 *
 * // How to use the getValidityStates callback
 * // validityStates will be an object with isInvalid and errorMSG properties
 * // use it to display the error message in other parts of the form
 * const [validityStates, setValidityStates] = useState({ isInvalid: false, errorMSG: '' });
 * <Textarea required getValidityStates={setValidityStates} withError={false} />
 *
 * // Pass a custom message for empty fields
 * <Textarea required customValidityMessages={{ valueMissing: 'This field is required' }} />
 *
 * // ******************************************************
 * // How to trigger a custom error type. Keep in mind that you can always pass customValidityMessages to the input for
 * // specific error messages like 'valueMissing', 'tooLong', etc.
 * const [hasCustomError, setHasCustomError] = useState<boolean | undefined>(undefined);
 *
 * // Handle change function. You need to set the custom validity.
 * const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
 *  const textarea = e.target as HTMLTextAreaElement;
 *  if (someCondition) {
 *    textarea.setCustomValidity('customError');
 *    setHasCustomError(true);
 *  } else {
 *    textarea.setCustomValidity('');
 *    setHasCustomError(false);
 *  }
 * };
 * <Textarea
 *  required
 *  hasCustomError={hasCustomError}
 *  customValidityMessages={{ customError: 'Some error message' }}
 *  onChange={handleChange}
 * />
 */
export const Textarea = forwardRef<FormElementRefImperative, FormTextareaInterface>((props, ref) => {
  // component props
  const {
    children,
    className = '',
    required,
    withError = true,
    placeholder = ' ',
    patternMismatchMessage = '',
    customValidityMessages,
    onBlur,
    onChange,
    getValidityStates,
    hasCustomError,
    ...attributes
  } = props;

  // form elements shared logic hook
  const {
    handleInteraction, elementRef, isInvalid, errorMSG, errorClass,
  } = useSingleFormElement({
    ref,
    patternMismatchMessage,
    customValidityMessages,
    className,
    getValidityStates,
    hasCustomError,
  });

  // Rendered component **********************************
  return (
    <>
      <textarea
        // there's no need to check optional fields interactions, we do it only for required fields
        // allow adding extra functions for onChange & onBlur
        onChange={(e) => { if (required) handleInteraction(e); if (onChange) onChange(e); }}
        onBlur={(e) => { if (required) handleInteraction(e); if (onBlur) onBlur(e); }}
        className={`${className}${isInvalid ? errorClass : ''}`}
        ref={elementRef}
        placeholder={placeholder}
        required={required}
        {...attributes}
      >
        {children}
      </textarea>
      { // textarea error field - show only if input is required and withError is true (default true)
        required && withError && <FormError errorMessage={errorMSG} isShowing={isInvalid} />
      }
    </>
  );
});
