// Conversations list *****************************************************************************
// pathname: /v1/conversations
// ************************************************************************************************
import { useSwrInfiniteApi } from '@hooks/useSwrApi';


// Interface
// ********************************************
interface ConversationsListSwrProps {
  type?: string;
  shouldFetch?: boolean;
  locale?: string;
  serverSideData?: ApiSchema<'ConversationRead'>[];
  startIndex?: number;
  limit?: number;
  restUrlParams?: string;
}


/**
 * @description SWR: fetch conversations list with infinite pagination.
 *
 * * **shouldFetch**: boolean. A flag indicating whether to start fetching conversations list.
 * * **locale**: string. What locale to use for the request.
 * * **serverSideData**: ApiSchema<'ConversationRead'>[], optional. The data we got from the server side request (if we do it). Default undefined.
 * * **startIndex**: number, optional. The first page number to fetch (ie: fetch starting with page 5). Default 1.
 * * **limit**: number, optional. The number of conversations to get per call. Modifies the *limit* url parameter (&limit=24). Default 24.
 * * **restUrlParams**: '&type=talent', optional. Default empty string.
 */
export const useSwrConversationsList = (props: ConversationsListSwrProps) => {
  // Props destructuring
  // ********************************************
  const {
    type = 'all',
    shouldFetch = true,
    locale,
    serverSideData,
    startIndex = 0,
    limit = 10,
    restUrlParams = '',
  } = props;


  // Get data from API
  // ********************************************
  const {
    data,
    error,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    mutateSiblings,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  } = useSwrInfiniteApi<ApiSchema<'ConversationRead'>[]>({
    url: '/v1/conversations',
    shouldFetch,
    locale,
    urlParams: `${type ? `&type=${type}` : ''}${restUrlParams}`,
    serverSideData,
    infiniteConfig: {
      paginationType: 'offset',
      limit,
      startIndex,
    },
    apiOptions: {
      keepPreviousData: true,
      revalidateOnFocus: false,
      dedupingInterval: 2000, // 2 seconds
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    mutateSiblings,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  };
};
