// Imports
// *****************************************************
import { Fragment } from 'react';
import { useTranslation } from 'next-i18next';
import { MainMenuInterface } from '@type/header-types';
import useWindowDimensions from '@hooks/useMediaQuery';
import { Anchor, Dropdown, LinkStyled } from '@components/common';
import { cn } from '@utils/cn';
import { i18nConfig } from 'i18n-config';
import { clsx } from 'clsx';
import { useDropdownHover } from '@hooks/common/useDropdownHover';
import { isHireRoute } from '@lib/functions/routes/isHireRoute';
import { mainMenuLinks } from './links-objects';
import { MainNavbarLink } from './MainNavbarLink';

/**
 * @description Main menu links component. We use the 'role' to determine which links to show and
 * also to check if the user is authenticated (role === undefined means the user is not authenticated).
 * * **role** - user role ('NONE', 'USER', 'COMPANY') - look at mainMenuLinks object for more info
 * * **currentRoute** - current route pathname - needed to highlight the current route in the menu for React pages
 * * **closePopover** - function to close the dropdown popover
 * * **locale** - the current app locale
 * * **canAbTest** - boolean value to determine if we can do A/B testing
 */
export const MainMenuLinks = (props: MainMenuInterface) => {
  // Destructuring props
  const {
    currentRoute = '',
    role,
    canAbTest = false,
  } = props;

  // Translate
  const { t } = useTranslation('common');

  // filter the main menu links based on the user login state
  let menusToShow = mainMenuLinks;
  if (role === undefined) {
    menusToShow = mainMenuLinks.filter((link) => link.onlyLoggedIn === false || link.onlyLoggedIn === undefined);
  }
  const { isMobile } = useWindowDimensions();

  const isActiveRoute = (linkRoute: string) => {
    if (!currentRoute) {
      return false;
    }

    // Extract the first segment of the path
    const pathSegments = currentRoute.split('/').filter(Boolean); // filters out empty strings

    // Check if the first segment is a supported locale
    const currentLocale = i18nConfig.locales.includes(pathSegments[0] as Locale) ? pathSegments[0] as Locale : null;

    // Get the base path by removing the current locale if it exists
    const basePath = currentLocale ? currentRoute.replace(`/${currentLocale}`, '') : currentRoute;

    return basePath.startsWith(linkRoute);
  };

  const { dropdownRef, buttonRef, closeFuncRef } = useDropdownHover({ enabled: !isMobile });

  // Render component
  // - Filter the main menu links based on the user role ('NONE', 'USER', 'COMPANY')
  // *****************************************************
  return (
    <>
      { // Main menu links
        menusToShow
          .filter((item) => (isHireRoute(currentRoute) ? item.forSection?.includes('HIRE') : item.forSection?.includes('TALENT')))
          .map((link) => {
            // destructuring link object
            const {
              type,
              label,
              route: linkRoute,
              onlyMobile,
              icon,
              children,
              dataTestId,
              subTitle,
            } = link;

            // If there is selected to show the links only on mobile, return with nothing. We are using isMobile hook.
            if (!isMobile && onlyMobile) { return (<Fragment key={label} />); }

            if (children) {
              return (
                <Dropdown key={label} ref={dropdownRef}>
                  {({ open, close }) => {
                    closeFuncRef.current = close;

                    return (
                      <>
                        <Dropdown.Button
                          as={MainNavbarLink}
                          type={type}
                          route={linkRoute}
                          label={label}
                          subTitle={subTitle}
                          icon={icon}
                          className={cn('relative h-full', open && 'bg-surface-50')}
                          ref={buttonRef}
                          {...(dataTestId ? { 'data-test-id': dataTestId } : {})}
                        />
                        <Dropdown.Items
                          animation="slideUp"
                          position="left"
                          className="fixed bottom-13 left-0 flex w-screen min-w-40 items-start gap-1 bg-surface-50 shadow-none md:absolute md:bottom-auto md:top-full md:w-auto md:flex-col md:pb-2 md:pt-4 md:shadow-md"
                        >
                          {children.map((item) => {
                            if (!isMobile && item.onlyMobile) { return (<Fragment key={label} />); }

                            const isAbTest = canAbTest && item.route === 'app_job_content_add';
                            const Component = item.type === 'react' || isAbTest ? LinkStyled : Anchor;
                            const isSymfony = isAbTest ? false : item.type === 'symfony';
                            const extraProps = isSymfony ? { symfonyRoute: true } : {};
                            const abTestJobAddLink = isAbTest ? '/job-add/edit' : item.route;

                            return (
                              <Dropdown.Item
                                key={item.label}
                                as={Component}
                                href={abTestJobAddLink}
                                styling="none"
                                className={clsx(
                                  'w-full text-nowrap px-4 py-3 text-center text-xs outline-none transition hover:bg-surface sm:text-sm lg:py-2 lg:text-left',
                                  !isSymfony && isActiveRoute(abTestJobAddLink) && 'text-secondary',
                                )}
                                {...extraProps}
                                {...(dataTestId ? { 'data-test-id': dataTestId } : {})}
                              >
                                {t(item.label)}
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Items>
                      </>
                    );
                  }}
                </Dropdown>
              );
            }

            return (
              <MainNavbarLink
                key={label}
                type={type}
                route={linkRoute}
                label={label}
                icon={icon}
                {...(dataTestId ? { 'data-test-id': dataTestId } : {})}
              />
            );
          })
      }

    </>
  );
};
