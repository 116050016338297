'use client';

import emptyImage from '@images/svg/no-results-image.svg';
import { useTranslation } from 'next-i18next';
import { ImageBj } from '@components/common/ImageBj/ImageBj';


interface NoResultsProps {
  isEmptyTitle?: string,
  isEmptyText?: string,
}


/**
 * @description Renders a no results message with an image.
 * - **isEmptyTitle** - The title to show when there are no results.
 * - **isEmptyText** - The text to show when there are no results.
 * @constructor
 */
export const NoResults = (props: NoResultsProps) => {
  const { isEmptyTitle, isEmptyText } = props;
  const { t } = useTranslation('common');

  return (
    <div className="mx-auto my-10 max-w-md text-center md:my-12">
      <ImageBj
        src={emptyImage as string}
        priority
        alt="No results"
        className="mx-auto mb-6 size-44"
      />
      { // Render the title if it exists
        isEmptyTitle && (
          <h2 className="text-xl font-bold">{t(isEmptyTitle)}</h2>
        )
      }
      { // Render the text if it exists
        isEmptyText && (
          <p>{t(isEmptyText)}</p>
        )
      }
    </div>
  );
};
