import { Fragment, ReactNode } from 'react';
import { Tab as HeadlessTab, Transition } from '@headlessui/react';
import { composeTabPanelAnimation } from './tabStyles';


// Interfaces
// Important: Tab.Panel types are a tuple of any, we need to some Generic magic to
// extract className separately
// *******************************************************************************************
type IRootTabPanel = {
  children: ReactNode,
  className?: string,
  unmount?: boolean,
  animation?: 'none' | 'fade' | 'slideUp' | 'slideLeft',
};


// Customized Tab.Panel component
// *******************************************************************************************
export const TabStyledRootPanel = (props: IRootTabPanel) => {
  const {
    children,
    className = '',
    animation = 'fade',
    unmount = true,
    ...rest
  } = props;

  // component animation
  const componentAnimation = composeTabPanelAnimation(animation);


  // Render component
  // **************************
  return (
    <HeadlessTab.Panel as={Fragment} unmount={false} {...rest}>
      {({ selected }) => (
        /* Use the internal `selected` state to conditionally animate the selected panel. */
        <Transition
          className={className}
          show={selected}
          unmount={unmount}
          {// conditional animation
            ...animation !== 'none' ? {
              enter: 'transition ease-out duration-500',
              enterFrom: componentAnimation.hiddenState,
              enterTo: componentAnimation.visibleState,
              leave: 'transition-none duration-0',
              leaveFrom: componentAnimation.visibleState,
              leaveTo: componentAnimation.hiddenState,
            } : {}
          }
        >
          { children }
        </Transition>
      )}
    </HeadlessTab.Panel>
  );
};
