import { getWithFlatKeys } from '../flatten-expand-object/flatten-expand-object';


// Item of array that we filter
export type ArrayFilterItem = string | number | object | [];

// Interface for filtering array for strings
export interface FilterStrings {
  (inputValue?: string | undefined, type?: 'includes' | 'startsWith', objectPaths?: string[]): (item: ArrayFilterItem) => boolean,
}


/**
 * @description Check if a string includes a compare value.
 * @param value
 * @param compareWith
 */
export const stringIncludes = (value: string, compareWith: string) => value.toLowerCase().includes(compareWith);


/**
 * @description Check if a string starts with a compare value.
 * @param value
 * @param compareWith
 */
export const stringStartsWith = (value: string, compareWith: string) => value.toLowerCase().startsWith(compareWith);


/**
 * @description Helper function for **array.filter()** method that will return anything that matches
 * the **inputValue** string. Can be used on arrays of strings and objects. Most importantly, **this
 * can be used for filtering deeply nested objects** too.
 *
 * @param inputValue string (required) - the compare value string
 * @param type - enum (optional): 'includes' | 'startsWith' (optional) - string methods for compare
 * @param objectPaths string[] (optional) - value at flattened path of object
 *
 * @example
 * const stringArray = ['Black', 'Red', 'Green', 'Blue', 'Orange', 'Purple'];
 * const filteredStringArray = stringArray.filter(filterStrings('b', 'startsWith'));
 * // => ['Black', 'Blue']
 *
 * const objectArray = [
 *  { name: 'Gigi', likes: { fruit: 'banana'}},
 *  { name: 'Ioana', likes: { fruit: 'apple'}},
 *  { name: 'Maria', likes: { fruit: 'cherry'}},
 * ];
 *
 * const filterObjectArray = objectArray.filter(filterStrings('ana', 'includes', ['name', 'likes.fruit']));
 * // first object has a match in 'likes.fruit: banana', second object in 'name: Ioana'.
 * // => [
 * //   { name: 'Gigi', likes: { fruit: 'banana'}},
 * //   { name: 'Ioana', likes: { fruit: 'apple'}},
 * // ]
 *
 * // For ComboBox
 * // 1. if you want to filter by string 'includes', just pass the function directly to options prop:
 * options={{
 *    filteringFunction: filterComboboxString,
 * }}
 *
 * // 2. if you want to filter by string 'startsWith', you need to compose a new function that you will
 * // pass to the ComboBox options as shown in example 1.
 * const filterStringStartsWith = (inputValue: string | undefined) => filterComboboxString(inputValue, 'startsWith');
 *
 */
export const filterStrings: FilterStrings = (inputValue, type, objectPaths) => {
  const compareValue = inputValue ? inputValue.toLowerCase() : '';
  return (item) => {
    // If we're filtering a string array
    if (typeof item === 'string') {
      return (type === 'startsWith' ? stringStartsWith(item, compareValue) : stringIncludes(item, compareValue));
    }

    // If we're filtering an object array
    if (typeof item === 'object' && item !== null && Array.isArray(objectPaths)) {
      // check each path and return a boolean array
      const checkEachPathResult = objectPaths.map((path) => {
        const valueFromKey = getWithFlatKeys(item, path);
        if (typeof valueFromKey === 'string') {
          return (type === 'startsWith' ? stringStartsWith(valueFromKey, compareValue) : stringIncludes(valueFromKey, compareValue));
        }
        return false;
      });

      return !inputValue || checkEachPathResult.includes(true);
    }

    return false;
  };
};
