import { ApiLiveIntervalOk } from '@type/v1-api-types';

/**
 * @description Check if the current date is inside the live interval
 * @param liveInterval
 * @param currentDateTime
 */
export const isDateInsideLiveInterval = (liveInterval: ApiLiveIntervalOk | null | undefined, currentDateTime: Date) => {
  if (liveInterval?.startAt === undefined || liveInterval?.endAt === undefined) {
    return false;
  }
  // live interval dates can be in the past due to caching
  const startDate = new Date();
  startDate.setHours(parseInt(liveInterval.startAt.substring(11, 13), 10));
  startDate.setMinutes(parseInt(liveInterval.startAt.substring(14, 16), 10), 0);

  const endDate = new Date();
  endDate.setHours(parseInt(liveInterval.endAt.substring(11, 13), 10));
  endDate.setMinutes(parseInt(liveInterval.endAt.substring(14, 16), 10), 0);

  return currentDateTime >= startDate && currentDateTime <= endDate;
};
