'use client';

// Package details for documentation
// https://www.npmjs.com/package/ga-gtag

import { useEffect } from 'react';
import { install } from 'ga-gtag';
import Script from 'next/script';

export const GoogleTagManager = () => {
  const googleTagManagerId = process.env.NEXT_PUBLIC_GTM_ID ?? '';

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    install(googleTagManagerId);
  }, [googleTagManagerId]);

  return (
    <>
      <Script
        id="Google Tag Manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: ` (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleTagManagerId}');`,
        }}
      />
    </>
  );
};
