import { RadioTextProps } from 'src/types/form-types';

/**
 * @description Simple component for styling the text for radio inputs and checkboxes
 * @description Prop: **className** - if you need to add more classes to Label
 * @param props
 * @example
 * // text for a checkbox
 * <RadioText>Un label pt checkbox</RadioText>
 */
export const RadioText = (props: RadioTextProps) => {
  const { className, children } = props;

  return (
    <span className={`ml-2 leading-tight${className ? ` ${className}` : ''}`}>{ children }</span>
  );
};
