import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer';
import employerReducer from './reducers/employerReducer';
import userOwnResumeReducer from './reducers/userOwnResumeReducer';
import socketOnlineReducer from './reducers/socketOnlineReducer';
import socketLiveInterviewReducer from './reducers/socketLiveInterviewReducer';
import jobReducer from './reducers/jobReducer';


// Create the store
// *******************************************************************
export const store = configureStore({
  reducer: {
    user: userReducer,
    employer: employerReducer,
    userOwnResume: userOwnResumeReducer,
    socketOnline: socketOnlineReducer,
    socketLiveInterview: socketLiveInterviewReducer,
    job: jobReducer,
  },
});


// Infer the `RootState` and `AppDispatch` types from the store itself
// *******************************************************************
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
