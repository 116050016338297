// Types
interface ChevronIcon {
  className?: string,
}


/**
 * @description Chevron up ↑ svg icon.
 * Optional **className** parameter: current classes can be overwritten.
 * @param props
 */
export const ChevronUp = (props: ChevronIcon) => {
  const { className = 'w-4.5 h-4.5 pointer-events-none' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className={className}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
    </svg>
  );
};


/**
 * @description Chevron down ↓ svg icon.
 * Optional **className** parameter: current classes can be overwritten.
 * @param props
 */
export const ChevronDown = (props: ChevronIcon) => {
  const { className = 'w-4.5 h-4.5 pointer-events-none' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className={className}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
};


/**
 * @description Clear circle 'x' svg icon.
 * Optional **className** parameter: current classes can be overwritten.
 * @param props
 */
export const ClearCircle = (props: ChevronIcon) => {
  const { className = 'w-4.5 h-4.5 pointer-events-none' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
};
