import { forwardRef } from 'react';
import { CardElementProps, CardRef } from '@type/card';


// Card Footer
// ***************************************************************
export const CardActions = forwardRef<CardRef, CardElementProps>((props, ref) => {
  // destructure props
  const {
    className = '',
    children = 'Card actions',
    ...restProps
  } = props;

  return (
    <div ref={ref} className={className} {...restProps}>
      { children }
    </div>
  );
});
