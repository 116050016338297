import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { fetcherSWR } from 'src/utils/data-fetching/fetcher';
import { GenericV1ErrorsArray } from 'src/types/v1-api-types/_global';
import { SwrApiInfiniteGlobalProps, ExtendedSWRInfiniteResponse } from '@type/swr';
import { useCallback, useMemo, useRef } from 'react';
import { isApiV1Errors } from 'src/type-predicates/v1-api-predicates/_global';
import { infiniteHelpers, getArrayFromObjectKey } from './middlewares/infiniteHelpers';
import { withToasts } from './middlewares/withToasts';
import { serverDataInfinite } from './middlewares/serverDataInfinite';
import { mutateEndpointSiblings } from './middlewares/mutateEndpointSiblings';


/**
 * @description SWR: fetch data with infinite pagination. You can pass serverSideData to it. The returned data type is always wrapped in array.
 *
 * * **url**: string, required. Ex: '/v1/notifications'.
 * * **urlParams**: '&sort=relevant', optional. Extra urlParams on top of the pagination params (page or offset in paginationType).
 * * **shouldFetch**: boolean, optional. Fetch data based on condition, i.e. yourState.
 * * **locale**: string, optional, default 'ro'. It basically tells the hook what locale to use for the request.
 * * **serverSideData**: if you have server side data, use it as initial data; the data will be checked if it's an error or not;
 * if it's not an error, it will be used as 'fallbackData' and 'revalidateOnMount' will be set to false (you don't need to fetch
 * twice, once on the server and once on the client); if the data is an error, the hook will auto-fetch on component mount; optional
 * * **fetcherConfig**: optional, with default fallback.
 * * **apiOptions**: nice config options for SWR, i.e. { dedupingInterval: 10000 }. optional. You should check both the global SWR options and the swrInfinite special options.
 * * **toastsOptions**: nice config options for SWR toasts, i.e. { showSuccessToast: true }; optional
 * * **infiniteConfig**: nice config options for SWR infinite, i.e. { paginationType: 'offset' }; optional
 *
 * For full list of **apiOptions** check this [link]{@link https://swr.vercel.app/docs/api#options} and
 * this [link]{@link https://swr.vercel.app/docs/pagination#api}.
 * For full list of **fetcherConfig** check the fetcher function.
 *
 * Toasts options:
 * * **showSuccessToast**: show success toast on data change; optional
 * * **showErrorToast**: show error toast on data change; optional
 * * **successToastText**: custom success toast text; optional. If not provided, it will show a default toast (200 success).
 * * **errorToastText**: custom error toast text; optional. If not provided, it will show a default static toast (useStaticToasts).
 * * **genericErrorToast**: use generic error toast with the default text; optional
 *
 * Infinite config options:
 * * **paginationType**: 'page' | 'offset', optional, default 'page'. It basically tells the hook what type of pagination (url param) we use.
 * * **limit**: number, optional, default 10. It basically tells the hook what is the number of items per page (the limit from the API endpoint - &limit=10).
 * * **startIndex**: number, optional, default 0. It basically tells the hook what is the index of the first page to load, or the offset to start from.
 * * **responseArrayKey**: string, optional, default undefined. If the response is an object, we need to pass the flattened object path to get the array of items to display.
 * This is important for pagination to work properly and also for the isEmpty and showLoadMore states. Default undefined.
 * For instance, if we have an object like this: interface ApiTalentPageV3Ok {items?: ApiUserTalentItem[], pages?: number, page?: number} we need to pass 'items' as responseArrayKey.
 *
 * We extend the SWRInfiniteResponse with our custom properties from infiniteHelpers.ts middleware & mutateEndpointSiblings.ts middleware.
 * * **isEmpty**: boolean. The list is an empty array, there are no results.
 * * **showLoadMore**: boolean. If the list has fewer items than the page limit (**limit**), we reached the end (false).
 * Use it to show/hide the load more button.
 * * **sizeAsRef**: MutableRefObject<number>. We need a ref for situations in which we need to access the size value inside the useEffect (refs don't trigger re-renders).
 * Access the value like this: sizeAsRef.current. Default 1.
 * * **mutatePage**: Mutate a specific page in the infinite list based on a compare function. It compares the data inside the page.
 * * **mutateSiblings**: Mutate the sibling queries from the SWR cache. We define siblings as all cache keys that match the
 * current API URL but have different query parameters. Keep in mind that the revalidation of the siblings data
 * (network request) will happen only when that sibling is accessed again.
 *
 * @example
 * // Model for Offset Based Paginated API (offset=0, offset=10, etc.)
 * // ***************************************************************************************
 * // Imports
 * import {
 *   useSwrInfiniteApi, ApiNotificationList, isNotificationInfiniteOk, isApiV1Errors,
 * } from '@hooks/useSwrApi';
 *
 * // Build the endpoint hook (will be used in component or page)
 * export const useSwrNotificationsInfinite = (locale: string, serverSideData?: ApiNotificationList, urlParams = '') => {
 *   // Get data from API
 *   // ********************************************
 *   const {
 *     data,
 *     error,
 *     size,
 *     setSize,
 *     isValidating,
 *     isLoading,
 *     mutate,
 *     isEmpty,
 *     showLoadMore,
 *     sizeAsRef,
 *   } = useSwrInfiniteApi<ApiNotificationsOk[]>({
 *     url: '/v1/notifications',
 *     urlParams,
 *     locale,
 *     serverSideData,
 *     apiOptions: {
 *       revalidateOnFocus: false,
 *       dedupingInterval: 1000 * 60, // 1 minute
 *       // redirect to login page if we get a 401
 *       onError: (newError) => {
 *         if (newError?.errors[0].code === 401) {
 *           window.location.href = '/login';
 *         }
 *       },
 *     },
 *     toastsOptions: {
 *       showErrorToast: true,
 *     },
 *     infiniteConfig: {
 *       paginationType: 'offset',
 *     },
 *   });
 *
 *   return {
 *     data,
 *     error,
 *     size,
 *     setSize,
 *     isValidating,
 *     isLoading,
 *     mutate,
 *     isEmpty,
 *     showLoadMore,
 *     sizeAsRef,
 *   };
 * };
 *
 * // Newly created hook usage (in component or page)
 * const { data, ... } = useSwrNotificationsInfinite(locale);
 *
 *
 * // Model for Page Based Paginated API (?page=1, ?page=2, etc.)
 * // ***************************************************************************************
 * import {
 *   useSwrInfiniteApi,
 *   ApiTalentPageV3,
 *   ApiTalentPageV3Ok,
 * } from '@hooks/useSwrApi';
 *
 *
 * // Interface for TalentSearchV3SwrProps
 * interface TalentSearchV3SwrProps {
 *   // What locale to use for the request.
 *   locale?: string;
 *   // The data we got from the server side request (if we do it). Default undefined.
 *   serverSideData?: ApiTalentPageV3;
 *   // The first page number to fetch (ie: fetch starting with page 5). Default 1.
 *   startIndex?: number;
 *   // The number of CVs to get per call. Modifies the *limit* url parameter (&limit=24). Default 24.
 *   limit?: number;
 *   // '&sort=relevant', optional. Default empty string.
 *   urlParams?: string;
 * }
 *
 * // Build the endpoint hook (will be used in component or page)
 * export const useSwrTalentSearchV3 = (props: TalentSearchV3SwrProps) => {
 *   // Props destructuring
 *   const {
 *     locale,
 *     serverSideData,
 *     startIndex = 1,
 *     limit = 24,
 *     urlParams = '',
 *   } = props;
 *
 *
 *   // Get data from API
 *   const {
 *     data,
 *     error,
 *     size,
 *     setSize,
 *     isValidating,
 *     isLoading,
 *     mutate,
 *     isEmpty,
 *     showLoadMore,
 *     sizeAsRef,
 *   } = useSwrInfiniteApi<ApiTalentPageV3Ok>({
 *     url: '/v3/talent-search',
 *     locale,
 *     urlParams,
 *     serverSideData,
 *     infiniteConfig: {
 *       paginationType: 'page',
 *       limit,
 *       // the key where the array of items is located in the response
 *       responseArrayKey: 'items',
 *       startIndex,
 *     },
 *     apiOptions: {
 *       keepPreviousData: true,
 *       revalidateOnFocus: false,
 *       dedupingInterval: 1000 * 60, // 1 minute
 *     },
 *     toastsOptions: {
 *       showErrorToast: true,
 *     },
 *   });
 *
 *
 *   // Return data
 *   return {
 *     data,
 *     error,
 *     size,
 *     setSize,
 *     isValidating,
 *     isLoading,
 *     mutate,
 *     isEmpty,
 *     showLoadMore,
 *     sizeAsRef,
 *   };
 * };
 *
 * // GSSP for TalentSearchV2
 * // ***************************************************************************************
 * export const getServerSideProps: GetServerSideProps = async (context) => {
 *   // Destructure context
 *   const {
 *     req,
 *     query,
 *     locale = 'ro',
 *   } = context;
 *
 *   // Process the query for the infinite scrolling. Use the returned object to pass parameters
 *   // to useSwrTalentSearchV3 hook and filters.
 *   const infiniteQueryParams = paginatedServerSideQuery(query, 'pageNr', 24);
 *
 *   // Talents data from server. With this we will pre-populate the infinite scrolling when passing it
 *   // to the useSwrTalentSearchV3 hook.
 *   const talentListFromServer = await fetcher(`/v2/talent-search${infiniteQueryParams.fetchUrlParams}`, locale, {
 *     ssrClientHeaders: req.headers,
 *   });
 *
 *   // Return props
 *   // *********************************
 *   return {
 *     props: {
 *       ...(await serverSideTranslations(locale, ['common'])),
 *       locale,
 *       infiniteQueryParams,
 *       talentListFromServer,
 *     },
 *   };
 * };
 *
 * // Newly created hook usage (in component or page)
 * // ****************************************************************************************
 * // Destructure page props
 * const {
 *   locale,
 *   infiniteQueryParams,
 *   talentListFromServer,
 * } = props;
 *
 * // Destructure paginatedQuery
 * const {
 *   page,
 *   limit,
 *   objFilterParams,
 * } = infiniteQueryParams;
 *
 *
 * // Use the hook
 * // ****************************
 * const {
 *  data, size, mutate,
 * } = useSwrTalentSearchV3({
 *  locale,
 *  limit,
 *  serverSideData: talentListFromServer,
 *  startIndex: page,
 *  // Pass the query params to the hook; convert all arrays to comma separated strings as required by API
 *  urlParams: buildSearchParams(queryParams, '&', true),
 * });
 *
 *
 * // How to revalidate only a certain page
 * // ****************************
 * interface ApiTalentPageV3Ok {
 *   ...,
 *   page?: number;
 * }
 *
 * const mutateThree = () => {void mutate(undefined, {
 *   revalidate: (newData) => newData.page === 3;
 * });};
 *
 * // with mutatePage helper function
 * mutatePage((data) => data?.page === 3);
 *
 * // How to revalidate the last page
 * // ****************************
 * const mutateLast = () => {void mutate(undefined, {
 *   revalidate: (newData) => newData.page === size;
 * });};
 *
 * // with mutatePage helper function
 * mutatePage((data) => data?.page === size || data?.page === size - 1);
 *
 *
 * // How to use the mutateSiblings function
 * // ****************************
 * // mutate all siblings
 * mutateSiblings();
 *
 * // Usage with a compare function and a data compare function. It will mutate the siblings that match the compare function and
 * // the data compare function. Same rules for infinite lists apply. In the example below, it will mutate only the sibling with the
 * // page number 2 and the sort query parameter.
 * mutateSiblings({
 *   paramsCompareFn: (key) => key.includes('sort=true'), // => /v2/job-name?page=1&sort=true, /v2/job-name?page=2&sort=true
 *   dataCompareFn: (data) => data?.page === '2', => /v2/job-name?page=2&sort=true
 *   revalidate: true,
 * });
 *
 *
 * // How to use as a State Management Hook and replace Redux or any other state management library
 * // ***************************************************************************************
 * // Let's say we have a generic swr API hook
 * export const useSwrSomeEndpoint = (props) => {
 *   const { locale, revalidateIfStale = true } = props;
 *   const { ... } = useSwrInfiniteApi<SomeInterface>({
 *    ...
 *    apiOptions: {
 *      // magic happens here
 *      revalidateIfStale,
 *    },
 *    ...
 *   });
 *  ...
 * };
 *
 * // When we want to use it a state management hook, we only need to read the data from the Cache. The good thing about it is that
 * // if there's no data in the cache, it will fetch it from the API. If the data is in the cache, it will return it instantly.
 * // All you have to do is to set the 'revalidateIfStale' to false, and you have a state management hook.
 * const { data, error, ... } = useSwrSomeEndpoint({
 *    locale,
 *    revalidateIfStale: false, // this means: don't trigger a re-fetch if we already have the data in the cache
 * });
 */
export const useSwrInfiniteApi = <DataType>(props: SwrApiInfiniteGlobalProps<DataType>) => {
  // Props destructuring
  // apiOptions full list (union between global SWR options & infinite special options).
  // global: https://swr.vercel.app/docs/api#options
  // infinite: https://swr.vercel.app/docs/pagination#parameters
  // **********************************
  const {
    // url: '/v1/notifications', required
    url,
    // urlParams: '&sort=relevant', optional. Default empty string.
    urlParams = '',
    // shouldFetch: false, optional. Default true.
    shouldFetch = true,
    // locale: locale, kind of required, but we have default fallback. Default 'ro'.
    locale = 'ro',
    serverSideData, // serverSideData: initial hook data, optional
    // config for fetcher function, optional
    fetcherConfig,
    // apiOptions: { revalidateOnFocus: false }, optional. Default has a middleware in use, take care to not override it.
    apiOptions = {},
    toastsOptions = {}, // ie: toastsOptions: { showErrorToast: true }, optional
    infiniteConfig, // infiniteConfig: { paginationType: 'offset' }, optional
  } = props;


  // Destructure infiniteConfig
  // **********************************
  const {
    // paginationType: 'offset', optional. Default 'page'.
    paginationType = 'page',
    // limit: 24, optional; the number of items per page, or the limit. This is a prerequisite for the showLoadMore state. Default 10.
    limit = 10,
    // startIndex: 5, optional; the index of the first page to load, or the offset to start from. Default 0 / 1 for offset / page pagination.
    startIndex,
    // If the response is an object, we need to pass the flattened object path to get the array of items to display.
    // This is important for pagination to work properly and also for the isEmpty and showLoadMore states.  Default 'items'.
    responseArrayKey = 'items',
  } = infiniteConfig;


  // Watch the urlParams, we will need to ignore the pagination from the server side (startIndex) when this happens,
  // otherwise we might end up with a wrong page number in the URL and get a not found error.
  // **********************************
  const prevUrlParams = useRef<string>(urlParams);
  const urlParamsChanged = useRef(false);
  if (prevUrlParams.current !== urlParams) urlParamsChanged.current = true;


  // Helpers
  // **********************************
  const fetchType = paginationType === 'page' ? '?page=' : '?offset=';
  const fetchIndex = paginationType === 'page' ? (urlParamsChanged.current ? 1 : startIndex) || 1 : (urlParamsChanged.current ? 0 : startIndex) || 0;

  // Needed for comparison in the middleware for initialServerData, when we need to check if the key is the same as the one on mount.
  const keyOnMount = useMemo(
    () => `${url}${fetchType}${fetchIndex}&limit=${limit}${urlParams}`,
    [url, urlParams, fetchType, fetchIndex, limit],
  );


  // SWR Infinite Key
  // If `null` is returned, the request of that page won't start.
  // pageIndex - internal state, starts at 0
  // **********************************
  const getKey: SWRInfiniteKeyLoader = useCallback((pageIndex, previousPageData) => {
    // Check the length of the array of items from the previous page
    const arrayLength = Array.isArray(previousPageData)
      ? previousPageData?.length
      : getArrayFromObjectKey(previousPageData as object, responseArrayKey)?.length;

    // Conditionally fetch || Reached the end
    if (!shouldFetch || (previousPageData && !arrayLength)) return null;

    // Build the items per page
    // 1. Because the setter 'setSize' sets the number of pages that need to be fetched, for offset navigation we cannot
    // do something like 'setSize(size + 10)' because it will do 10 requests using increments of 1. For this reason we need
    // will multiply the pageIndex with the limit to get the proper offset.
    // 2. Page pagination starts at 1, so with 'setSize(size + 1)' we get the page number: 0 + 1 = 1, 1 + 1 = 2, 2 + 1 = 3, etc.
    // With startIndex = 5, we get the page number: 5 + 1 = 6, 6 + 1 = 7, 7 + 1 = 8, etc.
    // 3. Offset pagination starts at 0, so with 'setSize(size + 1)' and limit = 10, we get the offset index (pageIndex * limit): 0 * 10 = 0, 1 * 10 = 10, 2 * 10 = 20 etc.
    // With startIndex = 5 and the above values, we get the offset index: 0 * 10 + 5 = 5, 1 * 10 + 5 = 15, 2 * 10 + 5 = 25, etc.
    const pageNr = paginationType === 'page' ? pageIndex + fetchIndex : pageIndex * limit + fetchIndex;

    // Build the url
    const limitParam = `&limit=${limit}`;
    const fetchUrl = `${url}${fetchType}${pageNr}${limitParam}${urlParams}`;

    // Arguments for fetcher
    return [fetchUrl, locale || 'ro', fetcherConfig || {}];
  }, [
    fetchIndex,
    fetchType,
    limit,
    locale,
    paginationType,
    shouldFetch,
    url,
    urlParams,
    responseArrayKey,
    fetcherConfig,
  ]);


  // Server side data
  // **********************************
  const initialData = useMemo(() => (
    !isApiV1Errors(serverSideData) ? serverSideData as DataType : undefined
  ), [serverSideData]);


  // Add default SWR API options. You can override them in your custom endpoint hook (ie: if you pass a 'use' there,
  // it will override the one declared here).
  // **********************************
  const defaultMiddlewares = [
    serverDataInfinite(keyOnMount),
    withToasts(toastsOptions),
    infiniteHelpers(limit, responseArrayKey),
    mutateEndpointSiblings(),
  ];

  // 1. If we have server side data, use it as fallback data. This hook returns an array of results, so we need to wrap
  // the server side data in an array.
  // 2. If we have server side data, and it's the last page don't revalidate on mount because we already have the data for the first render
  // 3. Disable revalidate first page, it will always re-fetch the first page alongside the current page, so we don't need it.
  // 4. Disable SWR retry on error, you don't want to retry on 401, 403, or 404 and not worth it to do it for 500...
  // 5. Add default middlewares
  // 6. Add custom apiOptions from custom endpoint hook
  const apiOptionsWithMiddleware = {
    ...(initialData ? { fallbackData: [initialData] } : null),
    revalidateFirstPage: false,
    shouldRetryOnError: false,
    use: defaultMiddlewares,
    ...apiOptions,
  };


  // SWR Infinite Hook
  // We extend the SWRInfiniteResponse with our custom properties from infiniteHelpers.ts middleware (isEmpty, showLoadMore)
  // **********************************
  const {
    data,
    error,
    size,
    setSize,
    mutate,
    mutatePage,
    mutateSiblings,
    isValidating,
    isLoading,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  } = useSWRInfinite<DataType, GenericV1ErrorsArray>(getKey, fetcherSWR, apiOptionsWithMiddleware) as
    ExtendedSWRInfiniteResponse<DataType, GenericV1ErrorsArray>;


  // Return the hook
  // *****************************************
  return {
    data,
    error,
    // In SWR Infinite isLoading it's bugged, and it's always true even if it's just revalidating a page. So, we use it
    // for the first page, and for the rest we check if we already have that page in the cache.
    isLoading: (!data && isLoading) || (size > 0 && data && typeof data[size - 1] === 'undefined'),
    isValidating,
    mutate,
    mutatePage,
    mutateSiblings,
    size,
    setSize,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  };
};
