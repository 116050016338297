// the source id is used to make sure that the overflow classes are only removed when the source is the same as the one that added them.
type DocumentBodyOverflowSourceId = 'mobile-menu' | 'cv-edit';

/**
 * @description Get the overflow source id from the body.
 */
const getDocumentBodyOverflowSourceId = () => document.body.getAttribute('data-overflow-id');

/**
 * @description Set the overflow classes on the body based on the source id.
 * @param sourceId
 */
export const setDocumentBodyOverflow = (sourceId: DocumentBodyOverflowSourceId) => {
  const initialSource = getDocumentBodyOverflowSourceId();
  if (!initialSource) {
    document.body.setAttribute('data-overflow-id', sourceId);
    document.body.classList.add('overflow-hidden', 'lg:overflow-auto');
  }
};

/**
 * @description Remove the overflow classes from the body.
 * @param sourceId
 */
export const removeDocumentBodyOverflow = (sourceId: DocumentBodyOverflowSourceId) => {
  const initialSource = getDocumentBodyOverflowSourceId();
  if (!initialSource || initialSource === sourceId) {
    document.body.removeAttribute('data-overflow-id');
    document.body.classList.remove('overflow-hidden', 'lg:overflow-auto');
  }
};
