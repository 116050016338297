import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import { Anchor } from 'src/components/common/Anchor/Anchor';
import { Button } from 'src/components/common/Forms/Button/Button';
import { LinkStyled } from '@components/common/Anchor/LinkStyled';
import toast, { toast as bjToast } from 'react-hot-toast/headless';
import { route } from '@services/symfony';
import { useRouter } from 'next/navigation';

// Icons
import {
  VideoCameraIcon, CheckCircleIcon, ServerStackIcon, LockClosedIcon, NoSymbolIcon,
  BookmarkSlashIcon, ClockIcon, ExclamationTriangleIcon, Square3Stack3DIcon, ExclamationCircleIcon, MinusCircleIcon,
} from '@heroicons/react/24/outline';
import { MedalSolidIcon } from '@components/common/Icons';


// Types
// ******************************************************
// helper type needed to pass an object as function argument
type ObjectAlias = object;

// interface for static toasts
export interface StaticToasts {
  (message?: string): void,
}

// interface for optional messages from showStatusToasts function
export interface OptionalToastMessages extends ObjectAlias {
  message_200?: string,
  message_201?: string,
  message_204?: string,
  message_400?: string;
  message_401?: string,
  message_403?: string,
  message_404?: string,
  message_408?: string,
  message_500?: string,
  message_601?: string,
  message_602?: string,
  message_603?: string,
  message_606?: string,
}

// interface for the showStatusToasts function
export interface ShowStatusToasts {
  (status: string | number, messages?: OptionalToastMessages, ignoreStatus?: string[]): void,
}

// the bi-dimensional array used inside showStatusToasts function
export interface StaticToastsArray {
  [0]: string,
  [1]: StaticToasts,
  [2]: string | undefined,
}

/**
 * @description Hook to expose all static custom toasts and messages.
 */
export const useStaticToasts = () => {
  const { t } = useTranslation('common');
  const router = useRouter();

  // Live Online: message, toast
  const LiveOnlineMessage = (message: string, cta: string, url?: string) => {
    const onStart = () => {
      toast.dismiss();
      void router.push(url || '/live');
    };

    const onClose = () => {
      toast.dismiss();
    };

    return (
      <>
        <span className="block text-md leading-snug">
          {message}
        </span>
        <div className="mt-5 flex gap-4">
          <Button onClick={onClose} styling="text" size="sm" color="ink" className="py-1">
            { t('global.btn.close')}
          </Button>
          <Button onClick={onStart} styling="solid" size="sm" color="primary-light" className="py-1">
            { cta }
          </Button>
        </div>
      </>
    );
  };


  const liveOnlineMessageToast = (message: string, cta: string, link?: string) => {
    bjToast(LiveOnlineMessage(message, cta, link), {
      icon: <VideoCameraIcon className="size-5 text-primary-light" />,
      className: 'border-l-3 border-primary-light',
      duration: Infinity,
    });
  };

  // 200 - OK: message, toast
  // ******************************************************
  const Status200Message = useCallback((message = t('global.status.200-ok.message')) => (
    <>
      <strong className="block">{ t('global.status.200-ok.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const status200Toast: StaticToasts = useCallback((message) => {
    bjToast(Status200Message(message), {
      icon: <CheckCircleIcon className="size-6 text-secondary" />,
      className: 'border-l-3 border-secondary',
    });
  }, [Status200Message]);


  // 201 - Created: message, toast
  // ******************************************************
  const Status201Message = useCallback((message = t('global.status.201-created.message')) => (
    <>
      <strong className="block">{ t('global.status.201-created.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const status201Toast: StaticToasts = useCallback((message) => {
    bjToast(Status201Message(message), {
      icon: <Square3Stack3DIcon className="size-6 text-secondary" />,
      className: 'border-l-3 border-secondary',
    });
  }, [Status201Message]);


  // 204 - No content: message, toast
  // ******************************************************
  const Status204Message = useCallback((message = t('global.status.204-no-content.message')) => (
    <>
      <strong className="block">{ t('global.status.204-no-content.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const status204Toast: StaticToasts = useCallback((message) => {
    bjToast(Status204Message(message), {
      icon: <ExclamationTriangleIcon className="size-6 text-secondary" />,
      className: 'border-l-3 border-secondary',
    });
  }, [Status204Message]);


  // Generic error: message, toast
  // ******************************************************
  const StatusGenericErrorMessage = useCallback((message = t('global.general.error.try-again')) => (
    <>
      <strong className="block">{ t('global.general.error.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const statusGenericErrorToast: StaticToasts = useCallback((message) => {
    bjToast(StatusGenericErrorMessage(message), {
      icon: <ExclamationCircleIcon className="size-6 text-error" />,
      className: 'border-l-3 border-error',
    });
  }, [StatusGenericErrorMessage]);


  // 400 - Bad Request: message, toast
  // ******************************************************
  const Status400Message = useCallback((message = t('global.status.400-bad-request.text')) => (
    <>
      <strong className="block">{ t('global.status.400-bad-request.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const status400Toast: StaticToasts = useCallback((message) => {
    bjToast(Status400Message(message), {
      icon: <MinusCircleIcon className="size-6 text-error" />,
      className: 'border-l-3 border-error',
    });
  }, [Status400Message]);


  // 401: Authentication error: message, toast
  // ******************************************************
  const Status401Message = useCallback((message = t('global.status.401-unauthorized.message')) => (
    <>
      <strong className="block">{ t('global.status.401-unauthorized.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
      <Anchor href={route('login', null, true)} styling="solid" size="xs" color="error" className="mt-3 py-1">
        { t('menu.sign_in-register') }
      </Anchor>
    </>
  ), [t]);

  const status401Toast: StaticToasts = useCallback((message) => {
    bjToast(Status401Message(message), {
      icon: <LockClosedIcon className="size-5.5 text-error" />,
      className: 'border-l-3 border-error',
    });
  }, [Status401Message]);


  // 403 - Forbidden: message, toast
  // ******************************************************
  const Status403Message = useCallback((message = t('global.status.403-forbidden.message')) => (
    <>
      <strong className="block">{ t('global.status.403-forbidden.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const status403Toast: StaticToasts = useCallback((message) => {
    bjToast(Status403Message(message), {
      icon: <NoSymbolIcon className="size-6 text-error" />,
      className: 'border-l-3 border-error',
    });
  }, [Status403Message]);


  // 404 - Not found: message, toast
  // ******************************************************
  const Status404Message = useCallback((message = t('global.status.404-not-found.message')) => (
    <>
      <strong className="block">{ t('global.status.404-not-found.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const status404Toast: StaticToasts = useCallback((message) => {
    bjToast(Status404Message(message), {
      icon: <BookmarkSlashIcon className="size-5.5 text-error" />,
      className: 'border-l-3 border-error',
    });
  }, [Status404Message]);


  // 408 - Timeout: message, toast
  // ******************************************************
  const Status408Message = useCallback((message = t('global.status.408-timeout.message')) => (
    <>
      <strong className="block">{ t('global.status.408-timeout.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const status408Toast: StaticToasts = useCallback((message) => {
    bjToast(Status408Message(message), {
      icon: <ClockIcon className="size-6 text-error" />,
      className: 'border-l-3 border-error',
    });
  }, [Status408Message]);


  // 500 - Server error: message, toast
  // ******************************************************
  const Status500Message = useCallback((message = t('global.status.500-server-error.message')) => (
    <>
      <strong className="block">{ t('global.status.500-server-error.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug text-ink-medium">{ message }</span>
    </>
  ), [t]);

  const status500Toast: StaticToasts = useCallback((message) => {
    bjToast(Status500Message(message), {
      icon: <ServerStackIcon className="size-6 text-error" />,
      className: 'border-l-3 border-error',
    });
  }, [Status500Message]);


  // 601: Premium Talent not enough credits. Opens Upgrade Subscription modal on button click
  // ******************************************************
  const Status601Message = useCallback((message = t('subscription.unlock.update.required')) => (
    <>
      <strong className="block">{ t('unlock.error.premium_talent') }</strong>
      <span className="mt-1.5 block text-sm leading-snug">{ message }</span>
      <LinkStyled href="/premium" styling="solid" size="xs" color="primary-light" className="mt-3 py-1" target="_blank">
        { t('offer.subscription.active.see-details.text-btn') }
      </LinkStyled>
    </>
  ), [t]);

  const status601Toast: StaticToasts = useCallback((message) => {
    bjToast(Status601Message(message), {
      icon: <MedalSolidIcon className="mt-0.5 size-5 fill-primary-light" />,
      className: 'border-l-3 border-primary-light',
    });
  }, [Status601Message]);


  // 602: Payment error information: message, toast
  // ******************************************************
  const Status602Message = useCallback((message = t('unlock.error.payment_required')) => (
    <>
      <strong className="block">{ t('global.status.602-payment.title') }</strong>
      <span className="mt-1.5 block text-sm leading-snug">{ message }</span>
      <LinkStyled target="_blank" href="/wallet/transactions" styling="solid" size="xs" color="primary-light" className="mt-3 py-1">
        { t('global.label.transactions') }
      </LinkStyled>
    </>
  ), [t]);

  const status602Toast: StaticToasts = useCallback((message) => {
    bjToast(Status602Message(message), {
      icon: <LockClosedIcon className="size-5.5 text-primary-light" />,
      className: 'border-l-3 border-primary-light',
    });
  }, [Status602Message]);


  // 603: Order expired message
  // ******************************************************
  const Status603Message = useCallback((message = t('unlock.error.order_expired')) => (
    <>
      <strong className="block">{ t('global.status.603-order-expired') }</strong>
      <span className="mt-1.5 block text-sm leading-snug">{ message }</span>
      <LinkStyled target="_blank" href="/wallet/transactions" styling="solid" size="xs" color="primary-light" className="mt-3 py-1">
        { t('global.label.transactions') }
      </LinkStyled>
    </>
  ), [t]);

  const status603Toast: StaticToasts = useCallback((message) => {
    bjToast(Status603Message(message), {
      icon: <LockClosedIcon className="size-5.5 text-primary-light" />,
      className: 'border-l-3 border-primary-light',
    });
  }, [Status603Message]);


  // 606: Order expired message
  // ******************************************************
  const Status606Message = useCallback((message = t('user.resume.confidential')) => (
    <>
      <strong className="block">{ t('cv.actions.unblock.cv') }</strong>
      <span className="mt-1.5 block text-sm leading-snug">{ message }</span>
    </>
  ), [t]);

  const status606Toast: StaticToasts = useCallback((message) => {
    bjToast(Status606Message(message), {
      icon: <LockClosedIcon className="size-5.5 text-primary-light" />,
      className: 'border-l-3 border-primary-light',
    });
  }, [Status606Message]);


  /**
   * @description Programmatically display a toast based on request status.
   *
   * * **status** [string | number, required]: the status code or status message
   * * **messages** [object, optional]: replace the default messages (title and icon are always static) with custom messages.
   * * **ignoreStatus** [string array, optional]: ignore these statuses (don't display a toast for them)
   *
   * @example
   * // display a toast based on request response, using the default message
   * showStatusToast(response.status);
   *
   * // add a custom message to a certain status (201 with 'response.message', 403 with custom message)
   * showStatusToast(response.status, {message_201: response.message, message_403: 'You can send only 10 emails/day.',});
   *
   * // ignore a certain status if you don't want to show a toast for it
   * showStatusToast(response.status, {}, ['401']);
   */
  const showStatusToast: ShowStatusToasts = useCallback((status, messages, ignoreStatus) => {
    // check if status is string or number
    const statusCode = typeof status === 'number' ? String(status) : status;

    // bi-dimensional array with all the static toasts
    const staticToasts: StaticToastsArray[] = [
      ['200', status200Toast, messages?.message_200],
      ['201', status201Toast, messages?.message_201],
      ['204', status204Toast, messages?.message_204],
      ['400', status400Toast, messages?.message_400],
      ['401', status401Toast, messages?.message_401],
      ['403', status403Toast, messages?.message_403],
      ['404', status404Toast, messages?.message_404],
      ['408', status408Toast, messages?.message_408],
      ['500', status500Toast, messages?.message_500],
      ['601', status601Toast, messages?.message_601],
      ['602', status602Toast, messages?.message_602],
      ['603', status603Toast, messages?.message_603],
      ['606', status606Toast, messages?.message_606],
    ];

    // toasts filtered against ignoreStatus; equals with staticToasts if we ignore nothing
    const acceptedToasts = Array.isArray(ignoreStatus) && ignoreStatus.length
      ? staticToasts.filter((staticToast) => !ignoreStatus.includes(staticToast[0]))
      : staticToasts;

    // check if we have a matching toast to show
    acceptedToasts.forEach((acceptedToast) => {
      const toastStatus = acceptedToast[0];
      const callToast = acceptedToast[1];
      const toastMessage = acceptedToast[2];

      if (statusCode === toastStatus) {
        // check if we have a custom message or use the default one
        if (toastMessage) {
          callToast(toastMessage);
        } else {
          callToast();
        }
      }
    });
  }, [
    status200Toast,
    status201Toast,
    status204Toast,
    status400Toast,
    status401Toast,
    status403Toast,
    status404Toast,
    status408Toast,
    status500Toast,
    status601Toast,
    status602Toast,
    status603Toast,
    status606Toast,
  ]);


  return {
    // generic toast
    bjToast,
    // toasts
    liveOnlineMessageToast,
    status200Toast,
    status201Toast,
    status204Toast,
    statusGenericErrorToast,
    status401Toast,
    status403Toast,
    status404Toast,
    status408Toast,
    status500Toast,
    status601Toast,
    status602Toast,
    status603Toast,
    status606Toast,
    // functions
    showStatusToast,
  };
};
