// RESUME CHECK UNLOCK ********************************************************************************
// pathname: /v1/resume-operation/${userId}/check-unlock/${type}
// ****************************************************************************************************
import {
  ApiCheckUnlockOk,
  ApiResumeOperationJobPayload,
  GenericV1ErrorsArray,
  useSwrMutationApi,
} from '@hooks/useSwrApi';
import { SwrToastsProps } from '@type/swr';

// Interface
// ********************************************
interface SwrResumeCheckUnlockProps {
  userSlug: string;
  type: 'download' | 'message' | 'contact'
  locale: string;
  rid?: string;
  successCallback?: () => void;
  errorCallback?: (errors: GenericV1ErrorsArray) => void;
  toastsOptions?: SwrToastsProps,
}

/**
 * @description SWR mutation: Check if the user can unlock a talent. Prepares contact, download or message events by unlocking / creating job.
 *
 * * **userSlug**: string. The slug of the user to check.
 * * **type**: 'download', 'message', 'contact'. Contacting type. Required.
 * * **locale**: string. Locale to use for the request.
 * * **rid**: string, optional. Request id..
 * * **successCallback**: function, optional.
 * * **errorCallback**: function, optional.
 * * **toastsOptions**: SWR toasts options, optional.
 */
export const useSwrResumeCheckUnlock = (props: SwrResumeCheckUnlockProps) => {
  // Props destructuring
  // ********************************************
  const {
    userSlug,
    type,
    locale,
    rid,
    successCallback,
    errorCallback,
    toastsOptions,
  } = props;

  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiCheckUnlockOk, ApiResumeOperationJobPayload>({
    url: `/v2/resume-operation/${userSlug}/check-unlock/${type}${rid ? `?rid=${rid}` : ''}`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        // Callback
        if (successCallback) successCallback();
      },
      onError: (errors) => {
        // Callback
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions,
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
