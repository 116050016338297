import { ImageBjSrc } from '@type/image-bj';
import { StaticImageData } from 'next/image';
import { StaticImport, StaticRequire } from 'next/dist/shared/lib/get-img-props';


// *********************************************************************************************
// TYPE PREDICATES
// *********************************************************************************************
/**
 * @description Type predicate to check if the src is StaticImageData
 * @param src - The src to check
 */
const isStaticImageData = (src: ImageBjSrc): src is StaticImageData => src !== undefined
  && src !== null
  && typeof src === 'object'
  && (src as StaticImageData)?.src !== undefined
  && (src as StaticImageData)?.height !== undefined
  && (src as StaticImageData)?.width !== undefined;


/**
 * @description Type predicate to check if the src is StaticRequire
 * @param src - The src to check
 */
const isStaticRequire = (src: ImageBjSrc): src is StaticRequire => src !== undefined
  && src !== null
  && typeof src === 'object'
  && isStaticImageData((src as StaticRequire).default);


/**
 * @description Type predicate to check if the src is a StaticImport
 * @param src - The src to check
 */
export const isStaticImport = (src: ImageBjSrc): src is StaticImport => isStaticImageData(src) || isStaticRequire(src);


/**
 * @description Check if the src is a Blob
 * @param src - The src to check
 */
export const stringSrcIsBlob = (src: ImageBjSrc): boolean => typeof src === 'string' && src.startsWith('blob:');
