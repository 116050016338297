/**
 * @description Badge component for notifications/messages count.
 * @param count - number of notifications/messages.
 * @param className - optional class name.
 * @param showCount - show the count or not.
 */
export const BadgeNotifications = ({ count, className, showCount = true }: { count: number | string, className?: string, showCount?: boolean }) => {
  const cssClasses = `bg-error inline-block text-white rounded-full text-tiny px-1.5 min-w-4.5 text-center ${className || ''}`;
  const cssClassesPill = `bg-error inline-block text-white rounded-full text-tiny size-2 ${className || ''}`;
  const hasCount = +count > 0;

  return (
    <>
      {hasCount && (
        <span className={showCount ? cssClasses : cssClassesPill}>
          {showCount ? count : ''}
        </span>
      )}
    </>
  );
};
