// Interfaces
// *******************************************************************************************
interface AnimationGroup {
  hiddenState: string,
  visibleState: string,
}

interface PanelAnimation {
  none: AnimationGroup,
  fade: AnimationGroup,
  slideUp: AnimationGroup,
  slideLeft: AnimationGroup,
}


// Classes
// *******************************************************************************************
// Tab panel animation
export const panelAnimation: PanelAnimation = {
  none: {
    hiddenState: '',
    visibleState: '',
  },
  fade: {
    hiddenState: 'opacity-0',
    visibleState: 'opacity-100',
  },
  slideUp: {
    hiddenState: 'opacity-0 translate-y-3',
    visibleState: 'opacity-100 translate-y-0',
  },
  slideLeft: {
    hiddenState: 'opacity-0 translate-x-5',
    visibleState: 'opacity-100 translate-x-0',
  },
};


/**
 * @description Compose tab panel animation
 * @param animation
 */
export const composeTabPanelAnimation = (animation: keyof PanelAnimation): AnimationGroup => panelAnimation[animation];
