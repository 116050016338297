'use client';

import { useRef, useState } from 'react';
import { clsx } from 'clsx';
import Slider, { CustomArrowProps } from 'react-slick';
import { Button } from '@components/common/Forms/Button/Button';
import { Modal } from '@components/common/Modal/Modal';
import { ImageBj } from '@components/common/ImageBj/ImageBj';
import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { ImageGalleryProps } from '@type/image-gallery-types';
import 'styles/bj_theme/bj_slick_image_gallery.css';


// Image Gallery Custom Nav Arrows
// ***********************************
const NextArrow = (props: CustomArrowProps) => {
  const { onClick, className } = props;
  return (
    <Button
      color="dark"
      rounding="full"
      className={`absolute right-2 top-1/2 z-2 -translate-y-1/2 bg-opacity-60 !p-2.5 ${className}`}
      onClick={onClick}
    >
      <ChevronRightIcon className="size-5" />
    </Button>
  );
};

const PrevArrow = (props: CustomArrowProps) => {
  const { onClick, className } = props;
  return (
    <Button
      color="dark"
      rounding="full"
      className={`absolute left-2 top-1/2 z-2 -translate-y-1/2 bg-opacity-60 !p-2.5 ${className}`}
      onClick={onClick}
    >
      <ChevronLeftIcon className="size-5" />
    </Button>
  );
};


/**
 * @description Image Gallery component.
 * A component to render an image gallery slideshow, based on `react-slick` library for the slider functionality
 * and Next.js's Image component for optimized image rendering.
 * Images are shown in a modal view when selected.
 * It also contains a thumbnail navigation, displayed below the main image slider for easy navigation between images.
 *
 * - **items** - ImageGalleryItem[]. The items that will be displayed in the gallery. An item is an object with `src`, 'thumbnail' and `alt` props. Required.
 * - **renderItems** - Render prop function. It is used to define how the images are initially rendered outside the modal.
 * This function takes a function as its argument (`handleImageClick`), used to handle image clicks and open the modal (with the selected index). Required.
 * - **withThumbnailNav** - boolean. Whether the thumbnail navigation is displayed. The thumbnails will be displayed below the main image slider.
 *
 *
 * @example
 *
 * // first, defined the images to be displayed in the gallery
 * const images = [
 *   { src: '/path/to/image1.jpg', alt: 'Image 1', thumbnail: '/path/to/thumb1.jpg' },
 *   { src: '/path/to/image2.jpg', alt: 'Image 2', thumbnail: '/path/to/thumb2.jpg' },
 *   // ...
 * ];
 *
 * // defines how the render the images outside the gallery (eg: a grid of thumbnails).
 * // each image thumbnail has an onClick event handler that calls `handleImageClick` with the index of the clicked image.
 * const CustomImageRenderer = (handleImageClick) => (
 *   <div className="image-grid">
 *     {images.map((image, index) => (
 *       <div key={image.src} onClick={() => handleImageClick(index)} className="image-item">
 *         <img src={image.thumbnail} alt={image.alt} className="image-thumbnail" />
 *       </div>
 *     ))}
 *   </div>
 * );
 *
 * <ImageGallery
 *   items={images}
 *   renderItems={CustomImageRenderer}
 *   withThumbnailNav={true}
 *  />
 *
 * @TODO
 * - transform thumbnail navigation in a react-slick carousel in order to be in sync with the main slider.
 */
export const ImageGallery = (props: ImageGalleryProps) => {
  // Destructure props
  const {
    items, withThumbnailNav = true, renderItems,
  } = props;

  // State
  // *****************************************
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);

  // Slider
  // *****************************************
  const [sliderRef, setSliderRef] = useState<Slider | null>(null);

  // Since the plugin `initialSlider` property is not working properly with next/prev buttons
  // we set an initial slide using the package API
  // Issue is created https://github.com/akiran/react-slick/issues/2207 / https://github.com/akiran/react-slick/issues/2166
  const initSlider = (ref: Slider) => {
    ref?.slickGoTo(currentIndex || 0, true);
    setSliderRef(ref);
  };

  // Helper variables
  // *****************************************
  const totalItems = items.length;

  // When the HeadlessUI Dialog is opened, it will focus the first element or the item passed as `initialFocus` property.
  // So we need to get the ref of the slide item and pass it to the Dialog component.
  const focusedElementRef = useRef<HTMLDivElement>(null);

  // Slider settings
  // *****************************************
  const sliderSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    className: 'bj-slick-image-gallery',
    beforeChange: (currentSlide: number, nextSlide: number) => {
      // Keep current index in sync with the slider's internal state.
      // It is necessary for arrows or thumbnail navigation.
      setCurrentIndex(nextSlide);
    },
  };

  // Close modal
  // *****************************************************
  const closeModal = () => {
    setCurrentIndex(undefined);
  };

  // Handle Image Click
  // *****************************************************
  const handleImageClick = (index: number) => {
    setCurrentIndex(index);
  };

  // Render component
  // *****************************************************
  return (

    <>
      {/* The jsx for images to display */}
      {renderItems(handleImageClick)}

      {/* Image Gallery Modal */}
      {currentIndex !== undefined && (
        <Modal
          onClose={closeModal}
          size="full"
          className="flex flex-col !bg-black/80"
          isOpen
          initialFocus={focusedElementRef}
        >

          {/* Modal Header - includes the current image index and a close button */}
          <div className="relative z-2 flex shrink-0 items-center justify-between p-2">
            <span className="text-sm text-white/90">
              {`${currentIndex + 1}/${totalItems}`}
            </span>
            <Button color="dark" className="!rounded-full bg-opacity-60 !p-2.5" onClick={closeModal}>
              <XMarkIcon className="size-5" />
            </Button>
          </div>

          {/* Image slider */}
          <Slider {...sliderSettings} ref={initSlider}>
            {items.map((item, index) => (
              <div
                className="relative size-full"
                key={item.src}
                ref={index === currentIndex ? focusedElementRef : null}
              >
                {item.src && (
                  <ImageBj
                    src={item.src}
                    extractImageName
                    alt={item.alt || `image${index + 1}`}
                    fill
                    className="object-scale-down"
                    quality={90}
                  />
                )}
              </div>
            ))}
          </Slider>

          {/* Thumbnails nav */}
          {withThumbnailNav && (
            <div className="mt-4 flex h-14 shrink-0 flex-nowrap justify-center space-x-4 overflow-auto pb-2">
              {items.map((item, index) => (
                <button
                  type="button"
                  key={item.thumbnail}
                  className={clsx('relative inline-flex w-16 shrink-0 overflow-hidden rounded-md border-2', { 'border-transparent opacity-50 transition-opacity hover:opacity-100': currentIndex !== index, 'border-primary-light': currentIndex === index })}
                  onClick={() => {
                    sliderRef?.slickGoTo(index);
                  }}
                >
                  {item.thumbnail && (
                    <ImageBj
                      src={item.thumbnail}
                      extractImageName
                      alt={item.alt || `image${index + 1}`}
                      className="object-cover"
                      fill
                    />
                  )}
                </button>
              ))}
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
