/*
  CUSTOM ICONS - OUTLINE
  You can find icons that are not available to hero-icons here: https://icons.getbootstrap.com/
  Note: To use bootstrap icons with text colour (i.e. text-blue-500 in TW, etc.) or to inherit
  parent text colour you need to add fill="currentColor" to the <svg> tag.

  For outline icons use 'Outline' suffix, e.g. 'PinIconOutline'.

  Current icons list:
  - pin solid (thumb-tack solid)
 */

// Default size: 16px
// *********************************
const defaultClasses = 'w-4 h-4';


/**
 * @description Pin outline (thumb-tack) svg icon. Default classes can be overwritten. Default size: 16px.
 * @param className
 */
export const PinIconOutline = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className={className}>
    <path
      d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354zm1.58 1.408-.002-.001.002.001zm-.002-.001.002.001A.5.5 0 0 1 6 2v5a.5.5 0 0 1-.276.447h-.002l-.012.007-.054.03a4.922 4.922 0 0 0-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a4.915 4.915 0 0 0-.881-.61l-.012-.006h-.002A.5.5 0 0 1 10 7V2a.5.5 0 0 1 .295-.458 1.775 1.775 0 0 0 .351-.271c.08-.08.155-.17.214-.271H5.14c.06.1.133.191.214.271a1.78 1.78 0 0 0 .37.282z"
    />
  </svg>
);

export const UserCheckOutlineIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg viewBox="0 0 19 15" fill="currentColor" className={className}>
    <path
      d="M18.168 5.17969L17.6211 4.63281C17.5117 4.52344 17.3477 4.52344 17.2383 4.63281L14.4219 7.47656L13.1094 6.16406C13 6.05469 12.8359 6.05469 12.7266 6.16406L12.207 6.71094C12.0977 6.82031 12.0977 6.98438 12.207 7.09375L14.2031 9.14453C14.3125 9.25391 14.5039 9.25391 14.6133 9.14453L18.168 5.5625C18.2773 5.45312 18.2773 5.28906 18.168 5.17969ZM6.875 8.625C9.03516 8.625 10.8125 6.875 10.8125 4.6875C10.8125 2.52734 9.03516 0.75 6.875 0.75C4.6875 0.75 2.9375 2.52734 2.9375 4.6875C2.9375 6.875 4.6875 8.625 6.875 8.625ZM6.875 2.0625C8.29688 2.0625 9.5 3.26562 9.5 4.6875C9.5 6.13672 8.29688 7.3125 6.875 7.3125C5.42578 7.3125 4.25 6.13672 4.25 4.6875C4.25 3.26562 5.42578 2.0625 6.875 2.0625ZM9.30859 9.0625C8.51562 9.0625 8.16016 9.5 6.875 9.5C5.5625 9.5 5.20703 9.0625 4.41406 9.0625C2.39062 9.0625 0.75 10.7305 0.75 12.7539V13.4375C0.75 14.1758 1.32422 14.75 2.0625 14.75H11.6875C12.3984 14.75 13 14.1758 13 13.4375V12.7539C13 10.7305 11.332 9.0625 9.30859 9.0625ZM11.6875 13.4375H2.0625V12.7539C2.0625 11.4414 3.10156 10.375 4.41406 10.375C4.82422 10.375 5.45312 10.8125 6.875 10.8125C8.26953 10.8125 8.89844 10.375 9.30859 10.375C10.6211 10.375 11.6875 11.4414 11.6875 12.7539V13.4375Z"
      fill="#224D89"
    />
  </svg>
);

export const AccountSwitcherOutlineIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 38 36" fill="none" className={className}>
    <path
      d="M31.942 12.9514H37L30.6486 6.4418C28.6038 4.34735 26.0567 2.84118 23.2633 2.0747C20.47 1.30821 17.5289 1.30844 14.7356 2.07535C11.9424 2.84225 9.39553 4.34882 7.35101 6.44358C5.30649 8.53834 3.8364 11.1475 3.08852 14.0087M1 28.9453V23.7986M1 23.7986H6.05803M1 23.7986L7.34942 30.3082C9.39424 32.4026 11.9414 33.9088 14.7347 34.6753C17.528 35.4418 20.4691 35.4416 23.2624 34.6747C26.0556 33.9077 28.6025 32.4012 30.647 30.3064C32.6915 28.2117 34.1616 25.6025 34.9095 22.7413M37 7.80471V12.9473"
      stroke="#677788"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RadioCheckedIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={className}>
    <path
      d="M17 12c0 2.8-2.2 5-5 5s-5-2.2-5-5 2.2-5 5-5 5 2.2 5 5zm4.8 0c0 5.4-4.4 9.8-9.8 9.8S2.3 17.4 2.3 12 6.6 2.3 12 2.3s9.8 4.3 9.8 9.7zm-1.5 0c0-4.6-3.7-8.3-8.3-8.3S3.8 7.4 3.8 12s3.7 8.3 8.3 8.3 8.2-3.7 8.2-8.3z"
    />
  </svg>
);

export const ClockRotateLeftIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className={className}>
    <path
      d="M418.4 117.1c-35.9-47.4-87.8-80.2-146-92.3-51.6-10.7-105.2-4.6-152.7 17.2L88.5.8c-.5-.6-1.2-.9-1.9-.8-.7.1-1.3.6-1.6 1.3L44.4 118.5c-.4 1.3.7 2.8 2 2.7l123.3-7.4c.6.1 1.1 0 1.6-.4.9-.7 1.1-1.9.4-2.8l-29-38.2c86.3-35.4 188.5-8.7 246 67.1 69.6 91.9 51.5 223.2-40.3 292.9-82.4 62.5-199.8 54.8-273.2-17.8-7.3-7.2-19-7.1-26.2.1-7.2 7.3-7.1 19 .1 26.2 41.7 41.3 97.1 66.3 155.9 70.4 59.4 4.2 118.3-13.4 165.8-49.4 108.3-81.9 129.6-236.6 47.6-344.8z"
    />
    <path
      d="M215.5 152.4c-10 0-18.1 5.5-18.1 12.4V270c0 1.4.3 2.7.9 3.9.2 1.4.7 2.6 1.6 3.5l99.4 98.5c3.8 3.7 12.7.8 20-6.6 7.3-7.4 10.1-16.4 6.4-20.1l-92.2-91.4v-93.1c.1-6.8-8-12.3-18-12.3z"
    />
  </svg>
);

export const HandPointRightIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" className={className}>
    <path
      d="M428.8 137.6h-86.2a115.5 115.5 0 0 0 2.2-22.4c0-47.9-35.1-83.2-92-83.2-45.3 0-57 48.5-75.7 78.8-7.7 12.4-17 23.3-25.9 33.3l-.1 .1-.1 .1C135.7 161.8 127.8 168 120.8 168h-2.7c-5.7-5-13.5-8-22.1-8H32c-17.7 0-32 12.9-32 28.8v230.4C0 435.1 14.3 448 32 448h64c8.6 0 16.4-3 22.1-8h2.7c28.7 0 67.1 40 127.2 40h21.3c62.5 0 98.8-38.7 99.9-91.1 12.5-17.8 18.5-40.8 16-62.8A93.1 93.1 0 0 0 393.2 304H428.8c45.4 0 83.2-37.6 83.2-83.2 0-45.1-38.1-83.2-83.2-83.2zm0 118.4h-91c12.8 14.7 14.4 42.8-5 61.1 11.2 19.6 1.7 45.6-12.9 53.6 6.5 39.1-10.1 61.3-50.6 61.3H248c-45.5 0-77.2-35.9-120-39.7V215.6c25.2-3 43-21.2 59.1-39.6 11.3-12.7 21.7-25.3 30.8-39.9C232.4 112.7 244 80 252.8 80c23.4 0 44 8.8 44 35.2 0 35.2-26.4 53.1-26.4 70.4h158.4c18.4 0 35.2 16.5 35.2 35.2 0 19-16.2 35.2-35.2 35.2zM88 384c0 13.3-10.7 24-24 24s-24-10.7-24-24 10.7-24 24-24 24 10.7 24 24z"
    />
  </svg>
);

export const CrosshairOutlineIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className={className}
    viewBox="0 0 16 16"
  >
    <path
      d="M8.5.5a.5.5 0 0 0-1 0v.518A7 7 0 0 0 1.018 7.5H.5a.5.5 0 0 0 0 1h.518A7 7 0 0 0 7.5 14.982v.518a.5.5 0 0 0 1 0v-.518A7 7 0 0 0 14.982 8.5h.518a.5.5 0 0 0 0-1h-.518A7 7 0 0 0 8.5 1.018zm-6.48 7A6 6 0 0 1 7.5 2.02v.48a.5.5 0 0 0 1 0v-.48a6 6 0 0 1 5.48 5.48h-.48a.5.5 0 0 0 0 1h.48a6 6 0 0 1-5.48 5.48v-.48a.5.5 0 0 0-1 0v.48A6 6 0 0 1 2.02 8.5h.48a.5.5 0 0 0 0-1zM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4"
    />
  </svg>
);
export const CameraVideoIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg
    fill="currentColor"
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8C0 6.89543 0.895431 6 2 6H1.92963C2.59834 6 3.2228 5.6658 3.59373 5.1094L4.40627 3.8906C4.7772 3.3342 5.40166 3 6.07037 3H9.92963C10.5983 3 11.2228 3.3342 11.5937 3.8906L12.4063 5.1094C12.5517 5.32755 12.5824 5.51154 12.6062 5.65463C12.6432 5.87649 12.6638 6 13.0704 6H13C14.1046 6 15 6.89543 15 8V15C15 16.1046 14.1046 17 13 17H2C0.895431 17 0 16.1046 0 15V8ZM12.5 11C12.5 13.4853 10.4853 15.5 8 15.5C5.51472 15.5 3.5 13.4853 3.5 11C3.5 8.51472 5.51472 6.5 8 6.5C10.4853 6.5 12.5 8.51472 12.5 11ZM8 14C9.65685 14 11 12.6569 11 11C11 9.34315 9.65685 8 8 8C6.34315 8 5 9.34315 5 11C5 12.6569 6.34315 14 8 14Z"
    />
    <path
      d="M20 7.56251C20 7.33501 19.8376 7.1299 19.5885 7.04283C19.3393 6.95577 19.0526 7.0039 18.8619 7.16477L16.1953 9.41476C16.0702 9.52025 16 9.66332 16 9.81251V13.1875C16 13.3367 16.0702 13.4798 16.1953 13.5852L18.8619 15.8352C19.0526 15.9961 19.3393 16.0442 19.5885 15.9572C19.8376 15.8701 20 15.665 20 15.4375V7.56251Z"
    />
  </svg>

);

export const CustomBiaOutline = ({ className = defaultClasses }: { className?: string }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M8 2C7.38461 2 5.82467 5.06633 5.38807 6.37614C5.22882 6.85391 4.85391 7.22882 4.37614 7.38807C3.06633 7.82468 1.08797e-09 9.38462 0 10C-1.08797e-09 10.6154 3.06633 12.1753 4.37614 12.6119C4.85391 12.7712 5.22882 13.1461 5.38807 13.6239C5.82467 14.9337 7.38461 18 8 18C8.61538 18 10.1753 14.9337 10.6119 13.6239C10.7712 13.1461 11.1461 12.7712 11.6239 12.6119C12.9337 12.1753 16 10.6154 16 10C16 9.38462 12.9337 7.82468 11.6239 7.38807C11.1461 7.22882 10.7712 6.85391 10.6119 6.37614C10.1753 5.06633 8.61538 2 8 2Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6C17.6569 6 19 4.65685 19 3C19 1.34315 17.6569 0 16 0C14.3431 0 13 1.34315 13 3C13 4.65685 14.3431 6 16 6ZM16 4.8C16.9941 4.8 17.8 3.99411 17.8 3C17.8 2.00589 16.9941 1.2 16 1.2C15.0059 1.2 14.2 2.00589 14.2 3C14.2 3.99411 15.0059 4.8 16 4.8Z"
    />
    <path d="M16 16V18H17V16H19V15H17V13H16V15H14V16H16Z" />
  </svg>
);

/**
 * @description Bia header icon for Bia chat section in the navbar
 * @constructor
 */
export const BiaHeaderIcon = ({ className = defaultClasses }: { className?: string }) => (
  <svg width="19" height="18" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      d="M8 2C7.38461 2 5.82467 5.06633 5.38807 6.37614C5.22882 6.85391 4.85391 7.22882 4.37614 7.38807C3.06633 7.82468 1.08797e-09 9.38461 0 10C-1.08797e-09 10.6154 3.06633 12.1753 4.37614 12.6119C4.85391 12.7712 5.22882 13.1461 5.38807 13.6239C5.82467 14.9337 7.38461 18 8 18C8.61538 18 10.1753 14.9337 10.6119 13.6239C10.7712 13.1461 11.1461 12.7712 11.6239 12.6119C12.9337 12.1753 16 10.6154 16 10C16 9.38461 12.9337 7.82468 11.6239 7.38807C11.1461 7.22882 10.7712 6.85391 10.6119 6.37614C10.1753 5.06633 8.61538 2 8 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6C17.6569 6 19 4.65685 19 3C19 1.34315 17.6569 0 16 0C14.3431 0 13 1.34315 13 3C13 4.65685 14.3431 6 16 6ZM16 4.8C16.9941 4.8 17.8 3.99411 17.8 3C17.8 2.00589 16.9941 1.2 16 1.2C15.0059 1.2 14.2 2.00589 14.2 3C14.2 3.99411 15.0059 4.8 16 4.8Z"
      fill="currentColor"
    />
    <path d="M16 16V18H17V16H19V15H17V13H16V15H14V16H16Z" fill="currentColor" />
  </svg>
);

/**
 * @description Bia chat icon for Bia chat section in the navbar
 * @constructor
 */
export const BiaChatIcon = () => (
  <svg width="30" height="30" viewBox="0 0 19 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2C7.38461 2 5.82467 5.06633 5.38807 6.37614C5.22882 6.85391 4.85391 7.22882 4.37614 7.38807C3.06633 7.82468 1.08797e-09 9.38461 0 10C-1.08797e-09 10.6154 3.06633 12.1753 4.37614 12.6119C4.85391 12.7712 5.22882 13.1461 5.38807 13.6239C5.82467 14.9337 7.38461 18 8 18C8.61538 18 10.1753 14.9337 10.6119 13.6239C10.7712 13.1461 11.1461 12.7712 11.6239 12.6119C12.9337 12.1753 16 10.6154 16 10C16 9.38461 12.9337 7.82468 11.6239 7.38807C11.1461 7.22882 10.7712 6.85391 10.6119 6.37614C10.1753 5.06633 8.61538 2 8 2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 6C17.6569 6 19 4.65685 19 3C19 1.34315 17.6569 0 16 0C14.3431 0 13 1.34315 13 3C13 4.65685 14.3431 6 16 6ZM16 4.8C16.9941 4.8 17.8 3.99411 17.8 3C17.8 2.00589 16.9941 1.2 16 1.2C15.0059 1.2 14.2 2.00589 14.2 3C14.2 3.99411 15.0059 4.8 16 4.8Z"
      fill="currentColor"
    />
    <path d="M16 16V18H17V16H19V15H17V13H16V15H14V16H16Z" fill="currentColor" />
  </svg>
);


export const CustomBiaEvaluate = ({ className = defaultClasses }: { className?: string }) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5 49.9557C39.6088 49.1799 50 38.3034 50 24.9999C50 11.6964 39.6088 0.819934 26.5 0.0441895V49.9557Z"
      fill="#5FBB46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.55969 13.9677L24.5 22.5V49.9951C10.9237 49.7288 0 38.6401 0 25C0 21.04 0.920693 17.2951 2.55969 13.9677Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5 0.00488281C15.7043 0.177453 8.02201 4.89256 3.70233 11.901L24.5 21V0.00488281Z"
      fill="#DE4437"
    />
    <rect x="3" y="3" width="44" height="44" rx="22" fill="white" />
    <path
      d="M23 17C22.3846 17 20.8247 20.0663 20.3881 21.3761C20.2288 21.8539 19.8539 22.2288 19.3761 22.3881C18.0663 22.8247 15 24.3846 15 25C15 25.6154 18.0663 27.1753 19.3761 27.6119C19.8539 27.7712 20.2288 28.1461 20.3881 28.6239C20.8247 29.9337 22.3846 33 23 33C23.6154 33 25.1753 29.9337 25.6119 28.6239C25.7712 28.1461 26.1461 27.7712 26.6239 27.6119C27.9337 27.1753 31 25.6154 31 25C31 24.3846 27.9337 22.8247 26.6239 22.3881C26.1461 22.2288 25.7712 21.8539 25.6119 21.3761C25.1753 20.0663 23.6154 17 23 17Z"
      fill="#1E2022"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 21C32.6569 21 34 19.6569 34 18C34 16.3431 32.6569 15 31 15C29.3431 15 28 16.3431 28 18C28 19.6569 29.3431 21 31 21ZM31 19.8C31.9941 19.8 32.8 18.9941 32.8 18C32.8 17.0059 31.9941 16.2 31 16.2C30.0059 16.2 29.2 17.0059 29.2 18C29.2 18.9941 30.0059 19.8 31 19.8Z"
      fill="#1E2022"
    />
    <path d="M31 31V33H32V31H34V30H32V28H31V30H29V31H31Z" fill="#1E2022" />
  </svg>
);

