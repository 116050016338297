/**
 * @description Renders the typing dots for the chat
 */
export const ChatTypingDots = () => {
  const baseDotClass = 'float-left w-2 h-2 bg-ink-medium opacity-0 rounded-full animate-loading-fade';

  return (
    <div className="relative bg-surface-100 p-2 rounded-3xl w-fit">
      <div className={`${baseDotClass} mr-1`} />
      <div className={`${baseDotClass} animate-delay-200 mr-1`} />
      <div className={`${baseDotClass} animate-delay-400`} />
    </div>
  );
};
