import { ReactNode } from 'react';


// Interface for the input group
// ****************************************
interface DecorativeGroupProps {
  children: ReactNode,
  className?: string,
  groupParentClasses?: string,
  hasError?: boolean,
}


// Styles for the input group
// ****************************************
const groupBaseClasses = {
  common: 'relative flex border transition-all duration-200',
  border: 'border-input',
  focus: 'group-focus-within:border-primary-light/80 group-focus-within:ring group-focus-within:ring-primary-light/40',
};
const groupError = 'border-error group-focus-within:border-error/80 group-focus-within:ring group-focus-within:ring-error-light/40';

const groupClasses = Object.values(groupBaseClasses).join(' ');
const groupErrorClasses = `${groupBaseClasses.common} ${groupError}`;


/**
 * @description Decorative component that renders an input group with a border and focus styles. Don't
 * use this component on elements with validity checks (that means required fields).
 * * **children** The child components (input, icon, button, etc.)
 * * **className** Optional classes to add to the input group.
 * * **groupParentClasses** Optional classes to add to the parent div.
 * * **hasError** Optional prop to add error styles to the input group.
 *
 * @example
 * <DecorativeGroup className="rounded-full p-1">
 *   <input type="text" placeholder={t('job.link-add.placeholder')} className="rounded-l-full rounded-r-0 !border-0 !outline-0 !ring-0" />
 *   <Button color="secondary" rounding="full">Send</Button>
 * </DecorativeGroup>
 */
export const DecorativeGroup = (props: DecorativeGroupProps) => {
  // Destructure props
  const {
    children,
    className = '',
    groupParentClasses = '',
    hasError = false,
  } = props;

  // Styles from props
  const propsClasses = className ? ` ${className}` : '';

  // Render component
  // ****************************************
  return (
    <div className={`${groupParentClasses} unStyled-form-elements group`}>
      <div className={`${hasError ? groupErrorClasses : groupClasses}${propsClasses}`}>
        {children}
      </div>
    </div>
  );
};
