// prop types interface
// ***************************************************************************************************
interface ButtonSizes {
  'text-size': string,
  padding: string,
}

interface ButtonColors {
  text: string,
  solid: string,
  soft: string,
  outline: string,
}

interface ButtonStyles {
  common: string,
  round: {
    [rounding: string]: string
  },
  sizes: {
    [size: string]: ButtonSizes
  },
  colors: {
    [color: string]: ButtonColors
  },
}


// common css classes for all buttons
// *******************************************************************************************************
const common = 'inline-flex items-center justify-center transition-all duration-200 disabled:opacity-60 outline-none';
const text = 'hover:underline disabled:no-underline';
const bordered = 'font-medium border hover:shadow-md'; // not for text
const solid = 'text-surface border-transparent disabled:shadow-none';
const soft = 'border-transparent disabled:shadow-none';
const outline = 'hover:text-surface active:text-surface disabled:bg-transparent disabled:shadow-none';
const disabledCursor = ' disabled:cursor-not-allowed';

// sizes for buttons; text buttons take only the 'text-size'
const sizes = {
  xs: {
    'text-size': 'text-tiny',
    padding: `px-3.5 py-2 ${bordered}`,
  },
  sm: {
    'text-size': 'text-sm',
    padding: `px-4.5 py-2.5 ${bordered}`,
  },
  md: {
    'text-size': 'text-base',
    padding: `px-5 py-3 ${bordered}`,
  },
  lg: {
    'text-size': 'text-lg',
    padding: `px-12 py-3.5 ${bordered}`,
  },
};

// rounding corners
const round = {
  none: '',
  normal: ' rounded',
  full: ' rounded-full',
};

// colors for buttons
const colors = {
  primary: {
    text: `${text} text-primary hover:text-primary-light disabled:text-primary`,
    solid: `${solid} bg-primary hover:bg-primary-light active:bg-primary-dark disabled:bg-primary`,
    soft: `${soft} text-primary bg-primary/10 hover:bg-primary hover:text-surface active:bg-primary active:text-surface disabled:bg-primary/10 disabled:text-primary`,
    outline: `${outline} text-primary border-primary hover:bg-primary active:bg-primary-dark active:border-primary-dark disabled:text-primary`,
  },
  'primary-light': {
    text: `${text} text-primary-light hover:text-primary-light disabled:text-primary`,
    solid: `${solid} bg-primary-light hover:brightness-110 active:bg-primary disabled:bg-primary-light`,
    soft: `${soft} text-primary-light bg-primary-light/10 hover:bg-primary-light hover:text-surface active:bg-primary-light active:text-surface disabled:bg-primary-light/10 disabled:text-primary-light`,
    outline: `${outline} text-primary-light border-primary-light hover:bg-primary-light active:bg-primary active:border-primary-dark disabled:text-primary-light`,
  },
  secondary: {
    text: `${text} text-secondary hover:text-secondary-light disabled:text-secondary`,
    solid: `${solid} bg-secondary hover:bg-secondary-light active:bg-secondary-dark disabled:bg-secondary`,
    soft: `${soft} text-secondary bg-secondary/10 hover:bg-secondary hover:text-surface active:bg-secondary active:text-surface disabled:bg-secondary/10 disabled:text-secondary`,
    outline: `${outline} text-secondary border-secondary hover:bg-secondary active:bg-secondary-dark active:border-secondary-dark disabled:text-secondary`,
  },
  'secondary-light': {
    text: `${text} text-secondary hover:text-secondary-light disabled:text-secondary`,
    solid: `${solid} bg-secondary-light hover:brightness-110 active:bg-secondary disabled:bg-secondary-light`,
    soft: `${soft} text-secondary-light bg-secondary-light/10 hover:bg-secondary-light hover:text-surface active:bg-secondary-light active:text-surface disabled:bg-secondary-light/10 disabled:text-secondary-light`,
    outline: `${outline} text-secondary-light border-secondary-light hover:bg-secondary-light hover:text-surface active:bg-secondary-dark active:border-secondary-dark disabled:text-secondary-light`,
  },
  warning: {
    text: `${text} text-warning hover:text-warning-light`,
    solid: `${solid} bg-warning hover:bg-warning-light active:bg-warning-light`,
    soft: `${soft} text-warning bg-warning/10 hover:bg-warning hover:text-surface active:bg-warning active:text-surface disabled:bg-warning/10 disabled:text-warning`,
    outline: `${outline} text-warning border-warning hover:bg-warning active:bg-warning-light active:border-warning-light disabled:text-warning`,
  },
  error: {
    text: `${text} text-error hover:text-error-light`,
    solid: `${solid} bg-error hover:bg-error-light active:bg-error-light`,
    soft: `${soft} text-error bg-error/10 hover:bg-error hover:text-surface active:bg-error active:text-surface disabled:bg-error/10 disabled:text-error`,
    outline: `${outline} text-error border-error hover:bg-error active:bg-error-light active:border-error-light disabled:text-error`,
  },
  light: {
    text: `${text} text-ink-light`,
    solid: 'bg-surface-100 text-ink border-transparent disabled:shadow-none',
    soft: `${soft} bg-surface-100/50 text-ink`,
    outline: 'text-ink border-surface-100 hover:bg-surface-100 active:bg-surface-100 active:border-surface-100 disabled:shadow-none disabled:bg-transparent',
  },
  ink: {
    text: `${text} text-ink`,
    solid: 'bg-ink text-surface hover:bg-ink-medium disabled:shadow-none disabled:bg-ink',
    soft: `${soft} text-ink bg-ink/10 hover:bg-ink hover:text-surface active:bg-ink active:text-surface disabled:bg-ink/10 disabled:text-ink`,
    outline: `${outline} text-ink border-ink hover:bg-ink hover:text-surface active:bg-ink active:border-ink active:text-surface disabled:text-ink`,
  },
  'ink-medium': {
    text: `${text} text-ink-medium`,
    solid: `${solid} bg-ink-medium hover:bg-ink-light disabled:bg-ink-medium`,
    soft: `${soft} text-ink-medium bg-ink-medium/10 hover:bg-ink-light hover:text-surface disabled:bg-ink-medium/10 disabled:text-ink-medium`,
    outline: `${outline} text-ink-medium border-ink-medium hover:bg-ink-medium active:bg-ink-medium active:border-ink-medium disabled:text-ink-medium`,
  },
  white: {
    text: `${text} text-white`,
    solid: 'bg-white text-dark border-transparent disabled:shadow-none',
    soft: `${soft} bg-white/10 text-white hover:bg-white hover:text-dark active:bg-white active:text-dark disabled:bg-surface/10 disabled:text-white`,
    outline: 'text-white border-white hover:bg-surface hover:text-gray-800 active:bg-surface active:border-white active:text-gray-800 disabled:shadow-none disabled:bg-transparent disabled:text-white',
  },
  info: {
    text: `${text} text-info`,
    solid: `${solid} bg-info hover:brightness-110`,
    soft: `${soft} text-info bg-info/10 hover:bg-info hover:text-surface active:bg-info active:text-surface disabled:bg-info/10 disabled:text-info`,
    outline: `${outline} text-info border-info hover:bg-info hover:text-white active:bg-surface active:border-white active:text-gray-800 disabled:shadow-none disabled:hover:text-info`,
  },
  pink: {
    text: `${text} text-pink`,
    solid: `${solid} bg-pink hover:brightness-110`,
    soft: `${soft} text-pink bg-pink/10 hover:bg-pink hover:text-surface active:bg-pink active:text-surface disabled:bg-pink/10 disabled:text-pink`,
    outline: `${outline} text-pink border-pink hover:bg-pink hover:text-white active:bg-surface active:border-white active:text-gray-800 disabled:shadow-none disabled:bg-transparent disabled:text-white`,
  },
  dark: {
    text: `${text} text-dark`,
    solid: `${solid} bg-dark text-white hover:brightness-110`,
    soft: `${soft} text-dark bg-dark/10 hover:bg-dark hover:text-white active:bg-dark active:text-white disabled:bg-dark/10 disabled:text-dark`,
    outline: `${outline} text-dark border-dark hover:bg-dark hover:text-white active:bg-dark active:text-white disabled:shadow-none disabled:bg-transparent disabled:text-dark`,
  },
};


/**
 * @description Object containing the anchors / buttons styles.
 * Take a look at the {@link composeButtonClasses} function for usage.
 */
export const buttonStyles:ButtonStyles = {
  common,
  round,
  sizes,
  colors,
};


/**
 * @description Composer for final classes to be passed to component based on color, size, styling and tailwind classes (className)
 * passed to component as props.
 * @param color
 * @param size
 * @param styling
 * @param rounding
 * @param className
 */
export const composeButtonClasses = (color: string, size: string, styling: string, rounding: string, className: string): string => {
  const colorClasses = buttonStyles.colors[color];
  const sizeClasses = buttonStyles.sizes[size];
  const roundingClasses = buttonStyles.round[rounding];
  const sizeStyle = styling === 'text' ? sizeClasses['text-size'] : `${sizeClasses['text-size']} ${sizeClasses.padding}`;

  let colorStyle = colorClasses.solid;
  if (styling === 'text') {
    colorStyle = colorClasses.text;
  } else if (styling === 'outline') {
    colorStyle = colorClasses.outline;
  } else if (styling === 'soft') {
    colorStyle = colorClasses.soft;
  }

  return `${buttonStyles.common} ${sizeStyle} ${colorStyle}${roundingClasses}${disabledCursor}${className ? ` ${className}` : ''}`;
};
