import { compile } from 'path-to-regexp';

/**
 * @description Converts the values of a pathParams object to strings.
 * @param pathParams - the pathParams object
 * @returns the pathParams object with all values converted to strings
 */
const convertValuesToString = (pathParams: Record<string, string | number>): Record<string, string> => {
  const stringPathParams: Record<string, string> = {};
  Object.keys(pathParams).forEach((key) => {
    stringPathParams[key] = String(pathParams[key]);
  });
  return stringPathParams;
};

/**
 * @description Builds the api path string from a query parameters object.
 * @param path - the path template
 * @param pathParams - the query parameters object. As of version 7.0.0, pathParams values must be strings.
 * (https://github.com/pillarjs/path-to-regexp/releases/tag/v7.0.0)
 * @returns the query string
 *
 * @example
 * const path = '/jobs/{jobId}';
 * const queryParams = { jobId: '123' };
 * const queryString = buildApiPath(path, queryParams); // returns '/jobs/123'
 */
export const buildApiPath = (path: string, pathParams?: Record<string, string | number>): string => {
  if (!pathParams || Object.keys(pathParams).length === 0) return path;
  // Convert {paramName} to :paramName for path-to-regexp compatibility
  const pathTemplate = path.replace(/{([^}]+)}/g, ':$1');
  // Compile the path template into a function
  const toPath = compile(pathTemplate);
  // Generate and return the final path by passing the pathParams object
  return toPath(convertValuesToString(pathParams));
};
