// Stats ******************************************************************************************
// pathname: /v1/subscription/stats
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';


/**
 * @description SWR: fetch premium subscription stats (for My premium page).
 * @param locale string - the locale to use for the request. Default 'ro'.
 * @param serverSideData ApiSubscriptionPackages - the data we got from the server side request (if we do it). Default undefined.
 */
export const useSwrSubscriptionStats = (locale = 'ro', serverSideData?: ApiSchema<'PremiumSubscription'>) => {
  // Get data from API
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiSchema<'PremiumSubscription'>>({
    url: '/v1/subscription/stats',
    locale,
    serverSideData,
    apiOptions: {
      // 1 minute
      dedupingInterval: 60 * 1000,
      keepPreviousData: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
