import { MessagesDropdownInterface } from '@type/header-types';
import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import { Anchor, Spinner, TailwindSpinner } from '@components/common';
import { useSwrApi, ApiLastMessagesOk } from '@hooks/useSwrApi';
import { dataTestId } from '@services/data-test-id';
import { MemoizedMessageInNavbar } from './MessageInNavbar';


/**
 * @description Renders the messages dropdown in the navbar. Popover content.
 * * **closePopover** Function to close the popover
 * * **locale** The locale of the app
 */
export const MessagesDropdownList = (props: MessagesDropdownInterface) => {
  // Props with default values
  const { closePopover, locale = 'ro' } = props;

  // translate
  const { t } = useTranslation('common');


  // Fetch messages with SWR
  // ******************************************
  const {
    data, isValidating, isLoading,
  } = useSwrApi<ApiLastMessagesOk[]>({
    url: '/v1/messages/last-messages',
    urlParams: '?limit=4',
    locale,
    apiOptions: {
      dedupingInterval: 60000, // 1 minute
      revalidateOnFocus: false,
    },
  });

  // Check data and provide a default value if needed
  const messagesData = data !== undefined ? data : [];
  const hasMessages = messagesData.length > 0;


  // Render component
  // ******************************************
  return (
    <div className="relative border-t">

      { /* Header */}
      <h6 className="flex items-center gap-2.5 border-b px-4 py-3 text-lg font-bold">
        {t('messages.title')}
        { /* small spinner - show the loading spinner when validating (updating data in cache) */ }
        { isValidating && !isLoading && <TailwindSpinner className="size-4.5 text-ink-light" /> }
      </h6>

      { /* Big loading spinner - when loading data for the first time and cache is empty */}
      { isLoading && <div className="border-b bg-surface-50 p-4"><Spinner /></div> }

      { /* Has messages */
        !isLoading && hasMessages && messagesData.map((message) => {
          // early return if the message is not valid
          if (!message) return null;

          // notification ok, render it
          return (
            <MemoizedMessageInNavbar message={message} key={message.conversationId} closePopover={closePopover} />
          );
        })
      }

      { /* No messages, the list is empty */
        !hasMessages && !isLoading && (
          <div className="border-b bg-surface-50 p-4">{t('messages.no_result_description')}</div>
        )
      }

      {/* Footer: go to messages page */}
      <div className="text-center">
        <Anchor
          href="app_message_inbox"
          symfonyRoute
          styling="text"
          size="sm"
          className="p-3"
          onClick={closePopover}
          {...dataTestId('navbar.messagesViewAll')}
        >
          {t('header.menu.all_messages')}
        </Anchor>
      </div>

    </div>
  );
};


// Memoization of the component. As long as the props don't change, the component will not be re-rendered.
// https://react.dev/reference/react/memo
// *****************************************************************************************************
export const MessagesDropdown = memo(MessagesDropdownList);
