// RESUME CHECK UNLOCK MESSAGE ************************************************************************
// pathname: /v1/resume-operation/${userId}/check-unlock/message
// ****************************************************************************************************
import {
  ApiCheckUnlockOk,
  ApiResumeCheckUnlockPayload,
  GenericV1ErrorsArray,
  useSwrMutationApi,
} from '@hooks/useSwrApi';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';

interface IProps {
  userId: number;
  rid?: string;
  locale: string;
  successCallback?: () => void;
  errorCallback?: (errors: GenericV1ErrorsArray) => void;
}

/**
 * @description SWR mutation: POST check if the user can unlock a message.
 * @param userId - number. The user id to check.
 * @param rid
 * @param locale
 * @param successCallback
 * @param errorCallback
 */
export const useSwrResumeCheckUnlockMessage = ({
  userId, rid, locale, successCallback, errorCallback,
}: IProps) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiCheckUnlockOk, ApiResumeCheckUnlockPayload>({
    url: `/v1/resume-operation/${userId}/check-unlock/message${rid ? `?rid=${rid}` : ''}`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        // Send Google Tag Manager event
        sendGoogleTagEvent('premium_contact_message', 'Custom');
        // Callback
        if (successCallback) successCallback();
      },
      onError: (errors) => {
        // Callback
        if (errorCallback) errorCallback(errors);
      },
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
