import { ChatBubbleBottomCenterIcon } from '@heroicons/react/24/outline';

export const MainNavbarPlaceholder = () => (
  <div className="flex grow animate-pulse items-center justify-end lg:justify-start lg:py-px">

    { /* Main menu */ }
    <div className="hidden lg:flex lg:grow lg:items-center lg:justify-end lg:gap-7 lg:pl-4">
      <span className="h-3 w-16 rounded bg-surface-200" />
      <span className="h-3 w-16 rounded bg-surface-200" />
      <span className="h-3 w-16 rounded bg-surface-200" />
      <span className="h-3 w-16 rounded bg-surface-200" />
    </div>


    <div className="ml-9 mr-1.5 flex shrink-0 items-center justify-between gap-6 text-surface-300">
      <ChatBubbleBottomCenterIcon className="hidden size-5 stroke-2 lg:block" />
    </div>

    { /* Profile */ }
    <div className="hidden lg:ml-5 lg:flex lg:shrink-0 lg:items-center lg:py-2">
      { /* User */ }
      <div className="flex items-center gap-2">
        <span className="size-10 shrink-0 rounded-full bg-surface-200" />
        <span className="flex shrink-0 flex-col">
          <span className="h-2 w-28 rounded bg-surface-200" />
        </span>
      </div>

      { /* Hamburger menu */ }
      <div className="ml-3.5 flex w-4 flex-col items-center justify-center">
        <span aria-hidden="true" className="my-px h-0.5 w-4 bg-surface-200" />
        <span aria-hidden="true" className="my-0.5 h-0.5 w-4 bg-surface-200" />
        <span aria-hidden="true" className="my-px h-0.5 w-4 bg-surface-200" />
      </div>
    </div>

  </div>
);
