/* eslint-disable max-len */
// IMPORTS ****************************************************************************************
// ************************************************************************************************
import {
  ApiEmployerProfileList,
  ApiEmployerProfileListOk,
  ApiEmployerProfileElement,
  ApiUploadedTextareaFileContent,
  IUploadedTextaareaFileContent,
  ApiUploadedImageContent,
  IUploadedImageContent,
  ApiFullTalentSearch,
} from 'src/types/v1-api-types';


// Employer profile list ******************************************************************
// pathname: /v1/employer/profile-list
// ****************************************************************************************
export const isEmployerProfileListOk = (res: ApiEmployerProfileList | void)
: res is ApiEmployerProfileListOk => res !== null && res !== undefined && Array.isArray(res) && res[0]?.name !== undefined;

// Employer profile details ***************************************************************
// pathname: /v1/employer/{id}
// ****************************************************************************************
export const isEmployerProfileElementOk = (res: ApiEmployerProfileElement | void)
: res is object => res !== null && res !== undefined && Object.keys(res as object)?.length === 0;

// Employer image upload ******************************************************************
// pathname: /v1/employer/image-upload/{type}
// ****************************************************************************************
export const isUploadedImageContentOk = (res: ApiUploadedImageContent | void)
: res is IUploadedImageContent => res !== null && res !== undefined && (res as IUploadedImageContent)?.name !== undefined;

// TinyMCE textarea upload ****************************************************************
// pathname: /v1/tinymce/upload
// ****************************************************************************************
export const isUploadedFileContentOk = (res: ApiUploadedTextareaFileContent | void)
: res is IUploadedTextaareaFileContent => res !== null && res !== undefined && (res as IUploadedTextaareaFileContent)?.name !== undefined;


// Full talent search ****************************************************************
// pathname: /v1/full-talent-search
// ****************************************************************************************
export const isFullTalentSearchOk = (res: ApiFullTalentSearch | void)
: res is [] => res !== null && res !== undefined && Array.isArray(res);
