// USER RESUME DETAIL *****************************************************************************
// pathname: /v2/user-resume-detail/{slug}
// ************************************************************************************************

// Interface
// ********************************************
import { ApiResumeDetailOk, useSwrApi } from '@hooks/useSwrApi';

interface UseSwrUserResumeDetailProps {
  locale: string,
  userSlug: string,
  shouldFetch?: boolean
}

/**
 * @description SWR: fetch user resume detail by slug.
 *
 * * **locale**: string - the locale to use for the request. Default 'ro'.
 * * **userSlug**: string - the user slug to get the resume for.
 * * **shouldFetch**: boolean, optional - fetch data based on condition.
 */
export const useSwrUserResumeDetail = (props: UseSwrUserResumeDetailProps) => {
  // Props destructuring
  // ********************************************
  const {
    locale,
    userSlug,
    shouldFetch = true,
  } = props;

  // SWR: fetch data
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiResumeDetailOk>({
    url: `/v2/user-resume-detail/${userSlug}`,
    locale,
    shouldFetch,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 5, // 5 minutes
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
