import { LOCALES } from '@type/locales';

/**
 * @description get the url without the locale
 * @example
 * getUrlWithoutLocale('/ro/locuri-de-munca') => '/locuri-de-munca'
 * getUrlWithoutLocale('/en/jobs') => '/jobs'
 * getUrlWithoutLocale('/hu/munkak') => '/munkak'
 * getUrlWithoutLocale('/locuri-de-munca') => '/locuri-de-munca'
 *
 * @param url
 */
export const getUrlWithoutLocale = (url: string): string => {
  if (!url.includes('/')) {
    return url;
  }
  const urlParts = url.split('/');
  const locale = urlParts[1];

  if (LOCALES.includes(locale)) {
    urlParts.splice(1, 1);
  }

  return urlParts.join('/');
};
