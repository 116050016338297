import { useEffect, useState, useCallback } from 'react';
import { useSWRConfig } from 'swr';
import { SwrCacheGlobalProps } from '@type/swr';
import { GenericV1ErrorsArray } from '@type/v1-api-types';


/**
 * @description This hook is used to access the global cache of SWR. This provides the same functionality as a global
 * state manager, but for SWR. It is used to access the cached data and errors from SWR without more fetching. The data and errors
 * are always returned as an array, even if there is only one item in the cache. That's because the main use is with SwrInfinite,
 * when we want to access multiple pages of data from the cache. Otherwise, we can just use the normal useSwrApi hook with the added
 * benefit of having no stale data.
 *
 * **You should use this in a Jod detail or Cv detail page to get the next and previous CV slug from the list.**
 *
 * - **url** - the url of the API endpoint. Do not include the locale or the urlParams, because we will use the url to match
 * the starting part of the cache keys.
 * - **urlParams** - the url params of the API endpoint. This is optional, and it is used to narrow the match the cache keys.
 * - **locale** - the locale of the API endpoint. This is optional, and it is used to narrow the match the cache keys. If
 * not provided, it will default to 'ro'.
 *
 * **You can refresh the cache, if it changed in other context, by calling the `revalidateCache` function.**
 *
 * @example
 * // Because the main use is with SwrInfinite, we will use it as an example.
 * import { useSwrCache } from '@hooks/useSwrApi';
 *
 * // Model for getting all the cached pages from an endpoint. It's a good idea to always ignore the limit parameter and the
 * // page parameter, this way you're sure you get all the pages.
 * // *******************************************
 * const { cachedData } = useSwrCache({
 *  url: '/v3/talent-search',
 *  locale: locale,
 * });
 *
 * // Model for getting all the cached pages from an endpoint with urlParams. This time we narrow the match to the cache keys
 * // by providing the relevant urlParams. We will keep ignoring the limit parameter and the page parameter.
 * // *******************************************
 * const { cachedData } = useSwrCache({
 *  url: '/v3/talent-search',
 *  locale: locale,
 *  urlParams: 'sort=distance&lastActivity=365',
 * });
 *
 */
export const useSwrCache = <DataType>(props?: SwrCacheGlobalProps) => {
  // Destructure props
  const {
    url = '',
    urlParams = '',
    locale = 'ro',
  } = props || {};

  // SWR config
  // **********************************
  const { cache } = useSWRConfig();


  // Filter the cache keys
  // **********************************
  const filterKeys = useCallback((key: string) => {
    const urlMatch = `@"${url}`;
    const localeMatch = `,"${locale}",`;

    // Guard against accepting all cache as true
    if (!url && !urlParams) return false;

    return key.startsWith(urlMatch) && key.includes(localeMatch) && (urlParams ? key.includes(urlParams) : true);
  }, [url, locale, urlParams]);


  // Get the desired type of data from the cache
  // **********************************
  const getCacheData = useCallback(
    (keys: (string | undefined)[], dataKey: boolean) => keys.length > 0
      && keys.map((key) => {
        const cachedKey = cache.get(key as string);
        return dataKey ? cachedKey?.data as DataType : cachedKey?.error as GenericV1ErrorsArray;
      })
        .filter((item) => item !== undefined),
    [cache],
  );


  // Data holders
  // **********************************
  const [cachedData, setCachedData] = useState<DataType[] | undefined>(undefined);
  const [cachedErrors, setCachedErrors] = useState<GenericV1ErrorsArray[] | undefined>(undefined);


  // Get the cached data
  // **********************************
  const revalidateCache = useCallback(() => {
    // Get the keys array
    const getKeysArray = () => Array
      .from(cache.keys(), (key) => (filterKeys(key) ? key : undefined))
      .filter((item) => item !== undefined);

    // Get the data
    const getData = (keysArray: (string | undefined)[]) => {
      const data = getCacheData(keysArray, true);
      const errors = getCacheData(keysArray, false);

      setCachedData(data && data.length > 0 ? data as DataType[] : undefined);
      setCachedErrors(errors && errors?.length && errors.length > 0 ? errors as GenericV1ErrorsArray[] : undefined);
    };

    // Set the returned data
    getData(getKeysArray());
  }, [cache, filterKeys, getCacheData]);

  // Get the cached data on mount
  useEffect(() => {
    revalidateCache();
  }, [revalidateCache]);


  // Return the cached data
  // **********************************
  return {
    cachedData,
    cachedErrors,
    revalidateCache,
  };
};
