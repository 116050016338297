// RESUME SET STATUS **********************************************************************************
// pathname: /v2/resume-operation/${userSlug}/status/${status}
// ****************************************************************************************************
import {
  ApiCheckUnlockOk, ApiResumeOperationJobPayload,
  GenericV1ErrorsArray,
  useSwrMutationApi,
} from '@hooks/useSwrApi';
import { SwrToastsProps } from '@type/swr';

// Interface
// ********************************************
interface SwrResumeSetStatusProps {
  userSlug: string,
  status: number,
  locale: string,
  successCallback?: (response: ApiCheckUnlockOk) => void,
  errorCallback?: (errors: GenericV1ErrorsArray) => void,
  toastsOptions?: SwrToastsProps,
  trackingId?: string,
}

/**
 * @description SWR mutation: Set applicant status.
 *
 * * **userSlug**: string. The user slug.
 * * **status**: number. Desired status.
 * * **locale**: string. Locale to use for the request.
 * * **successCallback**: function, optional.
 * * **errorCallback**: function, optional.
 * * **toastsOptions**: SWR toasts options, optional.
 * * **trackingId**: string, optional. The tracking id for the event.
 */
export const useSwrResumeSetStatus = (props: SwrResumeSetStatusProps) => {
  // Props destructuring
  // ********************************************
  const {
    userSlug,
    status,
    locale,
    successCallback,
    errorCallback,
    toastsOptions,
    trackingId,
  } = props;

  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiCheckUnlockOk, ApiResumeOperationJobPayload>({
    url: `/v2/resume-operation/${userSlug}/status/${status}`,
    urlParams: trackingId ? `?rid=${trackingId}` : '',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: (response) => {
        // Callback
        if (successCallback) successCallback(response);
      },
      onError: (errors) => {
        // Callback
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      showErrorToast: true,
      ...toastsOptions,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
