// Delete a conversation  ******************************************************************
// pathname: v1/conversations/{id}}
// *****************************************************************************************
import { useSwrMutationApi } from '@hooks/useSwrApi';
import { useTranslation } from 'next-i18next';


/**
 * @description SWR mutation: Delete a conversation.
 * @param conversationId - number. The conversation Id.
 * @param locale - string. The locale to use for the request. Default: 'ro'.
 */


// ********************************************
export const useSwrDeleteConversation = (conversationId: number, locale = 'ro') => {
  // Translation
  const { t } = useTranslation('common');

  // SWR wrapper
  const {
    data, error, trigger, reset, isMutating,
  } = useSwrMutationApi<object, undefined>({
    url: `/v1/conversations/${conversationId}`,
    locale,
    fetcherConfig: {
      method: 'DELETE',
    },
    toastsOptions: {
      showSuccessToast: true,
      successToastText: t('msg-conversation-deleted'),
      showErrorToast: true,
    },
  });

  // return data
  return {
    data,
    error,
    trigger,
    reset,
    isMutating,
  };
};
