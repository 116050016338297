// SPOKEN LANGUAGES LEVELS **************************************************************************************
// pathname: /v1/static-lists/spoken-languages-levels?objectResponse=1
// ************************************************************************************************
import { useContext } from 'react';
import { LanguagesListAndLevelsContext } from '@components/contexts/LanguagesListAndLevels';
import { useSwrApi } from 'src/hooks/useSwrApi';
import { ApiSpokenLanguageLevelsObj, ApiSpokenLanguageLevelsStr } from '@utils/data-fetching';


// Conditional type for the data
// ************************************
type LocalType<T> = T extends ApiSpokenLanguageLevelsObj ? ApiSpokenLanguageLevelsObj : ApiSpokenLanguageLevelsStr;

// SWR: fetch static spoken languages levels
// usage: const { data, error, ... } = useSwrSpokenLanguagesLevels(locale);
// ********************************************
/**
 * @description SWR: fetch static spoken languages levels. We use a conditional type to return the correct type for the data.
 * @param locale - string, the locale
 * @param asItemsArray - boolean, if true, the data will be returned as an array of objects (param objectResponse=1)
 *
 * @example
 * // if you want the data as a string array with ApiSpokenLanguageLevelsStr type
 * const { data, error, ... } = useSwrSpokenLanguagesLevels(locale);
 *
 * // if you want the data as an array of objects with ApiSpokenLanguageLevelsObj type
 * const { data, error, ... } = useSwrSpokenLanguagesLevels<ApiSpokenLanguageLevelsObj>(locale, true);
 */
export const useSwrSpokenLanguagesLevels = <T>(locale: string, asItemsArray = false) => {
  // Get the list from the context if available
  const { spokenLanguagesLevels } = useContext(LanguagesListAndLevelsContext);

  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<LocalType<T>>({
    url: '/v1/static-lists/spoken-language-levels',
    urlParams: asItemsArray ? '?objectResponse=1' : '',
    locale,
    serverSideData: spokenLanguagesLevels,
    apiOptions: {
      keepPreviousData: true,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 60 * 24, // 24 hours, the data is not supposed to change
    },
  });

  // Return data
  return {
    data: data !== undefined ? data : [],
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
