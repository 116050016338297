import { useEffect, useState } from 'react';
import { fetcher, ResponseWithHeaders } from '@utils/data-fetching';
import { flattenObj, getWithFlatKeys } from 'src/utils';
import {
  DefaultsObjectInArrayInterface,
  UseDefaultValuesInterface,
  LangTypeInterface,
  GenericStoredValuesInterface,
  FormStatusInterface,
  FormDataInterface,
  GetValuesFromAPIInterface,
  SetFlatFormValues,
} from 'src/types/form-types';

/**
 * @description Populate the form with data from server - **withDefaultValues** option in {@link useBjForm}
 *
 * @description Options are:
 * * **null** (default) - no form values
 * * **object** - directly pass an object with the form field's values.
 * Best to use Next.js **'getServerSideProps()'** - it will be server rendered data.
 * Otherwise, you can pass your custom-built object.
 * * **array** - pass an array containing a single object with the *reqURL* & *dataKey* (details below):
 * * reqURL (required) - the URL for the requests
 * * dataKey (optional) - in case the form data is inside a response's key
 *
 * ---------------------------
 *
 * @description **Returned functions:**
 * * {@link getValuesFromAPI} - API call function for getting form's values.
 * * {@link storedDefaultValues} - Store the values making a server side API call
 * * {@link formValues} - Store the values making a client side API call
 * * {@link setFormValues} - Set the values stored inside **formValues**
 * * {@link getValuesStatus} - Store the status of the client side call
 * * {@link isFetchingFormData} - The data fetching status. Boolean.
 *
 * ---------------------------
 *
 * @example
 * // standalone with API
 * const { storedDefaultValues, formValues, getValuesStatus } = useGetFormValues(locale, [{
 *   reqURL: `${apiURL}/v1/resume/experience/163`,
 *   dataKey: 'data', // you might get back a response object like this {schema: ..., data: ...}, but you only need the stuff from 'data'
 * }]);
 *
 * // standalone via object (you can obtain this object using getServerSideProps for example)
 * // don't worry about nesting, it will be automatically flattened to {user.firstName: ..., user.lastName: ...}
 *  const theUser = {
 *    user: {
 *      firstName: 'John',
 *      lastName: 'Doe',
 *    }
 *  }
 *
 *  const { storedDefaultValues, formValues, getValuesStatus } = useGetFormValues(locale, theUser);
 *
 * @param langCode
 * @param formDefaultValues
 *
 * @remarks The values object will be flattened automatically using the {@link flattenObj} function from utils
 */

export const useGetFormValues = (langCode: LangTypeInterface = undefined, formDefaultValues: UseDefaultValuesInterface = null) => {
  // The default values for elements (object or null)
  // these will be sent to useFormRecord and used as default values
  const storedDefaultValues = formDefaultValues && typeof formDefaultValues === 'object' && !Array.isArray(formDefaultValues)
    ? flattenObj(formDefaultValues) as GenericStoredValuesInterface // is object
    : null; // is null

  // boolean to see if we need to get the default values from API (we got an array)
  const valuesFromAPI = formDefaultValues && Array.isArray(formDefaultValues);

  let reqURL = '';
  let dataKey = '';
  let putInKey = '';

  // if we have an array, reassign the previously declared variables
  if (valuesFromAPI) {
    const propsObj = formDefaultValues[0] as DefaultsObjectInArrayInterface;
    reqURL = propsObj.reqURL;
    dataKey = propsObj.dataKey ? propsObj.dataKey : '';
    putInKey = propsObj.putInKey ? propsObj.putInKey : '';
  }

  // Store the values making a client side API call using 'useBjForm' defaultValues option
  // The object will be already flattened
  const [formValues, setFormValues] = useState({});

  // Store the status of the client side call
  const [getValuesStatus, setGetValuesStatus] = useState<FormStatusInterface>({});

  // Store the data fetching status
  const [isFetchingFormData, setIsFetchingFormData] = useState(false);


  // Function that flattens an object and sets it as form values.
  // It does nothing to an already flattened object.
  const setFlatFormValues: SetFlatFormValues = (obj = {}) => setFormValues(flattenObj(obj));


  /**
   * @description API call function for getting form's values. The function is using the fetcher, so there is no need to
   * provide the domain in the URL.
   * @description If you're using the function directly remember to declare states for
   * {@link formValues}: object and {@link getValuesStatus}: <FormStatusInterface>
   * @param URL
   * @param lang
   * @param childKey
   * @param parentKey if you want to put the response as a value of a key
   * @param method
   */
  const getValuesFromAPI: GetValuesFromAPIInterface = (URL, lang, childKey, parentKey = '', method = 'GET') => {
    // trigger the loading state
    setIsFetchingFormData(true);

    // make the call
    void fetcher(URL, lang, {
      method,
      withResponseHeaders: true,
    }).then(<T extends object>(res: unknown) => {
      // reset the loading state
      setIsFetchingFormData(false);

      // Destructure the response
      const {
        response, status, ok, statusText,
      } = res as ResponseWithHeaders<unknown>;

      // Handle success response
      if (ok) {
        // get the data from the response
        let apiFormValues = response as T;

        // if the form values are stored inside another object of data and not as direct children
        // get that value with flatKeys
        if (childKey && getWithFlatKeys(apiFormValues, childKey)) {
          apiFormValues = getWithFlatKeys(apiFormValues, childKey) as T;
        }

        if (parentKey) {
          apiFormValues = { [parentKey]: apiFormValues } as T;
        }

        // add elements to info state
        setGetValuesStatus({
          actionStatus: 'success',
          form: parentKey ? { [parentKey]: flattenObj(apiFormValues) } : flattenObj(apiFormValues),
          status,
          statusText,
        });

        // Flatten the object and add data to values
        setFormValues(flattenObj(apiFormValues));
      } else {
        setGetValuesStatus({
          actionStatus: 'error',
          status,
          statusText,
          data: response as FormDataInterface,
        });
      }
    }).catch((error: Error) => {
      setIsFetchingFormData(false);
      // set the form status for unexpected errors
      setGetValuesStatus({
        actionStatus: 'unexpected error',
        error,
      });
    });
  };


  // make the API call
  useEffect(() => {
    if (valuesFromAPI) {
      getValuesFromAPI(reqURL, langCode as string, dataKey, putInKey);
    }
  }, [dataKey, putInKey, langCode, reqURL, valuesFromAPI]);


  return {
    getValuesFromAPI,
    storedDefaultValues,
    formValues,
    setFormValues,
    setFlatFormValues,
    getValuesStatus,
    isFetchingFormData,
  };
};
