import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SocketOnlineStoreState } from '@type/redux';
import { ISocketCallParticipantData } from '@type/socket-online';

// Define the initial state
const initialState: SocketOnlineStoreState = {
  talentParticipant: undefined, // details of the talent participant in the call
  hasTalentAnswered: false, // is subscriber available
};

// Socket online reducers and actions
export const socketOnlineSlice = createSlice({
  name: 'socketOnline',
  initialState,
  reducers: {
    // save talent participant
    saveTalentParticipant: (state, action: PayloadAction<ISocketCallParticipantData>) => {
      state.talentParticipant = action.payload;
    },
    // reset talent participant
    clearTalentParticipant: (state) => {
      state.talentParticipant = initialState.talentParticipant;
    },
    // has talent answered
    setHasTalentAnswered: (state, action: PayloadAction<boolean>) => {
      state.hasTalentAnswered = action.payload;
    },
    clearState: (state) => {
      state.talentParticipant = initialState.talentParticipant;
      state.hasTalentAnswered = initialState.hasTalentAnswered;
    },
  },
});

export default socketOnlineSlice.reducer;
