// Imports
// *****************************************************
import { useEffect, useState } from 'react';
import { HeaderInterface } from 'src/types/header-types';
import { useInView } from 'react-intersection-observer';
import { AlertMessage } from '@components/common';
import { useTranslation } from 'next-i18next';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { TopBannersHub } from '@components/features/banners';
import { MainNavbar } from './MainNavbar/MainNavbar';
import { useHeaderInfoData } from './MainNavbar/data/useHeaderInfoData';
import { setMenuTopOffset } from './MainNavbar/config';


/**
 * @description Header component. If you want to render banners that are not sticky, just place them
 * outside the header tag. Anything placed inside the header tag will be sticky.
 * * **navbarTheme** - the theme of the navbar. Can be 'default' or 'transparent' atm.
 *
 * @example
 * // Place a non-sticky alert or banner at the top of the page.
 * // It will not scroll down with the header.
 * <>
 *   <Banner />
 *   <Header ... />
 * </>
 *
 * // Place a sticky alert or sticky banner. It will scroll down with the header.
 * <Header ... >
 *   <AlertMessage />
 *   <MainNavbar />
 * </Header>
 *
 * // Place a search bar inside the header
 * <Header ... >
 *   <MainNavbar />
 *   <SearchBar />
 * </Header>
 *
 */
export const Header = (props: HeaderInterface) => {
  // Main component props with default values
  const { navbarTheme, navbarType = 'main', withoutAlert } = props;

  // translate
  const { t } = useTranslation('common');


  // Detect if the header is pinned (stuck) or not
  // This hook also creates a ref for the pinned element, in our case the header. If needed, you can merge
  // the refs using the 'mergeRefs' function
  // *********************************************************************
  const [pinnedRef, notPinned] = useInView({
    threshold: 1,
  });


  // Get current route; needed to highlight the current route in the menu for React pages
  // Get current locale
  // *********************************************************************
  const { pathname, locale } = useExtendedRouter();

  // Get the header data
  // The data is not language dependent, but I agreed with Levi that it's safer to do it anyway,
  // because the data is not that big, and it's not like the users are changing the language every minute.
  // *********************************************************************
  const { headerInfoData, headerInfoLoading, headerInfoValidating } = useHeaderInfoData(locale);

  const [unpaidOrderMessage, setUnpaidOrderMessage] = useState<string>('');

  // MainNavbar props. Just destructure it on component
  // *********************************************************************
  const mainNavbarProps = {
    headerInfo: headerInfoData,
    headerInfoLoading,
    headerInfoValidating,
    navbarTheme,
    currentRoute: pathname,
    notPinned,
    locale,
  };

  useEffect(() => {
    if (headerInfoData?.ordersNotificationData && headerInfoData?.ordersNotificationData?.total) {
      let unpaidMessage = headerInfoData?.ordersNotificationData?.total > 1 ? t('complex-unpaid.multi-invoice.stripe') : t('complex-unpaid.single-invoice.stripe');

      unpaidMessage = unpaidMessage
        .replace('%nr%', String(headerInfoData?.ordersNotificationData?.total))
        .replace('%due_date%', headerInfoData?.ordersNotificationData?.oldestDueDate ?? '');
      if (headerInfoData?.ordersNotificationData?.amountCurrencyGroups?.length) {
        unpaidMessage = unpaidMessage
          .replace('%amount_currency%', headerInfoData?.ordersNotificationData?.amountCurrencyGroups[0]);
      }

      setUnpaidOrderMessage(unpaidMessage);
    }
  }, [headerInfoData?.ordersNotificationData, t]);


  // Calculate header height + offset for the menu. When having banners or alerts,
  // with show/hide conditions, add them to the dependency array.
  useEffect(() => {
    setMenuTopOffset();
  }, [headerInfoData, unpaidOrderMessage]);


  // Render the Header
  // *********************************************************************
  return (
    <header id="header" ref={pinnedRef} className="sticky -top-px z-10 print:hidden">
      { !withoutAlert && <TopBannersHub /> }

      {/* Alert 'Unpaid orders' message */
        unpaidOrderMessage !== '' && (
          <AlertMessage
            urlPath="app_order_list"
            symfonyRoute
            symfonyParams={{ unpaid: 1 }}
            message={unpaidOrderMessage}
            type="error"
            ctaText={t('global.alert.unpaid.invoices.action')}
          />
        )
      }
      {/* Main Navbar */}
      {navbarType === 'main' && <MainNavbar {...mainNavbarProps} />}
    </header>
  );
};
