import { forwardRef } from 'react';
import { ExtractProps } from '@type/utils/getComponentPropTypes';
import { Menu as HeadlessMenu } from '@headlessui/react';
import { DropdownItems } from './DropdownPanel';


// ROOT COMPONENT
// Change the default component tag from "Fragment" to "div".
// *******************************************************************************************
const DropdownRoot = forwardRef<
HTMLDivElement,
ExtractProps<typeof HeadlessMenu>
>((props, ref) => (
  <HeadlessMenu as="div" className="relative" ref={ref} {...props} />
));


// EXPORT COMPONENT WITH DOT NOTATION
// look at the types in library here: node_modules/@headlessui/react/dist/components/menu/menu.d.ts
// *******************************************************************************************
/**
 * @description Customized Menu component. It has a default Items component that is customized
 * with animations, positions and UI styles. This component is extended from the HeadlessUI Menu,
 * so it has all the same props and types (and some extras as previously said).
 *
 * If you need to show the dropdown in a parent with overflow: hidden, or you want to detect the screen margins and flip
 * the dropdown panel position, you can use the HeadlessFloat component. Refer to Headless UI Float documentation for more
 * info here: https://headlessui-float.vercel.app/react/quick-start.html.
 *
 * @example
 * <Dropdown>
 *
 *   // Toggle: model for Menu.Button using the prop "as" to render our own component
 *   <Dropdown.Button as={Button} styling="text" size="sm" color="Ink" className="outline-0">
 *     Toggle dropdown
 *   </Dropdown.Button>
 *
 *   // Items: this is the customized Menu.Items component. Extra props (from the original
 *   // HeadlessUI Menu.Items component) are: "animation", "position" and "ui".
 *   // Using ui="none" will reset all the classes from the component, you will have to add your own.
 *   // we inferred the types from original-component
 *   <Dropdown.Items animation="slideUp" position="left" className="mt-1">
 *
 *     // On click this will close the dropdown
 *     <Dropdown.Item as={LinkStyled} href="/some-page" styling="text" color="secondary">
 *       Link in dropdown
 *     </Dropdown.Item>
 *
 *   </Dropdown.Items>
 * </Dropdown>
 *
 *
 * // If you need to show the dropdown in a parent with overflow: hidden, or you want to detect the screen margins and flip
 * // the dropdown panel position, you can use the HeadlessFloat component.
 * <Dropdown>
 *   <HeadlessFloat animation="slideDown" strategy="fixed">
 *
 *     <Dropdown.Button as={Button} styling="text" size="sm" color="Ink" className="outline-0">
 *       Toggle dropdown
 *     </Dropdown.Button>
 *
 *     <Dropdown.Items position="float" ui="float" className="mt-1">
 *       <Dropdown.Item as={LinkStyled} href="/some-page" styling="text" color="secondary">
 *         Link in dropdown
 *       </Dropdown.Item>
 *     </Dropdown.Items>
 *
 *   </HeadlessFloat>
 * </Dropdown>
 */
export const Dropdown = Object.assign(DropdownRoot, {
  Button: HeadlessMenu.Button,
  Items: DropdownItems,
  Item: HeadlessMenu.Item,
});
