import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { MedalSolidIcon, HeroIconType } from '@components/common/Icons';
import {
  QuestionMarkCircleIcon,
  StarIcon,
} from '@heroicons/react/24/solid';
import { Tooltip } from '@components/common/Tooltip/GlobalTooltip';
import { badgeWrapperClasses, badgeIconClasses } from './styles/avatarBadgeStyles';


// Types
// *****************************************************
type AvatarBadgeProps = {
  size?: 'none' | 'sm' | 'md' | 'base' | 'lg' | 'xl',
  className?: string,
  iconClass?: string,
  iconName?: 'medal' | 'star',
  withRing?: boolean,
  withTooltip?: boolean,
  tooltipText?: string,
};


/**
 * @description Avatar Badge component. It displays a badge with an icon and a tooltip.
 * The badge can have a ring around it and the icon can be one of the following: default, star, medal.
 *
 * - **size:** 'none' | 'sm' | 'md' | 'base' | 'lg' | 'xl' - the badge's size
 * - **className:** string - additional classes for the badge
 * - **iconClass:** string - additional classes for the icon
 * - **iconName:** 'medal' | 'star' - the icon to display
 * - **withRing:** boolean - whether to add a ring around the badge
 * - **withTooltip:** boolean - whether to show a tooltip on hover; default: true
 * - **tooltipText:** string - the text to display in the tooltip, if you want to override the default text;
 * The text is already using the translation hook, so you can use the translation keys directly.
 *
 * @example
 * // Add a medal badge to the avatar
 * <div className="relative">
 *    <Avatar.Badge withRing iconName="medal" />
 *    <Avatar withRing name="Gigi" />
 * </div>
 *
 * // Customize the avatar with a star badge
 * <div className="relative">
 *    <Avatar.Badge
 *      withRing
 *      iconName="star"
 *      className="!-right-1 !bottom-auto top-0 !bg-secondary text-white !outline-surface !ring-surface"
 *    />
 *    <Avatar
 *      size="base"
 *      withRing
 *      imgPath={avatar_winnie.src}
 *      className="!outline-2 !outline-offset-3 !outline-secondary"
 *    />
 * </div>
 *
 * // Add a tooltip to the badge
 * <div className="relative">
 *   <Avatar.Badge
 *     withRing
 *     iconName="medal"
 *     tooltipText="Some text here"
 *   />
 *   <Avatar
 *     withRing name="Gigi"
 *   />
 * </div>
 */
export const AvatarBadge = (props: AvatarBadgeProps) => {
  // Component props
  const {
    size = 'base',
    className = '',
    iconClass = '',
    withRing = false,
    iconName = 'default',
    withTooltip = true,
    tooltipText,
  } = props;

  const { t } = useTranslation('common');


  // List of icons to render
  // *****************************************
  const iconList = useMemo(() => ({
    default: QuestionMarkCircleIcon,
    star: StarIcon,
    medal: MedalSolidIcon as HeroIconType,
  }), []);


  // Texts for the tooltip
  // *****************************************
  const textList = useMemo(() => ({
    default: 'Tooltip text',
    star: 'cv.completed.badge',
    medal: 'premium.badge.tooltip',
  }), []);


  // Get the icon from the list
  // *****************************************
  const IconName = iconList[iconName] || iconList.default;


  // Render component
  // *****************************************
  return (
    <span className={badgeWrapperClasses(size, withRing, className)}>
      {
        withTooltip ? (
          <Tooltip
            tooltipText={t(tooltipText || textList[iconName])}
            position="top"
            className="flex size-full items-center justify-center"
            triggerContainerClass="flex items-center justify-center w-full h-full"
            contentSizeClass="max-w-48"
            floating
          >
            <IconName className={badgeIconClasses(size, iconClass)} />
          </Tooltip>
        ) : (
          <IconName className={badgeIconClasses(size, iconClass)} />
        )
      }
    </span>
  );
};
