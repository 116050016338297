// IMPORTS ****************************************************************************************
// ************************************************************************************************

import { ApiUserSearch, UserSearch } from '@type/v1-api-types/user-search';


// OWN RESUME DETAIL ******************************************************************************
// pathname: /v1/conversations/user/{user_slug}
// ************************************************************************************************


export const isApiUserSearchOk = (res: ApiUserSearch | void)
: res is UserSearch => res !== undefined && res !== null && (res as UserSearch)?.recentSearches !== undefined;
