// Types
import { I18nBasePaths } from 'src/types/i18n-paths';

/**
 * @description Edit the project's translated URLs here.
 * @description This is the object in which we will input the translated URLs in a humanly readable
 * form. Keep in mind that this object will be further transformed with helper functions in way that
 * will make it easy to use inside middleware, language switcher and url generator. You will not
 * need extra rewrites/redirects, it will be all handled from here.
 *
 * @example The object has the following properties:
 * {
 *   'url to actually render': {
 *     ro: 'path in browser for ro',
 *     en: 'path in browser for en',
 *     hu: 'path in browser for hu',
 *   }
 * }
 *
 * // some real life example
 * {
 *   '/marketing/salary/some-page': {
 *     ro: '/marketing/nume-pagina',
 *     en: '/marketing/page-name',
 *     hu: '/marketing/oldalnev'
 *   }
 * }
 *
 * @remarks Please keep in mind that the [path in browser for ...] doesn't need a lang prefix in the URL.
 */
export const i18nBasePaths: I18nBasePaths = {
  // this is for testing purposes
  // '/temp/translate-url/localized-url': {
  //   ro: '/url-localizat',
  //   en: '/url-localized',
  //   hu: '/url-lokalizalt',
  // },
  // this is for testing purposes
  // '/temp/translate-url/:id': {
  //   ro: '/url-localizat/:id',
  //   en: '/url-localized/:id',
  //   hu: '/url-lokalizalt/:id',
  // },
  '/campaigns/cv-worth/:id': {
    ro: '/valoare-cv/:id',
    en: '/cv-worth/:id',
    hu: '/cv-ertek/:id',
    de: '/cv-wert/:id',
    fr: '/cv-valeur/:id',
    it: '/cv-valore/:id',
    es: '/cv-valor/:id',
  },
  '/campaigns/cv-worth': {
    ro: '/valoare-cv',
    en: '/cv-worth',
    hu: '/cv-ertek',
    de: '/cv-wert',
    fr: '/cv-valeur',
    it: '/cv-valore',
    es: '/cv-valor',
  },
  // suffix the parameter with an asterisk (*) to denote a zero or more parameter matches.
  '/job/list/location-:location{/*otherParams}': {
    ro: '/locuri-de-munca-in-:location{/*otherParams}',
    en: '/jobs-in-:location{/*otherParams}',
    hu: '/allasok-:location{/*otherParams}',
    de: '/stellenangebote-in-:location{/*otherParams}',
    fr: '/emplois-a-:location{/*otherParams}',
    it: '/lavori-a-:location{/*otherParams}',
    es: '/trabajos-en-:location{/*otherParams}',
  },
  //
  '/job/list{/*params}': {
    ro: '/locuri-de-munca{/*params}',
    en: '/jobs{/*params}',
    hu: '/allasok{/*params}',
    de: '/stellenangebote{/*params}',
    fr: '/emplois{/*params}',
    it: '/lavori{/*params}',
    es: '/trabajos{/*params}',
  },
  '/job/:jobSlug': {
    ro: '/loc-de-munca/:jobSlug',
    en: '/job/:jobSlug',
    hu: '/allas/:jobSlug',
    de: '/stellenangebot/:jobSlug',
    fr: '/emploi/:jobSlug',
    it: '/lavoro/:jobSlug',
    es: '/trabajo/:jobSlug',
  },
};
