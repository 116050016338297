import { ExtractProps } from '@type/utils/getComponentPropTypes';
import { Popover as HeadlessPopover } from '@headlessui/react';
import { PopoverStyledRootPanel } from './PopoverStyledPanel';


// ROOT COMPONENT
// *******************************************************************************************
const PopoverStyledRoot = (props: ExtractProps<typeof HeadlessPopover>) => (
  <HeadlessPopover className="relative" {...props} />
);


// EXPORT COMPONENT WITH DOT NOTATION
// look at the types in library here: node_modules/@headlessui/react/dist/components/popover/popover.d.ts
// *******************************************************************************************
/**
 * @description Customized Popover component. It has a default Panel component that is customized
 * with animations, positions and UI styles. This component is extended from the HeadlessUI Popover,
 * so it has all the same props and types (and some extras as previously said).
 *
 * If you need to show the popover in a parent with overflow: hidden, or you want to detect the screen margins and flip
 * the popover panel position, you can use the HeadlessFloat component. Refer to Headless UI Float documentation for more
 * info here: https://headlessui-float.vercel.app/react/quick-start.html.
 *
 * @example
 * <PopoverStyled>
 *
 *   // Toggle: model for Popover.Button using the prop "as" to render our own component
 *   <PopoverStyled.Button as={Button} styling="text" size="sm" color="ink" className="outline-0">
 *     Toggle popover
 *   </PopoverStyled.Button>
 *
 *   // Panel: this is the customized Popover.Panel component. Extra props (from the original
 *   // HeadlessUI Popover.Panel component) are: "animation", "position" and "ui".
 *   // Using ui="none" will reset all the classes from the component, you will have to add your own.
 *   // we inferred the types from original-component
 *   <PopoverStyled.Panel animation="slideUp" position="left" className="p-4">
 *      <h3 className="text-2xl">Panel content</h3>
 *      <p className="text-lg">Lorem ipsum dolor sit amet.</p>
 *
 *      // On click this will not close the popover
 *      <LinkStyled href="/some-page" styling="text">
 *        Some link
 *      </LinkStyled>
 *
 *      // On click this will close the popover (this is in the HeadlessUi Popover documentation)
 *      <PopoverStyled.Button as={LinkStyled} href="/another-page" styling="text">
 *        Another link
 *      </PopoverStyled.Button>
 *   </PopoverStyled.Panel>
 *
 * </PopoverStyled>
 *
 *
 * // If you need to show the popover in a parent with overflow: hidden, or you want to detect the screen margins and flip
 * // the popover panel position, you can use the HeadlessFloat component.
 * <PopoverStyled>
 *   <HeadlessFloat animation="slideDown" strategy="fixed">
 *
 *     <PopoverStyled.Button as={Button} styling="text" size="sm" color="ink" className="outline-0">
 *       Toggle popover
 *     </PopoverStyled.Button>
 *
 *     <PopoverStyled.Panel position="float" ui="float" className="p-4 w-max">
 *       <h3 className="text-2xl">Panel content</h3>
 *       <p className="text-lg">Lorem ipsum dolor sit amet.</p>
 *     </PopoverStyled.Panel>
 *
 *   </HeadlessFloat>
 * </PopoverStyled>
 */
export const PopoverStyled = Object.assign(PopoverStyledRoot, {
  Button: HeadlessPopover.Button,
  Panel: PopoverStyledRootPanel,
  Overlay: HeadlessPopover.Overlay,
  Group: HeadlessPopover.Group,
});
