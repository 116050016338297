
import {
  useSwrMutationApi,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: POST subscription order. Used when clicking on the 'Order subscription' button.
 * @param locale
 */
// export const useSwrMutationSubscriptionOrder = (locale = 'ro') => {
export const useSwrAddPaymentCard = (locale = 'ro') => {
  // SWR mutation: fetch order
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiSchema<'AddCardResponse'>, undefined>({
    url: '/v1/payment/add-credit-card',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
