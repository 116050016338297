import { EmployerResume, EmployerResumeUnlockResponse } from '@type/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state
const initialState: EmployerResume = {
  commentUnlockResponse: undefined,
};

// Create the slice
export const resumeSlice = createSlice({
  name: 'employerResume',
  initialState,
  reducers: {
    saveCommentUnlockResponse: (state, action: PayloadAction<EmployerResumeUnlockResponse>) => {
      state.commentUnlockResponse = action.payload;
    },
    clearCommentUnlockResponse: (state) => {
      state.commentUnlockResponse = initialState.commentUnlockResponse;
    },
  },
});

// Export the actions
export const {
  saveCommentUnlockResponse,
  clearCommentUnlockResponse,
} = resumeSlice.actions;


// Export the reducer
export const resumeReducer = resumeSlice.reducer;
