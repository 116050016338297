import { Anchor, LinkStyled } from '@components/common';
import { useTranslation } from 'next-i18next';
import { LogoBjLink } from '@components/layout/LogoBj/LogoBjLink';
import { useAppSelector } from 'src/app';
import { route } from '@services/symfony';
import { AppDownload, SocialMedia } from './partials/index';


export const Footer = () => {
  const { t } = useTranslation('common');
  const currentYear = new Date().getFullYear();

  // Add job is allowed only for not logged or company users. We need this to check if we need to switch account
  const headerInfo = useAppSelector((state) => state.user.headerInfo);
  const notTalent = !headerInfo || (headerInfo && headerInfo.role !== 'USER');

  // partners
  const bjPartners = [
    {
      name: 'Vivre',
      url: 'https://www.vivre.ro/?utm_source=bestjobs&utm_medium=web&utm_campaign=link-exchange',
    },
    {
      name: 'Hip*',
      url: 'https://www.hipstar.eu/ro?utm_source=vivre&utm_medium=menu_link',
    },
  ];

  // footer links
  const footerMenu = [
    {
      name: 'menu.sitemap',
      url: 'sitemap_jobs',
      urlParams: {},
      type: 'symfony',
    },
    {
      name: 'menu.support',
      url: 'https://support.bestjobs.eu/en/knowledge',
      urlParams: {},
      type: 'external',
    },
    {
      name: 'menu.conditions',
      url: '/legal/terms',
      urlParams: {},
      type: 'react',
    },
    {
      name: 'menu.confidentiality',
      url: '/legal/confidentiality',
      urlParams: {},
      type: 'react',
    },
    { // add job not allowed for talent; if logged in and talent, switch account to company
      name: 'menu.add-job',
      url: notTalent ? 'job_add' : 'account_user_type',
      urlParams: notTalent ? {} : { dest: route('job_add') },
      type: 'symfony',
    },
    {
      name: 'menu.pricing',
      url: '/company/offer',
      urlParams: {},
      type: 'react',
    },
    {
      name: 'menu.create-employer-account',
      url: '/register/recruiter',
      urlParams: {},
      type: 'external',
    },
  ];

  return (
    <footer className="border-t bg-surface print:hidden">
      {/*
        For desktop, we assume 3 rows (lg:row-span-3) and 12 columns; this way (3 rows) the Partners
        link can go right under the BjLogo, otherwise, if we assume 2 rows, the height will be
        split and there will be a lot of space between the logo and partner links
       */}
      <div className="container grid grid-cols-2 gap-x-7 py-8 lg:grid-cols-12">

        {/* Bestjobs logo - if logged-in redirect to jobs (talent/company) pages */}
        <div className="mb-6 lg:col-span-2 lg:mb-2.5">
          <LogoBjLink
            headerInfo={headerInfo}
            className="-mt-1 mr-auto lg:mr-6"
            imageClassName="w-29"
          />
        </div>

        {/* BJ Partners - iterate through 'bjPartners' array */}
        <ul className="col-span-2 grid grid-flow-col border-t py-4 lg:order-last lg:grid-flow-row lg:place-items-start lg:border-t-0 lg:py-0">
          {
            bjPartners.map((partner, index) => (
              <li key={partner.name} className={`${index === 0 ? '' : 'border-l lg:border-l-0 '}text-center lg:py-1.5`}>
                <Anchor href={partner.url} size="sm" styling="text" color="ink-medium" target="_blank">
                  {partner.name}
                </Anchor>
              </li>
            ))
          }
        </ul>

        {/*
          Footer links - iterate through 'footerMenu' array; we're using css columns (columns-2)
          which is displaying the content in columns, vertical direction
        */}
        <ul className="col-span-2 columns-2 gap-x-7 border-t py-4 lg:col-span-4 lg:row-span-3 lg:border-t-0 lg:py-0.5">
          {
            footerMenu.map((link) => {
              // if we're dealing with a React link, use LinkStyled component
              if (link.type === 'react') {
                return (
                  <li key={link.name} className="py-1.5">
                    <LinkStyled href={link.url} size="sm" styling="text" color="ink-medium">
                      {t(link.name)}
                    </LinkStyled>
                  </li>
                );
              }
              // if we're dealing with a symfony or external link, use Anchor component
              return (
                <li key={link.name} className="py-1.5">
                  <Anchor href={link.url} {...link.type === 'symfony' ? { symfonyRoute: true } : ''} symfonyParams={link.urlParams} size="sm" styling="text" color="ink-medium">
                    {t(link.name)}
                  </Anchor>
                </li>
              );
            })
          }
        </ul>

        {/* Social media & App download */}
        <div className="col-span-2 border-t pt-5 lg:col-span-5 lg:row-span-3 lg:justify-self-center lg:border-t-0 lg:pt-0.5">

          {/* Social media list - title, list (component) */}
          <div className="mb-5 text-center lg:text-left">
            <div className="mb-3 inline-block text-xs font-semibold text-ink-medium">
              { t('social-media.follow') }
            </div>
            <div className="flex items-center justify-center lg:justify-start">
              <SocialMedia />
            </div>
          </div>

          {/*
            App download list - this can be used anywhere we need apps buttons,
            ie in the after logout page - title, list (component)
          */}
          <div className="text-center lg:text-left">
            <div className="mb-3.5 inline-block text-xs font-semibold text-ink-medium">
              { t('apps.download') }
            </div>
            <div className="flex flex-wrap justify-center gap-2 text-left lg:justify-start">
              <AppDownload
                buttonProps={{
                  styling: 'outline',
                  color: 'ink-medium',
                  className: 'fill-ink-medium hover:fill-surface lg:hover:-translate-y-1.5 transform-gpu !pb-1.5',
                }}
                utmMedium="footer"
              />
            </div>
          </div>

        </div>

      </div>

      {/* copyright info */}
      <div className="border-t py-4 text-center text-xs uppercase">
        {`© ${currentYear} BESTJOBS RECRUTARE SA`}
      </div>

    </footer>
  );
};
