// Upgrade packages *******************************************************************************
// pathname: /v1/subscription/upgrade-packages
// ************************************************************************************************
import {
  useSwrApi,
  ApiSubscriptionPackagesParams,
} from 'src/hooks/useSwrApi';
import { buildSearchParams } from '@utils/data-fetching';


/**
 * @description SWR: fetch subscription upgrade packages.
 * @param locale - the locale to use for the request.
 * @param queryParams - the query params to use for the request, if there are monthly/yearly available packages. Can be a string or an object.
 *
 * @example
 * // Fetch all packages, no params (you can remove the second param, it's optional)
 * const { data: upgradePackages, ... } = useSwrUpgradePackages(locale, '');
 *
 * // Fetch all packages, with params
 * const { data: upgradePackages, ... } = useSwrUpgradePackages(locale, {section: 'freemium_year'});
 *
 * // Don't fetch packages, unless yourStateVariable is true
 * // It's useful when you put the hook on Modal, but you don't want to fetch the data until the Modal is open
 * const { data: upgradePackages, ... } = useSwrUpgradePackages(locale, '', yourStateVariable === false);
 */
export const useSwrUpgradePackages = (
  locale = 'ro',
  queryParams: string | ApiSubscriptionPackagesParams = '',
) => {
  // ********************************************
  // Build search params
  const searchParams = buildSearchParams(queryParams);


  // Get data from API
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiSchema<'SubscriptionUpgrade'>>({
    url: `/v1/subscription/upgrade-packages${searchParams}`,
    locale,
    apiOptions: {
      dedupingInterval: 60 * 1000, // 1 minute
      // on key change, keep previous data until new data is fetched
      // https://swr.vercel.app/docs/advanced/understanding#key-change--previous-data
      keepPreviousData: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
