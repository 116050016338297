'use client';

import { useTranslation } from 'next-i18next';
import { Button } from 'src/components/common';
import { CustomerForm } from '@components/templates/company/billing/CustomerForm';
import { useBjForm } from '@hooks/useBjForm';
import {
  FormEvent, useEffect, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '@store/index';
import { getSelectedSubscriptionPackage } from '@store/reducers/employer/subscription/subscriptionSelector';
import {
  saveOrderData,
  saveOrderStatus,
} from '@store/reducers/employer/subscription/subscriptionReducer';
import { extractNumber } from '@utils/strings/extractNumber';
import { Input } from '@components/common/Forms';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useRouterLocale } from '@hooks/common/useRouterLocale';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { useSwrAddPaymentCard } from '@hooks/useSwrApi/endpoints/subscription';
import { PricingFreeTrialFaq } from '../pricing/PricingFreeTrialFaq';
import { PricingUpgradePackage } from '../pricing/PricingUpgradePackage';

// Interface
// *****************************************
interface PaymentUpgradePackagesProps {
  paymentData?: ApiSchema<'SubscriptionPayment'>,
  showClearPackageButton?: boolean,
}


/**
 * @description Renders the payment upgrade packages view, with payment options and pricing.
 * * **paymentData** is the data for the payment options and pricing.
 * * **hasClearSelectedPackage** is the flag to show or hide the clear selected package button.
 */
export const StepPaySelectedPackage = (props: PaymentUpgradePackagesProps) => {
  // Props destructuring
  const { paymentData } = props;

  const { router } = useExtendedRouter();

  // Translation
  const { t } = useTranslation('common');

  const { pathname: asPath } = useExtendedRouter();

  const locale = useRouterLocale();

  // Dispatch
  const appDispatch = useAppDispatch();


  // 1. State used to get the selected payment method from the CardPaymentList
  // *****************************************
  const [
    selectedPaymentMethod,
  ] = useState(paymentData?.paymentTypes?.find((p) => p.type === 'bank'));

  const [
    selectedCard,
  ] = useState(Number(paymentData?.creditCards?.filter((card) => card.recurrent)[0]?.id) || 0);


  // 3. Redux Data
  // *****************************************
  // Get the selected upgrade package from Redux
  const selectedSubscriptionPackage = useAppSelector(getSelectedSubscriptionPackage);

  // Styles / text
  const categoryTitleStyle = 'text-sm text-ink-medium uppercase font-bold mb-4';

  const [hiddenForm, setHiddenForm] = useState(true);

  const {
    record, handleSubmit, formStatus, isPosting,
  } = useBjForm({
    submitURL: '/v1/subscription/order',
    submitMethod: 'POST',
  });


  const { trigger, data: addCreditCardData, isMutating: isLoadingCardDetails } = useSwrAddPaymentCard(locale);

  const handleOrder = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedPaymentMethod && selectedSubscriptionPackage) {
      try {
        const promises = [];
        if (!paymentData?.creditCards?.length) {
          promises.push(trigger());
        }
        await Promise.all(promises);
        handleSubmit(event);
      } catch (error) {
        console.error('Error in handleOrder:', error);
      }
    }
  };


  useEffect(() => {
    const handleFormSuccess = async () => {
      const data = formStatus.data as ApiSchema<'CartOrderResponse2'>;
      if (data && Object.keys(data).length > 0) {
        if (!paymentData?.creditCards?.length) {
          if (addCreditCardData?.checkoutSessionUrl) {
            window.location.replace(addCreditCardData.checkoutSessionUrl);
          }
        } else {
          void router.push('/wallet/transactions');
          appDispatch(saveOrderData(data));
          appDispatch(saveOrderStatus('success'));
        }
      }
    };

    if (formStatus.actionStatus === 'success') {
      void handleFormSuccess();
    } else if (formStatus.actionStatus === 'error' && formStatus?.status === 400) {
      setHiddenForm(false);
    }
  }, [formStatus, appDispatch, paymentData, router, addCreditCardData]);

  const benefits = [
    { id: 1, title: t('premium.payment.faqs.1.question'), text: t('premium.payment.faqs.1.answer') },
    { id: 2, title: t('premium.payment.faqs.2.question'), text: t('premium.payment.faqs.2.answer') },
    { id: 3, title: t('premium.payment.faqs.3.question'), text: t('premium.payment.faqs.3.answer') },
  ];

  // Render component
  // *****************************************
  return (
    <>
      <form onSubmit={(event) => {
        void handleOrder(event);
      }}
      >
        <div className="p-6">
          <CustomerForm customer={paymentData?.customer} record={record} hiddenForm={hiddenForm} locale={locale} />
          <Input
            type="hidden"
            value={selectedPaymentMethod?.id}
            {...record('paymentTypeId', {})}
          />
          <Input
            type="hidden"
            value={selectedSubscriptionPackage ? extractNumber(selectedSubscriptionPackage.packageId) : ''}
            {...record('packageId', {})}
          />
          <Input
            type="hidden"
            value={asPath as string}
            {...record('redirectUrl', {})}
          />
          {selectedPaymentMethod?.type === 'stripe' && (
            <Input
              type="hidden"
              value={selectedCard}
              {...record('creditCardId', { convertEmptyValue: 'toNumber' })}
            />
          )}

          {/* Pricing title */}
          <h2 className={categoryTitleStyle}>
            {t('payment.upgrade-subscription.pricing.label')}
          </h2>

          {/* Pricing card */}
          <PricingUpgradePackage
            paymentData={paymentData}
          />

          {/* Free trial FAQ */}
          {
            (paymentData && paymentData?.frequentlyAskedQuestions && paymentData?.frequentlyAskedQuestions?.length > 0)
            && (
              <PricingFreeTrialFaq
                questionsList={paymentData?.frequentlyAskedQuestions}
                titleStyle={categoryTitleStyle}
              />
            )
          }

          <hr className="my-3" />
          <div
            className="mb-6 mt-3 text-sm font-bold uppercase text-ink-light"
          >
            {t('premium_free_trial.faq.head.title')}
          </div>
          <div className="mb-5 rounded-md bg-surface">
            {benefits.map((item, index: number) => (
              <Disclosure
                as="div"
                className="border border-solid border-gray-300 p-2"
                key={item.id}
                defaultOpen={index === 0}
              >
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className="flex w-full  justify-between p-4 text-left text-lg font-medium text-ink"
                    >
                      <h2 className="font-bold">{item.title}</h2>
                      <ChevronDownIcon
                        className={`${
                          open ? 'rotate-180' : ''
                        } size-6 text-ink`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 py-2 text-md text-ink">
                      {item.text}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </div>

        </div>


        {/* Order the selected package */}
        <div className="border-t bg-surface px-6 py-4 text-right text-sm">
          <Button
            size="sm"
            color="secondary"
            rounding="full"
            className="!px-7"
            type="submit"
            isLoading={isPosting}
            disabled={isPosting || isLoadingCardDetails}
          >
            {paymentData?.ctaButtonLabel}
          </Button>
        </div>
      </form>
    </>
  );
};
