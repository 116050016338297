import { useEffect, useRef, useState } from 'react';

interface IProps {
  durationInSeconds?: number;
  onEndCallback?: () => void;
}

/**
 * @description counter hook for ascending time
 * @param durationInSeconds - duration of the counter in seconds
 * @param onEndCallback - callback when the counter ends (counterSeconds === durationInSeconds)
 */
export const useCounterAscending = ({ durationInSeconds, onEndCallback }: IProps) => {
  const [counterSeconds, setCounterSeconds] = useState<number | undefined>(undefined);

  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

  // handles timer
  useEffect(() => {
    if (!durationInSeconds) {
      return;
    }

    // time is up - trigger callback
    if (counterSeconds === durationInSeconds) {
      if (onEndCallback) {
        onEndCallback();
      }
      setCounterSeconds(undefined);

      // clear timer
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    }
  }, [onEndCallback, counterSeconds, durationInSeconds]);

  // when starting is available, reset timer
  useEffect(() => {
    setCounterSeconds(0);

    timerRef.current = setInterval(() => {
      setCounterSeconds((prevState) => (prevState ?? 1) + 1);
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
    };
  }, []);


  return {
    counterSeconds,
  };
};
