import { useEffect } from 'react';


/**
 * @description Hook that runs a callback when the tab is refocused. The component must be mounted,
 * otherwise the hook will not run.
 * @param callback - your callback function
 *
 * @example
 * const myCallback = () => {
 *  console.log('Tab refocused!');
 * };
 *
 * useOnTabRefocus(myCallback);
 */
export const useOnTabRefocus = (callback: () => void) => {
  useEffect(() => {
    // when tab is refocused, run the callback
    const tabFocusCallback = () => {
      if (document.visibilityState === 'visible') {
        callback();
      }
    };

    // Add event listener
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', tabFocusCallback, false);
    }

    // Cleanup the effect and remove the event listener
    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('visibilitychange', tabFocusCallback, false);
      }
    };
  }, [callback]);
};
