import DOMPurify, { Config } from 'isomorphic-dompurify';

/**
 * @description Convert a string to html {@link https://github.com/cure53/DOMPurify/tree/main/demos#what-is-this For Config options read documentation}
 * @param data
 * @param config
 * @example
 * Basic usage:
 *
 * const data = 'Some text with a <a href="https://www.google.com">link</a> in it.';
 *
 * const config = {ALLOWED_TAGS: ['b', 'a'], ALLOWED_ATTR: ['href']};
 *
 * const response = sanitizeHtml(data, config);
 *
 * The response is a string with the allowed html tags and attributes
 */
export const sanitizeHtml = (data?: string, config?: Config): string => {
  let response = '';

  if (typeof data === 'string') {
    response = DOMPurify.sanitize(data || '', config || {}) as string;
  }

  return response;
};
