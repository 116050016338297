// Packages ***************************************************************************************
// pathname: /v1/subscription/packages
// ************************************************************************************************
import { useMemo } from 'react';
import {
  useSwrApi,
  ApiSubscriptionPackagesParams,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR: fetch subscription packages.
 * @param locale string - the locale to use for the request. Default 'ro'.
 * @param serverSideData ApiSubscriptionPackages - the data we got from the server side request (if we do it). Default undefined.
 * @param shouldFetch boolean - condition for fetching the data. Default true.
 * @param revalidateOnMount boolean - condition for revalidating the data on mount. Default false.
 * @param packagesParams ApiSubscriptionPackagesParams - the packages params to use for the request. Default undefined.
 */
export const useSwrPackages = (
  locale = 'ro',
  serverSideData?: ApiSchema<'ActivationServices'>,
  shouldFetch = true,
  revalidateOnMount = false,
  packagesParams: ApiSubscriptionPackagesParams | undefined = undefined,
) => {
  // set query params only when location and jobTitle are set
  const queryParams = useMemo(() => {
    const params = [];

    if (packagesParams?.location?.length) {
      params.push(`location=${packagesParams.location}`);
    }
    if (packagesParams?.jobTitle?.length) {
      params.push(`jobTitle=${packagesParams.jobTitle}`);
    }

    return params.length ? `?${params.join('&')}` : '';
  }, [packagesParams]);

  // Get data from API
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiSchema<'ActivationServices'>>({
    url: '/v1/subscription/packages',
    urlParams: queryParams,
    locale,
    shouldFetch,
    serverSideData,
    apiOptions: {
      dedupingInterval: 60 * 1000, // 1 minute
      revalidateOnMount,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
