'use client';

import { useAppSelector } from 'src/app';
import { useRouter } from 'next/compat/router';
import { getUserHeaderInfo } from 'src/app/reducers/userSelector';
import { usePathname } from 'next/navigation';
import { SalesCampaignStripe } from './SalesCampaignStripe';

/**
 * @description TopBannersHub component for displaying all the banners that are not sticky
 * and are situated above the navbar.
 */
export const TopBannersHub = () => {
  // Get header info from Redux. The data for the SalesCampaignStripe banner is there.
  const headerInfo = useAppSelector(getUserHeaderInfo);

  const deniedBannerRoutes = ['/', '/[locale]/cv/[slug]', '/[locale]/job/[jobSlug]/cv/[slug]'];

  // Get current route pathname
  const pagesRouter = useRouter();
  const appRouterPathname = usePathname();
  const pathname = pagesRouter?.route || appRouterPathname || '';

  // Render component
  // ****************************************
  return (
    <>
      { /* Sales campaign stripe. The stripe is displayed only for companies and not on the homepage. */
        (headerInfo?.stripeNotification && headerInfo?.role === 'COMPANY'
        && !deniedBannerRoutes.includes(pathname)) && (
          <SalesCampaignStripe
            data={headerInfo?.stripeNotification}
          />
        )
      }
    </>
  );
};
