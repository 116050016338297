import { useAppDispatch, useAppSelector } from '@store/index';
import { useCallback, useEffect, useState } from 'react';
import {
  ISocketCallCallbackData,
  ISocketCallExitReason,
  ISocketCallStartDataResponse, ISocketOnlineCompanyEndCallType, socketOnlineCompanyEndCallType, socketOnlineErrCode,
  socketOnlineExitReason,
} from '@type/socket-online';
import { bjToast } from '@components/common';
import { useTranslation } from 'next-i18next';
import { useSwrUserCall } from '@hooks/useSwrApi/endpoints/user';
import { useRouter } from 'next/router';

export const useOpenTokCall = () => {
  const { t } = useTranslation('common');

  const { locale } = useRouter();

  const headerInfo = useAppSelector((state) => state.user.headerInfo);
  const { talentParticipant, hasTalentAnswered } = useAppSelector((state) => state.socketOnline);

  const dispatch = useAppDispatch();

  // TrackingId for Analytics
  const trackingId = talentParticipant?.trackingId;

  // session data
  const [sessionData, setSessionData] = useState<ISocketCallStartDataResponse | null>(null);

  // show company call options before starting the call
  const [showCompanyCallOptions, setShowCompanyCallOptions] = useState(false);

  // if is video call or audio call
  const [isVideoCall, setIsVideoCall] = useState(false);

  const [companyEndOfCallMessage, setCompanyEndOfCallMessage] = useState<null | string>(null);
  const [endOfCallReason, setEndOfCallReason] = useState<null | ISocketCallExitReason>(null);

  // base label for end of call message used for company
  const endOfCallBaseLabel = 'call_live.company.end_call.modal.title.';

  // clear redux state
  useEffect(() => {
    if (!sessionData) {
      dispatch({ type: 'socketOnline/clearState' });
    }
  }, [sessionData, dispatch]);

  // show company call options when company intent to call
  // it gets the talent participant data from redux, from other context
  useEffect(() => {
    if (talentParticipant) {
      setShowCompanyCallOptions(true);
    }
  }, [talentParticipant]);

  // clear local state and socket online reducer state
  const onClearState = useCallback(() => {
    // clear local state
    setCompanyEndOfCallMessage(null);
    setEndOfCallReason(null);
    setSessionData(null);
    setIsVideoCall(false);
    setShowCompanyCallOptions(false);
    // clear redux state
    dispatch({ type: 'socketOnline/clearState' });
  }, [dispatch]);

  // handles when the call is ended for company
  useEffect(() => {
    if (headerInfo?.role !== 'COMPANY' || !endOfCallReason || companyEndOfCallMessage) {
      return;
    }
    if (hasTalentAnswered) {
      setCompanyEndOfCallMessage(`${endOfCallBaseLabel}${socketOnlineCompanyEndCallType.END_CALL_ENDED}`);
      return;
    }
    if ([socketOnlineExitReason.TALENT_DECLINED].includes(endOfCallReason)) {
      setCompanyEndOfCallMessage(`${endOfCallBaseLabel}${socketOnlineCompanyEndCallType.END_CALL_DECLINED}`);
      return;
    }
    setCompanyEndOfCallMessage(`${endOfCallBaseLabel}${socketOnlineCompanyEndCallType.END_NO_ANSWER}`);
  }, [endOfCallReason, companyEndOfCallMessage, headerInfo?.role, hasTalentAnswered]);

  // callback for video call events
  const onCallbackCall = useCallback((response: ISocketCallCallbackData) => {
    // error event
    if (response.isError) {
      if (headerInfo?.role === 'COMPANY') {
        setCompanyEndOfCallMessage(response?.labelMessage ?? 'global.something.went.wrong');
        return;
      }

      onClearState();
      bjToast.error(t(response?.labelMessage ?? 'global.something.went.wrong'));
      return;
    }

    // start event received by company
    if (response.isStart) {
      setSessionData(response.data as ISocketCallStartDataResponse);
    }
  }, [onClearState, t, headerInfo?.role]);

  const {
    trigger: triggerUserCall,
    isMutating: isLoadingUserCall,
  } = useSwrUserCall({
    locale: locale ?? 'ro',
    trackingId,
    successCallback: (response) => {
      if (response.available) {
        onCallbackCall({
          isStart: true,
          data: {
            sessionId: response.sessionId,
            tokenId: response.token,
          },
        });
      } else {
        onCallbackCall({
          isError: true,
          labelMessage: `call_live.error.code.${socketOnlineErrCode.ERR_TALENT_NOT_AVAILABLE}`,
        });
      }

      // hides modal for showing company call options
      setShowCompanyCallOptions(false);
    },
    errorCallback: (response) => {
      onCallbackCall({ isError: true, labelMessage: `call_live.error.code.${response.errors[0].code}` });

      // hides modal for showing company call options
      setShowCompanyCallOptions(false);
    },
  });

  // start video call trigger manually by the company
  const onStartVideoCall = useCallback((isVideo: boolean) => {
    if (!talentParticipant || !headerInfo?.role) {
      return;
    }

    setIsVideoCall(isVideo);

    // API request to start video call
    void triggerUserCall({
      user: talentParticipant.slug,
      withVideo: isVideo,
    });
  }, [headerInfo?.role, talentParticipant, triggerUserCall]);

  // when opentok connection is established for a company
  const onOpentokCompanyConnect = useCallback((status: boolean) => {
    if (!sessionData?.sessionId || status) {
      return;
    }

    // clean open tok session data if can't connect to opentok session
    setSessionData(null);
  }, [sessionData?.sessionId]);

  // when opentok connection is established with success or fail
  const onOpenTokConnect = useCallback((status: boolean) => {
    if (headerInfo?.role === 'COMPANY') {
      onOpentokCompanyConnect(status);
    }
  }, [headerInfo?.role, onOpentokCompanyConnect]);

  // when talent closes the connection. It can be triggered by the talent.
  const onCloseConnection = useCallback((force: boolean, endType?: ISocketOnlineCompanyEndCallType) => {
    // clear state
    if (force) {
      onClearState();
    }

    // set end of call reason for company if available
    if (headerInfo?.role === 'COMPANY' && endType) {
      setCompanyEndOfCallMessage(`${endOfCallBaseLabel}${endType}`);
    }
  }, [headerInfo?.role, onClearState]);


  return {
    sessionData,
    showCompanyCallOptions,
    isVideoCall,
    endOfCallReason,
    companyEndOfCallMessage,
    onStartVideoCall,
    onOpenTokConnect,
    onCloseConnection,
    isLoadingUserCall,
    trackingId,
  };
};
