import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { FormElementCustomValidityMessages, FormElementTypes } from 'src/types/form-types';


/**
 * Custom hook to check the form element validity and assign an error message.
 * * @remarks We're using the HTML5 ValidityState API: https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
 *
 * * @returns errorMSG - string with the proper error message
 * * @returns checkValidation - function that checks the element's validity
 *
 * @example 1
 * // standalone use with normal HTML inputs (the Input component already has the FormError component built-in)
 * function Example() {
 *   const { errorMSG, checkValidation } = useCheckValidity();
 *
 *   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
 *     const element = event.target;
 *     const customError = 'Your custom error message';
 *     const patternMismatchError = 'The card number must be 1234-1234-1234-1234';
 *
 *     if (!element.validity.valid) {
 *       checkValidation(element, customError, patternMismatchError);
 *     }
 *   };
 *
 *
 * @example 2
 * // standalone use with normal HTML inputs and show custom validity messages
 * function Example() {
 *   const { errorMSG, checkValidation } = useCheckValidity();
 *
 *   const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
 *     const element = event.target;
 *     const customError = 'Your custom error message';
 *     const tooLongErrorMessage = 'Your custom too long error message';
 *     const valueMissingErrorMessage = 'Value missing error message';
 *
 *     if (!element.validity.valid) {
 *       checkValidation(element, customError, '', {tooLong: tooLongErrorMessage, valueMissing: valueMissingErrorMessage});
 *     }
 *   };
 *
 *   return (
 *     <>
 *        <input onChange={handleChange} />
 *        <FormError errorMessage={errorMSG} />
 *     </>
 *   );
 * }
 *
 * @version 1.0
 */


// HOOK DEFINITION *******************************************
export const useCheckValidity = () => {
  // store error messages; store the empty field error initially
  const { t } = useTranslation('common');
  const isEmptyErrorMessage: string = t('form.validity.value-missing');
  const [errorMSG, setErrorMSG] = useState(isEmptyErrorMessage);


  /**
   * @description We check through the element's validity object and assign an error message depending on the validity type
   * @description Find out more details and example usage inside {@link useCheckValidity useCheckValidity} custom hook
   * @param element
   * @param customErrorMessage
   * @param patternMismatchMessage
   * @param customValidityMessages
   */
  const checkValidation = (
    element: FormElementTypes,
    customErrorMessage: string,
    patternMismatchMessage: string,
    customValidityMessages?: FormElementCustomValidityMessages,
  ): void => {
    switch (!element.validity.valid) {
      // custom error - Custom validity message
      case (element.validity.customError):
        setErrorMSG(customErrorMessage);
        break;

      // if the value does not match the specified Pattern attr
      case (element.validity.patternMismatch):
        setErrorMSG(patternMismatchMessage);
        break;

      // the user has provided input that the browser is unable to convert.
      case (element.validity.badInput):
        setErrorMSG(customValidityMessages?.badInput ?? t('form.validity.validity.bad-input'));
        break;

      // input range type only; if the value is greater than the maximum specified by the Max attr
      case (element.validity.rangeOverflow):
        if (element instanceof HTMLInputElement) {
          setErrorMSG(customValidityMessages?.rangeOverflow ?? t('form.validity.range-overflow', { max: element.max }));
        }
        break;

      // input range type only; if the value is less than the minimum specified by the Min attr
      case (element.validity.rangeUnderflow):
        if (element instanceof HTMLInputElement) {
          setErrorMSG(customValidityMessages?.rangeUnderflow ?? t('form.validity.range-underflow', { min: element.min }));
        }
        break;

      // input range type only; if the value does not fit the rules determined by the Step attr
      case (element.validity.stepMismatch):
        setErrorMSG(customValidityMessages?.stepMismatch ?? t('form.validity.step-mismatch'));
        break;

      // if the value exceeds the specified MaxLength attr
      // only Input & Textarea have minLength attr, we exclude Select
      case (element.validity.tooLong):
        if (!(element instanceof HTMLSelectElement)) {
          setErrorMSG(customValidityMessages?.tooLong ?? t('form.validity.tooLong', { maxLength: element.maxLength }));
        }
        break;

      //  if the value fails to meet the specified MinLength attr
      // only Input & Textarea have minLength attr, we exclude Select
      case (element.validity.tooShort):
        if (!(element instanceof HTMLSelectElement)) {
          setErrorMSG(customValidityMessages?.tooShort ?? t('form.validity.tooShort', { minLength: element.minLength }));
        }
        break;

      // email or url types only; it does not fit the type; checks between Email and URL
      case (element.validity.typeMismatch):
        switch (true) {
          case (element.type === 'url'):
            setErrorMSG(customValidityMessages?.typeMismatch ?? t('form.validity.url-mismatch'));
            break;
          default:
            setErrorMSG(customValidityMessages?.typeMismatch ?? t('form.validity.email-mismatch'));
        }
        break;

      // default case; matches element.validity.valueMissing - field is Empty
      default:
        setErrorMSG(customValidityMessages?.valueMissing ?? isEmptyErrorMessage);
    }
  };

  return { errorMSG, checkValidation };
};
