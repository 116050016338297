// SUBSCRIPTION CANCEL (post request) *************************************************************
// pathname: /v1/subscription/close-request
// ************************************************************************************************
import {
  useSwrMutationApi,
  ApiSubscriptionCloseRequestPost,
} from 'src/hooks/useSwrApi';


/**
 * @description SWR mutation: post subscription close request. Used to cancel a premium subscription.
 * @param locale
 * @param successCallback
 */
export const useSwrPostCloseSubscription = (locale = 'ro', successCallback?: () => void) => {
  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, ApiSubscriptionCloseRequestPost>({
    url: '/v1/subscription/close-request',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        if (successCallback) {
          void successCallback();
        }
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
