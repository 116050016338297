import { ExtractProps } from '@type/utils/getComponentPropTypes';
import { Tab as HeadlessTab } from '@headlessui/react';
import { TabStyledRootPanel } from './TabStyledPanel';
import { TabStyledRootButton } from './TabStyledButton';


// ROOT COMPONENT
// *******************************************************************************************
const TabStyledRoot = (props: ExtractProps<typeof HeadlessTab>) => (
  <HeadlessTab {...props} />
);


// EXPORT COMPONENT WITH DOT NOTATION
// look at the types in library here: node_modules/@headlessui/react/dist/components/tabs/tabs.d.ts
// *******************************************************************************************
/**
 * @description Customized Tab component. It has a default Panel component that is customized
 * with animations. This component is extended from the HeadlessUI Tab, so it has all the same
 * props and types (and some extras as previously said).
 *
 * @example
 * <TabStyled>
 *   <TabStyled.List>
 *     <TabStyled as={Fragment}>
 *       {({ selected }) => (
 *         <Button size="sm" styling={selected ? 'solid' : 'outline'} className="outline-0 rounded-l-full" rounding="none">Tab 1</Button>
 *       )}
 *     </TabStyled>
 *     <TabStyled as={Fragment}>
 *       {({ selected }) => (
 *         <Button size="sm" styling={selected ? 'solid' : 'outline'} className="outline-0 rounded-r-full" rounding="none">Tab 2</Button>
 *       )}
 *     </TabStyled>
 *   </TabStyled.List>
 *
 *   <TabStyled.Panels className="bg-surface rounded p-6">
 *     <TabStyled.Panel animation="slideUp">Panel 1</TabStyled.Panel>
 *     <TabStyled.Panel animation="slideUp">Panel 2</TabStyled.Panel>
 *   </TabStyled.Panels>
 * </TabStyled>
 */
export const TabStyled = Object.assign(TabStyledRoot, {
  Group: HeadlessTab.Group,
  List: HeadlessTab.List,
  Panels: HeadlessTab.Panels,
  Panel: TabStyledRootPanel,
  Button: TabStyledRootButton,
});
