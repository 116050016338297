// LIVE INTERVAL **************************************************************************************
// pathname: /v1/static-lists/live-interval
// ************************************************************************************************
import { ApiLiveIntervalOk, useSwrApi } from 'src/hooks/useSwrApi';


// SWR: fetch static live interval
// usage: const { data, error, ... } = useSwrStaticLiveInterval(locale);
// ********************************************
export const useSwrStaticLiveInterval = (locale: string) => {
  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiLiveIntervalOk>({
    url: '/v1/static-lists/live-interval',
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 5, // 5 minute,
    },
  });

  // Return data
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
