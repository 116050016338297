import { ids } from './converted-ids';

/** Types for data-test-id */
export type DataTestIdKeys = keyof typeof ids;
type GetDataTestID = (key: DataTestIdKeys) => string;
type DataTestId = (key: DataTestIdKeys) => { 'data-test-id': string };

/**
 * @description Get the data-test-id value by key. It returns the value of the key.
 * - `key` is the key of the data-test-id.
 *
 * @example
 * <div data-test-id={getDataTestID('cookiesModal.acceptButton')} />
 */
export const getDataTestID: GetDataTestID = (key) => ids[key];

/**
 * @description Spread the data-test-id attribute with the value by key. It returns the data-test-id
 * attribute with the value of the key.
 * - `key` is the key of the data-test-id.
 *
 * @example
 * <div {...dataTestId('cookiesModal.acceptButton')} />
 */
export const dataTestId: DataTestId = (key) => ({
  'data-test-id': getDataTestID(key),
});
