import { useState, useEffect } from 'react';


/**
 * @description Hook that returns the current tab focus states. There are two states:
 * * **isTabRefocused** - true if the tab is currently refocused, false otherwise, false initially
 * * **isTabHidden** - true if the tab is currently hidden, false otherwise, false initially
 *
 * @example
 * const { isTabRefocused, isTabHidden } = useTabFocusState();
 *
 * if (isTabRefocused) {
 *   // run some code when the tab is refocused
 *   console.log('Tab refocused!');
 * }
 *
 * if (isTabHidden) {
 *   // run some code when the tab is hidden, ie pause a video
 *   console.log('Tab hidden!');
 * }
 */
export const useTabFocusState = () => {
  const [isTabRefocused, setIsTabRefocused] = useState<boolean>(false);
  const [isTabHidden, setIsTabHidden] = useState<boolean>(false);

  useEffect(() => {
    const tabFocusStatus = () => {
      if (document.visibilityState === 'visible') {
        setIsTabRefocused(true);
        setIsTabHidden(false);
      }
      if (document.visibilityState === 'hidden') {
        setIsTabRefocused(false);
        setIsTabHidden(true);
      }
    };

    // Add event listener
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', tabFocusStatus, false);
    }

    // Cleanup the effect and remove the event listener
    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('visibilitychange', tabFocusStatus, false);
      }
    };
  }, []);

  // return the states
  return {
    isTabRefocused,
    isTabHidden,
  };
};
