// Send apply message to employer *********************************************************************
// pathname: /v1/messages/{jobSlug}/send-message-to-employer
// ****************************************************************************************************
import {
  GenericV1ErrorsArray,
  useSwrMutationApi,
} from '@hooks/useSwrApi';
import { SwrToastsProps } from '@type/swr';

// Interface
// ********************************************
interface SwrSendApplyMessageProps {
  jobSlug: string;
  locale?: string;
  successCallback?: () => void;
  errorCallback?: (errors: GenericV1ErrorsArray) => void;
  toastsOptions?: SwrToastsProps,
}

/**
 * @description SWR mutation: Sends an application message to an employer for a specific job..
 *
 * * **jobSlug**: string. The slug identifier for the job to which the message is being sent.
 * * **locale**: string. The locale to be used for the request. Default 'ro'.
 * * **successCallback**: function, optional. A callback function that is executed on success.
 * * **errorCallback**: function, optional. A callback function that is executed when an error occurs.
 * * **toastsOptions**: SWR toasts options, optional.
 */
export const useSwrSendApplyMessage = (props: SwrSendApplyMessageProps) => {
  // Props destructuring
  // ********************************************
  const {
    jobSlug,
    locale = 'ro',
    successCallback,
    errorCallback,
    toastsOptions,
  } = props;

  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<object, { body: string }>({
    url: `/v1/messages/${jobSlug}/send-message-to-employer`,
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: () => {
        // Callback
        if (successCallback) successCallback();
      },
      onError: (errors) => {
        // Callback
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showSuccessToast: true,
      showErrorToast: true,
      ...toastsOptions,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
