import {
  enGB, hu, ro, Locale,
} from 'date-fns/locale';

export enum Locales {
  RO = 'ro',
  EN = 'en',
  HU = 'hu',
}

export const LOCALES = [Locales.RO as string, Locales.EN as string, Locales.HU as string];


export const localesDictionary = {
  [Locales.RO]: ro,
  [Locales.EN]: enGB,
  [Locales.HU]: hu,
} as { [Locales.RO]: Locale, [Locales.EN]: Locale, [Locales.HU]: Locale };

