import { Anchor, LinkStyled } from '@components/common';
import { useTranslation } from 'next-i18next';
import { HeroIconType } from '@type/icons';
import { cn } from '@utils/cn';
import { i18nConfig } from 'i18n-config';
import { useExtendedRouter } from '@hooks/next-routing-wrappers';
import { forwardRef, MouseEventHandler } from 'react';
import { AnchorRef } from '@type/anchors-buttons';
import { clsx } from 'clsx';
import { getI18nLinkHref } from '@services/i18nPaths';
import { mainMenuLinks } from './links-objects';

/**
 * Props for the MainNavbarLink component.
 */
interface MainNavbarLinkProps {
  type: 'symfony' | 'react' | 'external',
  route: string,
  label: string,
  icon?: HeroIconType,
  className?: string;
  onClick?: MouseEventHandler;
  dataTestId?: string,
  subTitle?: string
}

/**
 * MainNavbarLink component for rendering navigation links.
 * Supports Symfony, React, and external links.
 *
 * **type** - 'symfony' | 'react' | 'external': Type of the link.
 * **route** - string: URL or route path for the link.
 * **label** - string: Translation key for the link label.
 * **icon** - HeroIconType: Optional icon component.
 * **className** - string: Additional class names for styling/
 * **onClick** - function: Optional click event handler
 */
export const MainNavbarLink = forwardRef<AnchorRef, MainNavbarLinkProps>((props, ref) => {
  // Destructure props
  const {
    type,
    icon,
    label,
    route,
    className,
    onClick,
    dataTestId,
    subTitle,
  } = props;

  // Translate
  const { t } = useTranslation('common');

  // Helper classes
  const wrapperClasses = 'flex justify-center items-center lg:mx-1';
  const baseClasses = 'relative py-1.5 px-1 md:pt-5 md:pt-4 md:pb-4.5 md:px-3 xl:px-4 flex justify-center flex-col md:flex-row justify-between items-center outline-none whitespace-nowrap hover:text-primary';
  const textClasses = 'text-tiny md:text-md md:font-semibold';
  const activeIndicatorClasses = 'before:content-[\'\'] before:absolute before:bottom-0 before:left-0 before:right-0 before:h-[3px]';
  const activeClasses = 'text-secondary md:text-ink before:bg-secondary';
  const iconClasses = cn('size-5 md:mr-2 md:hidden');

  // Extract the icon component
  const Icon = icon;

  const { pathname, locale } = useExtendedRouter();

  const isActiveRoute = (linkRoute: string) => {
    if (!pathname) {
      return false;
    }

    // Extract the first segment of the path
    const pathSegments = pathname.split('/').filter(Boolean); // filters out empty strings

    // Check if the first segment is a supported locale
    const currentLocale = i18nConfig.locales.includes(pathSegments[0] as Locale) ? pathSegments[0] as Locale : null;

    // Get the base path by removing the current locale if it exists
    const basePath = currentLocale ? pathname.replace(`/${currentLocale}`, '') : pathname;

    const itemsWithChildren = mainMenuLinks.filter((item) => item.children)?.[0];
    if (linkRoute === itemsWithChildren?.route) {
      if (itemsWithChildren?.children?.some((item) => item.route === basePath)) {
        return true;
      }
    }

    return basePath.startsWith(getI18nLinkHref(linkRoute, locale));
  };

  // 1. If we're dealing with a symfony type link, use the Anchor component
  // 2. Symfony's routes are never the current route in react, so no 'current' marking
  if (type === 'symfony' || type === 'external') {
    return (
      <Anchor
        key={label}
        ref={ref}
        href={route}
        symfonyRoute={type === 'symfony'}
        styling="none"
        className={clsx(wrapperClasses, className)}
        onClick={onClick}
        {...(dataTestId ? { 'data-test-id': dataTestId } : {})}
        {...(type === 'external' ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      >
        <span className={clsx(baseClasses, textClasses)}>
          {Icon && <Icon className={iconClasses} />}
          {t(label)}
        </span>

      </Anchor>
    );
  }

  // 1. If we're dealing with a React type link, use the LinkStyled component
  // 2. Mark the 'current' route link
  return (
    <LinkStyled
      key={label}
      ref={ref}
      href={route}
      styling="none"
      className={clsx(wrapperClasses, className)}
      onClick={onClick}
      {...(dataTestId ? { 'data-test-id': dataTestId } : {})}
    >
      <span className={clsx(baseClasses, textClasses, activeIndicatorClasses, isActiveRoute(route) ? activeClasses : '', subTitle ? '!px-10' : '')}>
        {Icon && <Icon className={iconClasses} />}
        {t(label)}
      </span>
      {subTitle && <div className="absolute bottom-1 hidden whitespace-nowrap px-1 text-tiny md:block">{t(subTitle)}</div>}
    </LinkStyled>
  );
});
