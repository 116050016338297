// IMPORTS ****************************************************************************************
// ************************************************************************************************
import {
  ApiSuggestedSalary,
  ApiSuggestedSalaryOk,
  ApiCheckUnlockOk,
  ApiCheckUnlock,
  ApiResumeDetail,
  ApiResumeDetailOk,
  ApiSimilarCandidate,
  ApiSimilarCandidateOk,
  ApiResumeVideoUrl,
  ApiResumeVideoUrlOk, ApiCheckUnlockPay, ApiCheckUnlockPayOk,
} from 'src/types/v1-api-types';


// SUGGESTED SALARY *******************************************************************************
// pathname: /v1/resume-operation/resume/suggested-salary
// ************************************************************************************************
// suggested-salary response OK
export const isSuggestedSalaryOk = (res: ApiSuggestedSalary | void): res is ApiSuggestedSalaryOk => res !== undefined
  && res !== null && (res as ApiSuggestedSalaryOk)?.salarySuggestion !== undefined;


// CHECK UNLOCK CV *******************************************************************************
// pathname: /v1/resume-operation/{userId}/check-unlock/{status}
// pathname: /v1/resume-operation/{userId}/{type}
// ************************************************************************************************
export const isCheckUnlockStatusOk = (res: ApiCheckUnlock | void): res is ApiCheckUnlockOk => res !== undefined
  && res !== null && (res as ApiCheckUnlockOk)?.jobSlug !== undefined;

// CHECK UNLOCK CV *******************************************************************************
// pathname: /v1/resume-operation/{userId}/check-unlock/{status}
// pathname: /v1/resume-operation/{userId}/{type}
// ************************************************************************************************
export const isCheckUnlockStatusPayOk = (res: ApiCheckUnlockPay | void): res is ApiCheckUnlockPayOk => res !== undefined
    && res !== null && (res as ApiCheckUnlockPayOk)?.id !== undefined;

// RESUME DETAIL *******************************************************************************
// pathname: /v2/job/${jobSlug}/user-resume-detail/${slug}
// pathname: /v2/user-resume-detail/${slug}
// ************************************************************************************************
export const isResumeDetailStatusOk = (res: ApiResumeDetail | void): res is ApiResumeDetailOk => res !== undefined
  && res !== null && (res as ApiResumeDetailOk)?.blurred !== undefined;

// RESUME SIMILAR CANDIDATES *******************************************************************************
// pathname: /v1/${slug}/similar-users
// ************************************************************************************************
export const isSimilarCandidateStatusOk = (res: ApiSimilarCandidate | void): res is ApiSimilarCandidateOk => res !== undefined
    && res !== null && (res as ApiSimilarCandidateOk)?.length >= 0;


// GET RESUME VIDEO URL *******************************************************************************
// pathname: /v1/resume-operation/{userId}/video-url
// ****************************************************************************************************
export const isResumeVideoUrlOk = (res: ApiResumeVideoUrl | void)
: res is ApiResumeVideoUrlOk => res !== undefined && res !== null && (res as ApiResumeVideoUrlOk).length > 0;
