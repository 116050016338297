// IMPORTS ****************************************************************************************
// ************************************************************************************************
import { IGenericApiStrings } from 'src/types/generic-api-strings';
import {
  ApiDeleteProfileExperience,
  ApiDeleteProfileExperienceOk,
  ApiDeleteResumeStudy,
  ApiDeleteResumeStudyOk,
  ApiExperienceAdd,
  ApiExperienceAddOK,
  ApiExperienceId,
  ApiExperienceIdOk,
  ApiGetResumeStudy,
  ApiGetResumeStudyOk,
  ApiGetResumeStudySpecialization,
  ApiGetResumeStudySpecializationOk,
  ApiLanguageAdd,
  ApiLanguageAddOK,
  ApiLanguageRemove,
  ApiLanguageRemoveOK,
  ApiLastWorkExperience,
  ApiLastWorkExperienceOk,
  ApiProfileExperience,
  ApiProfileExperienceOk,
  ApiResumeAttachmentRemove,
  ApiResumeAttachmentRemoveOk,
  ApiResumeAttachmentsUpload,
  ApiResumeAttachmentsUploadOk,
  ApiResumeDeleteVideo,
  ApiResumePersonalData,
  ApiResumePersonalDataSave,
  ApiResumePicture, ApiResumeSetVideoNotificationStatus, ApiResumeSetVideoNotificationStatusOk,
  ApiResumeSkillList,
  ApiResumeUploadVideo,
  ApiResumeVideoCV,
  ApiSkillAdd,
  ApiSkillAddOk,
  ApiSkillList,
  ApiSkillListOk,
  ApiSkillRemove,
  ApiSkillRemoveOk,
  ApiUserAboutMeData,
  ApiUserJobTypePreferences,
  ApiUserJobTypePreferencesSave,
  ApiResumeAboutMeDataOk,
  ApiUserJobTypePreferencesOk,
  ApiResumePersonalDataOk,
  ApiResumeVideoCVOk, ApiResumeNextJobslList,
} from 'src/types/v1-api-types';
import { ApiDrivingSkillsAdd } from 'src/types/v1-api-types/static';

// Resume skill list ******************************************************************************
// pathname: /v1/resume/skill/list
// ************************************************************************************************
export const isSkillListOk = (res: ApiSkillList | void)
: res is ApiSkillListOk => res !== null && res !== undefined && (res as ApiSkillListOk)?.length > 0 && (res as ApiSkillListOk)[0]?.id !== undefined;


// Resume Skill Add *******************************************************************************
// pathname: /v1/resume/skill/add/{id}
// ************************************************************************************************
export const isSkillAddOk = (res: ApiSkillAdd | void)
: res is ApiSkillAddOk => res !== null && res !== undefined && Object.keys(res as ApiSkillAddOk)?.length === 0;

// Resume Skill Remove ************************************************************************************
// pathname: /v1/resume/skill/remove/{id}
// ************************************************************************************************
export const isSkillRemoveOk = (res: ApiSkillRemove | void): res is ApiSkillRemoveOk => res !== undefined && res !== null
  && Object.keys(res as ApiSkillAddOk)?.length === 0;

// Resume Create Spoken language ******************************************************************
// pathname: /v1/resume/spoken-language
// ************************************************************************************************
export const isLanguageAddOk = (res: ApiLanguageAdd | void)
: res is ApiLanguageAddOK => res !== null && res !== undefined && Object.keys(res as ApiLanguageAddOK)?.length === 0;


// Resume Delete Spoken language ************************************************************************************
// pathname: /v1/resume/spoken-language
// ************************************************************************************************
export const isLanguageRemoveOk = (res: ApiLanguageRemove | void): res is ApiLanguageRemoveOK => res !== undefined && res !== null
  && Object.keys(res as ApiLanguageAddOK).length === 0;


// Resume Create Experience ***********************************************************************
// pathname: /v1/resume/work-experience
// ************************************************************************************************
export const isExperienceAddOk = (res: ApiExperienceAdd | void)
: res is ApiExperienceAddOK => res !== null && res !== undefined && Object.keys(res as ApiExperienceAddOK)?.length === 0;


// OWN RESUME DETAIL ******************************************************************************
// pathname: /v1/own-resume-detail
// ************************************************************************************************
export const isExperienceIdOk = (res: ApiExperienceId | void)
: res is ApiExperienceIdOk => res !== null && res !== undefined && (res as ApiExperienceIdOk)?.data !== undefined;


// LAST WORK EXPERIENCE ***************************************************************************
// pathname: /v1/resume/last-work-experience
// ************************************************************************************************
export const isLastWorkExperienceOk = (res: ApiLastWorkExperience | void)
: res is ApiLastWorkExperienceOk => res !== null && res !== undefined && (res as ApiLastWorkExperienceOk)?.title !== undefined;

// (POST) DRIVING SKILLS       ********************************************************************
// pathname: /v1/resume/driving-skills
// ************************************************************************************************
export const isDrivingSkillsAddOk = (res: ApiDrivingSkillsAdd | void)
: res is object => res !== null && res !== undefined && Object.keys(res as object)?.length === 0;

// (GET) USER RESUME PERSONAL DATA ****************************************************************
// pathname: /v1/resume/personal-data
// ************************************************************************************************
export const isUserResumePersonalDataOk = (res: ApiResumePersonalData | void)
: res is ApiResumePersonalDataOk => res !== undefined && res !== null && Object.keys(res as ApiResumePersonalDataOk).length > 0;

// (POST) USER RESUME PERSONAL DATA ***************************************************************
// pathname: /v1/resume/personal-data
// ************************************************************************************************
export const isUserResumePersonalDataSaveOk = (res: ApiResumePersonalDataSave | void)
: res is object => res !== undefined && res !== null && Object.keys(res as object)?.length === 0;

// (GET) USER RESUME ABOUT ME DATA ****************************************************************
// pathname: /v1/resume/about-me
// ************************************************************************************************
export const isUserResumeAboutMeDataOk = (res: ApiUserAboutMeData | void)
: res is ApiResumeAboutMeDataOk => res !== undefined && res !== null && Object.keys(res as ApiResumeAboutMeDataOk)?.length > 0;

// (POST) USER RESUME ABOUT ME DATA **********************************************************************
// pathname: /v1/resume/about-me
// ************************************************************************************************
export const isUserResumeAboutMeDataSaveOk = (res: object | void)
: res is object => res !== undefined && res !== null && Object.keys(res as ApiResumeAboutMeDataOk)?.length === 0;

// (GET) USER RESUME JOB TYPE PREFERENCES DATA ****************************************************
// pathname: /v1/resume/preferences
// ************************************************************************************************
export const isUserResumeJobTypePreferencesOk = (
  res: ApiUserJobTypePreferences | void,
): res is ApiUserJobTypePreferencesOk => res !== undefined && res !== null;

// (POST) USER RESUME JOB TYPE PREFERENCES DATA ***************************************************
// pathname: /v1/resume/preferences
// ************************************************************************************************
export const isUserResumeJobTypePreferencesSaveOk = (res: ApiUserJobTypePreferencesSave | void)
: res is object => res !== undefined && res !== null && Object.keys(res)?.length === 0;

// (GET) USER WORK EXPERIENCE *********************************************************************
// pathname: /v1/resume/work-experience/{experienceId}
// ************************************************************************************************
export const isProfileExperienceOk = (res: ApiProfileExperience | void)
: res is ApiProfileExperienceOk => res !== undefined && res !== null && (res as ApiProfileExperienceOk)?.title !== undefined;


// DELETE USER RESUME EXPERIENCE DATA **********************************************************************
// pathname: /v1/resume/work-experience/{id}
// ************************************************************************************************
export const isDeleteProfileExperienceOk = (res: ApiDeleteProfileExperience | void)
: res is ApiDeleteProfileExperienceOk => res !== undefined && res !== null && Object.keys(res)?.length === 0;


// USER RESUME STUDY DATA **********************************************************************
// pathname: /v1/resume/study/{id}
// ************************************************************************************************
export const isGetUserResumeStudyOk = (res: ApiGetResumeStudy | void):
  res is ApiGetResumeStudyOk => res !== undefined && res !== null && (res as ApiGetResumeStudyOk).institution !== undefined;

// DELETE USER RESUME STUDY DATA **********************************************************************
// pathname: /v1/resume/study/{id}
// ************************************************************************************************
export const isDeleteUserResumeStudyOk = (res: ApiDeleteResumeStudy | void):
  res is ApiDeleteResumeStudyOk => res !== undefined && res !== null && Object.keys(res as ApiDeleteResumeStudyOk).length === 0;


// USER RESUME STUDY SPECIALIZATION **********************************************************************
// pathname: /v1/resume/study/specialization/{id}
// ************************************************************************************************
export const isGetUserResumeStudySpecializationOk = (res: ApiGetResumeStudySpecialization | void):
  res is ApiGetResumeStudySpecializationOk => res !== undefined && res !== null && (res as ApiGetResumeStudySpecializationOk).id !== undefined;


// (GET) Resume get video cv **********************************************************************
// pathname: /v1/resume/video
// ************************************************************************************************
export const isResumeVideoCVOk = (res: ApiResumeVideoCV | void): res is ApiResumeVideoCVOk => res !== undefined && res !== null
    && (res as ApiResumeVideoCVOk)?.name !== undefined;

// (DELETE) Resume Delete video cv ****************************************************************
// pathname: /v1/resume/video
// ************************************************************************************************
export const isResumeDeleteVideoOk = (res: ApiResumeDeleteVideo | void): res is object => res !== undefined && res !== null
    && Object.keys(res)?.length === 0;

// (POST) Resume Upload video cv ******************************************************************
// pathname: /v1/resume/video
// ************************************************************************************************
export const isResumeUploadVideoOk = (res: ApiResumeUploadVideo | void): res is object => res !== undefined && res !== null
    && Object.keys(res)?.length === 0;

// USER RESUME SET VIDEO NOTIFICATION STATUS  **********************************************************************
// pathname: /v1/resume/video/notification/{status}
// ************************************************************************************************
export const isResumeSetVideoNotificationStatusOk = (res: ApiResumeSetVideoNotificationStatus)
: res is ApiResumeSetVideoNotificationStatusOk => res !== undefined && res !== null && Object.keys(res as ApiResumeSetVideoNotificationStatusOk).length === 0;


// (POST) Resume Upload picture  ******************************************************************
// pathname: /v1/resume/picture
// ************************************************************************************************
export const isResumePictureUploadOk = (res: ApiResumePicture | void): res is object => res !== undefined && res !== null
    && Object.keys(res)?.length === 0;


// USER RESUME SKILL LIST *************************************************************************
// pathname: /v1/resume/skill/list
// ************************************************************************************************
export const isUserResumeSkillListOk = (res: ApiResumeSkillList | void)
: res is IGenericApiStrings[] => res !== undefined && res !== null && (res as IGenericApiStrings[]).length !== undefined;


// UPLOAD ATTACHMENTS  *****************************************************************************
// pathname: /v1/resume/attachment/upload
// ************************************************************************************************
export const isUserResumeAttachmentUploadOk = (res: ApiResumeAttachmentsUpload | void)
: res is ApiResumeAttachmentsUploadOk => res !== undefined && res !== null
  && (res as ApiResumeAttachmentsUploadOk).length > 0
  && (res as ApiResumeAttachmentsUploadOk)[0].id !== undefined;


// DELETE ATTACHMENT  *****************************************************************************
// pathname: /v1/resume/attachment/remove/{id}
// ************************************************************************************************
export const isUserResumeAttachmentRemoveOk = (res: ApiResumeAttachmentRemove | void)
: res is ApiResumeAttachmentRemoveOk => res === undefined || (res !== null && Object.keys(res).length === 0);

// USER RESUME NEXT JOBS LIST *********************************************************************
// pathname: /v1/resume/next-jobs/list
// ************************************************************************************************
export const isUserResumeNextJobsListOk = (res: ApiResumeNextJobslList | void)
: res is IGenericApiStrings[] => res !== undefined && res !== null && (res as IGenericApiStrings[]).length !== undefined;
