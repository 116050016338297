// Interfaces
// *********************************************************************
interface RoundingClasses {
  [round: string]: string
}

interface NoImgSizes {
  [size: string]: string,
}

interface ImgSizes {
  [size: string]: number,
}

interface CompanyLogoImgSizes {
  [size: string]: {
    width: number,
    height: number,
  },
}

interface AvatarStyles {
  randomColors: string[],
  ring: string,
  rounding: RoundingClasses,
  baseImg: string,
  imgSizes: ImgSizes,
  companyLogoImgSizes: CompanyLogoImgSizes,
  companyLogoSizes: NoImgSizes,
  baseNoImg: string,
  noImgSizes: NoImgSizes,
}


// Avatar component styles
// *********************************************************************
// Array of colors for random avatar (the one without image)
const randomColors = [
  'bg-primary/10 text-primary',
  'bg-secondary/10 text-secondary',
  'bg-warning/10 text-warning',
  'bg-error/10 text-error',
  'bg-ink/10 text-ink',
  'bg-pink/10 text-pink',
  'bg-social-twitter/10 text-social-twitter',
  'bg-amber-600/10 text-amber-600',
  'bg-purple-600/10 text-purple-600',
  'bg-cyan-600/10 text-cyan-600',
];

// Ring classes
const ring = 'outline outline-1 outline-offset-2 outline-ink-border ring-2 ring-surface';

// Base classes with image
const baseImg = 'h-auto aspect-square object-contain';

// Base classes without image
const baseNoImg = 'inline-flex items-center justify-center font-semibold leading-none';

// Rounding classes
const rounding = {
  none: 'rounded-none',
  base: 'rounded',
  full: 'rounded-full',
};

// Size classes
const noImgSizes = {
  none: '',
  xs: 'w-8 h-8 text-xs',
  sm: 'w-9 h-9 text-xs',
  md: 'w-15 h-15 text-base',
  base: 'w-20 h-20 text-xl',
  lg: 'w-32 h-32 text-5xl',
  xl: 'w-44 h-44 text-10xl',
  '2xl': 'w-64 h-64 text-11xl',
  '3xl': 'w-90 h-90 text-13xl',
  fill: 'max-w-fit max-h-fit aspect-square',
};

// Image sizes (in pixels)
const imgSizes = {
  none: 0,
  xs: 24,
  sm: 36,
  md: 60,
  base: 80,
  lg: 128,
  xl: 176,
  '2xl': 256,
  '3xl': 360,
  fill: 0,
};

// Company logo sizes (in pixels) (7/3 ratio)
const companyLogoImgSizes = {
  none: {
    width: 0,
    height: 0,
  },
  xs: {
    width: 56,
    height: 24,
  },
  sm: {
    width: 84,
    height: 36,
  },
  md: {
    width: 140,
    height: 60,
  },
  base: {
    width: 188,
    height: 80,
  },
  lg: {
    width: 300,
    height: 128,
  },
  xl: {
    width: 412,
    height: 176,
  },
  '2xl': {
    width: 412,
    height: 256,
  },
  '3xl': {
    width: 412,
    height: 336,
  },
  fill: {
    width: 0,
    height: 0,
  },
};

const companyLogoSizes = {
  none: 'w-0 h-0',
  xs: 'w-auto h-auto max-w-18 max-h-6',
  sm: 'w-auto h-auto max-w-21 max-h-9',
  md: 'w-auto h-auto max-w-35 max-h-15',
  base: 'w-auto h-auto max-w-46 max-h-20',
  lg: 'w-auto h-auto max-w-74 max-h-32',
  xl: 'w-auto h-auto max-w-102 max-h-44',
  '2xl': 'w-auto h-auto max-w-102 max-h-64',
  '3xl': 'w-auto h-auto max-w-102 max-h-84',
  fill: 'w-auto h-auto object-contain',
};

// Export avatar styles
export const avatarStyles: AvatarStyles = {
  randomColors,
  ring,
  rounding,
  baseImg,
  imgSizes,
  companyLogoImgSizes,
  baseNoImg,
  noImgSizes,
  companyLogoSizes,
};


// Helper function to compose the classes for the avatar with image
// *********************************************************************
export const composeAvatarImgClasses = (round: string, withRing: boolean, className: string): string => {
  const roundClass = avatarStyles.rounding[round];
  const ringClass = withRing ? avatarStyles.ring : '';

  return `${avatarStyles.baseImg} ${roundClass}${ringClass ? ` ${ringClass}` : ''}${className ? ` ${className}` : ''}`;
};


// Helper function to compose the classes for the company logo
// *********************************************************************
export const composeCompanyLogoClasses = (size: string, className: string)
: string => `${avatarStyles.companyLogoSizes[size]}${className ? ` ${className}` : ''}`;


// Helper function to compose the classes for the avatar without image
// *********************************************************************
export const composeAvatarNoImgClasses = (color: string, round: string, size: string, withRing: boolean, className: string): string => {
  const roundClass = avatarStyles.rounding[round];
  const sizeClass = avatarStyles.noImgSizes[size];
  const ringClass = withRing ? avatarStyles.ring : '';

  return `${avatarStyles.baseNoImg} ${color} ${roundClass} ${sizeClass}${ringClass ? ` ${ringClass}` : ''}${className ? ` ${className}` : ''}`;
};
