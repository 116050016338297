import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserOwnResumeStoreState } from '@type/redux';
import { ApiOwnResumeDetailOk, GetPhoneNumberDataApi } from '@type/v1-api-types';

// Define the initial state
const initialState: UserOwnResumeStoreState = {
  userResume: undefined,
  personalData: undefined,
  phoneNumber: undefined,
};

// Define reducers and actions
export const userOwnResumeSlice = createSlice({
  name: 'userOwnResume',
  initialState,
  reducers: {
    // save user resume
    saveUserResume: (state, action: PayloadAction<ApiOwnResumeDetailOk>) => {
      state.userResume = action.payload;
    },
    // reset user resume
    clearUserResume: (state) => {
      state.userResume = initialState.userResume;
    },
    // update resume phone number
    updateResumePhoneNumber: (state, action: PayloadAction<string>) => {
      if (state.userResume?.privateInformation) {
        state.userResume.privateInformation.phone = action.payload;
      }
    },
    // remove attachment from resume
    removeAttachmentById: (state, action: PayloadAction<string>) => {
      if (state.userResume?.privateInformation?.attachments?.length) {
        state.userResume.privateInformation.attachments = state.userResume.privateInformation.attachments
          .filter((attachment) => attachment.id !== action.payload);
      }
    },

    saveDriversLicenceB: (state, action: PayloadAction<boolean>) => {
      if (state.userResume) {
        state.userResume.licenceCatB = action.payload;
      }
    },

    // save personal data
    savePersonalData: (state, action: PayloadAction<ApiOwnResumeDetailOk>) => {
      state.personalData = action.payload;
    },
    // reset personal data
    clearPersonalData: (state) => {
      state.personalData = initialState.personalData;
    },

    // save phone number
    savePhoneNumber: (state, action: PayloadAction<GetPhoneNumberDataApi>) => {
      state.phoneNumber = action.payload;
    },
    // reset phone number
    clearPhoneNumber: (state) => {
      state.phoneNumber = initialState.phoneNumber;
    },

    // reset the user own resume state
    resetUserOwnResumeState: () => initialState,
  },
});

// Export the reducer, either as a default or named export
export default userOwnResumeSlice.reducer;
