import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionPackages, SubscriptionOrderStatus } from '@type/redux';

// Define the initial state
// *******************************************************************
const initialState: SubscriptionPackages = {
  selectedSubscriptionPackage: undefined,
  paymentData: undefined,
  orderData: undefined,
  orderStatus: undefined,
};


// Define reducers and actions
// *******************************************************************
export const subscriptionSlice = createSlice({
  name: 'employerSubscription',
  initialState,
  reducers: {

    // Selected upgrade package. Context: upgrade subscription or buy extra contacts.
    saveSelectedUpgradePackage: (state, action: PayloadAction<ApiSchema<'SubscriptionPackageModel'>>) => {
      state.selectedSubscriptionPackage = action.payload;
    },

    // Subscription payment data.
    savePaymentData: (state, action: PayloadAction<ApiSchema<'SubscriptionPayment'>>) => {
      state.paymentData = action.payload;
    },
    clearPaymentData: (state) => {
      state.paymentData = initialState.paymentData;
    },

    // Payment order data.
    saveOrderData: (state, action: PayloadAction<ApiSchema<'CartOrderResponse2'>>) => {
      state.orderData = action.payload;
    },
    clearOrderData: (state) => {
      state.orderData = initialState.orderData;
    },

    // Payment status. Hide for undefined. Show succes status for true. Show error status for false.
    saveOrderStatus: (state, action: PayloadAction<SubscriptionOrderStatus>) => {
      state.orderStatus = action.payload;
    },
    clearOrderStatus: (state) => {
      state.orderStatus = initialState.orderStatus;
    },

    // Clear all data.
    clearAllSubscriptionData: (state) => {
      state.selectedSubscriptionPackage = initialState.selectedSubscriptionPackage;
      state.paymentData = initialState.paymentData;
      state.orderData = initialState.orderData;
      state.orderStatus = initialState.orderStatus;
    },

  },
});


// Export actions
// When writing to store use like this (you will need to import them though):
// appDispatch(saveSubscriptionUpgradePayment(newData));

// If you can't use the action directly, or don't want to import the actions, use this form instead:
// appDispatch({ type: 'employer/saveSubscriptionUpgradePayment', payload: newData });
// *******************************************************************
export const {
  saveSelectedUpgradePackage,
  savePaymentData,
  clearPaymentData,
  saveOrderData,
  clearOrderData,
  saveOrderStatus,
  clearOrderStatus,
  clearAllSubscriptionData,
} = subscriptionSlice.actions;


// Export reducer
// *******************************************************************
export const subscriptionReducer = subscriptionSlice.reducer;
