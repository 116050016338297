import React, { forwardRef, useState } from 'react';
import { useSingleFormElement } from '@hooks/useBjForm';
import { FormDatePickerInterface, FormElementRefImperative } from 'src/types/form-types';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { format } from 'date-fns';
import { IconCalendar, IconSort } from '@components/common/Forms/DatePickerInput/IconsDatePickerInput';
import { FormError } from '../FormError/FormError';


// Default props by picker type
// Used to simplify the usage of the datepicker
const DEFAULT_PROPS_BY_TYPE = {
  default: {
    dateFormat: 'yyyy-MM-dd',
  },
  month: {
    showMonthYearPicker: true,
    dateFormat: 'yyyy-MM',
  },
  year: {
    showYearPicker: true,
    dateFormat: 'yyyy',
  },
} as Record<string, Partial<ReactDatePickerProps>>;


/**
 * @description DatePickerInput component for useBjForm.
 * * It uses react-datepicker package to handle calendar with a custom input
 *
 * @description Element's Props:
 * * **pickerProps** - react-datepicker package props you can pass (e.g. minDate, maxDate, dateFormat etc.)
 * * **pickerType** - calendar format you can choose to display the date: year, monthly, default
 * * **date** - the date value
 * * **setDate** - the function to set the date value
 * * **onSelectCallback** - the callback function after selecting a date
 * * **wrapperClassName,** - custom class for the date picker wrapper
 * * input props
 * * **className** - pass here your Tailwind classes if you need further customization
 * * **withError** - if you want to show this field's validation error messages; default true
 * * **patternMismatchMessage** - the custom message for pattern mismatch validation; default ''
 * * **customValidityMessages** - the custom validity messages for any html input validation - @link https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
 * * other normal HTML input attributes: required, defaultValue, etc.
 *
 * @remarks When used with **useBjForm** custom hook and the **record** function do not pass a **name** or **id** attribute; these should be handled inside the **record** function
 *
 * @example
 * // without using the 'record' function
 * <DatePickerInput withError={false} name="date" />
 *
 * // using the 'record function'
 * <DatePickerInput required {...record('date')} pickerType="year" />
 */
export const DatePickerInput = forwardRef<FormElementRefImperative, FormDatePickerInterface>((props, ref) => {
  // component props
  const {
    // picker props
    pickerProps = {},
    pickerType = 'default',
    date,
    setDate,
    onSelectCallback,
    wrapperClassName,

    // input props
    className = '',
    required,
    withError = true,
    placeholder = '',
    patternMismatchMessage = '',
    customValidityMessages,
    ...attributes
  } = props;

  // form elements shared logic hook
  const {
    handleInteraction, setElementValue, elementRef, isInvalid, errorMSG, errorClass,
  } = useSingleFormElement({
    ref,
    patternMismatchMessage,
    customValidityMessages,
    className,
  });

  // handles if picker is shown
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);
  // enables opening picker on click
  const [enablePickerOpeningOnInputClick, setEnablePickerOpeningOnInputClick] = useState<boolean>(true);

  // default props by pickerType
  const pickerPropsByType = DEFAULT_PROPS_BY_TYPE[pickerType];

  // date format used for displaying date as string in input.
  // format can be overridden by pickerProps
  const dateFormat = (pickerProps?.dateFormat ?? pickerPropsByType.dateFormat) as string;

  // Rendered component **********************************
  return (
    <>
      <DatePicker
        // default props
        {...pickerPropsByType}
        open={isPickerOpen}
        // datepicker specific package props
        selected={date}
        // set input value after picking from picker card and hide picker
        onChange={(newDate) => {
          setDate(newDate);
          setElementValue(!newDate ? '' : format(newDate, dateFormat));

          if (newDate) {
            setEnablePickerOpeningOnInputClick(false);
            setIsPickerOpen(false);

            // optional callback function after selecting a date
            if (onSelectCallback) {
              onSelectCallback(format(newDate, dateFormat));
            }
          }
        }}
        // make sure input value is related with Date value
        // set input value from date after clicking outside
        onClickOutside={() => {
          setIsPickerOpen(false);
          setElementValue(!date ? '' : format(date, dateFormat));
        }}
        // check input validation
        onBlur={(event) => {
          if (required) {
            handleInteraction(event);
          }
        }}
        // custom render using input
        customInput={(
          // first div it used for react-datepicker props. it overrides all the other props that are set
          <div>
            <div className="relative">
              <div className="absolute flex size-full justify-between">
                {/* left icon */}
                <div className="flex h-full flex-col justify-center pl-4">
                  <IconCalendar />
                </div>
                {/* right icon */}
                <div className="flex h-full flex-col justify-center pr-4">
                  <IconSort />
                </div>
              </div>
              {/* input text */}
              <input
                // called when clicking on input or when changing value from picker
                onChange={() => {
                  setElementValue(!date ? '' : format(date, dateFormat));
                }}
                onClick={() => {
                  if (enablePickerOpeningOnInputClick) {
                    // opens date picker
                    setIsPickerOpen(true);
                    return;
                  }
                  // enables default behaviour
                  setEnablePickerOpeningOnInputClick(true);
                }}
                className={`px-10 ${className}${isInvalid ? errorClass : ''}`}
                ref={elementRef}
                placeholder={placeholder}
                required={required}
                type="text"
                minLength={1}
                {...pickerProps?.selected && { defaultValue: format(pickerProps?.selected, dateFormat) }}
                {...attributes}
              />
            </div>
          </div>
        )}
        showPopperArrow={false}
        {...wrapperClassName && { wrapperClassName }}
        // date picker native props you can set as an extra
        {...pickerProps}
      />
      { // input's error field - show only if input is required and withError is true (default true)
        required && withError && <FormError errorMessage={errorMSG} isShowing={isInvalid} />
      }
    </>
  );
});
