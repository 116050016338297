// USER RESUME COMMENTS ***************************************************************************
// pathname: /v1/user-resume-comments/{userSlug}
// ************************************************************************************************
import {
  useSwrApi, ApiUserResumeComments, ApiUserResumeCommentOk,
} from 'src/hooks/useSwrApi';


interface UseSwrUserResumeComments {
  locale: string,
  userSlug: string,
  serverSideData?: ApiUserResumeComments,
  shouldFetch?: boolean
}


/**
 * @description SWR: fetch user resume comments.
 * * **locale**: string - the locale to use for the request. Default 'ro'.
 * * **userSlug**: string - the user slug to get the comments for.
 * * **serverSideData**: ApiUserResumeComments - the data we got from the server side request (if we do it). Default undefined.
 */
export const useSwrUserResumeComments = (props: UseSwrUserResumeComments) => {
  // Props destructuring
  const {
    locale = 'ro',
    userSlug,
    serverSideData,
    shouldFetch = true,
  } = props;


  // SWR: fetch data
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiUserResumeCommentOk[]>({
    url: `/v1/user-resume-comments/${userSlug}`,
    locale,
    shouldFetch,
    serverSideData,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 5, // 5 minutes
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
