import { compileToPath, getRegexPathParams } from '@utils/path-to-regexp';
import { pathToRegexp } from 'path-to-regexp';
import { I18nLangPaths, SwitcherPathsInterface, RegexSwitcherPaths } from 'src/types/i18n-paths';
import { i18nConfig } from 'i18n-config';
import { localesRegex, setLocalePrefix } from './helpers_all';
import { i18nBasePaths } from './paths';


/**
 * @description Get the helper variables from the i18nConfig object.
 */
const { locales } = i18nConfig;


/**
 * @description Make an array with the objects containing the i18n paths from the main
 * i18nBasePaths object.
 *
 * @example
 * const i18nSwitcherPaths = [
 *    {ro: 'path', en: 'path', hu: 'path'},
 *    {ro: 'path', en: 'path', hu: 'path'},
 *    ...,
 * ]
 */
const i18nSwitcherPaths: I18nLangPaths[] = Object.values(i18nBasePaths);


/**
 * @description Transform URLs (from previous step) to routing regex. We also keep the source path
 * that was used for regex (keyL source).
 *
 * @example
 * const regexSwitcherPaths = [
 *  {
 *    ro: {pattern: 'Regex string', source: 'source pathname'},
 *    en: {pattern: 'Regex string', source: 'source pathname'},
 *    hu: {pattern: 'Regex string', source: 'source pathname'}
 *  },
 *  ...,
 * ]
 */
const regexSwitcherPaths: RegexSwitcherPaths[] = i18nSwitcherPaths
  .map(
    (group) => Object.fromEntries(Object.entries(group)
      .map(
        (entry) => [entry[0], { pattern: pathToRegexp(entry[1]).regexp, source: entry[1] }],
      )),
  );


/**
 * @description Check if the url parameter matches an i18n path and return the containing object.
 * Because we want to be efficient and save us from another check, we also send the current lang on
 * which we had a match.
 * @param asPath string, the current page path, as shown in browser (including search params)
 *
 * @example
 * getSwitcherI18nPaths('/url-localized');
 * // returns if we find true
 * [
 *  ro,
 *  {
 *    ro: {pattern: 'Regex string', source: 'source pathname'},
 *    en: {pattern: 'Regex string', source: 'source pathname'},
 *    hu: {pattern: 'Regex string', source: 'source pathname'}
 *  },
 * ]
 */
const getSwitcherI18nPaths = (asPath: string): ([string, RegexSwitcherPaths] | undefined) => {
  let langMatch = '';

  // strip asPath of the search params
  const cleanPath = asPath.split('?')[0];

  // check if the asPath matches any of the regex paths
  const pathsMatch = regexSwitcherPaths.find((item) => Object.entries(item)
    .find((e) => {
      if (e[1].pattern.test(cleanPath)) {
        [langMatch] = e;
        return true;
      }
      return undefined;
    }));

  return pathsMatch ? [langMatch, pathsMatch] : undefined;
};


/**
 * @description Transform the urls into usable form with proper dynamic parameters.
 *
 * - `regexPaths`: [string, RegexSwitcherPaths], the regex paths for the current page
 * - `asPath`: string, the current page path, as shown in browser (including search params)
 */
const transformSwitcherRegexPaths = (regexPaths: [string, RegexSwitcherPaths], asPath: string) => {
  // array destructuring
  const [lang, paths] = regexPaths;

  // extract clean path and search parameters from asPath
  const [cleanPath, rawSearchParams] = asPath.split('?');

  // construct searchParams if they exist
  const searchParams = rawSearchParams ? `?${rawSearchParams}` : '';

  // get the dynamic parameters (if any) from path (ie: {id: "confidentiality"})
  const dynamicParams = getRegexPathParams(cleanPath, paths[lang]);

  // transform paths into a bi-dimensional array with the proper links
  return Object.entries(paths).map(
    (entry) => [entry[0], `${setLocalePrefix(entry[0])}${compileToPath(entry[1].source, dynamicParams)}${searchParams}`],
  );
};


/**
 * @description A bi-dimensional array for the regular urls, not translated (not i18n). This will run
 * 99% percent of the times.
 * @param asPath string, the current page path, as shown in browser (including search params)
 *
 * @example
 * // returns
 * [['ro', 'ro-url'], ['en', 'en-url'], ['hu', 'hu-url']]
 */
const getSwitcherNormalPaths = (asPath: string) => {
  const result: string[][] = [];

  // modify the paths for the switcher
  locales.forEach((locale) => {
    result.push([locale, `${setLocalePrefix(locale, asPath)}${asPath}`]);
  });
  return result;
};


/**
 * @description Function that will return a bi-dimensional array which we will use for building
 * the language switcher urls or meta alternates. If we have i18n paths we will return those,
 * otherwise we return the normal paths.
 * @param asPath string, the current page path, as shown in browser (including search params)
 *
 * @example
 * switcherPaths.map((lang) => {
 *   const langName = lang[0];
 *   const urlPath = lang[1];
 *   return (
 *     <Link href={urlPath} locale={langName} key={langName}>{ langName }</Link>
 *   );
 * });
 */
export const getSwitcherPaths: SwitcherPathsInterface = (asPath) => {
  // strip the locale from the path
  const cleanPath = asPath?.replace(localesRegex, '');

  // get the i18n paths if any
  const i18nPaths = getSwitcherI18nPaths(cleanPath);

  return (
    i18nPaths !== undefined
      ? transformSwitcherRegexPaths(i18nPaths, cleanPath)
      : getSwitcherNormalPaths(cleanPath)
  );
};
