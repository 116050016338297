import { ApiHeaderInfoOk } from 'src/types/v1-api-types';
import { ErrorBoundary, LinkStyled } from '@components/common';
import { LogoBjFull } from '@components/layout/LogoBj/LogoBjFull';
import { LogoBj } from './LogoBj';

interface LogoBjInterface {
  headerInfo?: ApiHeaderInfoOk;
  className?: string;
  iconColor?: string;
  textColor?: string;
  width?: string;
  height?: string;
  imageClassName?: string;
}

/**
 * @description Bestjobs logo component. This component has links to the jobs pages, depending on
 * the user role. If the user is not authenticated, the link will be to the home page.
 */
export const LogoBjLink = (props: LogoBjInterface) => {
  const {
    headerInfo,
    className = '',
    iconColor = 'fill-secondary',
    textColor = 'fill-ink',
    width = '100%',
    height = '100%',
    imageClassName = '',
  } = props;

  const isCompany = !headerInfo || (headerInfo && headerInfo.role === 'COMPANY');
  const isUser = !headerInfo || (headerInfo && headerInfo.role === 'USER');

  // If the user is authenticated, we need to redirect him to the correct homepage page
  // *******************************************************************************
  if (headerInfo) {
    return (
      <ErrorBoundary
        fallback={<div className="py-1.5 text-xl font-bold">Bestjobs</div>}
      >
        <LinkStyled
          href="/"
          aria-label="bestjobs"
          className={className}
          styling="none"
        >
          <LogoBj />
        </LinkStyled>
      </ErrorBoundary>
    );
  }

  // If the user is not authenticated, we set the link to the home page or live page.
  // *******************************************************************************
  return (
    <ErrorBoundary
      fallback={<div className="py-1.5 text-xl font-bold">Bestjobs</div>}
    >
      <LinkStyled
        href="/"
        aria-label="bestjobs"
        className={className}
        styling="none"
      >
        {isCompany || isUser ? (
          <LogoBj />
        ) : (
          <LogoBjFull
            iconColor={iconColor}
            textColor={textColor}
            width={width}
            height={height}
            className={imageClassName}
          />
        )}
      </LinkStyled>
    </ErrorBoundary>
  );
};
