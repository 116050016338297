import { RootState } from '@store/store';

// Get the selected upgrade package.
export const getSelectedSubscriptionPackage = (state: RootState) => state.employer.subscription.selectedSubscriptionPackage;

// Get the subscription upgrade payment data.
export const getPaymentData = (state: RootState) => state.employer.subscription.paymentData;

// Get the payment order data.
export const getOrderData = (state: RootState) => state.employer.subscription.orderData;

// Get the automatic payment status.
export const getOrderStatus = (state: RootState) => state.employer.subscription.orderStatus;
