import { combineReducers } from '@reduxjs/toolkit';
import { employerListsReducer } from '@store/reducers/employer/lists/listsReducer';
import { resumeReducer } from '@store/reducers/employer/resume/resumeReducer';
import { subscriptionReducer } from './employer/subscription/subscriptionReducer';


// Export the combined reducers as a single 'employer' reducer
// *******************************************************************
export default combineReducers({
  subscription: subscriptionReducer,
  lists: employerListsReducer,
  resume: resumeReducer,
});
