// IMPORTS ****************************************************************************************
// ************************************************************************************************
import {
  ApiBurgerMenu,
  ApiBurgerMenuOk,
  ApiLocationTooltipOk,
  ApiLocationTooltip,
  ApiJobTitleAutocomplete,
  ApiJobTitleAutocompleteOk,
  ApiTagCloudOk,
  ApiTagCloud,
  ApiGetDataLayerOk,
  ApiGetDataLayer,
  ApiPromotedEmployersResultOk, ApiPromotedEmployers,
} from 'src/types/v1-api-types';


// BURGER MENU ************************************************************************************
// pathname: /v1/burger-menu
// ************************************************************************************************
export const isBurgerMenuOk = (res: ApiBurgerMenu | void)
: res is ApiBurgerMenuOk => res !== null && res !== undefined && Array.isArray(res) && res[0]?.label !== undefined;


// LOCATION TOOLTIP *******************************************************************************
// pathname: /v1/location-tooltip
// ************************************************************************************************
export const isLocationTooltipOk = (res: ApiLocationTooltip | void)
: res is ApiLocationTooltipOk[] => res !== null && res !== undefined && (res as ApiLocationTooltipOk[])?.length >= 0;


// JOB TITLE AUTOCOMPLETE *************************************************************************
// pathname: /job-title-autocomplete
// ************************************************************************************************
export const isJobTitleAutocompleteOk = (res: ApiJobTitleAutocomplete | void)
: res is ApiJobTitleAutocompleteOk => (res as ApiJobTitleAutocompleteOk)?.name !== undefined;


// TAG CLOUD **************************************************************************************
// pathname: /v1/tag-cloud
// ************************************************************************************************
export const isTagCloudOk = (res: ApiTagCloud | void)
: res is ApiTagCloudOk => res !== null && res !== undefined && Array.isArray(res) && res[0]?.type !== undefined;

// TAG CLOUD **************************************************************************************
// pathname: /v2/promoted-employers
// ************************************************************************************************
export const isPromotedEmployersOk = (res: ApiPromotedEmployers | void)
: res is ApiPromotedEmployersResultOk => res !== undefined && res !== null && (res as ApiPromotedEmployersResultOk)?.items !== undefined;


// GET DATA LAYER CONTENT *************************************************************************
// pathname: /v1/datalayer/content
// ************************************************************************************************
export const isGetDataLayerOk = (res: ApiGetDataLayer | void)
: res is ApiGetDataLayerOk => res !== undefined && res !== null && (res as ApiGetDataLayerOk)?.content !== undefined;
