// pathname: /v1/company/check-identifier/${identifier}
// ************************************************************************************************
import {
  ICompanyCheckIdentifier,
  useSwrMutationApi,
} from 'src/hooks/useSwrApi';

/**
 * @description SWR mutation: get company details by cui.
 * the payload.
 * @param locale
 * @param identifier
 */
export const useSwrMutationCheckIdentifier = (locale = 'ro', identifier = '') => {
// SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ICompanyCheckIdentifier, object>({
    url: `/v1/company/check-identifier/${identifier}`,
    locale,
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
