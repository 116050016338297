import { Button, ErrorBoundary, PopoverStyled } from '@components/common';
import { toggleMenu, closeMenu } from 'src/components/layout/Header/MainNavbar/config';
import { MobileMenuInterface } from '@type/header-types';
import { MainNavbarLink } from '@components/layout/Header/MainNavbar/links/MainNavbarLink';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { ProfileAvatar } from '@components/layout/Header/MainNavbar/profile/ProfileAvatar';
import { useTranslation } from 'next-i18next';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { LanguageList } from '@components/layout/LanguageSwitch/LanguageList';
import { clsx } from 'clsx';
import { BurgerMenuLinks, LoginButton } from '../links';

/**
 * @description Renders the mobile menu. It's a popover with a hamburger button and imports the
 * links from the links folder.
 * * **headerInfo** - The headerInfo data
 * * **locale** - The current app locale
 * * **currentRoute** - The current route - used to mark the active link
 * * **className** - Any additional classes to add to the component
 */
export const MobileMenu = (props: MobileMenuInterface) => {
  // Main component props with default values
  const {
    headerInfo = undefined,
    className,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  return (
    <PopoverStyled className={className}>
      {({ open, close }) => {
        // Remove the overflow from the body when the menu is closed, because closing the menu
        // might be triggered externally. First check if the window object exists, because this
        // component is also used in SSR.
        if (typeof window !== 'undefined' && !open) closeMenu();

        // Render the rest of the component
        return (
          <>
            {/* Mobile Menu button */}
            {headerInfo ? (
              <PopoverStyled.Button
                as={Button}
                styling="none"
                className="flex flex-col items-center justify-center outline-none"
                onClick={() => toggleMenu(!open)}
              >
                <ProfileAvatar headerInfo={headerInfo} />
                <span className="text-tiny">{t('global.label.account')}</span>
              </PopoverStyled.Button>
            ) : (
              <PopoverStyled.Button
                as={MainNavbarLink}
                route="login"
                type="symfony"
                label="global.label.menu"
                icon={UserCircleIcon}
                onClick={() => toggleMenu(!open)}
              />
            )}

            {/* Overlay - for navbar background when opening menu on transparent theme */}
            <PopoverStyled.Overlay className="absolute inset-0 -z-1 bg-surface" />

            {/* Dropdown */}
            <PopoverStyled.Panel className="!fixed !inset-0 flex h-dvh w-screen flex-col overflow-y-auto bg-surface-50 shadow-none" position="right">

              {/* Dropdown Header */}
              <div className="flex items-center px-6 py-4">
                {headerInfo && (
                  <span className="text-lg font-bold">{t('header.label.profile')}</span>
                )}
                <Button
                  color="light"
                  size="sm"
                  className="ml-auto !rounded-full !p-1.5"
                  onClick={close}
                >
                  <XMarkIcon className="size-5" />
                </Button>
              </div>

              {/* Login button */
                !headerInfo && <div className="px-6 pb-4 pt-0"><LoginButton className="w-full" /></div>
              }

              { /* Links: burger menu */}
              <div className="border-t py-6">
                { headerInfo && <BurgerMenuLinks closePopoverDesktop={close} /> }
              </div>

              {/* Language switcher */}
              <ErrorBoundary fallback={<div className="ml-4 mr-2 size-4.5 rounded-full bg-error md:mr-4" />}>
                <div className={clsx('px-0.5 py-4', headerInfo && 'mt-auto')}>
                  <LanguageList withCurrentLocaleName />
                </div>
              </ErrorBoundary>

            </PopoverStyled.Panel>
          </>
        );
      }}
    </PopoverStyled>
  );
};
