/**
 * @description Get the last digit of a number.
 * @param integer - the number
 * @param fallbackInteger - the number to return if the integer is undefined
 *
 * @example
 * getLastDigit(123) // 3
 * getLastDigit(1234) // 4
 * getLastDigit(8) // 8
 */
export const getLastDigit = (integer: string | number | undefined, fallbackInteger = 0) => {
  let num = integer;

  // short-circuit if undefined
  if (num === undefined) return fallbackInteger;

  // if type string, try to parse it to integer
  if (typeof num === 'string') {
    num = Number.isNaN(parseInt(num, 10)) ? fallbackInteger : parseInt(num, 10);
  }

  return num % 10;
};
