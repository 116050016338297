import { convertEmptyValue } from '@utils/convert-empty-value/convert-empty-value';

/**
 * @description Remove 'empty-sh' object keys. First level keys only (use it with flattened objects).
 * @description The empty-sh values are the ones from {@link convertEmptyValue}
 *
 * @param obj
 *
 * @example
 * const someObject = {
 *   'user.firstName': 'John',
 *   'user.lastName': 'Doe',
 *   'user.location': '',
 *   'user.age': null,
 *   'user.sex': undefined,
 * }
 *
 * const cleanedObject = objectRemoveEmptyKeys(someObject);
 *
 * // cleanedObject = {
 * //   'user.firstName': 'John',
 * //   'user.lastName': 'Doe',
 * // }
 */
export const objectRemoveEmptyKeys = (obj: object): object => {
  const cleanedObj: object = obj;
  Object.keys(cleanedObj).forEach((key) => {
    const value = convertEmptyValue('toString', cleanedObj[key as keyof typeof cleanedObj]);
    if (value === '') {
      delete cleanedObj[key as keyof typeof cleanedObj];
    }
  });

  return cleanedObj;
};
