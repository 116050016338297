import Image from 'next/image';
import { ImageBjProps, ImageBjLoader, ImageFolders } from '@type/image-bj';
import { isStaticImport, stringSrcIsBlob } from 'src/type-predicates/image-bj';
import { getImgProxyUrl, getFolderFromSrc } from '@services/image/imgproxyHashing';
import { isFullUrl } from '@utils/data-fetching/utils';
import { fileNameFromUrl } from '@utils/strings/fileNameFromUrl';


/**
 * @description ImageBj component that wraps Next Image component and add a custom 'loader'. This allows us to process the image with imgproxy,
 * and dramatically reduce the image size for faster loading times.
 * When the src is just the image name or static import, it will use the loader.
 * When the src is a full url (ie: string starting with http or https), it will not use the loader and the image will not be
 * processed with imgproxy.
 *
 * - **imgFolder:** The folder where the image is located. If not provided, it will default to 'next' if the src is a static import.
 * If the src is a full url, and the string doesn't include one of the folders it will default to 'none' and the image will not be processed with imgproxy.
 * Else, it will automatically extract the folder from the src string. But it's recommended to provide the folder, as it will be future-proof when API changes.
 * - **aspectRatio:** The aspect ratio of the image: default' | 'square-fill' | 'square-fit' | 'square-uncertain' | 'landscape'. These will be passed to the imgproxy.
 * - **extractImageName:** If true, it will extract the image name from the src string for the imgProxy loader as long as you also an *imgFolder* or the src
 * includes the image folder, ie: https://example.com/resume_image/image-name.jpg.
 * - **the rest of the props of the Next.js Image component.**
 *
 * Image folders:
 * - **none:** no url preset;
 * - **next:** static images from the _next folder (imported images with 'import xxx from "@images/xxx.jpg"');
 * - **public:** images from the Next.js public folder; this references the root public folder, so you will need to add the rest of the path, ie: images/avatars/;
 * - **resume_image:** resume images from the Google Storage bucket;
 * - **resume_attachment:** resume attachments from the Google Storage bucket;
 * - **employer_logo:** employer logos from the Google Storage bucket.
 * - **employer_gallery:** employer galleries from the Google Storage bucket.
 * - **feed_image:** feed images from the Google Storage bucket.
 *
 * Keep in mind that resume images are stored in the Google Storage bucket, but are mixed with local default avatars. The adjustments for path
 * are made inside the {@link getImgProxyUrl} function.
 *
 * Aspect ratios:
 * - **default:** Default aspect ratio, no aspect-ratio processing will be done for this value. Used mostly for static imports.
 * - **square-fill:** Square aspect ratio with fill. Used for resume images.
 * - **square-fit:** Square aspect ratio with fit. Used for employer logos in certain cases: messages, employer profile, etc.
 * - **square-uncertain:** Square aspect ratio that can be either fill or fit. Use this when the aspect ratio is uncertain, ie: message avatars,
 * that can be either resume images (fill) or employer logos (fit).
 * - **landscape:** 7:3 aspect ratio. Used for employer logos on cards or anywhere where we don't need a square but still need a minimum
 * of constraint.
 *
 * ** IMPORTANT: ** While the examples provide methods for both resume images and employer logos, it's best to use the
 * Avatar component in those cases, as it provides a more consistent and future-proof way of handling these images, and also
 * provides a fallback for when the image is not available.
 *
 *
 * @example
 * // Using it just like the regular 'Image' component from Next.js. Note that in this case, the image will
 * // not be processed with imgproxy (full url string & no 'extractImageName' prop).
 * <ImageBj
 *    src="https://www.example.com/image.jpg"
 *    width={200}
 *    height={200}
 *    alt="Image"
 * />
 *
 *
 * // Triggering the image processing with imgproxy.
 * // *******************************************************
 * // 1. When using static imported images, you don't need to specify the folder, static import is automatically
 * // detected. Don't pass the 'extractImageName' prop. Processed with imgproxy.
 * import someImage from '@images/image.jpg';
 * ...
 * <ImageBj
 *    // no need to pass width and height, it will be automatically detected from static import
 *    src={someImage}
 *    alt="Image"
 * />
 *
 *
 * // 2. When using the Next.js public folder: public folder has only the base URL, so you need to add the rest of the
 * // path in front of the image name. Don't pass the 'extractImageName' prop. Processed with imgproxy.
 * <ImageBj
 *    src="images/avatars/avatar-male-01.jpg"
 *    imgFolder="public"
 *    alt="Image"
 *    width={80}
 *    height={80}
 * />
 *
 *
 * // 3. Extract the image name from the src string from a bestjobs API response. It is recommended to also pass
 * // the 'imgFolder' prop if you know it. IF you don't know it, or it can be of multiple types, we will try to extract
 * // the folder name from the full URL. 'extractImageName' prop is required. Processed with imgproxy.
 * <ImageBj
 *    src="https://example.com/employer_logo/employer-logo-1234.jpg"
 *    // this is optional, but recommended, may be useful in the future or if the URL doesn't include the folder name
 *    imgFolder="employer_logo"
 *    extractImageName
 *    alt="Image"
 *    width={80}
 *    height={80}
 * />
 *
 *
 * // 4. API provides both the image name and the folder name (will become the norm). Processed with imgproxy.
 * // apiObject = {resume_image: 'image-name.jpg'}
 * <ImageBj
 *   src={apiObject.resume_image}
 *   imgFolder="resume_image"
 *   alt="Image"
 *   width={80}
 *   height={80}
 * />
 *
 *
 * // 5. Using the 'square-uncertain' aspect ratio. This is useful when the aspect ratio is uncertain, ie: message avatars.
 * // Let's say we have a message avatar that can be either a resume image or an employer logo like:
 * // - https://example.com/resume_image/image-name.jpg or https://example.com/employer_logo/employer-logo-1234.jpg.
 * // In this case, we will use the 'square-uncertain' aspect ratio. Processed with imgproxy.
 * const apiImgPath = 'https://example.com/resume_image/image-name.jpg' || 'https://example.com/employer_logo/employer-logo-1234.jpg';
 * <ImageBj
 *   src={apiImgPath}
 *   aspectRatio="square-uncertain"
 *   extractImageName
 *   alt="Image"
 *   width={80}
 *   height={80}
 * />
 *
 *
 * // 6. Blob source. No loader will be used in this case, but you can mix it with the 'extractImageName' prop. This is useful
 * // when you have a blob source from upload, but you want to also display the image from Google storage later.
 * const src = stringSrcIsBlob(stringSrc) ?
 * ? 'blob:https://example.com/1234-5678-9101-1121'
 * : 'https://example.com/employer_logo/employer-logo-1234.jpg';
 *
 * <ImageBj
 *   src={src}
 *   // the image will be processed with imgproxy if the src is not a blob (doesn't start with 'blob:')
 *   imgFolder="employer_logo"
 *   extractImageName
 *   alt="Image"
 *   width={80}
 *   height={80}
 * />
 *
 */
export const ImageBj = (props: ImageBjProps) => {
  // Destructure props
  const {
    src: iSrc,
    imgFolder,
    aspectRatio = 'default',
    extractImageName = false,
    loader,
    ...restProps
  } = props;


  // Check if we're dealing with a blob source. No loader will be used in this case.
  const isBlob = stringSrcIsBlob(iSrc);

  // Extract the image folder from the src string. This is used when the extractImageName prop is true and imageFolder is undefined.
  const extractedFolder = extractImageName && typeof iSrc === 'string' && !imgFolder ? getFolderFromSrc(iSrc) : undefined;

  // Extract the image name from the image source if the extractImageName prop is true.
  const imageSrc = extractImageName && typeof iSrc === 'string' && !isBlob && !isStaticImport(iSrc) ? fileNameFromUrl(iSrc) || '' : iSrc;

  // Determine if we're dealing with a full url without the extractImageName prop. We will not use the loader in this case.
  const isFullUrlImage = typeof imageSrc === 'string' && !extractImageName && isFullUrl(imageSrc);


  // Determine folder
  // *************************************
  let imageFolder: ImageFolders;
  switch (true) {
    // provided folder case
    case imgFolder !== undefined && !isFullUrlImage && !isBlob:
      imageFolder = imgFolder;
      break;
    // static import from next.js case
    case (imgFolder === undefined && isStaticImport(imageSrc)):
      imageFolder = 'next';
      break;
    // if no folder is provided and the image is not a static import, we will try to extract the folder from the src string
    case (imgFolder === undefined && extractedFolder !== undefined):
      imageFolder = extractedFolder;
      break;
    // placeholder avatar images case (user has not uploaded an image)
    case (imgFolder === undefined && extractedFolder === undefined && typeof imageSrc === 'string' && imageSrc.startsWith('img-profile-pic-placeholder-')):
      imageFolder = 'resume_image';
      break;
    default:
      imageFolder = 'none';
  }


  // Detect the correct aspect ratio for the image in certain cases. For example in messages the aspect-ratio is uncertain,
  // we want to have a square-fit for company logo and square-fill for resume image.
  // *************************************
  const detectedAspectRatio = aspectRatio === 'square-uncertain' && imageFolder === 'employer_logo' ? 'square-fit' : 'square-fill';


  // Loader function
  // *************************************
  const imageBjLoader: ImageBjLoader = (
    { src, width, quality },
  ) => getImgProxyUrl(src, width, quality, imageFolder, aspectRatio === 'square-uncertain' ? detectedAspectRatio : aspectRatio);


  // Render component
  // *************************************
  return (
    <Image
      src={imageSrc}
      {...(imageFolder !== 'none' ? {
        loader: imageBjLoader,
      } : {})}
      {...restProps}
    />
  );
};
