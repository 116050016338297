// IMPORTS ****************************************************************************************
// ************************************************************************************************
import {
  ApiGetPhoneNumber,
  ApiGetPhoneNumberOk,
  ApiHeaderInfo,
  ApiHeaderInfoOk,
  ApiOwnResumeDetail,
  ApiOwnResumeDetailOk,
  ApiUserPhoneConfirm,
  ApiUserPhoneConfirmOk,
  ApiUserPhoneInfo,
  ApiUserPhoneInfoOk,
  ApiUserPhoneVerify,
  ApiUserPhoneVerifyOk,
  ApiUserResumeCommentOk,
  ApiUserResumeComments,
  ApiUserSlugById,
  ApiUserSlugByIdOk,
} from 'src/types/v1-api-types';


// HEADER INFO ************************************************************************************
// pathname: /v1/header-info
// ************************************************************************************************
export const isHeaderInfoOk = (res: ApiHeaderInfo | void)
: res is ApiHeaderInfoOk => res !== undefined && res !== null && (res as ApiHeaderInfoOk)?.role !== undefined;


// OWN RESUME DETAIL ******************************************************************************
// pathname: /v1/own-resume-detail
// ************************************************************************************************
export const isOwnResumeDetailOk = (res: ApiOwnResumeDetail | void)
: res is ApiOwnResumeDetailOk => (res as ApiOwnResumeDetailOk)?.id !== undefined;


// PHONE NUMBER INFO ******************************************************************************
// pathname: /v1/phone-number/info
// ************************************************************************************************
export const isPhoneNumberInfoOk = (res: ApiUserPhoneInfo | void)
: res is ApiUserPhoneInfoOk => res !== undefined && res !== null && (res as ApiUserPhoneInfoOk).isConfirmed !== undefined;


// PHONE NUMBER VERIFY ***************************************************************************
// pathname: /v1/phone-number/verify
// ************************************************************************************************
export const isPhoneNumberVerifyOk = (res: ApiUserPhoneVerify | void)
: res is ApiUserPhoneVerifyOk => res !== undefined && res !== null && Object.keys(res as ApiUserPhoneVerifyOk).length === 0;


// PHONE NUMBER CONFIRM ***************************************************************************
// pathname: /v1/phone-number/confirm
// ************************************************************************************************
export const isPhoneNumberConfirmOk = (res: ApiUserPhoneConfirm | void)
: res is ApiUserPhoneConfirmOk => res !== undefined && res !== null && Object.keys(res as ApiUserPhoneConfirmOk).length === 0;


// USER RESUME COMMENTS ***************************************************************************
// pathname: /v1/user-resume-comments/{userSlug}
// ************************************************************************************************
export const isUserResumeCommentsOk = (res: ApiUserResumeComments | void)
: res is ApiUserResumeCommentOk[] => res !== undefined && res !== null && Array.isArray(res) && (res)?.length > 0 && res[0]?.id !== undefined;


// Get User Slug By Id *******************************************************************
// pathname: /v1/user-slug/{id}
// ************************************************************************************************
export const isApiUserSlugByIdOk = (res: ApiUserSlugById | void)
: res is ApiUserSlugByIdOk => res !== undefined && res !== null && typeof res === 'string';


// GET PHONE NUMBER ******************************************************************************
// pathname: /v1/user/phone-number
// ************************************************************************************************
export const isUserGetPhoneNumberOk = (res: ApiGetPhoneNumber | void)
: res is ApiGetPhoneNumberOk => res !== undefined && res !== null
  && (res as ApiGetPhoneNumberOk).data !== undefined;
