// Brand icons
export * from './Brands';

// Custom Solid Icons
export * from './CustomSolid';

// Custom Outline Icons
export * from './CustomOutline';

// Types (ie: HeroIconType => Use it when you need to mix custom icons with hero-icons
// (like burgerMenu icons).
export * from 'src/types/icons';
