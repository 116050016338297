// IMPORTS ****************************************************************************************
// ************************************************************************************************
import { GenericV1ErrorsArray, SearchGeneratorInterfaceOk, SearchGeneratorInterface } from 'src/types/v1-api-types/_global';


// TYPE PREDICATES ********************************************************************************
// Avoid type casting hell with type predicates.
// Find out more about creating and using them at
// https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
// ************************************************************************************************
/**
 * @description Check if we're dealing with the response errors object.
 * @param obj
 */
export const isApiV1Errors = <T>(obj: GenericV1ErrorsArray | T):
  obj is GenericV1ErrorsArray => obj !== undefined
  && obj !== null
  // the magic is here; check for an object key that does not exist in T (or w/e other interface)
  && (obj as GenericV1ErrorsArray)?.errors !== undefined;


// SEARCH GENERATOR *******************************************************************************
// pathname: https://www.bestjobs.eu/complete/api/as/v1/engines/bestjobs/query_suggestion
// ************************************************************************************************
export const isSearchGeneratorOk = (res: SearchGeneratorInterface | void)
: res is SearchGeneratorInterfaceOk => res !== undefined && res !== null && (res as SearchGeneratorInterfaceOk)?.results !== undefined;
