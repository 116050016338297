import { useCallback, useEffect, useState } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { ILLMStartStreamPayload, ILLMStreamConfig, LLMResponseMessageJobAdd } from '@type/hooks-functions/llm-stream';

// llm base url
const LLM_BASE_URL = process.env.NEXT_PUBLIC_OPEN_API_URL as string;

// controller to abort the stream. used outside the hook to remove stopStream dependency
let controller: AbortController | null = null;

/**
 * @description - Custom hook to start and stop LLM stream
 *
 * @param {ILLMStreamConfig} - configuration object
 * - urlPath: string - url path to start the stream
 * - callback: (data: LlmStreamCallbackData) => void - callback function to handle stream messages
 *
 * @example
 *
 * const { startStream, stopStream } = useLLMStream({
 *   urlPath: '/add-job',
 *   callback: (data) => {console.log(data);}
 * });
 *
 * startStream({
 *  message: 'Hello',
 *  });
 */
export const useLLMStream = ({ callback, urlPath }: ILLMStreamConfig) => {
  // received message string. it contains all the content received from the stream
  const [receivedMessage, setReceivedMessage] = useState<string | null>(null);
  // flag to check if end of stream is reached
  const [isEndOfStream, setIsEndOfStream] = useState<boolean>(false);

  // stops the stream function
  const stopStream = useCallback(() => {
    controller?.abort();
    controller = null;
  }, []);

  // stops the stream on unmount
  useEffect(() => () => stopStream(), [stopStream]);

  // receives the message and calls the callback
  useEffect(() => {
    if (!receivedMessage?.length) {
      return;
    }

    // if end of stream is reached, send the message and set the state to initial
    if (isEndOfStream) {
      callback({ message: receivedMessage, isEnd: true });
      setIsEndOfStream(false);
      setReceivedMessage(null);
      return;
    }

    // if message is received, send the message
    callback({ message: receivedMessage, isSuccess: true });
  }, [isEndOfStream, receivedMessage, callback]);


  // starts the stream and listens for messages
  const startStream = useCallback(
    async (
      payload: ILLMStartStreamPayload,
    ) => {
      const newController = new AbortController();
      controller = newController;

      // fetch the event source
      await fetchEventSource(`${LLM_BASE_URL}${urlPath}`, {
        signal: newController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(payload?.headers ?? {}),
        },
        body: JSON.stringify({
          config: {},
          input: {
            message: payload.message,
            messages: [],
            agent_scratchpad: [],
          },
        }),
        credentials: 'include',
        openWhenHidden: true,
        // when connection is open
        async onopen(response) {
          if (response.status !== 200) {
            callback({ isError: true });
            return;
          }
          callback({ isStart: true });
        },
        // when message is received
        onmessage(msg) {
          // parse the message
          if (msg.event === 'data') {
            try {
              const response = JSON.parse(msg.data) as LLMResponseMessageJobAdd;
              // handle job id
              if (response?.job_id?.length) {
                callback({ jobId: response.job_id });
                setReceivedMessage((prevState) => (prevState ?? '') + response.content);
                setIsEndOfStream(true);
                return;
              }

              if (!response.content) {
                return;
              }
              // set the received message
              setReceivedMessage((prevState) => (prevState ?? '') + response.content);
            } catch (e) {
              console.error('Error parsing message', e);
            }

            return;
          }

          // handle end of stream
          if (msg.event === 'end') {
            setIsEndOfStream(true);
            return;
          }

          // handle metadata
          if (msg.event === 'metadata') {
          //   TODO REDIRECT TO JOB DETAILS PAGE
          }

          // handle error
          if (msg.event === 'error') {
            callback({ isError: true });
          }
        },
        // when connection is closed
        onclose() {
          controller = null;
        },
        // when error occurs
        onerror() {
          callback({ isError: true });
          controller = null;
        },
      });
    },
    [callback, urlPath],
  );

  return {
    startStream,
    stopStream,
  };
};
