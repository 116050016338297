import {
  useMemo, useState, Dispatch, SetStateAction,
} from 'react';
import { RadioGroup } from '@headlessui/react';
import { SubscriptionCard } from './SubscriptionCard';
import { getInitialSelectedSubscription } from '../utils/utils';


// Interface
// *****************************************
interface SubscriptionListProps {
  subscriptionsData?: ApiSchema<'SubscriptionPackageModel'>[],
  shareSelectedSubscription?: Dispatch<SetStateAction<ApiSchema<'SubscriptionPackageModel'> | undefined>>,
  className?: string,
}

/**
 * @description Subscriptions list component. It renders a list of subscriptions, separated by
 * period (monthly/yearly). It also handles the selection of a subscription.
 *
 * * **subscriptionsData** - The data for the subscriptions. It is an array of objects, each object
 * representing a subscription.
 * * **shareSelectedSubscription** - Optional state setter for the selected subscription. It is used
 * to share the selected subscription with the parent component (for the action button).
 * * **className** - Optional extra classes for the component.
 */
export const SubscriptionsList = (props: SubscriptionListProps) => {
  // Props destructuring
  const {
    subscriptionsData,
    shareSelectedSubscription,
    className = '',
  } = props;

  // 1. Separate subscriptions by period (monthly/yearly)
  // We don't want to recalculate on every render, so we use useMemo. It will recalculate only when subscriptionsData changes
  // *****************************************
  // Monthly subscriptions
  const monthlySubscriptions = useMemo(
    () => subscriptionsData?.filter((subscription) => subscription?.period === 'month' && subscription?.type === 'freemium'),
    [subscriptionsData],
  );

  // 2.1. On initial render, select a subscription from the list (for radio).
  // Order of selection: current, selected, recommended, available with price 300, available.
  // *****************************************
  const initialSelectedSubscription = useMemo(
    () => getInitialSelectedSubscription(subscriptionsData),
    [subscriptionsData],
  );


  // 3. Radio & Tabs logic
  // *****************************************
  // Subscription selection state
  const [
    selectedSubscription, setSelectedSubscription,
  ] = useState(initialSelectedSubscription);

  // Handle selection change.
  // Also share the selected subscription with the parent component (for the action button).
  const handleSubscriptionChange = (subscription: ApiSchema<'SubscriptionPackageModel'>) => {
    setSelectedSubscription(subscription);
    if (shareSelectedSubscription) shareSelectedSubscription(subscription);
  };


  // If there are no subscriptions, don't render anything
  // *****************************************
  if (!subscriptionsData) return null;


  // Render component
  // *****************************************
  return (
    <div className={className || 'p-5 xl:p-6 xl:text-center'}>
      <RadioGroup className="justify-center gap-x-3 md:flex" value={selectedSubscription} by="packageId" onChange={handleSubscriptionChange}>
        {
              monthlySubscriptions?.map((subscription) => (
                <RadioGroup.Option
                  as={SubscriptionCard}
                  value={subscription}
                  key={subscription?.packageId}
                  subscriptionData={subscription}
                  disabled={!subscription?.available}
                />
              ))
            }
      </RadioGroup>
    </div>
  );
};
