// IMPORTS ****************************************************************************************
// ************************************************************************************************
import { CvDetailBaseOperation } from '@components/templates/cv/interface/CvDetailInterface';
import { GenericV1ErrorsArray } from './_global';


// HEADER INFO ************************************************************************************
// pathname: /v1/header-info
// ************************************************************************************************
export type ApiUserRole = 'USER' | 'COMPANY';

export interface StripeNotificationOk {
  messageTarget?: string[],
  body?: string,
  url?: string,
  cta?: string,
}

export type ApiHeaderInfoOk = ApiSchema<'HeaderInfo'>;

export type ApiHeaderInfo = ApiHeaderInfoOk | GenericV1ErrorsArray;


// PARTIALS USER RESUME ***************************************************************************
// Incomplete: add more partials to extend as you need
// ************************************************************************************************
// private attachment (in array)
export type UserPrivateInfoAttachment = Record<'downloadLink' | 'imageUrl' | 'name' | 'originalName' | 'mimeType', string>;

// private personal link (in array)
export type UserPrivateInfoPersonalLinks = Record<'id' | 'name' | 'url', string>;

// private info
export interface UserPrivateInfo {
  name?: string,
  phone?: string,
  email?: string,
  videoUrl?: string,
  attachments?: UserPrivateInfoAttachment[],
  personalLinks?: UserPrivateInfoPersonalLinks[]
}

// experience
export interface UserExperience {
  id: number,
  title: string,
  company: string,
  location?: string,
  start: string,
  end: string,
  period: string,
  salary?: string,
  description?: string,
}

// study
export interface UserStudy {
  id: number,
  institution: string,
  faculty?: string,
  studyLevel?: string,
  admissionYear?: string,
  graduationYear?: string,
  location?: string,
  description?: string,
  duration?: string,
}

// spoken language (in array)
export type UserSpokenLanguage = Record<'name' | 'shortName' | 'level' | 'flagUrl', string>;


// OWN RESUME DETAIL ******************************************************************************
// pathname: /v1/own-resume-detail
// Incomplete: add more partials to PARTIALS USER RESUME to extend as you need
// ************************************************************************************************

// attachment
export interface IAttachment {
  name: string;
  downloadLink: string;
  id: string;
  imageUrl?: string;
  mimeType: string;
  originalName: string;
}

// personal link
export interface IPersonalLink {
  id: 'linkedin' | 'github' | 'professional';
  name: string;
  url: string;
}

// experience
export interface IUserResumeExperience {
  id: number;
  title: string;
  company: string;
  employerSlug?: string;
  employerLogo?: string;
  location: string;
  start: string;
  end: string;
  period: string;
  salary?: string;
  description?: string;
  workplaceSlug?: string;
  workplaceLogo?: string;
  workplaceName?: string;
}

// study
export interface IUserResumeStudy {
  id: number;
  institution: string;
  specialization: string;
  studyLevel?: string;
  admissionYear?: string;
  graduationYear?: string;
  location?: string;
  description?: string;
  duration?: string;
}

// comment
export interface IUserResumeComment {
  id: number;
  ownerName: string;
  ownerImgUrl: string;
  commentBody: string;
  createdAt: string;
  availableOperations: [];
}

// spoken language
export interface IUserResumeSpokenLanguage {
  name: string;
  shortName: string;
  level: string;
  flagUrl: string;
}

// skill
export interface ISkillWithHighlight {
  name: string;
  highlight: boolean;
}

// interview item
export interface IInterviewItem {
  question: string;
  answer: string;
  name: string;
  id: string;
}

// application
export interface IUserResumeApplication {
  applicantId: number;
  jobId: number;
  jobTitle: string;
  jobUrl: string;
  createdAt: string;
  statusId: number;
  status: string;
  userInitiative: boolean;
  viewedAt: string;
  miniInterview: IInterviewItem[];
  isRecommended: boolean;
  hiredConfirm: { withSalary: boolean; };
  salary: number;
  applicantSeenContact: boolean;
}

// agent
export interface IUserResumeAgent {
  name: string;
  phone: string;
  email: string;
  imgUrl: string;
}

export enum ResumeVideoNotificationEnum {
  VIDEO_NOTIFICATION_DEFAULT = 0,
  VIDEO_NOTIFICATION_APPROVED = 1,
  VIDEO_NOTIFICATION_APPROVED_SEEN = 2,
  VIDEO_NOTIFICATION_DELETED = 3,
  VIDEO_NOTIFICATION_DELETED_SEEN = 4,
}

export interface ApiOwnResumeDetailOk {
  id: number;
  isOwnCV: boolean;
  online: boolean;
  hasUnreadMessages: boolean;
  slug: string;
  licenceCatB: boolean;
  privateInformation: {
    name?: string;
    phone?: string;
    email?: string;
    videoUrl?: string;
    attachments?: IAttachment[];
    personalLinks: IPersonalLink[];
  };
  location?: string;
  extendedLocation?: { locality: string; county: string; country: string };
  age?: string;
  birthDate?: string;
  gender?: string;
  imgUrl: string;
  experiences?: IUserResumeExperience[];
  leadExperience: IUserResumeExperience;
  studies?: IUserResumeStudy[];
  comments: IUserResumeComment[];
  spokenLanguages?: IUserResumeSpokenLanguage[];
  skills?: string[];
  skillsWithHighlight: ISkillWithHighlight[];
  extraSkills?: string[];
  application?: IUserResumeApplication;
  applications?: IUserResumeApplication[];
  preferences?: { preferenceType: 'timeAndPay' | 'location'; value: string; }[];
  selfInterview?: IInterviewItem[];
  price: string;
  cvCompletion?: number;
  availableOperations: [];
  shortDescription?: string;
  availableLanguages: string[];
  completionPercentagePerLocale?: { locale: string; percentage: number; }[];
  activityStatus: string;
  locked: boolean;
  privacyLevel?: number;
  interest: { type: string; value: string; };
  talentRatingData?: { averageRating?: number; reviewsCount?: number; };
  expectedSalary: number;
  salaryCurrency: string;
  salaryConfidential: boolean;
  accessToPrivateInformation: boolean;
  blurred: boolean;
  experienceSummary?: { duration: string };
  agent?: IUserResumeAgent;
  videoNotification?: ResumeVideoNotificationEnum;
  hasResume?: boolean;
}

export type ApiOwnResumeDetail = ApiOwnResumeDetailOk | GenericV1ErrorsArray;


// USER RESUME COMMENTS ***************************************************************************
// pathname: /v1/user-resume-comments/{userSlug}
// ************************************************************************************************
interface UserResumeCommentOperation extends CvDetailBaseOperation {
  operationLink: string,
  operationMethod: string,
}

export interface ApiUserResumeCommentOk {
  id: number,
  ownerName?: string,
  ownerCompanyName?: string,
  ownerImgUrl?: string,
  commentBody?: string,
  createdAt: string,
  availableOperations?: UserResumeCommentOperation[],
}

export type ApiUserResumeComments = ApiUserResumeCommentOk[] | GenericV1ErrorsArray;


// SWITCH LANG ************************************************************************************
// pathname: /v1/user/switch-lang
// ************************************************************************************************
export interface ApiUserSwitchLangPost {
  langCode: string,
}

export type ApiUserSwitchLangOk = object;
export type ApiUserSwitchLang = ApiUserSwitchLangOk | GenericV1ErrorsArray;


// Get User Slug By Id *******************************************************************
// pathname: /v1/user-slug/{id}
// ************************************************************************************************
export type ApiUserSlugByIdOk = string;
export type ApiUserSlugById = ApiUserSlugByIdOk | GenericV1ErrorsArray;


// PHONE NUMBER INFO ******************************************************************************
// pathname: /v1/user-phone/info
// ************************************************************************************************
export interface ApiUserPhoneInfoOk {
  isAlreadyInUse: boolean;
  isConfirmed: boolean;
}

export type ApiUserPhoneInfo = ApiUserPhoneInfoOk | GenericV1ErrorsArray;


// PHONE NUMBER INFO ******************************************************************************
// pathname: /v1/user-phone/confirm
// ************************************************************************************************
export type ApiUserPhoneVerifyOk = object;
export type ApiUserPhoneVerify = ApiUserPhoneVerifyOk | GenericV1ErrorsArray;


// PHONE NUMBER INFO ******************************************************************************
// pathname: /v1/user-phone/confirm
// ************************************************************************************************
export type ApiUserPhoneConfirmOk = object;
export type ApiUserPhoneConfirm = ApiUserPhoneConfirmOk | GenericV1ErrorsArray;


// GET PHONE NUMBER ******************************************************************************
// pathname: /v1/user/phone-number
// ************************************************************************************************
export interface GetPhoneNumberDataApi {
  country: {
    data: {
      countryCode: string,
      regionCode?: string,
      regionName?: string,
    }
  },
  number: string,
}

export interface GetPhoneNumberApi {
  data: GetPhoneNumberDataApi,
  schema?: object[],
}

export type ApiGetPhoneNumberOk = GetPhoneNumberApi;
export type ApiGetPhoneNumber = ApiGetPhoneNumberOk | GenericV1ErrorsArray;

// CALL USER **************************************************************************************
// pathname: /v1/user/call
// ************************************************************************************************
export interface ApiUserCallPayload {
  user: string; // The slug of the user to check.
  withVideo: boolean; // A flag indicating whether the call should include video.
}

export interface ApiUserCallOk {
  sessionId: string; // A unique identifier for the session related to the Vonage OpenTok service.
  token: string; // An authorization token for the session generated by the Vonage OpenTok service.
  available: boolean; // A flag indicating the availability status of the callee.
}
