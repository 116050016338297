import { useState, ChangeEvent } from 'react';


/**
 * @description Hook to pick a value based on checkbox checked state. While most of the use cases
 * will involve strings, keep in mind that you can use this hook for any type of value, **for
 * instance pick between two objects or arrays to be sent to the backend**.
 *
 * @param initialValue value to be picked when checkbox is unchecked
 * @param replacementValue value to be picked when checkbox is checked
 *
 * @example
 * // init the hook
 * const { pickedValue, handleCheckboxChange } = useCheckboxValuePicker<string>('initial text', 'replacement text');
 *
 * // inside render, this is the checkbox picker
 * <input type="checkbox" onChange={handleCheckboxChange} />
 *
 * // inside render, this is the picked value that will change depending on checkbox checked state
 * <div>{pickedValue}</div>
 *
 */
export const useCheckboxValuePicker = <T>(initialValue: T, replacementValue: T) => {
  // State to keep track of checkbox checked state
  const [isChecked, setIsChecked] = useState(false);

  // Hold the picked value
  const pickedValue = isChecked ? replacementValue : initialValue;

  // Handle checkbox change for picking the value
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(event.target.checked);
  };

  return {
    isChecked,
    pickedValue,
    handleCheckboxChange,
  };
};
