// SPOKEN LANGUAGES **************************************************************************************
// pathname: /v1/static-lists/spoken-languages
// ************************************************************************************************
import { useContext } from 'react';
import { LanguagesListAndLevelsContext } from '@components/contexts/LanguagesListAndLevels';
import { useSwrApi } from 'src/hooks/useSwrApi';
import { ApiSpokenLanguagesObj, ApiSpokenLanguagesArr } from '@utils/data-fetching';


// Conditional type for the data
// ************************************
type LocalType<T> = T extends ApiSpokenLanguagesArr ? ApiSpokenLanguagesArr : ApiSpokenLanguagesObj;


/**
 * @description SWR: fetch static spoken languages. We use a conditional type to return the correct type for the data.
 * @param locale - string, the locale
 * @param asItemsArray - boolean, if true, the data will be returned as an array of objects (param objectResponse=1)
 *
 * @example
 * // if you want the data as an object with ApiSpokenLanguagesObj type
 * const { data, error, ... } = useSwrSpokenLanguages(locale);
 *
 * // if you want the data as an array of objects with ApiSpokenLanguagesArr type
 * const { data, error, ... } = useSwrSpokenLanguages<ApiSpokenLanguagesArr>(locale, true);
 */
export const useSwrSpokenLanguages = <T>(locale: string, asItemsArray = false) => {
  // Get the list from the context if available
  const { spokenLanguagesList } = useContext(LanguagesListAndLevelsContext);

  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<LocalType<T>>({
    url: '/v1/static-lists/spoken-languages',
    urlParams: asItemsArray ? '?objectResponse=1' : '',
    locale,
    serverSideData: spokenLanguagesList,
    apiOptions: {
      keepPreviousData: true,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 60 * 24, // 24 hours, the data is not supposed to change
    },
  });

  // Return data
  return {
    data: data !== undefined ? data : [],
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
