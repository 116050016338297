import { AnchorBaseSize } from '@type/anchors-buttons';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@store/index';
import { Anchor, Button } from '@components/common';
import { useSwrMutationPaymentType } from '@hooks/useSwrApi/endpoints/subscription';
import { saveSelectedUpgradePackage } from '@store/reducers/employer/subscription/subscriptionReducer';
import { getUserHeaderInfo } from 'src/app/reducers/userSelector';
import { extractNumber } from '@utils/strings/extractNumber';
import { route } from '@services/symfony';
import { useRouter } from 'next/router';
import { getInitialSelectedSubscription } from '../utils/utils';


// Interface
// *****************************************
interface SubscriptionsListActionProps {
  subscriptionsData?: ApiSchema<'SubscriptionPackageModel'>[],
  userSelectedSubscription?: ApiSchema<'SubscriptionPackageModel'>,
  locale?: string,
  className?: string,
  buttonSize?: AnchorBaseSize,
  openPaymentModal?: () => void,
  closePrevModal?: () => void,
}


/**
 * @description Renders the action button for the Premium Subscriptions List.
 *
 * * **subscriptionsData** is the data for the subscriptions.
 * * **userSelectedSubscription** is the selected subscription from the parent component (for the action button).
 * * **locale** is the locale for the SWR requests.
 * * **className** is the optional extra classes for the component.
 * * **buttonSize** is the optional size for the action button.
 * * **callback** is the optional callback function for the action button.
 */
export const SubscriptionsListAction = (props: SubscriptionsListActionProps) => {
  // Props destructuring
  const {
    locale = 'ro',
    userSelectedSubscription,
    subscriptionsData,
    className,
    buttonSize = 'sm',
    openPaymentModal,
    closePrevModal,
  } = props;

  // Redux dispatch
  const appDispatch = useAppDispatch();

  // Get the user data from the store.
  // If the user is not logged-in, the value is undefined.
  // *****************************************
  const isLoggedIn = Boolean(useAppSelector(getUserHeaderInfo));


  // Get the selected subscription for the action button label
  // Order of selection for initial: current, selected, recommended, available with price 300, available.
  // *****************************************
  const initialSelectedSubscription = useMemo(
    () => getInitialSelectedSubscription(subscriptionsData),
    [subscriptionsData],
  );

  // Selected subscription
  const selectedSubscription = userSelectedSubscription || initialSelectedSubscription;

  function sucessCallback() {
    if (openPaymentModal) openPaymentModal();
    if (closePrevModal) closePrevModal();
  }

  // SWR - POST data with the packageId to get the payment options
  // We save the response in Redux state from inside this hook
  // *****************************************
  const { trigger, isMutating } = useSwrMutationPaymentType(locale, sucessCallback);

  const router = useRouter();

  // NOT LOGGED-IN ACTION. We handle the value saved in storage with the 'useHandleNotAuthOrder' hook
  // *****************************************
  // 1. Save the selected subscription in session storage
  // 2. Redirect to login page
  // *****************************************
  const handleNotLoggedInAction = () => {
    if (selectedSubscription) {
      sessionStorage.setItem('selectedSubscription', JSON.stringify(selectedSubscription));
      void router.push(route('login', { _target_path: encodeURIComponent(`/${locale}/premium`) }));
    }
  };


  // LOGGED-IN ACTIONS
  // *****************************************
  // Trigger SWR post request if the selected subscription is available and is of type upgrade (not
  // current or unavailable). Also save the selected subscription in Redux state.
  // *****************************************
  const handleUpgradeAction = () => {
    if (selectedSubscription?.available && !selectedSubscription?.current) {
      // the packageId is a string, but the API expects a number
      const packageId = extractNumber(selectedSubscription?.packageId);
      if (packageId) {
        appDispatch(saveSelectedUpgradePackage(selectedSubscription));
        void trigger({ packageId });
      }
    }
  };


  // Don't render if there are no subscriptions
  // *****************************************
  if (!subscriptionsData) return null;


  // Render component
  // *****************************************
  return (
    <div className={className || 'border-t bg-surface px-6 py-4 text-right'}>
      { /* Not logged-in action */
        !isLoggedIn && (
          <Button
            size={buttonSize}
            color="primary"
            styling="outline"
            rounding="full"
            className="w-full sm:w-auto sm:!px-10"
            onClick={handleNotLoggedInAction}
          >
            { selectedSubscription?.ctaTitle }
          </Button>
        )
      }

      { /* Special case for current subscription. It routes to add job */
        isLoggedIn && selectedSubscription?.current && (
          <Anchor
            size={buttonSize}
            color="primary"
            styling="outline"
            rounding="full"
            className="w-full sm:w-auto sm:!px-10"
            href="app_job_content_add"
            symfonyRoute
          >
            { selectedSubscription?.ctaTitle }
          </Anchor>
        )
      }

      { /* Default action button */
        isLoggedIn && !selectedSubscription?.current && (
          <Button
            size={buttonSize}
            color="primary"
            styling="outline"
            rounding="full"
            className="w-full sm:w-auto sm:!px-10"
            isLoading={isMutating}
            onClick={handleUpgradeAction}
            disabled={!selectedSubscription?.available}
          >
            { selectedSubscription?.ctaTitle }
          </Button>
        )
      }
    </div>
  );
};
