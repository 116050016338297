import { TFunction } from 'i18next';
import { ApiUserJobTypePreferencesOk, ApiOwnResumeDetailOk } from 'src/types/v1-api-types';
import { IJobPreferencesUpdatedValues } from 'src/types/job-preferences';

export const locationPreferencesKeys = {
  withRemote: 'cv.detail.available.remote2',
  onCompanyPremises: 'cv.detail.available.company.office',
  travel: 'cv.detail.available.field.trips',
  relocation: 'cv.detail.available.relocation',
} as { [key: string]: string };

export const timeAndPayPreferencesKeys = {
  fullTime: 'cv.detail.available.full-time',
  partTime: 'cv.detail.available.part-time',
  recruitmentProjects: 'cv.detail.jobs.paid_by_hour',
} as { [key: string]: string };


/**
 * @description Checks if the job preferences are empty based on preferences and salary
 * @param userResume
 */
export const areJobPreferencesEmpty = (userResume: ApiOwnResumeDetailOk | undefined)
: boolean => (!userResume?.preferences || !userResume.preferences.length) && !userResume?.expectedSalary;


/**
 * @description Counts the number of job preferences elements
 * @param userResume
 */
export const countJobPreferencesElements = (userResume: ApiOwnResumeDetailOk | undefined): number => {
  if (!userResume?.preferences) {
    return 0;
  }

  const hasLocationPref = userResume.preferences.filter((pref) => pref.preferenceType === 'location').length > 0;
  const hasTimeAndPayPref = userResume.preferences.filter((pref) => pref.preferenceType === 'timeAndPay').length > 0;

  return (hasLocationPref ? 1 : 0) + (hasTimeAndPayPref ? 1 : 0) + (userResume.expectedSalary ? 1 : 0);
};

/**
 * @description Converts the job type preferences to the user resume format
 */
export const convertJobTypePreferencesToUserResumeFormat = (data: ApiUserJobTypePreferencesOk, t: TFunction): IJobPreferencesUpdatedValues => {
  const locationPreferences = Object.keys(locationPreferencesKeys).filter((key) => data[key as keyof ApiUserJobTypePreferencesOk]).map((key) => ({
    preferenceType: 'location',
    value: t(locationPreferencesKeys[key as keyof typeof locationPreferencesKeys]),
  }));

  const timeAndPayPreferences = Object.keys(timeAndPayPreferencesKeys).filter((key) => data[key as keyof ApiUserJobTypePreferencesOk]).map((key) => ({
    preferenceType: 'timeAndPay',
    value: t(timeAndPayPreferencesKeys[key as keyof typeof timeAndPayPreferencesKeys]),
  }));

  return {
    salaryConfidential: data.salary?.confidential ?? false,
    salaryCurrency: data?.salary?.currency ?? 'RON',
    expectedSalary: data?.salary?.amount ?? 350,
    preferences: locationPreferences.concat(timeAndPayPreferences),
  };
};
