import { useTranslation } from 'next-i18next';
import { Card } from '@components/common';
import { Disclosure } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';


// Interface
// *****************************************
interface PricingFreeTrialFaqProps {
  questionsList?: ApiSchema<'OptionItem2'>[],
  titleStyle?: string,
  className?: string,
}


export const PricingFreeTrialFaq = (props: PricingFreeTrialFaqProps) => {
  // Props destructuring
  const {
    questionsList,
    titleStyle,
    className = '',
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Render component
  // *****************************************
  return (
    <div className={className}>
      {/* FAQ title */}
      <h2 className={`${titleStyle || ''} mt-7`}>
        {t('premium_free_trial.faq.head.title')}
      </h2>

      {/* FAQ list */}
      <Card className="rounded-md border bg-surface-50 px-6 py-0.5 text-ink-medium">
        <div className="divide-y">
          {
            questionsList?.map((question) => (
              <Disclosure as="dl" key={question.id} className="py-3 text-sm">
                <Disclosure.Button
                  as="dt"
                  className="flex cursor-pointer items-center gap-2 font-semibold hover:underline"
                >
                  <PlusCircleIcon className="size-5 shrink-0 text-secondary" />
                  {question.id}
                </Disclosure.Button>
                <Disclosure.Panel as="dd" className="pl-7 pt-1 leading-snug">{question.name}</Disclosure.Panel>
              </Disclosure>
            ))
          }
        </div>
      </Card>
    </div>
  );
};
