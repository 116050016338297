import { useEffect, useState } from 'react';
import { Button, Modal } from '@components/common';
import { PhoneIcon, VideoCameraIcon } from '@heroicons/react/24/solid';
import { useAppSelector } from '@store/index';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

interface IProps {
  forceOpen: boolean;
  onStartInterview: (isVideo: boolean) => void;
  onClose: () => void;
  isLoadingUserCall?: boolean;
}

/**
 * @description - Company calling modal show few informations about the talent
 * @param forceOpen - force open the modal
 * @param onStartInterview - callback to start the interview - video / audio
 * @param onClose - callback to close the modal
 * @param isLoadingUserCall - whether the call user/call is loading or not.
 */
export const CompanyCallOptionsModal = ({
  forceOpen, onStartInterview, onClose, isLoadingUserCall,
}: IProps) => {
  const { t } = useTranslation('common');

  const talentParticipant = useAppSelector((state) => state.socketOnline.talentParticipant);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(forceOpen);
  }, [forceOpen]);

  return (
    <Modal
      isOpen={isOpen}
      size="xs"
      centered
      onClose={() => onClose()}
    >
      <Modal.Body>
        <div className="flex flex-col gap-4 text-center">
          <p className="text-ink text-lg border-b font-bold pb-4">
            {t('call_live.company.call.modal.title')}
          </p>
          <p className="text-ink">
            {t('call_live.company.call.modal.text')}
            <br />
            <span className="font-bold">{talentParticipant?.userName}</span>
          </p>

          {/* video / audio / close buttons */}
          <div className="flex flex-row justify-center gap-4">
            {/* video */}
            <Button size="md" rounding="full" color="secondary" onClick={() => onStartInterview(true)} disabled={isLoadingUserCall}>
              <VideoCameraIcon className="w-5 h-5" />
            </Button>
            {/* audio */}
            <Button size="md" rounding="full" color="light" className="text-primary" onClick={() => onStartInterview(false)} disabled={isLoadingUserCall}>
              <PhoneIcon className="w-5 h-5" />
            </Button>
            {/* close button */}
            <Button size="md" rounding="full" color="error" onClick={() => onClose()} disabled={isLoadingUserCall}>
              <XMarkIcon className="w-5 h-5" />
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
