// Polyfills; they must always be first!!!
import '../src/polyfills';
// styles
import 'styles/globals.css';

import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import { store } from 'src/app';
import { useRouter } from 'next/router';
import { useEffect, ReactNode } from 'react';
import { CookieConsent } from '@components/features/cookie-consent/CookieConsent';

import SymfonyRouter from '@services/symfony';
import { LayoutDefault } from '@components/layout';
import { ToastsContainer, ErrorBoundary, ClientSideErrorPage } from '@components/common';
import { AppProps } from 'next/app';
import { NextPage } from 'next';
import Head from 'next/head';
import NiceModal from '@ebay/nice-modal-react';

// Canonical url functions
import { getCanonicalUrl, getSwitcherPaths } from '@services/i18nPaths';
import { logErrorEvent } from '@utils/logger/logger';
import { OpenTokCall } from '@components/features/openTokCall';
import { fontLocal } from '@utils/fonts';
import { GoogleTagManager } from '@components/common/Scripts/GoogleTagManager';

// Next.js Local font

type GetLayout = (page: ReactNode) => ReactNode;

type Page<P = Record<string, never>, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayout;
};

type Props<P = Record<string, never>> = AppProps<P> & {
  Component: Page<P>;
};

const defaultGetLayout: GetLayout = (page: ReactNode): ReactNode => (
  <LayoutDefault>{page}</LayoutDefault>
);

const MyApp = ({ Component, pageProps }: Props) => {
  const { query, asPath } = useRouter();

  const locale = query?.locale as string;

  SymfonyRouter.setLocale(locale);

  useEffect(() => {
    // listen to errors
    window.addEventListener('error', logErrorEvent);

    return () => {
      // remove error listener
      window.removeEventListener('error', logErrorEvent);
    };
  }, []);

  const getLayout = Component.getLayout || defaultGetLayout;

  // Get the canonical URL (string) and Meta alternates (array)
  const canonicalUrl = getCanonicalUrl(asPath);
  const metaAlternates = getSwitcherPaths(asPath);

  return (
    <>
      { /* font as CSS variable, to be passed to tailwind.config.js */}
      <style jsx global>
        {`:root {--font-base: ${fontLocal.style.fontFamily};}`}
      </style>

      <ErrorBoundary fallbackComponent={<ClientSideErrorPage />}>
        <Provider store={store}>
          <NiceModal.Provider>
            <GoogleTagManager />
            <CookieConsent />

            { /*
              Default head; missing meta tags in custom Headers will be inherited. IE: if we put only
              title & description in our custom Head for a page, the canonical meta will be inherited.
            */}
            <Head>
              <title>BestJobs</title>
              <meta name="description" content="BestJobs" />
              <link rel="canonical" href={canonicalUrl} key={`canonical-${locale}`} />
              { /* Alternate languages urls for page */
              metaAlternates.filter((alternate) => alternate[0] !== locale).map((lang) => {
                // made the variables for readability
                const langName = lang[0];
                const urlPath = lang[1];

                return (
                  <link rel="alternate" hrefLang={langName} href={getCanonicalUrl(urlPath)} key={`alternate-${langName}`} />
                );
              })
            }
            </Head>

            { /* Toasts container */ }
            <ToastsContainer />

            {getLayout(<Component {...pageProps} />)}

            { /* Open Tok Call */ }
            <OpenTokCall />
          </NiceModal.Provider>
        </Provider>
      </ErrorBoundary>
    </>
  );
};

export default appWithTranslation(MyApp);
