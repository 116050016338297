import { forwardRef } from 'react';
import { useSingleFormElement } from '@hooks/useBjForm';
import { convertEmptyValue } from 'src/utils';
import { FormElementRefImperative, FormSelectInterface } from 'src/types/form-types';
import { FormError } from '../FormError/FormError';

/**
 * @description Select component for useBjForm. Can be used without the hook though.
 *
 * @description Element's Props:
 * * **className** - pass here your Tailwind classes if you need further customization
 * * **withError** - if you want to show this field's validation error messages; default true
 * * **patternMismatchMessage** - the custom message for pattern mismatch validation; default ''
 * * **customValidityMessages** - the custom validity messages for any html input validation - @link https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
 * * **onBlur** - the onBlur event function
 * * **onChange** - the onChange event function
 * * **getValidityStates** - the getValidityStates function callback. Use a state setter function to get the validity states. Returns an object with isInvalid and errorMSG properties.
 * * **hasCustomError** - a boolean to trigger a custom error message; default undefined
 * * other normal HTML select attributes: required, defaultValue, etc.
 *
 * @example
 * // without using the 'record' function
 * <Select name="rating" defaultValue="2">
 *  <option value={0}>Default select option</option>
 *  <option value={1}>1</option>
 *  <option value={2}>2</option>
 * </Select>
 *
 * // using the 'record function'
 * <Select required {...record('rating', {defaultValue: 2})}>
 *  <option value={0}>Default select option</option>
 *  <option value={1}>My first option</option>
 *  <option value={2}>My second option</option>
 * </Select>
 *
 * // How to use the getValidityStates callback
 * // validityStates will be an object with isInvalid and errorMSG properties
 * // use it to display the error message in other parts of the form
 * const [validityStates, setValidityStates] = useState({ isInvalid: false, errorMSG: '' });
 * <Select required getValidityStates={setValidityStates} withError={false} />
 *
 * // Pass a custom message for empty fields
 * <Select required customValidityMessages={{ valueMissing: 'This field is required' }} />
 *
 * // ******************************************************
 * // How to trigger a custom error type. Keep in mind that you can always pass customValidityMessages to the input for
 * // specific error messages like 'valueMissing', 'tooLong', etc.
 * const [hasCustomError, setHasCustomError] = useState<boolean | undefined>(undefined);
 *
 * // Handle change function. You need to set the custom validity.
 * const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
 *  const select = e.target as HTMLSelectElement;
 *  if (someCondition) {
 *    select.setCustomValidity('customError');
 *    setHasCustomError(true);
 *  } else {
 *    select.setCustomValidity('');
 *    setHasCustomError(false);
 *  }
 * };
 *
 * <Select
 *  required
 *  hasCustomError={hasCustomError}
 *  customValidityMessages={{ customError: 'Some error message' }}
 *  onChange={handleChange}
 * />
 *
 @remarks When used with **useBjForm** custom hook and the **record** function do not pass a **name** or **id** attribute; these should be handled inside the **record** function
 */
export const Select = forwardRef<FormElementRefImperative, FormSelectInterface>((props, ref) => {
  // component props
  const {
    children,
    className = '',
    required,
    withError = true,
    defaultValue,
    patternMismatchMessage = '',
    customValidityMessages,
    onBlur,
    onChange,
    getValidityStates,
    hasCustomError,
    ...attributes
  } = props;

  // form elements shared logic hook
  const {
    handleInteraction,
    checkEmpty,
    elementRef,
    isInvalid,
    errorMSG,
    hasEmptyValue,
    errorClass,
    emptyClass,
  } = useSingleFormElement({
    ref,
    patternMismatchMessage,
    customValidityMessages,
    className,
    getValidityStates,
    hasCustomError,
  });

  // we make sure that the default value is not of the 'empty' type
  // we want to be able to remove the 'emptyValue' class if we have a non 'empty' default value
  const convertedDefaultValue = convertEmptyValue('toString', defaultValue);

  // Rendered component **********************************
  return (
    <>
      <select
        // there's no need to check optional fields interactions, we do it only for required fields
        // allow adding extra functions for onChange & onBlur
        onChange={(e) => { if (required) handleInteraction(e); checkEmpty(e); if (onChange) onChange(e); }}
        onBlur={(e) => { if (required) handleInteraction(e); if (onBlur) onBlur(e); }}
        className={`${className}${isInvalid ? errorClass : ''}${hasEmptyValue && !convertedDefaultValue ? emptyClass : ''}`}
        ref={elementRef}
        required={required}
        defaultValue={defaultValue}
        {...attributes}
      >
        {children}
      </select>
      { // textarea error field - show only if input is required and withError is true (default true)
        required && withError && <FormError errorMessage={errorMSG} isShowing={isInvalid} />
      }
    </>
  );
});
