import { ReactNode } from 'react';


// This layout is used for pages that don't have both a header and a footer
// *************************************************************************************
export const LayoutClean = ({ children }: { children?: ReactNode }) => (
  <>
    { children }
  </>
);
