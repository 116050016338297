'use client';

import { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';
import sendGoogleConsent from '@utils/gtm/gtm';
import { CookieConsentModal } from '@components/features/modals/cookie-consent-modal';

export const CookieConsent = () => {
  const [cookieConsent, setCookieConsent] = useState(true);

  const onCookieConsent = () => {
    setCookieConsent(true);
  };

  useEffect(() => {
    // set cookie consent
    if (window.localStorage.getItem('cc_ts') !== null || getCookie('cc_ts') !== undefined) {
      setCookieConsent(true);
    } else {
      setCookieConsent(false);
    }

    // Send Google Consent v2 tag updates
    sendGoogleConsent({
      ad_storage: getCookie('cc_ads') === 'granted' ? 'granted' : 'denied',
      analytics_storage: getCookie('cc_analytics') === 'granted' ? 'granted' : 'denied',
      ad_user_data: getCookie('cc_ads') === 'granted' ? 'granted' : 'denied',
      ad_personalization: getCookie('cc_ads') === 'granted' ? 'granted' : 'denied',
    });
  }, []);

  return (
    <CookieConsentModal
      isOpen={!cookieConsent}
      onConsent={onCookieConsent}
      cookiesBlocked={false}
      onClose={onCookieConsent}
    />
  );
};
