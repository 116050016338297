// ************************************************************************************************
// pathname: /v1/conversations/user/{user_slug}
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';

/** Props */
interface SwrConversationUserProps {
  locale: string;
  userSlug: string;
  shouldFetch?: boolean;
}

/**
 * @description SWR: fetch conversation by user.
 * - `userSlug` (string): The user slug.
 * - `locale` (string): The locale to use for the request.
 * - `shouldFetch` (boolean, optional): If `true`, the request will be made. Defaults to `true`.
 */
export const useSwrConversationUser = (props: SwrConversationUserProps) => {
  const {
    locale,
    userSlug,
    shouldFetch = true,
  } = props;

  return useSwrApi<ApiSchema<'ConversationRead'>>({
    url: `/v1/conversations/user/${userSlug}`,
    locale,
    shouldFetch,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 10 * 1000, // 10 seconds
    },
  });
};
