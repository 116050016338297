/**
 * Generate an array of numbers from start to end with a step.
 * @param start - number, the start number, required
 * @param end - number, the end number, required
 * @param step - number, the step, optional, default 1
 *
 * @example
 * generateNumbersArray(1, 10, 2) // [1, 3, 5, 7, 9]
 * generateNumbersArray(1, 10) // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
 * generateNumbersArray(0, 50, 10) // [0, 10, 20, 30, 40, 50]
 */
export function generateNumbersArray(start: number, end: number, step?: number) {
  const array = [];
  for (let i = start; i <= end; i += step || 1) {
    array.push(i);
  }
  return array;
}
