import { LabelProps } from 'src/types/form-types';

/**
 * @description Label component form group style. Component's props are:
 *
 * - `description` - the text of the Label
 * - `descriptionLabel` - extra text in the Label description
 * - `required` - adds the 'required field' red asterisk mark
 * - `descriptionClass` - if you need to add more classes to description's span container
 * - `className` - if you need to add more classes to Label
 * - `defaultMargin` - if you need to remove the default margin from the Label
 * - `as` - the tag of the Label, default is 'label'. Use 'div' if you need a div container or a non-focusable element.
 * Don't use 'htmlFor with' 'div' tag, it will show an error, because we're using a conditional type.
 *
 * @example
 * // label for a required field
 * <Label htmlFor="elementTwo" description="Textarea label" descriptionHelper="(min. 3 caractere)" required>
 *   <Textarea required {...record('elementTwo')} />
 * </Label>
 *
 * // label as a div container
 * <Label as="div" className="grid gap-y-4" description="Textarea label">
 *   <Textarea required {...record('elementTwo')} />
 * </Label>
 */
export const Label = <T extends 'label' | 'div' = 'label'>(props: LabelProps<T>) => {
  const {
    description,
    descriptionHelper,
    descriptionClass,
    defaultMargin = true,
    className,
    required,
    children,
    as = 'label',
  } = props;

  // classes for required asterisk
  const requiredAsterisk = ' after:content-[\'*\'] after:ml-1 after:text-error';

  // Get the tag name
  const Tag = as;

  return (
    <Tag className={`w-full scroll-mt-20${defaultMargin ? ' mt-4' : ''}${className ? ` ${className}` : ''}`}>
      {
        description && (
        <span className={`mb-1 inline-block text-sm${required ? requiredAsterisk : ''}${descriptionClass ? ` ${descriptionClass}` : ''}`}>
          {description}
          {descriptionHelper && (<span className="ml-1.5 font-normal text-ink-light">{descriptionHelper}</span>)}
        </span>
        )
      }
      {children}
    </Tag>
  );
};
