'use client';

import {
  useEffect,
  useRef,
  useState,
  cloneElement,
  Children,
  Fragment,
  ReactElement,
  ReactNode,
  Dispatch,
  SetStateAction,
  isValidElement,
  PropsWithChildren,
} from 'react';
import { useInView } from 'react-intersection-observer';


// Interface
// ************************************************************************************
interface PageCounterProps {
  children?: ReactNode,
  currentPage?: number,
  callback?: (page: number) => void | Dispatch<SetStateAction<number>>,
  parentWidth?: number,
  pageGroup?: unknown,
}


/**
 * @description It uses Intersection Observer to show the current page visible in the viewport. That means that if
 * you scroll down or up the page number will change to reflect that. Used for infinite lists.
 * - **children** - The child component to wrap around.
 * - **currentPage** - The current page. Optional. Default: 1. This value will be passed to the callback function.
 * - **callback** - The callback function to execute. This is needed to pass a callback to the parent component (to
 * modify the URL, query params, etc.). Optional. For example, you can pass the 'setCurrentPage' function from useFiltersQueryParams.
 * - **parentWidth** - The width of the parent component. Optional. Default: undefined. This is used to re-trigger the calculation
 * for the watcher height.
 * - **pageGroup** - The page group. Optional. Default: undefined. This is used to re-trigger the calculation for the watcher height.
 *
 * @example
 * <div ref={parentRef} className="grid gap-4 my-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:my-6">
 *   {
 *     data?.map((pageGroup) => {
 *       const viewportPage = pageGroup?.page || 1;
 *       return (
 *         <PageCounter currentPage={viewportPage} callback={setCurrentPage} key={`page-${viewportPage}`} parentWidth={parentWidth}>
 *           {pageGroup?.items?.map((item) => (
 *             <TalentCardMemo
 *               cardData={item}
 *               cardSpotlight="experience"
 *               key={item.id}
 *             />
 *           ))}
 *         </PageCounter>
 *       );
 *     })
 *   }
 * </div>
 */
export const PageCounter = (props: PageCounterProps) => {
  // Props destructuring
  const {
    children,
    currentPage = 1,
    callback,
    parentWidth,
    pageGroup,
  } = props;

  // Get the children as an array, so we can use the first and last item
  const arrayChildren = Children.toArray(children);

  // Refs
  const firstItemRef = useRef<HTMLDivElement>(null);
  const lastItemRef = useRef<HTMLDivElement>(null);

  // Watcher state: top and height
  const [watcherTop, setWatcherTop] = useState<number>(0);
  const [watcherHeight, setWatcherHeight] = useState<number>(0);

  // Intersection observer
  const [watcherRef, watcherInView] = useInView({
    threshold: 0,
    rootMargin: '-44% 0%',
  });

  // Set the watcher top and height. Account for 1 row or just 1 item.
  useEffect(() => {
    const timer = setTimeout(() => {
      if (firstItemRef?.current) {
        setWatcherTop(firstItemRef.current.offsetTop);
        if (lastItemRef?.current) {
          setWatcherHeight(lastItemRef.current.offsetTop - firstItemRef.current.offsetTop + 1);
        } else {
          setWatcherHeight(1);
        }
      }
    }, 50);

    return () => clearTimeout(timer);
  }, [parentWidth, pageGroup]);

  // Callback for setting the current page
  useEffect(() => {
    const timer = setTimeout(() => {
      if (watcherInView && callback) callback(currentPage);
    }, 50);

    return () => clearTimeout(timer);
  }, [watcherInView, currentPage, callback]);

  // If there are no children, return null
  if (!children) return null;

  // Render component
  // **********************************************
  return (
    <>
      {/* Watcher */}
      <div
        ref={watcherRef}
        className="absolute w-1 overflow-hidden pointer-events-none"
        style={{ top: watcherTop, height: watcherHeight }}
      />

      { // Clone children and add refs to the first and last item
        // Children might be wrapped in a Fragment, so when iterating over children, we check if they are single elements or an array.
        Children.map(children, (child, index) => {
          if (index === 0 && isValidElement(child)) {
            if (child.type === Fragment) {
              const fragmentChildrenArray = Children.toArray((child as ReactElement<PropsWithChildren>).props.children);
              if (fragmentChildrenArray.length > 0 && isValidElement(fragmentChildrenArray[0])) {
                return (
                  <>
                    {cloneElement(fragmentChildrenArray[0] as ReactElement, { ref: firstItemRef })}
                    {fragmentChildrenArray.slice(1)}
                  </>
                );
              }
            }

            return cloneElement(child as ReactElement, { ref: firstItemRef });
          }

          if (index === arrayChildren.length - 1 && isValidElement(child)) {
            if (child.type === Fragment) {
              const fragmentChildrenArray = Children.toArray((child as ReactElement<PropsWithChildren>).props.children);
              if (fragmentChildrenArray.length > 0 && isValidElement(fragmentChildrenArray[fragmentChildrenArray.length - 1])) {
                return (
                  <>
                    {fragmentChildrenArray.slice(0, -1)}
                    {cloneElement(fragmentChildrenArray[fragmentChildrenArray.length - 1] as ReactElement, { ref: lastItemRef })}
                  </>
                );
              }
            }

            return cloneElement(child as ReactElement, { ref: lastItemRef });
          }

          return child;
        })
      }
    </>
  );
};
