import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal } from '@components/common/Modal/Modal';
import { useTranslation } from 'next-i18next';
import { useHandleSubscriptionModalClose } from '@components/templates/employer/subscription/utils';
import { UpgradeSubscriptionHubModal } from '@components/templates/employer/subscription/UpgradeSubscriptionHubModal';


/**
 * @description Renders the upgrade subscription modal, with payment options, pricing and if
 * it's the case the 'not enough credits' and payment status possible steps.
 * The modal can be opened anywhere in the app using the **showUpgradeSubscriptionModal** function.
 */
export const UpgradeSubscriptionModal = NiceModal.create(() => {
  // Use a hook to manage the modal state
  const modal = useModal();

  // Close modal
  const closeModal = () => {
    void modal.hide();
  };

  // Translation
  const { t } = useTranslation('common');

  // Cleanup Redux states and re-fetch after closing the modal depending on the flow step
  const { handleSubscriptionModalClose } = useHandleSubscriptionModalClose();

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={modal.visible}
      onClose={closeModal}
      afterLeave={handleSubscriptionModalClose}
      size="lg"
      header={t('subscription.limit.reached.title')}
      body={<UpgradeSubscriptionHubModal closeModal={closeModal} />}
    />
  );
});


/**
 * @description Opens the upgrade subscription modal anywhere in the app. Modal comes with packages
 * selection, payment options, pricing and if it's the case the 'not enough credits' and payment
 * status possible steps.
 *
 * @example
 * // import
 * import { showUpgradeSubscriptionModal } from '@components/templates/employer/subscription/UpgradeSubscriptionModal';
 * // usage
 * <Button onClick={showUpgradeSubscriptionModal}>Show upgrade subscription modal</Button>
 */
export const showUpgradeSubscriptionModal = () => {
  void NiceModal.show(UpgradeSubscriptionModal);
};
