// Generic Object Interface
export interface GenObjI {
  [key: string | number]: string | number | [] | object
}

/**
 * @description Helper to add more separators to a key (1-2 characters). 2 characters will be treated as brackets
 * @param separator
 * @param key
 * @example
 * // used within flattenObj function to add bracket notation as delimiters
 * delimit('[]', 'hi') = '[hi]'
 * // flattened objects will look like this:
 * // { 'key1[keyA]': 'value 1' }
 * // instead of this:
 * // { 'key1.keyA': 'value 1' }
 */
const delimit = (separator: string, key: string) => {
  if (separator.length === 1) {
    return separator + key;
  }

  const separators = separator.split('');
  return separators[0] + key + separators[1];
};


/**
 * @description Helper to split a key based on a delimiter (1-2 characters). 2 characters will be treated as brackets.
 * @param key
 * @param separator
 * @example
 * // used within expandObj function to split bracket notation
 * { 'key1[keyA]': 'value 1' } => { key1: { keyA: 'value 1' }}
 */
const splitKey = (separator: string, key: string) => {
  if (separator.length === 1) {
    return key.split(separator);
  }

  const separators = separator.split('');
  const regex = new RegExp(`${separators[1]}`, 'g');
  // remove trailing bracket from string
  const result = key.replace(regex, '');
  // split on leading bracket
  return (result.split(separators[0]));
};


/**
 * @description Takes an object and recursively flattens it. Used primarily in Bj Form Library. If you need to build url
 * search params, use the brackets notation as separators (ie: '[]' or '{}').
 * (ie: {@link convertOldApiFormErrors} or {@link useGetFormValues} custom hooks)
 * to transform nested form objects into something that can be easily used in React.
 * * @param target - the object to be flattened
 * * @param separator - the separator between keys (default[.])
 * * @param preserveArray - if we want to keep the arrays expanded (default[true])
 *
 * @example
 * const myObject = {
 *     key1: {
 *      keyA: 'value 1',
 *     },
 *     key2: ['text 1', 'text 2'],
 *     key3: {
 *         a: {
 *             b: {
 *                 c: 2
 *             }
 *         }
 *     },
 * };
 *
 * const flattenedObj = flattenObj(myObject);
 *
 * // result:
 * // flattenedObj = {
 * //   'key1.keyA': 'value 1',
 * //   'key2': ['text 1', 'text 2'],
 * //   'key3.a.b.c': 2
 * // }
 *
 * const flattenedObjTwo = flattenObj(myObject, '_', false);
 *
 * // result
 * // flattenedObjTwo = {
 * //   'key1_keyA': 'value 1',
 * //   'key2_0': 'text 1',
 * //   'key2_1': 'text 2',
 * //   'key3_a_b_c': 2
 * // }
 */
export const flattenObj = <T extends object, K extends keyof T>(target: T, separator = '.', preserveArray = true): T => {
  const output = {} as T;

  // recursive function
  function step(object: T, prev = '') {
    Object.keys(object).forEach((key) => {
      const value = object[key as K] as T;
      const isArray = preserveArray && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isObject = (
        type === '[object Object]'
        || type === '[object Array]'
      );

      // Need to handle bracket notation as separators, so we're using a helper function
      const newKey = prev ? prev + delimit(separator, key) : key;

      if (!isArray && isObject && Object.keys(value).length) {
        return step(value as unknown as T, newKey);
      }

      output[newKey as K] = value as T[K];

      return undefined;
    });
  }

  step(target);

  return output;
};

/**
 * @description Recursively expands a flattened object. Used primarily in Bj Form Library (ie: {@link formValues} function)
 * to transform a flattened form object into a proper format accepted by the backend. If the flattened object uses bracket
 * notation as separators, don't forget to pass the same brackets as separator (ie: '[]' or '{}').
 * * @param target - the object to be expanded
 * * @param separator - the separator between keys (default[.])
 * * @param returnKeyNumberAsArray - if we want to expand the numeric keys into array (default[true])
 *
 * @example
 * const object = {
 *    'key1.keyA': 'value 1',
 *    'key2.0': 'text 1',
 *    'key2.1': 'text 2',
 *    'key3.a.b.c': 2
 * };
 *
 * expandObj(object);
 *
 * // {
 * //   key1: {
 * //     keyA: 'value 1',
 * //   },
 * //   key2: ['text 1', 'text 2'],
 * //   key3: { a: { b: { c: 2 } } },
 * // }
 *
 */
export const expandObj = <T extends object, K extends keyof T>(target: T, separator = '.', returnKeyNumberAsArray = true): T => {
  const result = {};
  const arrayOrObject = returnKeyNumberAsArray ? [] : {};
  // fix potential for prototype pollution
  const proto = ['__proto__', 'constructor', 'prototype'];
  // check if the object is of Date type
  const isDate = (obj: object) => obj instanceof Date;

  if (typeof target !== 'object' || isDate(target)) return target;

  const expand = (originalObj: T) => {
    Object.keys(originalObj).forEach((key) => {
      const newKeys = splitKey(separator, key);
      newKeys.reduce((obj: GenObjI, k, i) => {
        if (proto.includes(newKeys[i])) return obj;
        const partial = newKeys.length - 1 === i ? originalObj[key as K] : {};

        if (obj[k]) {
          return obj[k];
        }

        // we reassign params as new variables (eslint)
        const obj1 = obj;
        const k1 = k;

        // check if we're dealing with a potential array
        if (Number.isNaN(Number(newKeys[i + 1]))) {
          obj1[k1] = partial as T;
        } else {
          obj1[k1] = arrayOrObject;
        }

        return obj1[k1];
      }, result);
    });
  };

  expand(target);

  return result as T;
};


/**
 * @description Gets the value at flattened path of object. If the resolved value is undefined, it
 * returns undefined. You should always check the returned result for proper type.
 * @param object the target object
 * @param stringPath flattened string path
 *
 * @example
 * const simpleObject = { 'a': 'some value' };
 * getWithFlatKeys(simpleObject, 'a');
 * // => 'some value'
 *
 * const nestedObject = { 'a': { 'b': { 'c': 'nested value' } } };
 * getWithFlatKeys(nestedObject, 'a.b.c');
 * // => 'nested value'
 *
 * getWithFlatKeys(nestedObject, 'a.address');
 * // => undefined
 */
export const getWithFlatKeys = (object: object, stringPath: string): unknown => stringPath
  .split('.')
  .reduce((obj, key) => obj?.[key as keyof typeof obj], object);
