import { forwardRef } from 'react';
import { useSingleFormElement } from '@hooks/useBjForm';
import { FormElementRefImperative, FormInputInterface } from 'src/types/form-types';
import { FormError } from '../FormError/FormError';

/**
 * @description Input component for useBjForm. Can be used without the hook though.
 *
 * @description Element's Props:
 * * **className** - pass here your Tailwind classes if you need further customization
 * * **withError** - if you want to show this field's validation error messages; default true
 * * **patternMismatchMessage** - the custom message for pattern mismatch validation; default ''
 * * **customValidityMessages** - the custom validity messages for any html input validation - @link https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
 * * **onBlur** - the onBlur event function
 * * **onChange** - the onChange event function
 * * **getValidityStates** - the getValidityStates function callback. Use a state setter function to get the validity states. Returns an object with isInvalid and errorMSG properties.
 * * **hasCustomError** - a boolean to trigger a custom error message; default undefined
 * * other normal HTML input attributes: required, defaultValue, pattern, minLength, maxLength, min, max, range, etc.
 *
 * @remarks When used with **useBjForm** custom hook and the **record** function do not pass a **name** or **id** attribute; these should be handled inside the **record** function
 *
 * @example
 * // without using the 'record' function
 * <Input type="text" className="mt-2" defaultValue="hello!" withError={false} name="username" />
 *
 * // using the 'record function'
 * <Input type="text" minLength={3} required {...record('username')} />
 *
 * // adding a custom filter label (for type text the default is the input value, you don't need to pass the filtersLabel prop for it)
 * // also don't use the translation function here, just pass the key as a simple string and then use the mergeFilterValues() function
 * <Input type="radio" {...record('someRadio', { filtersLabel: 'username' })} />
 *
 * // Mixing the input value with some strings and passing the label as a data attribute ('data-filters-label' is the attribute name for the filter label)
 * <Input type="range" {...record('someRange')} data-filters-label={`+${range} km`} onInput={(e) => setRange(e.currentTarget.value)} />
 *
 * // and most importantly, you can also use double curly braces to translate parts of the label when using mergeFilterValues()
 * data-filters-label={`{{filters.studies.studies-end}}: ${endYear}`}
 *
 * // How to use the getValidityStates callback
 * // validityStates will be an object with isInvalid and errorMSG properties
 * // use it to display the error message in other parts of the form
 * const [validityStates, setValidityStates] = useState({ isInvalid: false, errorMSG: '' });
 * <Input type="text" required getValidityStates={setValidityStates} withError={false} />
 *
 * // Pass a custom message for empty fields
 * <Input type="text" required customValidityMessages={{ valueMissing: 'This field is required' }} />
 *
 * // ******************************************************
 * // How to trigger a custom error type. Keep in mind that you can always pass customValidityMessages to the input for
 * // specific error messages like 'valueMissing', 'tooLong', 'min', 'max', etc.
 * const [hasCustomError, setHasCustomError] = useState<boolean | undefined>(undefined);
 *
 * // Handle change function. You need to set the custom validity.
 * const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
 *  const input = e.target as HTMLInputElement;
 *  if (someCondition) {
 *    input.setCustomValidity('customError');
 *    setHasCustomError(true);
 *  } else {
 *    input.setCustomValidity('');
 *    setHasCustomError(false);
 * };
 *
 * <Input
 *  type="text"
 *  required
 *  hasCustomError={hasCustomError}
 *  customValidityMessages={{ customError: 'Some error message' }}
 *  onChange={handleChange}
 * />
 *
 */
export const Input = forwardRef<FormElementRefImperative, FormInputInterface>((props, ref) => {
  // component props
  const {
    className = '',
    required,
    withError = true,
    placeholder = ' ',
    patternMismatchMessage = '',
    customValidityMessages,
    onBlur,
    onChange,
    getValidityStates,
    hasCustomError,
    ...attributes
  } = props;

  // form elements shared logic hook
  const {
    handleInteraction, elementRef, isInvalid, errorMSG, errorClass,
  } = useSingleFormElement({
    ref,
    patternMismatchMessage,
    customValidityMessages,
    className,
    hasCustomError,
    getValidityStates,
  });

  // Rendered component **********************************
  return (
    <>
      <input
        // there's no need to check optional fields interactions, we do it only for required fields
        // allow adding extra functions for onChange & onBlur
        onChange={(e) => { if (required) handleInteraction(e); if (onChange) onChange(e); }}
        onBlur={(e) => { if (required) handleInteraction(e); if (onBlur) onBlur(e); }}
        className={`${className}${isInvalid ? errorClass : ''}`}
        ref={elementRef}
        placeholder={placeholder}
        required={required}
        {...attributes}
      />
      { // input's error field - show only if input is required and withError is true (default true)
        required && withError && <FormError errorMessage={errorMSG} isShowing={isInvalid} />
      }
    </>
  );
});
