// prop types interface
// ***************************************************************************************************
interface BadgePillSizes {
  'text-size': string,
  padding: string,
}

interface BadgePillColors {
  solid: string,
  soft: string,
  outline: string,
}

interface BadgePillStyles {
  common: string,
  round: {
    [rounding: string]: string
  },
  sizes: {
    [size: string]: BadgePillSizes
  },
  colors: {
    [color: string]: BadgePillColors
  }
}


// common css classes for all badge pills
// *******************************************************************************************************
const common = 'inline-flex items-center justify-center gap-x-1';
const solid = 'text-surface border-transparent';
const soft = 'border-transparent';
const outline = 'border';

// sizes for badge pills
const sizes = {
  pico: {
    'text-size': 'text-pico',
    padding: 'px-2.5 py-0.5',
  },
  xxs: {
    'text-size': 'text-tiny',
    padding: 'px-3.5 py-1',
  },
  xs: {
    'text-size': 'text-tiny',
    padding: 'px-3.5 py-2',
  },
  sm: {
    'text-size': 'text-sm',
    padding: 'px-4.5 py-2.5',
  },
  md: {
    'text-size': 'text-base',
    padding: 'px-5 py-3',
  },
  lg: {
    'text-size': 'text-lg',
    padding: 'px-12 py-3.5',
  },
};

// rounding corners
const round = {
  none: '',
  normal: ' rounded',
  full: ' rounded-full',
};

// colors for badge pill
const colors = {
  primary: {
    solid: `${solid} bg-primary`,
    soft: `${soft} text-primary bg-primary/10`,
    outline: `${outline} text-primary border-primary`,
  },
  'primary-light': {
    solid: `${solid} bg-primary-light`,
    soft: `${soft} text-primary-light bg-primary-light/10`,
    outline: `${outline} text-primary-light border-primary-light`,
  },
  secondary: {
    solid: `${solid} bg-secondary`,
    soft: `${soft} text-secondary bg-secondary/10`,
    outline: `${outline} text-secondary border-secondary`,
  },
  'secondary-light': {
    solid: `${solid} bg-secondary-light`,
    soft: `${soft} text-secondary-light bg-secondary-light/10`,
    outline: `${outline} text-secondary-light border-secondary-light`,
  },
  warning: {
    solid: `${solid} bg-warning`,
    soft: `${soft} text-warning bg-warning/10`,
    outline: `${outline} text-warning border-warning`,
  },
  error: {
    solid: `${solid} bg-error`,
    soft: `${soft} text-error bg-error/10`,
    outline: `${outline} text-error border-error`,
  },
  light: {
    solid: 'bg-surface-100 text-ink border-transparent',
    soft: `${soft} bg-surface-100/50 text-ink`,
    outline: 'text-ink border-surface-100',
  },
  ink: {
    solid: 'bg-ink text-surface',
    soft: `${soft} text-ink bg-ink/10`,
    outline: `${outline} text-ink border-ink`,
  },
  'ink-medium': {
    solid: `${solid} bg-ink-medium`,
    soft: `${soft} text-ink-medium bg-ink-medium/10`,
    outline: `${outline} text-ink-medium border-ink-medium`,
  },
  white: {
    solid: 'bg-white text-dark border-transparent',
    soft: `${soft} bg-white/10 text-white`,
    outline: 'text-white border-white',
  },
  info: {
    solid: `${solid} bg-info`,
    soft: `${soft} text-info bg-info/10`,
    outline: `${outline} text-info border-info`,
  },
  pink: {
    solid: `${solid} bg-pink`,
    soft: `${soft} text-pink bg-pink/10`,
    outline: `${outline} text-pink border-pink`,
  },
  dark: {
    solid: `${solid} bg-dark text-white`,
    soft: `${soft} text-dark bg-dark/10`,
    outline: `${outline} text-dark border-dark`,
  },
};

/**
 * @description Object containing the badge pill styles.
 * Take a look at the {@link composeBadgePillClasses} function for usage.
 */
export const badgePillStyles: BadgePillStyles = {
  common,
  round,
  sizes,
  colors,
};


/**
 * @description Composer for final classes to be passed to component based on color, size, styling and tailwind classes (className)
 * passed to component as props.
 * @param color
 * @param size
 * @param styling
 * @param rounding
 * @param className
 */
export const composeBadgePillClasses = (color: string, size: string, styling: string, rounding: string, className: string): string => {
  const colorClasses = badgePillStyles.colors[color];
  const sizeClasses = badgePillStyles.sizes[size];
  const roundingClasses = badgePillStyles.round[rounding];
  const sizeStyle = styling === 'text' ? sizeClasses['text-size'] : `${sizeClasses['text-size']} ${sizeClasses.padding}`;

  let colorStyle = colorClasses.solid;
  if (styling === 'outline') {
    colorStyle = colorClasses.outline;
  } else if (styling === 'soft') {
    colorStyle = colorClasses.soft;
  }

  return `${badgePillStyles.common} ${sizeStyle} ${colorStyle}${roundingClasses}${className ? ` ${className}` : ''}`;
};
