import { useTranslation } from 'next-i18next';
import React, { RefObject } from 'react';

interface IVideoInterface {
  classNames: string;
  url: string;
  controls: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  videoRef?: RefObject<HTMLVideoElement>;
  videoProps?: React.VideoHTMLAttributes<HTMLVideoElement>;
}

export const Video = ({
  classNames, url, controls, videoRef, autoPlay, muted, videoProps = {},
} : IVideoInterface) => {
  const { t } = useTranslation('common');

  return (
    <>
      {videoRef ? (
      // custom video for video cv record example
        <video
          loop
          controls={controls}
          playsInline
          className={classNames}
          ref={videoRef}
          src={`${url}`}
          {...videoProps}
        >
          {t('global.your.browser.does.not.support.video.tag')}
        </video>
      ) : (
      // default video
        <video
          autoPlay={autoPlay}
          muted={autoPlay ? true : muted}
          loop
          controls={controls}
          playsInline
          className={classNames}
          src={url}
          {...videoProps}
        >
          {t('global.your.browser.does.not.support.video.tag')}
        </video>
      )}
    </>
  );
};
