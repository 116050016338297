import { gtag } from 'ga-gtag';

type ConsentSettings = {
  ad_storage: 'granted' | 'denied',
  analytics_storage: 'granted' | 'denied',
  ad_user_data: 'granted' | 'denied',
  ad_personalization: 'granted' | 'denied',
};

export default function sendGoogleConsent(consent: ConsentSettings) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  gtag('consent', 'update', consent);
}
