import { useTranslation } from 'next-i18next';
import { UpgradeCard } from './UpgradeCard';


// Interface
interface ExtraCreditsUpgradeProps {
  packagesData?: ApiSchema<'SubscriptionUpgrade'>,
  className?: string,
}


/**
 * @description Renders the upgrade card list for Subscription Upgrades.
 * * **data** is the data for the upgrade packages.
 * * **className** prop for extra classes you might want to pass on the card. If you pass extra classes
 * to the component, it will override the default classes.
 */
export const UpgradeList = (props: ExtraCreditsUpgradeProps) => {
  // Props destructuring
  const {
    packagesData,
    className = '',
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Helpers
  const availablePackages = packagesData?.upgradePackages;
  const hasPackages = availablePackages && availablePackages?.length > 0;


  // Render component
  // *****************************************
  return (
    <div className={`grid grid-cols-1 justify-center gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:flex ${className}`}>
      { // Render the upgrade packages
        hasPackages && availablePackages.map((packageData) => (
          <UpgradeCard
            key={packageData.packageId}
            packageData={packageData}
          />
        ))
      }

      { // Fallback for no packages
        (!availablePackages || !hasPackages) && (
          <div className="w-full max-w-72 rounded-md border bg-surface p-6 text-center">
            {t('subscription.packages.no-result')}
          </div>
        )
      }
    </div>
  );
};
