import { format } from 'date-fns';
import { Locales, localesDictionary } from 'src/types/locales';


/**
 * @description Helper for detecting the Locale value
 * @param locale
 */
const getLang = (locale?: string): Locales => {
  let lang = locale as Locales;
  if (!locale || !Object.keys(localesDictionary).includes(locale)) {
    lang = Locales.RO;
  }
  return lang;
};


/**
 * @description Returns a formatted date string based on locale and a string date
 * @param date - string date
 * @param locale
 */
export const convertToLocalizedDate = (date: string, locale?: string): string => {
  const lang = getLang(locale);

  return format(new Date(date), 'dd MMM yyyy', { locale: localesDictionary[lang] });
};

/**
 * @description Returns a formatted date string based on locale and a date
 * @param date - Date
 * @param locale
 */
export const formatDateToString = (date: Date, locale?: string): string => {
  const lang = getLang(locale);
  return format(date, 'dd MMM yyyy', { locale: localesDictionary[lang] });
};

/**
 * @description Returns a formatted minutes and seconds string based on seconds
 * @param seconds
 */
export const formatSecondsToMinuteSecondsFormat = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};

/**
 * @description Returns a formatted day string with date based on locale and a date
 * @returns string - Joi, 13.01.2021
 */
export const getDayDateByLocale = (date: Date, locale: string, capitalize = true): string => {
  const lang = getLang(locale);
  const day = format(date, 'EEEE, dd.MM.yyyy', { locale: localesDictionary[lang] });

  return capitalize ? day.charAt(0).toUpperCase() + day.slice(1) : day;
};

/**
 * @description Returns time from date and locale
 */
export const getTimeFromDateAndLocale = (date: Date, locale: string): string => {
  const lang = getLang(locale);
  return format(date, 'HH:mm', { locale: localesDictionary[lang] });
};

/**
 * @description Returns a formatted day and month string based on locale and a date
 * @param date
 * @param locale
 */
export const getDayMonthByLocale = (date: Date, locale: string): string => {
  const lang = getLang(locale);
  const day = format(date, 'EEEE, d', { locale: localesDictionary[lang] });
  const month = format(date, 'MMM', { locale: localesDictionary[lang] });

  return `${day.charAt(0).toUpperCase() + day.slice(1)} ${month.charAt(0).toUpperCase()}${month.slice(1)}`;
};
