import {
  ApiInterviewInfo,
  ApiInterviewInfoOk,
  ApiInterviewReport,
  ApiInterviewReportOk,
  ApiInterviewSetCompanyInfo,
  ApiInterviewSetCompanyInfoOk,
  ApiInterviewSetUserInfo,
  ApiInterviewSetUserInfoOk,
  ApiInterviewShareContactData,
  ApiInterviewShareContactDataOk,
  ApiInterviewUserInfo,
  ApiInterviewUserInfoOk,
} from '@type/v1-api-types';

// Interview Get User Info *******************************************************************
// pathname: /v1/interview/user-info
// ************************************************************************************************
export const isApiInterviewUserInfoOk = (res: ApiInterviewUserInfo | void)
: res is ApiInterviewUserInfoOk => res !== undefined && res !== null && (res as ApiInterviewUserInfoOk)?.userId !== undefined;


// Interview Get Interview Info *******************************************************************
// pathname: /v1/interview/interview-info
// ************************************************************************************************
export const isApiInterviewInfoOk = (res: ApiInterviewInfo | void)
: res is ApiInterviewInfoOk => res !== undefined && res !== null && (res as ApiInterviewInfoOk)?.canJoin !== undefined;


// Interview Set User Info *******************************************************************
// pathname: /v1/interview/user
// ************************************************************************************************
export const isApiInterviewSetUserInfoOk = (res: ApiInterviewSetUserInfo | void)
: res is ApiInterviewSetUserInfoOk => res !== undefined && res !== null && Object.keys(res as ApiInterviewInfoOk)?.length === 0;


// Interview Set Company Info *******************************************************************
// pathname: /v1/interview/company
// ************************************************************************************************
export const isApiInterviewSetCompanyInfoOk = (res: ApiInterviewSetCompanyInfo | void)
: res is ApiInterviewSetCompanyInfoOk => res !== undefined && res !== null && Object.keys(res as ApiInterviewSetCompanyInfoOk)?.length === 0;


// Interview Share Contact Data *******************************************************************
// pathname: /v1/interview/share-contact-data
// ************************************************************************************************
export const isApiInterviewShareContactDataOk = (res: ApiInterviewShareContactData | void)
: res is ApiInterviewShareContactDataOk => res !== undefined && res !== null && Object.keys(res as ApiInterviewShareContactDataOk)?.length === 0;


// Interview Report User *************************************************************************
// pathname: /v1/interview/report
// ************************************************************************************************
export const isApiInterviewReportOk = (res: ApiInterviewReport | void)
: res is ApiInterviewReportOk => res !== undefined && res !== null && Object.keys(res as ApiInterviewReportOk)?.length === 0;

