import { Footer } from '@components/layout/Footer/Footer';
import { ReactNode } from 'react';

// This layout is used for pages that don't have a header
// *************************************************************************************
export const LayoutWithFooter = ({ children }: { children?: ReactNode }) => (
  <>
    { children }
    <Footer />
  </>
);
