// SELF INTERVIEW QUESTIONS **************************************************************************************
// pathname: /v2/resume/self-interview-list
// ************************************************************************************************
import { useSwrApi } from 'src/hooks/useSwrApi';
import { ApiGetSelfInterviewQuestionOk } from '@utils/data-fetching';


// SWR: fetch static self interview questions
// usage: const { data, error, ... } = useSwrDefaultSelfInterview(locale);
// ********************************************
export const useSwrDefaultSelfInterview = (locale: string) => {
  // SWR: fetch data
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiGetSelfInterviewQuestionOk[]>({
    url: '/v2/resume/self-interview-list',
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 5, // 5 minutes,
    },
  });

  // Return data
  return {
    data: data !== undefined ? data : [],
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
