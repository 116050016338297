'use client';

import { ImageBjSrc, ImageFolders, ImageAspectRatios } from '@type/image-bj';
import { ImageBj } from '@components/common/ImageBj/ImageBj';
import { useMemo } from 'react';
import { getLastDigit } from '@utils/numbers/getLastDigit';
import { getNameInitials } from '@utils/name-initials/name-initials';
import { getRandomArrayIndex } from '@utils/arrays/getRandomArrayIndex';
import {
  avatarStyles, composeAvatarImgClasses, composeAvatarNoImgClasses, composeCompanyLogoClasses,
} from './styles/avatarStyles';
import { AvatarBadge } from './AvatarBadge';


// Interface for AvatarPic component
// *************************************************************************************************
interface AvatarPicInterface {
  className?: string,
  imgPath?: ImageBjSrc,
  imgFolder?: ImageFolders,
  extractImageName?: boolean;
  imgPriority?: boolean,
  name?: string,
  alt?: string,
  width?: number,
  height?: number,
  rounding?: 'none' | 'base' | 'full',
  size?: 'none' | 'xs' | 'sm' | 'md' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | 'fill',
  id?: string | number,
  withRing?: boolean,
  aspectRatio?: Exclude<ImageAspectRatios, 'default'>,
}


/**
 * @description Avatar Picture component. It can be a photo or a colored circle with the user's initial(s).
 * IMPORTANT: when using size="fill", the image will be square and will fill the entire container. You will
 * have to add the "aspect-square" and "w-*" classes to the parent container. Also, when using any size other
 * than "none", you won't need to add the "width" and "height" attributes to the Image component, they will be
 * added automatically.
 *
 * -----------------------
 * **Parameters:**
 * - className: string - additional classes for the component (usually positioning classes)
 * - imgPath: string - the path to the image (if available)
 * - imgFolder: string - the folder where the image is located (default: none)
 * - extractImageName: If true, it will extract the image name from the full src string for the imgProxy loader. It's a
 * good practice to also add the imgFolder prop, but if not, it will try to also extract the folder from the src string.
 * - imgPriority: boolean - whether to prioritize the image loading (default: false)
 * - name: string - the user's name (if available). used for the initials when no image is available
 * - alt: string - the image's alt attribute
 * - width: number - the image's width (in pixels). use when you want to set a custom width. in this case make size="none"
 * - height: number - the image's height (in pixels). use when you want to set a custom height. in this case make size="none"
 * - rounding: 'none' | 'base' | 'full' - the image's rounding (default: full)
 * - size: the avatar size: 'none' | 'sm' | 'md' | 'base' etc. (default: base). It's dependent on the aspect ratio though
 * ('landscape' is 7:3 and is used for company logos anywhere where we don't need a square, so the width in px is different).
 * - id: string | number - the user's id (if available). used for the static color, so the color won't change on every rerender.
 * - withRing: boolean - whether to add a ring around the image (default: false)
 * - aspectRatio: 'square-fill' | 'square-fit' | 'landscape' - the image's aspect ratio (default: square-fill)
 *
 *  -----------------------
 * **Avatar sizes:**
 * - square: none (use width & height) | sm (36px) | md (60px) | base (80px) | lg (128px) | xl (176px) | 2xl (256px) | 3xl (360px) | fill (100%)
 * - landscape: none (use width & height) | sm (84 x 36) | md (140 x 60) | base (188 x 80) | lg (300 x 128) | xl (412 x 176) | 2xl (412 x 256) | 3xl (412 x 336) | fill (100% x 100%)

 -----------------------
 ** NB:** [Avatar.Badge]{@link AvatarBadge} is a subcomponent of Avatar. It's used to add a badge to the avatar.
 *
 *
 * @example
 * // 1. Basic usage. Note that you can use both the 'imgPath' or the 'name' prop, the internal logic will handle
 * // the priority. If you have both, the 'imgPath' will be used, and the 'name' will be used only if the image is
 * // not available (imgPath === undefined).
 * <Avatar
 *    imgPath="/path/to/image"
 *    name="John Doe"
 *    // the 'id' is used to generate a static color for the circle with the user's initials,
 *    // so it won't change on every rerender
 *    id={1}
 *    alt="image alt"
 * />
 *
 * // 2. Doing the imageProxy processing for the image when the API provides a full url. While the
 * // 'imgFolder' is not required, it's a good practice to add it, so when the API changes and provides only the
 * // image name, we won't have to make adjustments on the frontend.
 * <Avatar
 *    imgPath="https://www.bestjobs.dev/resume_image/a167-b554-c234.jpg"
 *    extractImageName
 *    // the 'imgFolder' is inferred from the 'imgPath' string
 * />
 *
 * <Avatar
 *    imgPath="https://www.bestjobs.dev/resume_image/a167-b554-c234.jpg"
 *    imgFolder="resume_image"
 *    extractImageName
 *    // the 'imgFolder' is explicitly set, if API changes to provide only the image name, we won't have to make
 *    // any adjustments on the frontend
 * />
 *
 * // 3. Dealing with an uncertain aspect ratio. This is useful in messages, when the avatar can be either a
 * // 'resume_image' (always square-fill) or a 'employer_logo' (square-fit, so we won't crop parts of the logo).
 * // In this cases set the 'aspectRatio' prop to 'square-uncertain', the folder and proper aspect ratio will be
 * // inferred from the 'imgPath' string.
 * <Avatar
 *    // let's say that the src provided by the API can be either
 *    // a resume image ('https://www.bestjobs.dev/resume_image/a167-b554-c234.jpg') or
 *    // a company logo ('https://www.bestjobs.dev/employer_logo/a167-b554-c234.jpg')
 *    imgPath="[fullUrl image path]"
 *    aspectRatio="square-uncertain"
 *    extractImageName
 * />
 *
 * // 4. Handling the company logos on cards or anywhere where we don't want a circle avatar. In these
 * // cases, the 'aspectRatio' should be set to 'landscape'.
 * <Avatar
 *   imgPath="[fullUrl image path]"
 *   aspectRatio="landscape"
 *   imgFolder="employer_logo"
 *   extractImageName
 *   // fallback for the initials circle if the image is not available
 *   name="Company Name"
 *   id={1}
 * />
 *
 * // 5. Adding a badge to the avatar. The badge can be a medal or a star. The badge can have a ring around it
 * // and a tooltip on hover. The tooltip text can be customized (the property is optional).
 * <div className="relative">
 *    <Avatar.Badge withRing iconName="medal" tooltipText="Some text" />
 *    <Avatar withRing name="Gigi" />
 * </div>
 *
 * // 6. Miscellaneous examples
 * // ***********************************
 * // with custom width and height
 * <Avatar size="none" width={48} height={48} className="w-12 h-12 text-sm" />
 *
 * // fill the entire container
 * <div className="w-full aspect-square">
 *   <Avatar size="fill" />
 * </div>
 *
 * // if you have no id, but still want to use a static color, convert the first letter of the name to a number
 * <Avatar name={user.name} id={(user.name).charCodeAt(0)} />
 */
export const Avatar = (props: AvatarPicInterface) => {
  // Component props
  const {
    className = '',
    imgPath,
    imgFolder,
    imgPriority,
    name = 'Anonymous',
    alt = '',
    width,
    height,
    rounding = 'full',
    size = 'base',
    id,
    withRing = false,
    aspectRatio = 'square-fill',
    extractImageName,
  } = props;


  // Image sizes square
  // *****************************************
  const imgWidth = width || avatarStyles.imgSizes[size];
  const imgHeight = height || avatarStyles.imgSizes[size];

  // Image sizes landscape
  // *****************************************
  const imgWidthLandscape = width || avatarStyles.companyLogoImgSizes[size].width;
  const imgHeightLandscape = height || avatarStyles.companyLogoImgSizes[size].height;


  // No image logo classes
  // Memoization of the color => the color will be generated only once and not on every re-render
  // If you want to have a static color (ie user account logo), pass a number as the id prop (best to use the user's id)
  // *****************************************
  const colorsArr = avatarStyles.randomColors;
  const memoizedColor = useMemo(() => colorsArr[getRandomArrayIndex(colorsArr)], [colorsArr]);
  const staticColor = colorsArr[getLastDigit(id)];
  const color = id || id === 0 ? staticColor : memoizedColor;


  // Render component
  // *****************************************
  return (
    <>
      { /*
          IMAGE LOGO - aspectRatio: square (default) - the classic rounded avatar
          Conditionally add attributes => if size === fill, we don't need width and height on Image
          component, else we add them because they are required when not using fill or statically
          imported images.
        */
        imgPath && aspectRatio !== 'landscape' && (
          <ImageBj
            src={imgPath}
            imgFolder={imgFolder}
            alt={alt}
            aspectRatio={aspectRatio}
            {...(extractImageName && imgPath && typeof imgPath === 'string' ? { extractImageName } : {})}
            {...(imgPriority ? { priority: imgPriority } : {})}
            {...(size === 'fill' ? { fill: true } : { width: imgWidth, height: imgHeight })}
            className={composeAvatarImgClasses(rounding, withRing, className)}
          />
        )
      }

      { /*
          IMAGE LOGO - aspectRatio: landscape - for company logos on cards for example
          Conditionally add attributes => if size === fill, we don't need width and height on Image
          component, else we add them because they are required when not using fill or statically
          imported images.
        */
        imgPath && aspectRatio === 'landscape' && (
          <ImageBj
            src={imgPath}
            imgFolder={imgFolder}
            alt={alt}
            aspectRatio={aspectRatio}
            {...(extractImageName && imgPath && typeof imgPath === 'string' ? { extractImageName } : {})}
            {...(imgPriority ? { priority: imgPriority } : {})}
            {...(size === 'fill' ? { fill: true } : { width: imgWidthLandscape, height: imgHeightLandscape })}
            className={composeCompanyLogoClasses(size, className)}
          />
        )
      }

      { /* NO IMAGE LOGO. No simple way to generate the same random both on server and on client */
        !imgPath && (
          <span className={composeAvatarNoImgClasses(color, rounding, size, withRing, className)} suppressHydrationWarning>
            {name ? getNameInitials(name) : '?'}
          </span>
        )
      }
    </>
  );
};


// Add dot notation for sub-components
// *************************************************************************************************
Avatar.Badge = AvatarBadge;
