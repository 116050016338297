// Messages from the given conversation ***********************************************************
// pathname: /v1/messages/{conversationId}/messages
// ************************************************************************************************
import { useSwrInfiniteApi } from '@hooks/useSwrApi';


// Interface
// ********************************************
interface ConversationMessagesListSwrProps {
  conversationId: string;
  withMessageEvent?: boolean;
  shouldFetch?: boolean;
  locale?: string;
  serverSideData?: ApiSchema<'MessageRead'>[];
  startIndex?: number;
  limit?: number;
  restUrlParams?: string;
}


/**
 * @description SWR: fetch messages list from the given conversation with infinite pagination.
 *
 * * **conversationId**: string. The conversation ID.
 * * **withMessageEvent**: boolean. Return or not message events in the list. Default: 0.
 * * **shouldFetch**: boolean. A flag indicating whether to start fetching messages list.
 * * **locale**: string. What locale to use for the request.
 * * **serverSideData**: ApiSchema<'MessageRead'>[], optional. The data we got from the server side request (if we do it). Default undefined.
 * * **startIndex**: number, optional. The first page number to fetch (ie: fetch starting with page 5). Default 1.
 * * **limit**: number, optional. The number of conversations to get per call. Modifies the *limit* url parameter (&limit=24). Default 24.
 * * **restUrlParams**: '&type=talent', optional. Default empty string.
 */
export const useSwrConversationMessagesList = (props: ConversationMessagesListSwrProps) => {
  // Props destructuring
  // ********************************************
  const {
    conversationId,
    withMessageEvent,
    shouldFetch = true,
    locale,
    serverSideData,
    startIndex = 0,
    limit = 10,
    restUrlParams = '',
  } = props;


  // Get data from API
  // ********************************************
  const {
    data,
    error,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  } = useSwrInfiniteApi<ApiSchema<'MessageRead'>[]>({
    url: `/v1/messages/${conversationId}/messages`,
    shouldFetch,
    locale,
    urlParams: `${withMessageEvent ? '&message_event=1' : ''}${restUrlParams}`,
    serverSideData,
    infiniteConfig: {
      paginationType: 'offset',
      limit,
      startIndex,
    },
    apiOptions: {
      keepPreviousData: true,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60, // 1 minute
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });


  // Return data
  // ********************************************
  return {
    data,
    error,
    size,
    setSize,
    isValidating,
    isLoading,
    mutate,
    isEmpty,
    showLoadMore,
    sizeAsRef,
  };
};
