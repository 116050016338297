import { BjThemeColors } from '@type/bj_theme';


// Interface
// *****************************************************
interface MeterProps {
  baseColor?: BjThemeColors,
  activeColor?: BjThemeColors,
  activeWidth?: number,
  height?: 'string',
  rounding?: 'none' | 'normal' | 'full',
  className?: string,
}


// Colors
// *****************************************************
const colors = {
  primary: 'bg-primary',
  'primary-light': 'bg-primary-light',
  secondary: 'bg-secondary',
  'secondary-light': 'bg-secondary-light',
  warning: 'bg-warning',
  error: 'bg-error',
  light: 'bg-surface-100',
  ink: 'bg-ink',
  'ink-medium': 'bg-ink-medium',
  white: 'bg-white',
  info: 'bg-info',
  pink: 'bg-pink',
  dark: 'bg-dark',
};


/**
 * @description Horizontal progress bar component. It has a base color and an active color, and the
 * active width is a percentage.
 * * **baseColor** - the base color of the bar; default: 'light'
 * * **activeColor** - the active color of the bar; default: 'secondary'
 * * **activeWidth** - the active width of the bar in percentage (pass only the number though); default: 0
 * * **height** - the height of the bar using tailwind height classes; default: 'h-1.5'
 * * **rounding** - the rounding of the bar: none, normal, full; default: 'full'
 * * **className** - additional classes to be added to the bar; default: ''
 *
 * @example
 * // display a bar with 50% active width, default colors, default height and default rounding
 * <Meter activeWidth={50} />
 *
 * // customize a bar
 * <Meter activeColor="pink" activeWidth={50} height="h-2" rounding="none" className="grow" />
 *
 */
export const Meter = (props: MeterProps) => {
  // Destructure props
  const {
    baseColor = 'light',
    activeColor = 'secondary',
    activeWidth = 0,
    height = 'h-1.5',
    rounding = 'full',
    className = '',
  } = props;

  // Rounding classes
  const roundingClasses = {
    none: '',
    normal: 'rounded-md',
    full: 'rounded-full',
  };

  // Active width
  const activeWidthPercentage = `${activeWidth}%`;


  // Render component
  // *****************************************************
  return (
    <div className={`${height} ${roundingClasses[rounding]} ${colors[baseColor]} w-full overflow-hidden ${className}`}>
      <div className={`${colors[activeColor]} h-full`} style={{ width: activeWidthPercentage }} />
    </div>
  );
};
