import { Float, FloatProps } from '@headlessui-float/react';
import { composePopoverPanelAnimation } from 'src/components/common/Popover/popoverStyles';


// Interfaces
// ******************************************
interface HeadlessFloatProps extends FloatProps {
  animation?: 'fade' | 'slideUp' | 'slideDown',
}


// Customized Float component
// ******************************************
export const HeadlessFloat = (props: HeadlessFloatProps) => {
  const {
    animation = 'fade',
    ...rest
  } = props;

  // component animation
  const componentAnimation = composePopoverPanelAnimation(animation);

  // Render component
  // **************************
  return (
    <Float
      enter="transition ease-out duration-200"
      enterFrom={componentAnimation.hiddenState}
      enterTo={componentAnimation.visibleState}
      leave="transition ease-in duration-150"
      leaveFrom={componentAnimation.visibleState}
      leaveTo={componentAnimation.hiddenState}
      flip
      {...rest}
    />
  );
};
