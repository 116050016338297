import { flattenObj } from '@utils/index';
import { ServerFieldErrorInterface, StoredElementsInterface, ElementsRefsInterface } from 'src/types/form-types';

/**
 * @description Custom hook that contains all the functions needed for handling form errors.
 * The functions are:
 * @description {@link convertOldApiFormErrors} - Convert old API errors (v0) from object to array of objects.
 * @description {@link handleFormErrors} - Handles form errors from server.
 *
 * @returns convertOldApiFormErrors
 * @returns handleFormErrors
 */

export const useHandleFormErrors = () => {
  /**
  * @description Convert old API errors (v0) from object to array of objects
  *
  * @example
  * const oldApiError = {children: {account: {children: {identifier: {errors: [
  *  {
  *    message: 'Please double-check. You have entered an invalid Fiscal Code number',
  *    errorCode: 400,
  *  }, ], }, }, }, }, };
  * convertOldApiFormErrors(oldApiError);
  *
  * // output:
  * // [{
  * //   path: 'account.identifier',
  * //   message: 'Please double-check. You have entered an invalid Fiscal Code number',
  * // }]
  *
  * @param errorsObj
  */
  const convertOldApiFormErrors = (errorsObj: object): ServerFieldErrorInterface[] => {
    // step 1: flatten the errors object
    const flatObjErrors: object = flattenObj(errorsObj['children' as keyof typeof errorsObj]);

    // step 2: get the correct names from keys; remove unwanted words from keys
    const keyNames = Object.keys(flatObjErrors)
      .map((item) => item
        .replace(/.children/g, '')
        .replace(/.errors/g, ''));

    // step 3: get the error messages for each field with name
    const keyValues = Object.values(flatObjErrors)
      .map((item: object[]) => item[0]['message' as keyof object]);

    // the errors holder in the desired format
    const errorsFinal = [];

    // build the array of objects with errors
    for (let i = 0; i < keyNames.length; i += 1) {
      errorsFinal.push({
        path: keyNames[i],
        message: keyValues[i],
      });
    }

    return errorsFinal;
  };

  /**
  * @description Handles form errors from server. It parses the stored form elements array and for each element's name
  * that matches the one from an object inside the errors array, it will trigger the serInvalidFromServer method for that field
  * from {@link useSingleFormElement}. It will also scroll to the first error.
  *
  * @description Requires {@link storedElements} and {@link elementsRefs} from the {@link useRecordForm useRecordForm} hook.
  *
  * @param errorsArr
  * @param storedElements
  * @param elementsRefs
  * @param scrollToFirstError
  *
  * @example
   * // errors array example
   * const errorsArray = [
   *    {
   *      code: 400,
   *      path: userName,
   *      message: "The username is already registered",
   *    },
   * ];
  */
  const handleFormErrors = (
    errorsArr: ServerFieldErrorInterface[],
    storedElements: StoredElementsInterface[],
    elementsRefs: ElementsRefsInterface,
    scrollToFirstError: boolean,
  ) => {
    storedElements.forEach((element) => {
      const refKey:string = element.id;
      const currElement = elementsRefs[refKey]?.current;

      // we look at the array with errors and for each match we call the error message
      // NOTE1: we also have to flatten the nested field names ('path' in response)? No API model yet.
      // NOTE2: if no nested path then we will need a regex to take the value after the last dot
      errorsArr.map((item: ServerFieldErrorInterface, index) => {
        if (item.path === element.name) {
          currElement?.setInvalidFromServer(item.message);

          // scroll to first error
          if (index === 0 && scrollToFirstError) currElement?.scrollToElement();
        }
        return false;
      });
    });
  };


  return { convertOldApiFormErrors, handleFormErrors };
};
