import { useTranslation } from 'next-i18next';
import { CustomBiaEvaluate } from '@components/common/Icons';

// Interface
// ***********************************
interface ChatAgentProps {
  name?: string;
  description: string;
}

/**
 * @description  Chat agent component.
 * A reusable component for displaying chat AI agents with their name and description.
 *
 * - **name**: The name of the chat agent.
 * - **description**: The description of the chat agent.
 */
export const ChatAgent = (props: ChatAgentProps) => {
  // Destructure props
  const { name = 'Bia', description } = props;
  const { t } = useTranslation('common');

  // Render component
  // *****************************************************
  return (
    <div className="mb-3 mt-1 flex flex-row items-stretch">
      <CustomBiaEvaluate className="size-14" />
      <div className="ml-3 flex flex-col justify-center">
        <h2 className="text-lg font-bold">{name}</h2>
        <p className="text-sm">{t(description)}</p>
      </div>
    </div>
  );
};
