import { Trans } from 'next-i18next';
import { Card } from '@components/common';

// Interface
// *****************************************
interface CardSubscriptionPricingProps {
  paymentData?: ApiSchema<'SubscriptionPayment'>,
  className?: string,
}


/**
 * @description Renders the pricing card in upgrade subscription packages payment view.
 * * **paymentData** is the data for the payment options and pricing.
 * * **packagePrice** is the price of the selected package.
 * * **paymentMethodType** is the type of payment method selected.
 */
export const PricingUpgradePackage = (props: CardSubscriptionPricingProps) => {
  // Props destructuring
  const {
    paymentData,
    className = '',
  } = props;

  // Prices
  // *****************************************
  const priceCredits = paymentData?.periodicPaymentAmount || 0;

  // Render component
  // *****************************************
  return (
    <Card className={`rounded-md border bg-surface-50 ${className}`}>

      <Card.Header className="flex items-center justify-between gap-2 rounded-t-md border-b bg-surface px-6 py-3.5">
        {/* Selected package name */}
        <h3 className="text-lg font-bold">
          {paymentData?.paymentTitle || ''}
        </h3>
      </Card.Header>
      <div className="bg-surface-100 p-6">
        <Trans
          i18nKey="premium.payment.package.desc"
          values={{
            paymentTitle: priceCredits,
          }}
        />
      </div>
      <Card.Body className="px-6 text-ink-medium">

        {/* Free trial description */}
        {paymentData?.periodicPaymentDescription && (
          <div className="-mx-6 -mt-3 mb-3 border-b bg-surface-100 px-6 py-3 text-sm text-ink-medium">
            {paymentData?.periodicPaymentDescription}
          </div>
        )}

        {/* Package price in credits */}
        <div className="flex items-center justify-between py-1.5">
          <span>{paymentData?.periodicPaymentLabel}</span>
          <strong className="text-right text-ink">{priceCredits}</strong>
        </div>

        {/* Package price in euros; Free trial data is first in pecking order */}
        <div className="flex items-center justify-between border-t py-1.5">
          <span>{paymentData?.ctaInfoLabel}</span>
          <strong
            className="text-right text-lg text-secondary"
          >
            {/* We are showing 0 because billing happens at the end of month, hardcoded because app uses old version, not great not terrible... */}
            € 0
          </strong>
        </div>
      </Card.Body>
    </Card>
  );
};
