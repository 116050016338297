export const LOCAL_STORAGE_KEYS = {
  // Same key in SYMFONY. timestamp key to store the last time the user received an online notification
  timestampReceivedOnlineNotification: 'timestampReceivedOnlineNotification',
  // Same key in SYMFONY. timestamp key to store the last time a start session notification was displayed
  timestampDisplayedOnlineStartSessionNotification: 'timestampDisplayedOnlineStartSessionNotification',
  // selected domains for live interview
  liveSelectedDomains: 'liveSelectedDomains',
  // selected domain to filter the live interview
  liveSelectedDomainExclusive: 'liveSelectedDomainExclusive',
  // selected location to filter the live interview
  liveSelectedLocationExclusive: 'liveSelectedLocationExclusive',
  // shown survey after login
  shownSurveyAfterLogin: 'shownSurveyAfterLogin',
};
