import {
  Dispatch, forwardRef, SetStateAction,
} from 'react';
import { useTranslation } from 'next-i18next';
import { Card, ImageBj } from '@components/common';
import { CardProps } from '@type/card';
import { CreditCardIcon, WalletIcon, BanknotesIcon } from '@heroicons/react/24/outline';
import poweredByStripe from '@images/svg/payment/powered_by_stripe.svg';
import { HeroIconType } from '@type/icons';
import { CreditCardList } from './CreditCardList';
import { ChooseCreditCard } from './ChooseCreditCard';


// Interfaces
// *****************************************
interface CardPaymentProps extends CardProps {
  paymentMethod: ApiSchema<'PaymentTypeModel'>,
  creditsAmount?: number,
  userSavedCreditCards?: ApiSchema<'CreditCardRead'>[] | undefined,
  getSelectedPaymentCard?: Dispatch<SetStateAction<number>>
}

interface CardTypes {
  [type: string]: {
    icon: HeroIconType,
    text: string,
  }
}


// Types of payment cards
// *****************************************
const cardTypes: CardTypes = {
  stripe: { // pay with stripe
    icon: CreditCardIcon,
    text: '',
  },
  bank: { // pay with wallet
    icon: WalletIcon,
    text: '',
  },
  op: { // pay with ordin de plata
    icon: BanknotesIcon,
    text: 'payment.by.op.description',
  },
};


// Payment styles
// *****************************************
// Classes with UI modifiers 'ui-selected:' & 'ui-not-selected:'
const cardBaseStyles = {
  common: 'rounded-md bg-surface border cursor-pointer',
  selected: 'ui-checked:border-primary-light/80 ui-checked:ring ui-checked:ring-primary-light/30 ui-checked:outline-dotted ui-checked:outline-primary-light/30',
  notSelected: 'ui-not-checked:hover:border-primary-light/40',
};

const cardStyles = Object.values(cardBaseStyles).join(' ');


/**
 * @description Renders a payment card method.
 * * **paymentMethod** is the payment method object.
 * * **className** extra classes you might want to pass on the card.
 */
export const CardPayment = forwardRef<HTMLDivElement, CardPaymentProps>((props, ref) => {
  // Props destructuring
  const {
    paymentMethod,
    className = '',
    userSavedCreditCards,
    getSelectedPaymentCard,
    ...restProps
  } = props;

  const { type, name } = paymentMethod;

  // Translation
  const { t } = useTranslation('common');

  // Get the content from the cardTypes object
  const IconName: HeroIconType = type ? cardTypes[type].icon : cardTypes.bank.icon;
  const cardText = type ? cardTypes[type].text : '';

  // Render card
  // *****************************************
  return (
    <Card ref={ref} animation="riseUp" className={`${cardStyles} ${className}`} {...restProps}>
      <Card.Body className="flex gap-3 p-4">
        { /* Icon */ }
        <IconName className="size-9 shrink-0 stroke-1 text-primary-light" />

        { /* Content */ }
        <div className="grow">
          { /* Name */ }
          <h2 className="mb-1.5 mt-1 pt-px text-lg font-bold">{name}</h2>
          {cardText !== '' && (
            <p className="text-sm text-ink-medium">{t(cardText)}</p>
          )}
          {paymentMethod.unavailabilityReason && (
            <p className="text-sm text-ink-medium">{paymentMethod.unavailabilityReason}</p>
          )}
          { /* Description */}


          { /* Content only for: Pay with stripe */
            type === 'stripe' && (
            <>
              { /* List all the saved credit cards */
                userSavedCreditCards && userSavedCreditCards?.length > 0 && (
                  <div className="mb-6 mt-3 sm:mb-4 sm:mt-2.5 sm:flex sm:items-center sm:gap-2.5">
                    <span className=" text-sm text-ink-medium">
                      {`${t('label-payment.selected-card')}:`}
                    </span>
                    <ChooseCreditCard
                      className="relative grow"
                      getSelectedPaymentCard={getSelectedPaymentCard}
                      userSavedCreditCards={userSavedCreditCards}
                    />
                  </div>
                )
              }
              { /* Stripe and credit card company logos */ }
              <div className="mt-4 flex items-end justify-between gap-2">
                <CreditCardList className="shrink-0" logoSize="w-6" />
                <ImageBj
                  src={poweredByStripe as string}
                  alt="Powered by Stripe"
                  width={20}
                  height={20}
                  className="h-5 w-auto opacity-60"
                />
              </div>
            </>
            )
          }
        </div>
      </Card.Body>
    </Card>
  );
});
