import { Middleware, SWRResponse } from 'swr';
import { useRef, useEffect, useState } from 'react';
import { dequal } from 'dequal';

/** Types */
type DataType = SWRResponse['data'];
type CallbackFn = (data?: DataType) => void;

/**
 * @description SWR Middleware that triggers a callback function when the data changes. We need this because
 * onSucces triggers only after fetching the data and not when the data is loaded from the SWR cache.
 * - `callbackFn` - the function that will be called when the data changes.
 */
export const onDataChange = (callbackFn: CallbackFn): Middleware => (useSWRNext) => (key, fetcher, config) => {
  // Previous data. Use the fallback data if just mounted.
  const prevData = useRef<DataType>(config?.fallbackData);

  // Actual SWR hook.
  const swr = useSWRNext(key, fetcher, config);
  const { data: currentData } = swr;

  // Data changed state
  const [dataChanged, setDataChanged] = useState(false);

  // Compare the previous data with the current data to check if the data has changed.
  useEffect(() => {
    if (currentData !== undefined && !dataChanged) {
      // Check if the data has changed and call the callback function
      if (!dequal(prevData.current, currentData)) {
        setDataChanged(true);
      } else {
        setDataChanged(false);
      }
      // Update the previous data
      prevData.current = currentData;
    }
  }, [currentData, dataChanged]);

  // Run the callback function if the data has changed.
  if (dataChanged) {
    setDataChanged(false);
    callbackFn(currentData);
  }

  return swr;
};
