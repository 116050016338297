import { ModalCommonProps } from 'src/types/modal-types';
import { ModalStyles } from './_modalStyles';


/**
 * @description Modal footer component.
 * * **className** - additional classes for the footer
 * * **content** - footer content when not using children (passed as prop)
 * * **children** - footer content when using the footer with dot notation. It will display nothing
 * if you pass the content prop.
 */
export const ModalFooter = (props: ModalCommonProps) => {
  // Destructuring props
  const {
    children,
    content = '',
    className = '',
  } = props;

  // Footer content; can be either from content prop or children, but not both
  const footerContent = content || children;

  // Render component
  // **************************************
  return (
    <div className={`${ModalStyles.footer} ${className}`}>
      {footerContent}
    </div>
  );
};
