// Interfaces
// *********************************************************************
interface SizeClasses {
  [size: string]: string,
}

interface TypeClasses {
  [type: string]: SizeClasses,
}

interface AvatarBadgeStyles {
  base: string,
  ring: string,
  sizeBadge: TypeClasses,
  sizeIcon: SizeClasses,
}


// Avatar badge component styles
// *********************************************************************
// Base classes
const base = 'absolute flex items-center justify-center rounded-full bg-surface';

// Ring classes
const ring = 'outline outline-1 outline-offset-1 outline-ink/20 ring-1 ring-transparent';

// Size classes for badge
const sizeBadge = {
  withRing: {
    none: '',
    sm: 'w-4 h-4 -left-0.5 -bottom-0.5',
    md: 'w-5 h-5 -left-0.5 -bottom-0.5',
    base: 'w-6 h-6 left-0 bottom-0',
    lg: 'w-8 h-8 left-0 bottom-0',
    xl: 'w-10 h-10 left-0 bottom-0',
  },
  withoutRing: {
    none: '',
    sm: 'w-4 h-4 -left-0.5 -bottom-0.5',
    md: 'w-5.5 h-5.5 -left-0.5 -bottom-0.5',
    base: 'w-6.5 h-6.5 left-0 bottom-0',
    lg: 'w-8.5 h-8.5 left-0 bottom-0',
    xl: 'w-11 h-11 left-0 bottom-0',
  },
};

// Size classes for icon
const sizeIcon = {
  none: '',
  sm: 'w-2 h-2',
  md: 'w-2.5 h-2.5',
  base: 'w-3 h-3',
  lg: 'w-4 h-4',
  xl: 'w-5 h-5',
};

// Export the styles
export const avatarBadgeStyles: AvatarBadgeStyles = {
  base,
  ring,
  sizeBadge,
  sizeIcon,
};


// Helper function to compose the classes for the badge wrapper
// *********************************************************************
export const badgeWrapperClasses = (size: string, withRing: boolean, className: string): string => {
  const ringClass = withRing ? avatarBadgeStyles.ring : '';
  const sizeClass = withRing
    ? avatarBadgeStyles.sizeBadge.withRing[size]
    : avatarBadgeStyles.sizeBadge.withoutRing[size];

  return `${avatarBadgeStyles.base} ${sizeClass}${ringClass ? ` ${ringClass}` : ''}${className ? ` ${className}` : ''}`;
};


// Helper function to compose the classes for the badge icon
// *********************************************************************
export const badgeIconClasses = (size: string, iconClass: string): string => {
  const sizeClass = avatarBadgeStyles.sizeIcon[size];
  return `${sizeClass}${iconClass ? ` ${iconClass}` : ''}`;
};
