import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import {
  CheckCircleIcon, InformationCircleIcon, ExclamationTriangleIcon, ExclamationCircleIcon,
} from '@heroicons/react/24/solid';
import { Anchor } from '@components/common/Anchor/Anchor';


// Props
interface IProps {
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  isClosable?: boolean;
  onClose?: () => void;
  ctaText?: string;
  urlPath?: string;
  symfonyRoute?: boolean;
  symfonyParams?: object;
}


// icons by type
const ICONS = {
  success: <CheckCircleIcon className="fill-secondary" />,
  info: <InformationCircleIcon className="fill-info" />,
  warning: <ExclamationTriangleIcon className="fill-warning-light" />,
  error: <ExclamationCircleIcon className="fill-error-light" />,
};

// container classes by type
const containerClassesByType = {
  success: 'bg-secondary bg-opacity-10',
  info: 'bg-info bg-opacity-10',
  warning: 'bg-warning bg-opacity-10',
  error: 'bg-error bg-opacity-10',
};


/**
 * @description AlertMessage component for displaying different type of messages
 *
 * **IMPORTANT**: Don't use router.push() for non React routes. If you do, we'll get errors like:
 * https://[name].bestjobs.dev/_next/static/chunks/pages/ro/account/settings.js net::ERR_ABORTED 404
 * Error: Failed to load script: /_next/static/chunks/pages/ro/account/settings.js
 *     at script.onerror (webpack-internal:///./node_modules/next/dist/client/route-loader.js:102:52)
 *
 * Just leverage Anchor component for non React routes, it takes care of this as long as you pass the
 * symfonyRoute and symfonyParams props.
 *
 * @param message
 * @param type
 * @param ctaText
 * @param ctaOnClick
 * @param isClosable
 * @param onClose
 * @param symfonyRoute
 * @param symfonyParams
 * @param urlPath
 *
 */
export const AlertMessage = ({
  message,
  type,
  ctaText,
  isClosable = false,
  onClose = () => {},
  symfonyRoute = false,
  symfonyParams = {},
  urlPath,
}: IProps) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  // Translate
  const { t } = useTranslation('common');

  const mainContainerAlignClasses = (ctaText && urlPath) ? 'justify-between' : 'justify-center';
  const subContainerAlignClasses = (ctaText && urlPath) ? '' : 'flex-grow';

  const onLocalClose = () => {
    setIsClosed(true);
    onClose();
  };

  if (isClosed) {
    return null;
  }

  // Render component
  // ****************************************************************
  return (
    <div className="bg-surface">
      <div className={`${containerClassesByType[type]}`}>
        <div className={`container flex w-full rounded px-4 py-2 ${mainContainerAlignClasses}`}>
          <div className={`flex items-center justify-center ${subContainerAlignClasses}`}>
            {/* svg icon */}
            <div>
              <div className="size-5">
                {ICONS[type]}
              </div>
            </div>
            <p className="mx-3 text-sm font-bold">
              {message}
            </p>
          </div>

          {/* close icon */}
          { !ctaText && !urlPath && isClosable && (
          <button type="button" className="relative flex size-5 items-center" onClick={onLocalClose} aria-label={t('global.btn.close')}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth={1.5} className="absolute h-full stroke-ink">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          )}

          {/* cta button */}
          { ctaText && urlPath && (
          <div className="my-auto">
            <Anchor
              href={urlPath}
              symfonyRoute={symfonyRoute}
              symfonyParams={symfonyParams}
              rounding="full"
              type="button"
              size="sm"
              className="!py-2"
              color={type === 'success' ? 'secondary' : type}
            >
              {ctaText}
            </Anchor>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};
