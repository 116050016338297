import { BiaDropdown } from '@components/layout/Header/MainNavbar/bia/BiaDropdown';
import { useEffect, useState } from 'react';
import { Agent } from '@type/bia';
import { CustomBiaEvaluate } from '@components/common/Icons/CustomOutline';
import { XMarkIcon } from '@heroicons/react/24/outline'; // Import the XMarkIcon

/**
 * @description Renders the bia chat section in the navbar with a floating button.
 * * **className** Extra classes
 * * **locale** The locale of the app
 */
export const BiaSection = () => {
  // Component props with default values

  // Hanna agent is default agent now,
  // keeping this structure if we want to add more agents in future
  const hannaAgent: Agent = {
    id: 'hr',
    name: 'Hanna',
    description: 'hanna_description',
    logo: 'hr/logo.png',
    imageUrl: '/images/agent/hanna.png',
    videoUrl: '/videos/agent/hanna.mp4',
    slug: 'hanna',
    isSpecial: false,
    isActive: false,
    welcomeMessage: 'hanna_welcome',
  };

  // State to toggle the visibility of the content
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Handle button click to toggle the content
  const handleButtonClick = () => {
    setIsOpen((prevState) => !prevState);
  };


  useEffect(() => {
    if (isOpen) {
      setIsLoaded(true);
    }
  }, [isOpen]);


  // Render component
  // ******************************************
  return (
    <div
      className={`fixed bottom-0 right-0 z-50 flex h-full items-end border-b transition-all duration-500 md:h-2/3 ${
        isOpen ? 'translate-x-0' : 'translate-x-[calc(100%_-_65px)]'
      }`}
    >

      <div
        role="presentation"
        onClick={handleButtonClick}
        className={`${isOpen ? 'hidden' : 'flex'} mb-20 mr-10 size-12 items-center justify-center rounded-full bg-surface shadow-lg md:mb-10`}
      >
        <CustomBiaEvaluate className="size-12" />
      </div>
      <div className="relative h-full max-w-[450px] rounded-tl-lg bg-surface shadow-lg">

        {isOpen
          && (
          <div
            role="presentation"
            onClick={handleButtonClick}
            className="absolute right-0 top-3 z-1 flex size-12 cursor-pointer items-center justify-center rounded-l-xl bg-surface shadow-lg"
          >
            <XMarkIcon className="size-8 " />
          </div>
          )}

        {isLoaded && <BiaDropdown agent={hannaAgent} />}
      </div>

    </div>
  );
};
