/**
 * @description Omits key/value pairs from an object and returns a new object. Works similar to
 * TypeScript's Omit utility type.
 *
 * @param obj - the object to omit from
 * @param keys - the keys to omit (string array)
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * omitFromObject(obj, ['a', 'b']);
 * // => { c: 3 }
 */
export const omitFromObject = <T extends object, K extends keyof T>(obj: T, keys: K[]) => {
  const newObj = { ...obj };

  keys.forEach((key) => {
    delete newObj[key];
  });

  return newObj;
};
