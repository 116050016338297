export const ids = {
  'cookiesModal.acceptButton': 'cookie-consent-accept',
  'navbar.loginButton': 'navbar-login-button',
  'navbar.profile': 'navbar-profile',
  'navbar.neuroButton': 'navbar-neuro-button',
  'navbar.switchLanguage': 'navbar-switch-language',
  'navbar.cvSearchPage': 'navbar-cv-search',
  'navbar.jobsPage': 'navbar-jobs',
  'navbar.myJobsPage': 'navbar-my-jobs',
  'navbar.salariesPage': 'navbar-salaries',
  'navbar.addJobPage': 'navbar-add-job',
  'navbar.servicesPage': 'navbar-services',
  'navbar.messagesPage': 'navbar-messages',
  'navbar.messagesButton': 'navbar-messages-button',
  'navbar.messagesViewAll': 'navbar-messages-view-all',
  'navbar.notificationsPage': 'navbar-notifications',
  'navbar.notificationsButton': 'navbar-notifications-button',
  'navbar.notificationsViewAll': 'navbar-notifications-view-all',
  'navbar.notificationsDeleteAll': 'navbar-notifications-delete-all',
  'navbar.biaButton': 'navbar-bia-button',
  'navbar.searchInput': 'navbar_keyword',
  'eventsFeedList.loadMoreButton': 'feed-load-more-button',
  'eventsFeedCard.footer.login': 'feedCard-footer-login',
  'eventsFeedCard.footer.viewCv': 'feedCard-footer-view-cv',
  'eventsFeedCard.footer.companyProfile': 'feedCard-footer-companyProfile',
  'eventsFeedCard.footer.candidateHire': 'feedCard-footer-candidateHire',
  'eventsFeedCard.footer.experience': 'feedCard-footer-experience',
  'eventsFeedCard.footer.salaryAdd': 'feedCard-footer-salaryAdd',
  'eventsFeedCard.footer.jobDetails': 'feedCard-footer-jobDetails',
  'eventsFeedCard.footer.jobApply': 'feedCard-footer-jobApply',
  'lexicalEditor.inputFileUpload': 'image-modal-file-upload',
  'lexicalEditor.inputFileConfirmButton': 'image-modal-file-upload-btn',
  'cvSearchPage.filtersButton': 'cv-search-filters-button',
  'cvSearchPage.filters.clearAll': 'cv-search-filters-clearAll',
  'cvSearchPage.filters.location': 'cv-search-filters-location',
  'cvSearchPage.filters.searchIn': 'cv-search-filters-searchIn',
  'cvSearchPage.filters.display': 'cv-search-filters-display',
  'cvSearchPage.filters.lastActive': 'cv-search-filters-lastActive',
  'cvSearchPage.filters.spokenLanguages': 'cv-search-filters-spokenLanguages',
  'cvSearchPage.filters.careerLevels': 'cv-search-filters-careerLevels',
  'cvSearchPage.filters.domain': 'cv-search-filters-domain',
  'cvSearchPage.filters.studyLevels': 'cv-search-filters-studyLevels',
  'cvSearchPage.filters.submit': 'cv-search-filters-submit',
  'cvSearchPage.filters.close': 'cv-search-filters-close',
  'cvSearchPage.orderByRelevance': 'cv-search-order-by-relevance',
  'cvSearchPage.orderByDistance': 'cv-search-order-by-distance',
  'cvSearchPage.orderByActivity': 'cv-search-order-by-activity',
  'cvSearchPage.card.saveToShortlistAction': 'cv-search-card-saveToShortlistAction',
  'cvSearchPage.card.unlockByCall': 'cv-search-card-unlockByCall',
  'cvSearchPage.card.unlockWithMessage': 'cv-search-card-unlockWithMessage',
  'cvSearchPage.card.default': 'cv-search-card',
  'companyOfferPage.creditBuyButton': 'company-offer-credit-buy-button',
  'continueOrderPage.orderButton': 'continue-order-button',
  'orderCheckoutPage.orderButton': 'order-checkout-continue-button',
  'jobAddPage.showMoreButton': 'jobAdd-show-more-button',
  'jobAddPage.jobAddress.addButton': 'jobAdd-add-location-button',
  'jobAddPage.jobDescription.container': 'jobAdd-description-container',
  'jobAddPage.jobDescription.stopAiGenerateButton': 'jobAdd-stop-ai-generate-button',
  'jobAddPage.jobDescription.regenerateDescription': 'jobAdd-regenerate-description-button',
  'jobAddPage.jobSalary.addSuggestedSalaryButton': 'jobAdd-add-suggested-salary-button',
  'jobAddPage.jobDomain.openEditButton': 'jobAdd-open-domain-edit-button',
  'jobAddPage.jobInterviewQuestions.addButton': 'jobAdd-add-interview-question-button',
  'jobAddPage.jobActivation.fixCategoryButton': 'jobAdd-fix-category-button',
  'jobAddPage.jobActivation.flexCategoryButton': 'jobAdd-flex-category-button',
  'jobAddPage.jobActivation.openDropdownButton': 'jobAdd-open-dropdown-button',
  'jobAddPage.jobBenefits.editBenefitsButton': 'jobAdd-edit-benefits-button',
  'jobAddPage.jobBenefits.saveBenefitsButton': 'jobAdd-save-benefits-button',
  'jobAddPage.jobActions.saveJobButton': 'jobAdd-save-job-button',
  'jobAddPage.jobActions.activateJobButton': 'jobAdd-activate-job-button',
  'jobAddPage.jobActions.previewJobButton': 'jobAdd-preview-job-button',
};
