import { flattenObj } from '@utils/flatten-expand-object/flatten-expand-object';
import { compileToPath, getRegexPathParams, PathToRegexpParseResult } from '@utils/path-to-regexp';
import { I18nLangPaths } from 'src/types/i18n-paths';
import { i18nConfig } from 'i18n-config';
import { pathToRegexp } from 'path-to-regexp';
import { localesRegex } from './helpers_all';
import { i18nBasePaths } from './paths';


/**
 * @description Get the helper variables from the i18nConfig object.
 */
const {
  locales,
  defaultLocale,
  prefixedDefaultLocale,
} = i18nConfig;


/**
 * @description First step in transforming the humanly readable i18nBasePaths into something easy
 * to use inside middleware. Flatten the i18nBasePaths object
 *
 * @example
 * // now the flatten object will look like this
 * {
 *   '/temp/translate-url/localized-url.ro': '/url-localizat',
 *   '/temp/translate-url/localized-url.en': '/url-localized',
 *   '/temp/translate-url/localized-url.hu': '/url-lokalizalt',
 * }
 */
const flattenI18nPaths = flattenObj(i18nBasePaths);


/**
 * @description Helper function that will take a string and will split it at the dot [.]
 * We will use the second part after the dot as URL prefix.
 * @param url
 *
 * @example
 * const roOriginalString = '/temp/translate-url/localized-url.ro';
 * const enOriginalString = '/temp/translate-url/localized-url.en';
 *
 * reorderI18nUrlString(roOriginalString);
 * reorderI18nUrlString(enOriginalString);
 *
 * // output
 * // 'ro/temp/translate-url/localized-url'
 * // 'en/temp/translate-url/localized-url'
 */
const reorderI18nUrlString = (url: string) => {
  // before the dot
  const baseUrl = url.split('.')[0];
  // after the dot
  const locale = url.split('.')[1];

  // return the prefixed url
  return `/${locale}${baseUrl}`;
};


/**
 * @description Final step in obtaining an object that will be quickly & easy parsed
 * inside middleware. We take the flattenI18nPaths object, we swap the key & value pairs (the key
 * will become value and vice versa) and then create the final paths object.
 *
 * @example
 * // the final paths object will be like this
 * // [pathname in browser]: url to actually render;
 * {
 *   '/url-localizat':  '/temp/translate-url/localized-url',
 *   '/url-localized':  '/en/temp/translate-url/localized-url',
 *   '/url-lokalizalt': '/hu/temp/translate-url/localized-url'
 * }
 */
const i18nMiddlewarePaths = Object.fromEntries(
  Object.entries(flattenI18nPaths)
    .map(([key, value]) => [value, reorderI18nUrlString(key)]),
) as I18nLangPaths;


/**
 * @description Bi-dimensional array that holds the dynamic parameters obtained using
 * **path-to-regexp** module.
 *
 * @example
 * // the first element is the URL to check against current page URL,
 * // the second element is the URL for the rewrite
 * const i18nParamsMap = {
 *    [[{pattern: 'Regex string', source: 'url string'}], 'rewrite url string'],
 * }
 */
const i18nMiddlewareParamsMap: (PathToRegexpParseResult | string)[][] = Object.entries(i18nMiddlewarePaths)
  .map((entry) => {
    const result = [];
    result.push({ pattern: pathToRegexp(entry[0]).regexp, source: entry[0] }, entry[1]);
    return result;
  });


/**
 * @description Check if the provided pathname is part of the i18nParamsMap (normal & dynamic i18n routes).
 * If it is, take the parameters, pass them to the rewrite URL and return that url for middleware.
 * @param pathname the current page pathname or one to check against
 */
export const checkI18nPath = (pathname: string): string | undefined => {
  let newUrl: string | undefined;

  // strip the locale from the pathname
  const cleanPathname = pathname?.replace(localesRegex, '');

  i18nMiddlewareParamsMap.forEach((element) => {
    const parsedObj = element[0] as PathToRegexpParseResult;
    const rewriteUrl = element[1] as string;

    // test current pathname for matching the dynamic path
    // if it is, inject the correct parameters and return the pathname for rewrite
    if (parsedObj.pattern.test(cleanPathname)) {
      const dynamicParams = getRegexPathParams(cleanPathname, parsedObj);
      newUrl = compileToPath(rewriteUrl, dynamicParams);
    }
  });

  return newUrl;
};


/**
 * @description Function to check if we should redirect the default locale paths. If `prefixedDefaultLocale` from
 * i18nConfig is set to `true`, we will redirect all the missing locales to the default locale. If it's set to `false`,
 * we will strip the default locale from the pathname and redirect.
 *
 * **Note**: This function should be used together with `checkDefaultLocaleRewrite`.
 *
 * @param pathname - string,  the current page pathname
 *
 * @example
 * // in middleware.ts, check if we need to redirect the default locale
 * const newPath = checkDefaultLocaleRedirect('/ro/some-page');
 *
 * // redirect to the new path
 * if (newPath) {
 *    return NextResponse.redirect(new URL(`${newPath}${search}`, url));
 * }
 */
export const checkDefaultLocaleRedirect = (pathname: string): string | undefined => {
  const defaultLocaleFragment = `/${defaultLocale}`;

  // Case without prefixed default locale
  // If the pathname starts with the default locale, remove it
  if (!prefixedDefaultLocale) {
    const hasDefaultLocale = pathname?.startsWith(defaultLocaleFragment) || pathname === defaultLocaleFragment;
    return hasDefaultLocale
      ? pathname?.replace(defaultLocaleFragment, pathname === defaultLocaleFragment ? '/' : '')
      : undefined;
  }

  // Case with prefixed default locale
  // If the pathname doesn't start with any locale, add the default locale
  const hasNoDefaultLocale = locales.every((lang) => !pathname?.startsWith(`/${lang}`) && pathname !== `/${lang}`);
  return hasNoDefaultLocale
    ? `/${defaultLocale}${pathname?.startsWith('/') ? '' : '/'}${pathname}`
    : undefined;
};


/**
 * @description Function to check if we should rewrite the default locale paths. If `prefixedDefaultLocale` from
 * i18nConfig is set to `true`, we don't do anything. If it's set to `false`, we rewrite the URL to include it,
 * so Next.js can understand that even if the URL is '/page', it should pretend to be '/ro/page'.
 * **Note**: This function should be used together with `checkDefaultLocaleRedirect`.
 *
 * **NB: don't do it for i18n paths, as they are already rewritten.**
 *
 * @param pathname - string, the current page pathname
 * @param isI18nPath - boolean, if the current path is an i18n path
 *
 * @example
 * // in middleware.ts, check if we need to rewrite the default locale
 * const newPath = checkDefaultLocaleRewrite('/some-page', i18nPath);
 *
 * // rewrite the path
 * if (newPath) {
 *   return NextResponse.rewrite(new URL(`${newPath}${search}`, url));
 * }
 */
export const checkDefaultLocaleRewrite = (pathname: string, isI18nPath: string | undefined): string | undefined => {
  if (!prefixedDefaultLocale && !isI18nPath) {
    const hasNoDefaultLocale = locales.every((lang) => !pathname?.startsWith(`/${lang}`) && pathname !== `/${lang}`);
    return hasNoDefaultLocale
      ? `/${defaultLocale}${pathname?.startsWith('/') ? '' : '/'}${pathname}`
      : undefined;
  }

  return undefined;
};
