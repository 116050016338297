// COMBOBOX (Autocomplete) ************************************************************************
// We're using Downshift library hooks and useSingleFormElement hook to build a custom Combobox
// Downshift page: https://www.downshift-js.com/
// Complete documentation for Downshift on GitHub: https://github.com/downshift-js/downshift
// ************************************************************************************************

import { useCombobox } from 'downshift';
import {
  ChangeEvent, forwardRef, Fragment, useEffect, useState, useCallback,
} from 'react';
import { useSingleFormElement, useFetchComboboxData } from '@hooks/useBjForm';
import { FormElementRefImperative, FormComboboxInterface } from '@type/form-types';
import { ArrayFilterItem } from '@utils/arrays/filter-strings';
import { Transition } from '@headlessui/react';
import { getWithFlatKeys } from '@utils/flatten-expand-object/flatten-expand-object';
import { TailwindSpinner } from '@components/common';
import { useTranslation } from 'next-i18next';
import { useDebounce } from '@hooks/useDebounce/useDebounce';
import { FormError } from '../FormError/FormError';
import { stateReducerSelect } from './stateReducers';
import { ChevronUp, ChevronDown, ClearCircle } from './IconsCombobox';


/**
 * @description Combobox component for useBjForm. If you''re using the Label component as a wrapper, don't forget to
 * render the Label as='div'. Downshift hates focusable elements.
 *
 * @description Props for the input field:
 * * **className** - pass here your Tailwind classes if you need further customization
 * * **withError** - if you want to show this field's validation error messages; default true
 * * **patternMismatchMessage** - the custom message for pattern mismatch validation; default ''
 * * **customValidityMessages** - the custom validity messages for any html input validation - @link https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
 * * **buttonsContainerClass** - extra classes for the buttons container
 * * other normal HTML input attributes: required, defaultValue, pattern, minLength, maxLength, min, max, range, etc.
 * ------------------------------------------
 *
 * @description Specific ComboBox props
 * * **staticList** - if you're using a static list, ie not one from API client side request
 * * **forceRefreshStaticList** - if we want to refresh the static list on open, maybe for dynamic data
 * * **apiList** - if you're using a dynamic list, ie one from API client side request
 * * **valueFromKey** - when using array of objects, this is the value that will be used for input value on select from list; use flat path string
 * * **showResetButton** - if we show the reset button; default true
 * * **showDropdownButton** - if we show the toggle dropdown button when list is not empty; default true
 * * **freeSolo** - if we allow to keep the user typed input on blur; default true
 * * **filteringFunction** - for filtering items on the client side; use abstract function 'filterStrings' (look at examples)
 * * **clearOnSelect** - if we clear the input field after selecting an item from the list
 * * **externalGetQuery** - pass an external state setter to get the user typed input value (query string)
 * * **currentFieldValue** - pass an external state setter to get the current value of the input field, no matter if it was user typed, selected from the list or
 * changed programmatically with setFormValues. Don't use this to get the user typed input value for fetch searches, use externalGetQuery instead.
 * * **unmount** - whether the dropdown list should be unmounted or just hidden, based on the open/closed state; default true
 * * **renderList** - a render prop function for custom list rendering; it exposes all the arguments needed for building a proper list for autocomplete
 * * **onSelectedItemChange** - callback for detecting when combobox value is changed (onSelectedItemChange={item, inputRef => console.log(item); inputRef.current.blur()})
 * * **onInputValueChange** - callback for detecting when combobox input value is changed (onInputValueChange={value => console.log(value)})
 * * **onInputSubmit** - callback for detecting when user presses Enter on input field (onInputSubmit={(value, event) => console.log(event)})
 * * **onClear** - callback for detecting when user clears the input field by clicking the reset button (onClearValue={() => console.log('cleared')})
 * * **fetchDynamicListOnOpen** - if we want to fetch data from the API on open. It will fetch data even if the input has no value.
 * * **showNoResultsMessage** - a boolean indicating whether to display a message when there are no results in autocomplete.
 * * **noResultsText** - the text to show when there are no results in autocomplete.
 * * **translateFn** - a function to translate the item value before setting it in the input field (ie 't' for i18n)
 * * **isItemDisabledFn** - a function that can be used to disable items in the list. It receives the item and the index as arguments.
 * * **keepOpen** - a boolean indicating whether the combobox should keep the dropdown open after selecting an item; default false
 * * **behaveAs** - the behavior of the combobox; default 'default'; options: 'default', 'select'
 * * **closeOnBlur** - boolean. Force closing the dropdown on blur event
 * * **selectOptions** - an object containing the following properties for select behavior:
 * - - **multiple** - a boolean indicating whether the combobox should behave as a select-multiple; optional
 * - - **setFormValues** - the function to set the form values; required
 * - - **checkboxName** - the name of the checkbox input; you can pass an array if you have multiple groups; required
 * - - **checkboxValueFromKey** - the key for the checkbox value; required
 * - - **initialSelectedValues** - the initial selected values for the checkbox; optional
 * - - **updatedSelectedValues** - the updated selected values for the checkbox; optional
 *
 * **RenderList function arguments:**
 * - `item` - the item from the list
 * - `index` - the index of the item in the list
 * - `listKey` - the key for the list item
 * - `getItemProps` - the Downshift function to get the props for the list item
 * - `listStyling` - the classes for the list item
 * - `highlightedIndex` - the index of the highlighted item
 * - `isDisabled` - a boolean indicating whether the item is disabled
 *
 * @example
 * // see the example page for ComboBox component
 * @link http://localhost:3000/temp/combobox
 */
export const ComboBox = forwardRef<FormElementRefImperative, FormComboboxInterface>((props, ref) => {
  // Translation
  const { t } = useTranslation();

  // Input field props
  // ***********************************************
  const {
    options,
    className = '',
    buttonsContainerClass,
    required,
    minLength,
    maxLength,
    withError = true,
    placeholder = ' ',
    patternMismatchMessage = '',
    customValidityMessages,
    onBlur,
    onFocus,
    onChange,
    onInput,
    onKeyDown,
    name,
    id,
    type,
    defaultValue,
    disabled,
    readOnly,
    renderList,
    onSelectedItemChange,
    onInputValueChange,
    onInputSubmit,
    onClear,
    closeOnBlur,
  } = props;


  // ComboBox config options (in TSX)
  // ***********************************************
  const {
    filteringFunction,
    staticList,
    forceRefreshStaticList,
    apiList,
    valueFromKey,
    externalGetQuery,
    currentFieldValue,
    clearOnSelect,
    showResetButton = true,
    showDropdownButton = true,
    freeSolo = true,
    unmount = true,
    fetchDynamicListOnOpen,
    showNoResultsMessage = false,
    noResultsText = t('no_results'),
    isItemDisabled,
    keepOpen = false,
    behaveAs = 'default',
    selectOptions,
    translateFn,
  } = options;

  // Spread the selectOptions object
  const {
    multiple,
    setFormValues,
    checkboxName,
    checkboxValueFromKey,
    initialSelectedValues,
    updatedSelectedValues,
  } = selectOptions || {};


  // useSingleFormElement hook from BjFormLibrary
  // ***********************************************
  const {
    handleInteraction,
    setElementValue,
    getElementValue,
    setCheckInvalid,
    elementRef,
    isInvalid,
    errorMSG,
    errorClass,
  } = useSingleFormElement({
    ref,
    patternMismatchMessage,
    customValidityMessages,
    className,
  });

  // Use the minLength value for the minimum required length of string before start fetching
  const minLengthForFetch = minLength && minLength > 0 ? minLength : 0;

  // Init the variables for data fetching
  let fetchUrl = '';
  let queryParams;
  let fetcherConfig;

  // Reassign the data fetching variables
  if (apiList) {
    fetchUrl = apiList.fetchUrl;
    queryParams = apiList.queryParams;
    fetcherConfig = apiList.fetcherConfig;
  }


  // useFetchComboboxData hook from BjFormLibrary
  // ***********************************************
  const { getComboboxData, isLoading } = useFetchComboboxData(fetchUrl, queryParams, fetcherConfig);


  // State for base List
  // ***********************************************
  const [baseList, setBaseList] = useState(staticList || []);

  // State for client side filtered list (if using)
  // ***********************************************
  const [filteredItemsList, setFilteredItemsList] = useState(baseList);


  // Used items list -> pick the filtered (from state) or unfiltered (from options) items list if we
  // have a filtering function inside ComboBox options.
  // *****************************************************
  const usedItemsList = filteringFunction ? filteredItemsList : baseList;


  // Since we're using external fetching we also need to watch for updates of the base list.
  // But also watch the static list in case we're updating it.
  // *****************************************************
  useEffect(() => {
    if (externalGetQuery || staticList) {
      setBaseList(staticList || []);
    }
  }, [staticList, externalGetQuery]);

  // Fetch the data from the API using a helper function and set the base list
  const getComboboxDataHelper = (value: string) => {
    void getComboboxData(value)
      .then((response) => {
        if (Array.isArray(response)) setBaseList(response);
      });
  };

  // Debounce fetch the combobox data from the API
  const getComboboxDataDebounced = useDebounce(getComboboxDataHelper, 350);


  // SELECT BEHAVIOR
  // ****************************************************
  // Find the selectedItems based on the selected checkbox values
  const findSelectedItemsByValue = useCallback((selectedValues: ArrayFilterItem | ArrayFilterItem[] | undefined) => {
    if (!selectedValues) return [] as ArrayFilterItem[];
    const selectedHolder: ArrayFilterItem[] = [];
    const selectedValuesHolder = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    usedItemsList.forEach((item) => {
      if (selectedValuesHolder.includes(getWithFlatKeys(item as object, checkboxValueFromKey as string) as string)) {
        selectedHolder.push(item);
      }
    });
    return selectedHolder;
  }, [usedItemsList, checkboxValueFromKey]);

  // State for keeping track of the selected items when using the select behavior
  const [selectedItems, setSelectedItems] = useState<ArrayFilterItem[]>(initialSelectedValues ? findSelectedItemsByValue(initialSelectedValues) : []);

  // Get the string representation of the selected items for a certain key
  // Use it for setting the input value when selecting multiple items
  const getSelectedItemsString = useCallback((items: ArrayFilterItem[], itemKey: string) => {
    let selectedItemsString = '';
    if (items.length) {
      const holder = items.map((selItem) => {
        if (typeof selItem === 'string' || typeof selItem === 'number') return selItem;
        return getWithFlatKeys(selItem as object, itemKey);
      });

      if (translateFn) {
        selectedItemsString = holder.map((item) => translateFn(String(item))).join(', ');
      } else {
        selectedItemsString = holder.join(', ');
      }
    }
    return selectedItemsString;
  }, [translateFn]);


  // Set the input value for select behavior
  // ****************************************************
  useEffect(() => {
    if (behaveAs === 'select' && valueFromKey) {
      setElementValue(getSelectedItemsString(selectedItems, String(valueFromKey)));
    }
  }, [selectedItems, valueFromKey, getSelectedItemsString, setElementValue, behaveAs]);

  // Update the selectedItems state when the selected values are updated from the outside (select-multiple behavior)
  // ****************************************************
  useEffect(() => {
    if (updatedSelectedValues) {
      setSelectedItems(findSelectedItemsByValue(updatedSelectedValues));
    }
  }, [findSelectedItemsByValue, updatedSelectedValues]);


  // useCombobox hook from Downshift
  // ***********************************************
  const {
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    setInputValue,
    selectItem,
    isOpen,
    highlightedIndex,
    selectedItem,
    inputValue,
    closeMenu,
  } = useCombobox({
    // We need id & name to prevent mismatch on SSR.
    id,
    // Prevent closing the menu on selection
    ...(keepOpen && { stateReducer: stateReducerSelect }),
    // Choose what list to use for filtered / unfiltered list
    items: usedItemsList,
    // Compare function for disabling items in the list
    ...(isItemDisabled && { isItemDisabled }),
    // If select behavior, we need to set the selectedItem to null (allows for click toggle)
    ...(behaveAs === 'select' && { selectedItem: null }),
    itemToString(item) {
      // Here we set the string representation that will be rendered in the input
      let itemVal = '';

      // Check if we're dealing with a simple string or number array.
      if (typeof item === 'string') itemVal = item;
      if (typeof item === 'number') itemVal = String(item);

      // Check if we're dealing with an object; also check for object key since is optional.
      if (typeof item === 'object' && item !== null && valueFromKey) {
        const tempVal = getWithFlatKeys(item, valueFromKey as string);
        itemVal = typeof tempVal === 'string' ? tempVal : '';
      }

      // Check if we're dealing with a bi-dimensional array; also check for array index since is optional.
      // We return only strings or numbers for the input value.
      if (Array.isArray(item) && valueFromKey && typeof valueFromKey === 'number') {
        if (typeof item.at(valueFromKey) === 'string' || typeof item.at(valueFromKey) === 'number') {
          itemVal = String(item.at(valueFromKey));
        }
      }

      // We're handling the select behaviour input text in a different way inside the onSelectedItemChange method.
      if (behaveAs === 'select') itemVal = '';

      return translateFn ? translateFn(itemVal) : itemVal;
    },
    onInputValueChange(changes) {
      // Remove selected item highlight if user adds his input on ComboBox
      // We also do the fetch here if option available
      if (changes.type === useCombobox.stateChangeTypes.InputChange) {
        const userInput = changes.inputValue;
        selectItem(null);

        // FETCH THE DATA (if option available)
        // query parameters for fetching the data are passed as a function in props in order to have
        // access to the userInput variable
        if (apiList && userInput && userInput.length >= minLengthForFetch) {
          if (apiList?.debouncedFetch) {
            getComboboxDataDebounced(userInput);
          } else {
            getComboboxDataHelper(userInput);
          }
        }

        // Send the query to external setter. This is the way you can get the input value outside
        // the component if you need it. We're only interested on user typed values.
        if (externalGetQuery && userInput) {
          void externalGetQuery(userInput);
        }

        // Execute external callback when combobox input value is changed
        if (onInputValueChange && userInput) {
          onInputValueChange(userInput);
        }
      }
      // If we have filtering on the client side
      if (filteringFunction) {
        setFilteredItemsList(baseList.filter(filteringFunction(changes.inputValue)));
      }
    },
    onSelectedItemChange(changes) {
      // Since we changed the input to be uncontrolled, we can't rely on downshift for changing
      // the input value, so we have to set it ourselves
      // callback for detecting when combobox value is changed
      if (onSelectedItemChange && !(behaveAs === 'select')) {
        onSelectedItemChange(changes.selectedItem as ArrayFilterItem, elementRef);
      }

      // Clear the input value on select if option available
      if (clearOnSelect) {
        setElementValue('');
        selectItem(null);
      } else {
        setElementValue(changes.inputValue || '');
      }

      // Handle select behavior
      if (behaveAs === 'select' && changes?.selectedItem) {
        const index = selectedItems.indexOf(changes.selectedItem);
        let selection = [];

        if (multiple) {
          if (index > 0) {
            selection = [
              ...selectedItems.slice(0, index),
              ...selectedItems.slice(index + 1),
            ];
          } else if (index === 0) {
            selection = selectedItems.slice(1);
          } else {
            selection = [...selectedItems, changes.selectedItem];
          }
        } else {
          selection = [changes.selectedItem];
        }

        setSelectedItems(selection);
        if (onSelectedItemChange) onSelectedItemChange(selection, elementRef);

        // Set the form values for select behavior
        if (setFormValues && checkboxName && checkboxValueFromKey) {
          const formValues = getSelectedItemsString(selection, checkboxValueFromKey).split(', ');
          // make sure we get the right type of value
          const typedValues = formValues.map((item, i) => {
            const typeOfItem = typeof getWithFlatKeys(selection[i], checkboxValueFromKey);
            if (typeOfItem === 'number') return Number(item);
            if (typeOfItem === 'boolean') return Boolean(item);
            return item;
          });

          if (Array.isArray(checkboxName)) {
            // handle multiple checkbox names at the same time (ie: 'careerLevels' and 'employmentTypes')
            const formValuesObj = checkboxName
              .reduce((acc: Record<string, typeof typedValues>, str) => {
                acc[str] = typedValues;
                return acc;
              }, {} as Record<string, typeof typedValues>);
            setFormValues(formValuesObj);
          } else {
            setFormValues({ [checkboxName]: typedValues });
          }
        }
      }
    },
    onStateChange(changes) {
      // Set the internal Downshift state in case of external input changes (like when using
      // 'setElementValue' from the useSingleFormElement / BjFormLibrary) or user typing inside
      // the input field
      if (Object.hasOwn(changes, 'inputValue') && changes.inputValue !== undefined) {
        setInputValue(changes.inputValue);
      }

      // Pass the current field value to the external state setter if option available
      // It will pass the current value of the input field, no matter if it was user typed, selected or changed programmatically
      if (currentFieldValue && changes.inputValue !== undefined) {
        void currentFieldValue(changes.inputValue);
      }
    },
    onIsOpenChange(changes) {
      // If we're using a static list, and we want to refresh the list on open
      if (forceRefreshStaticList && changes.isOpen) {
        setFilteredItemsList(staticList || []);
      // If we want to fetch data from API on open
      } else if (fetchDynamicListOnOpen && changes.isOpen && apiList) {
        const value = changes.inputValue || '';
        getComboboxDataHelper(value);
      }
    },
  });


  // Component styling
  // *****************************************************
  let dropdownContainerStyle = 'absolute mt-1 max-h-72 w-full overflow-auto rounded-md bg-surface-dropdown py-1 shadow-md z-4 scrollbar-thin pointer-events-auto';
  dropdownContainerStyle += isOpen && ((showNoResultsMessage && !isLoading && inputValue?.length >= minLengthForFetch) || (!showNoResultsMessage && usedItemsList.length)) ? '' : ' hidden';

  // Set the right padding for input to make room for buttons (if any)
  const inputPaddingDropdown = `${showDropdownButton && usedItemsList.length > 0 ? 'pr-10' : ''}`;
  const inputPaddingReset = `${inputValue && showResetButton ? 'pr-10' : ''}`;
  const inputPaddingAllButtons = `${inputPaddingDropdown && inputPaddingReset ? 'pr-20' : ''}`;
  const inputPaddingRight = inputPaddingAllButtons || inputPaddingDropdown || inputPaddingReset || '';


  // Render component
  // *****************************************************
  return (
    <div className="relative grow">

      {/* Input field & action buttons (dropdown select & reset) */}
      <div className="relative">
        <input
          data-test-id={id}
          onInput={(e) => {
            // If the event is not trusted, it means it was triggered by the setFormValues function from BjFormLibrary,
            // and we add the value to the external state setter as well. If the value is empty, we also clear the
            // selected item from the list.
            if (currentFieldValue && !e.isTrusted) {
              const externalValue = e.currentTarget.value;
              currentFieldValue(externalValue);
              if (externalValue === '') selectItem(null);
            }
            if (onInput) onInput(e);
          }}
          {...getInputProps({
            type: type || 'text',
            ref: elementRef,
            placeholder,
            name,
            id,
            required,
            // Here we're making the input uncontrolled, and we can no longer rely on setInputValue
            // from Downshift to change the value, and we replaced it with setElementValue function.
            value: undefined,
            defaultValue,
            minLength,
            // We're setting the maxLength to 0 for select and select-multiple behaviors to prevent
            // to add/remove characters from the input field.
            maxLength: behaveAs === 'select' ? 0 : maxLength,
            disabled,
            readOnly,
            ...(// Conditional classes
              (className || isInvalid || inputPaddingRight)
              && {
                className: `${className ? `${className} ` : ''}${inputPaddingRight}${isInvalid ? ` ${errorClass}` : ''}`,
              }
            ),
            // Last IF in both actions (ex: if (onChange) onChange(e);) is to allow adding extra
            // functions on component for onChange & onBlur
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              if (required) handleInteraction(e);
              if (onChange) onChange(e);
            },
            onKeyDown: (e) => {
              if (onKeyDown) onKeyDown(e);
              if (e.key === 'Enter') {
                if (onInputSubmit) {
                  onInputSubmit(e.currentTarget.value, e);
                }
              }
              // Prevent deleting the input value on keypress if the behavior is 'select' or 'select-multiple'
              if (behaveAs === 'select' && ['Backspace', 'Delete'].includes(e.key)) {
                e.preventDefault();
              }
            },
            onFocus: (e) => {
              if (onFocus) onFocus(e);
            },
            onBlur: (e) => {
              if (closeOnBlur) closeMenu();
              if (required) handleInteraction(e);
              // When in Box mode (freeSolo: false) if the user didn't select an option clear
              // the Combobox input field.
              if (!freeSolo && !selectedItem) {
                setInputValue('');
                setElementValue('');
                setCheckInvalid();
              }
              if (onBlur) onBlur(e);
            },
          })}
        />

        { // Dropdown toggle & reset buttons & loading indicator; there are props on main component for showing them
          (showResetButton || showDropdownButton || isLoading) && (
            <div className={`absolute flex divide-x ${buttonsContainerClass || ' right-0.5 top-2'}`}>
              { // dropdown toggle button; we hide it if there are no items in autocomplete dropdown; tabindex for Safari
                showDropdownButton && usedItemsList.length > 0 && (
                  <button aria-label="toggle menu" className="flex size-9 items-center justify-center" type="button" tabIndex={-1} {...getToggleButtonProps()}>
                    { isOpen ? <ChevronUp /> : <ChevronDown /> }
                  </button>
                )
              }
              { // reset input value button; we hide it if the input value is empty or while fetching data; tabindex for Safari
                showResetButton && (inputValue || getElementValue() !== '') && !isLoading && (
                  <button
                    aria-label="clear menu"
                    type="button"
                    className="flex size-9 items-center justify-center text-ink-medium"
                    tabIndex={-1}
                    onClick={() => {
                      selectItem(null);
                      setElementValue('');
                      setSelectedItems([]);
                      if (behaveAs === 'select' && onSelectedItemChange) onSelectedItemChange([]);
                      if (onClear) onClear();
                    }}
                  >
                    <ClearCircle />
                  </button>
                )
              }
              { // show spinner when fetching combobox data
                isLoading && (
                  <div className="flex size-9 items-center justify-center">
                    <TailwindSpinner className="size-4 text-ink-medium" />
                  </div>
                )
              }
            </div>
          )
        }
      </div>

      {/* Dropdown for autocomplete items ************************ */}
      <Transition
        as={Fragment}
        unmount={false}
        show={isOpen}
        enter="transition-all duration-300"
        enterFrom="opacity-0 -translate-y-1.5"
        enterTo="opacity-100 translate-y-0"
      >
        <ul className={dropdownContainerStyle} {...getMenuProps()}>
          {(unmount ? isOpen : true) && usedItemsList.length > 0 && usedItemsList.map((item, index) => {
            // list common props
            const baseListStyling = {
              base: 'py-2.5 px-3 flex transition-all duration-150',
              highlight: highlightedIndex === index ? ' bg-primary text-white' : '',
              selected: selectedItem === item || selectedItems.includes(item) ? ' font-bold' : '',
              border: index !== 0 ? ' border-t' : '',
            };
            const listStyling = Object.values(baseListStyling).join(' ');
            const listKey = String(item['title' as keyof typeof item]) + String(index);
            const isDisabled = isItemDisabled && isItemDisabled(item, index);

            // We're using a Render Prop function for cases when we want to have more control over
            // the rendered list. The function exposes all the arguments needed for building a proper
            // list for autocomplete. While you could ignore the index, listStyling and
            // highlightedIndex arguments, you will always need item, listKey and getItemProps on the
            // rendered list items.
            if (renderList) {
              return renderList(item, index, listKey, getItemProps, listStyling, highlightedIndex, isDisabled);
            }

            // This is the default rendering if we don't pass the render prop 'renderList' to the
            // component when using it (ie: <ComboBox />).

            // render valueFromKey if item is an object
            let itemString = null;
            if (typeof item === 'object' && item !== null && valueFromKey) {
              const tempVal = getWithFlatKeys(item, valueFromKey as string);
              itemString = typeof tempVal === 'string' ? tempVal : null;
            }

            // render direct value if item is string or number
            if (typeof item === 'string' || typeof item === 'number') {
              itemString = item;
            }

            return (
              <li className={`${listStyling}${isDisabled ? ' cursor-not-allowed opacity-50' : ''}`} key={listKey} {...getItemProps({ item, index })}>
                {itemString ?? 'Default rendering only for string and number arrays!'}
              </li>
            );
          })}

          {showNoResultsMessage && isOpen && usedItemsList.length === 0 && (
            <li className="px-3 py-2.5">
              {noResultsText}
            </li>
          )}
        </ul>
      </Transition>

      { // Validation error field - show only if input is required and withError is true (default true)
        required && withError && <FormError errorMessage={errorMSG} isShowing={isInvalid} />
      }

    </div>
  );
});
