import { Header } from '@components/layout/Header/Header';
import { Footer } from '@components/layout/Footer/Footer';
import { ReactNode } from 'react';
import { NavbarTheme } from 'src/types/header-types';
import { useRevalidateOnRefocus } from '@hooks/useSwrApi';
import { HeaderSearchContext } from '@components/contexts/HeaderSearchContext';
import { FiltersQueryParamsStates, JobSearchQueryParams, TalentSearchQueryParams } from '@type/filters';


// Interface
// ******************************************
interface LayoutDefaultProps {
  children?: ReactNode,
  navbarTheme?: NavbarTheme,
  searchContext?: FiltersQueryParamsStates<TalentSearchQueryParams | JobSearchQueryParams>,
}

/**
 * @description Default layout. It renders the header and the footer.
 * - children - The children to render inside the layout.
 * - navbarTheme - The theme of the navbar, 'default' | 'transparent'.
 * - searchContext - The search context to pass to the search input component in the MainNavbar.
 *
 * @example
 * // if you want a transparent header, pass the navbarTheme prop
 * MyPage.getLayout = function getLayout(page: ReactNode) {
 *   return (
 *     <LayoutDefault navbarTheme="transparent">
 *       {page}
 *     </LayoutDefault>
 *   );
 * };
 */
export const LayoutDefault = function LayoutFunction(props: LayoutDefaultProps) {
  // Destructure props
  const {
    children,
    navbarTheme,
    searchContext,
  } = props;

  // Check if we should revalidate SWR cache on tab refocus
  useRevalidateOnRefocus();


  return (
    <HeaderSearchContext.Provider value={searchContext}>
      {/* Place all the sticky top banners in Header component */}
      <Header navbarTheme={navbarTheme} />
      {children}
      <Footer />
    </HeaderSearchContext.Provider>
  );
};
