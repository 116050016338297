// Interface
// ***********************************
interface ChatTextareaProps {
  id?: string;
  name?: string;
  input: string;
  required?: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  rows?: number;
  textareaSizeStyle?: string;
  textareaWrapperSizeStyle?: string;
  textareaAlignmentStyle?: string;
  textareaWrapperAlignmentStyle?: string;
  customWrapperStyle?: string;
  customTextareaStyle?: string;
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
}

/**
 * @description  Chat textarea component that is auto resizable and supports custom styles
 * It uses a CSS trick to wrap the textarea in a div and clone the value to make it auto resizable
 * The height, width, margin, and padding styles are the same for the textarea and the wrapping div to make it work
 *
 * - **id**: The id of the textarea
 * - **name**: The name of the textarea
 * - **input**: The value of the textarea
 * - **required**: A boolean that determines if the textarea is required
 * - **handleInputChange**: The function to handle input change in the parent component
 * - **handleKeyDown**: The function to handle keydown events in the parent component
 * - **placeholder**: The placeholder text for the textarea
 * - **rows**: The number of rows for the textarea - default is 1
 * - **textareaSizeStyle**: Custom styles for the textarea size
 * - **textareaWrapperSizeStyle**: Custom styles for the wrapping div size
 * - **textareaAlignmentStyle**: Custom styles for the textarea alignment
 * - **textareaWrapperAlignmentStyle**: Custom styles for the wrapping div alignment
 * - **customWrapperStyle**: Custom styles for the wrapping div
 * - **customTextareaStyle**: Custom styles for the textarea
 * - **textareaRef**: A ref object for the textarea for extra functionality
 */
export const ChatTextarea = (props: ChatTextareaProps) => {
  const {
    id = 'chat-textarea',
    name = 'chat-textarea',
    input,
    required = true,
    handleInputChange,
    handleKeyDown,
    placeholder,
    rows = 1,
    textareaSizeStyle = 'w-full max-h-24',
    textareaWrapperSizeStyle = 'w-full max-h-24',
    textareaAlignmentStyle = 'mx-0 p-0',
    textareaWrapperAlignmentStyle = 'm-2 after:p-0',
    customWrapperStyle = 'transition-none leading-6',
    customTextareaStyle = 'appearance-none border-0 leading-6 shadow-none outline-none transition-none focus:ring-0 active:border-0 active:ring-0',
    textareaRef,
  } = props;

  // Handle input change to update the cloned value to make the textarea auto resizable
  const handleInput = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { parentNode } = e.currentTarget;
    if (parentNode) {
      (parentNode as HTMLElement).dataset.clonedVal = e.currentTarget.value;
    }
    handleInputChange(e as React.ChangeEvent<HTMLTextAreaElement>);
  };

  // Render component
  // ****************************************************
  return (
    <div
      className={`
        grid
        after:invisible
        after:whitespace-pre-wrap
        after:border-none
        after:text-inherit
        after:content-[attr(data-cloned-val)_'_']
        after:[grid-area:1/1/2/2]
        [&>textarea]:resize-none
        [&>textarea]:overflow-auto
        [&>textarea]:text-inherit
        [&>textarea]:[grid-area:1/1/2/2]
        ${textareaWrapperSizeStyle}
        ${textareaWrapperAlignmentStyle}
        ${customWrapperStyle}
      `}
      data-cloned-val={input}
    >
      <textarea
        ref={textareaRef}
        className={`${textareaSizeStyle} ${textareaAlignmentStyle} ${customTextareaStyle}`}
        id={id}
        name={name}
        rows={rows}
        onInput={handleInput}
        placeholder={placeholder}
        value={input}
        onKeyDown={handleKeyDown}
        required={required}
      />
    </div>
  );
};
