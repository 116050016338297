import { useSwrGlobal } from '@hooks/useSwrApi';
import { useAppDispatch, useAppSelector } from '@store/index';
import { clearAllSubscriptionData } from '@store/reducers/employer/subscription/subscriptionReducer';
import { getOrderStatus } from '@store/reducers/employer/subscription/subscriptionSelector';


/**
 * @description Common logic to handle the subscription modal close. In order to have a better UX,
 * and reduce the number of clicks the user needs to do in order to buy a subscription or credits packages,
 * we need different actions depending on the order status: success, error or not enough credits. The
 * goal is to show the STEP 2: 'Choose payment method' whenever possible.
 *
 * * **apiUrl** string - The API URL for which we need to clear the cache in SWR.
 */
export const useHandleSubscriptionModalClose = () => {
  // 1. SWR Global
  // *****************************************
  const { clearMultipleCacheKeys } = useSwrGlobal();


  // 2. Redux
  // *****************************************
  const appDispatch = useAppDispatch();

  // Get data from Redux
  const orderStatus = useAppSelector(getOrderStatus);

  // Clear data from Redux
  const clearAllSubscriptionDataAction = () => appDispatch(clearAllSubscriptionData());


  // 3. Handle the modal close
  // *****************************************
  const handleSubscriptionModalClose = () => {
    // Clear the Redux cache for subscriptions on modal close.
    clearAllSubscriptionDataAction();

    // HANDLE SUCCESS
    // *****************************************************************************************
    // If the user successfully bought or upgraded a subscription or extra credits package we need
    // to clear the cache for the relevant API endpoints. If the relevant hooks are mounted, they
    // will revalidate the data and show the updated data. Otherwise, the data will be updated when
    // the hooks are mounted.
    // *****************************************************************************************
    if (orderStatus && orderStatus === 'success') {
      const swrCacheKeys = [
        '/v1/subscription/upgrade-packages',
        '/v1/subscription/packages',
        '/v1/subscription/stats',
      ];
      clearMultipleCacheKeys(swrCacheKeys, true);
    }
  };

  return {
    handleSubscriptionModalClose,
  };
};
