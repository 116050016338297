// USER CALL ******************************************************************************************
// pathname: /v1/user/call
// ****************************************************************************************************
import {
  ApiUserCallOk,
  ApiUserCallPayload,
  GenericV1ErrorsArray,
  useSwrMutationApi,
} from '@hooks/useSwrApi';
import { SwrToastsProps } from '@type/swr';

// Interface
// ********************************************
interface SwrUserCallProps {
  locale: string;
  successCallback?: (response: ApiUserCallOk) => void;
  errorCallback?: (errors: GenericV1ErrorsArray) => void;
  toastsOptions?: SwrToastsProps,
  trackingId?: string;
}

/**
 * @description SWR mutation: Initiates a call to a specified user by sending a push notification to VoIP-enabled apps and initializing a Vonage session with a token.
 *
 * * **locale**: string. Locale to use for the request.
 * * **successCallback**: function, optional.
 * * **errorCallback**: function, optional.
 * * **toastsOptions**: SWR toasts options, optional.
 * * **trackingId**: string, optional. The tracking id for the event.
 */
export const useSwrUserCall = (props: SwrUserCallProps) => {
  // Props destructuring
  // ********************************************
  const {
    locale,
    successCallback,
    errorCallback,
    toastsOptions,
    trackingId,
  } = props;

  // SWR mutation
  // ********************************************
  const {
    data, error, isMutating, reset, trigger,
  } = useSwrMutationApi<ApiUserCallOk, ApiUserCallPayload>({
    url: '/v1/user/call',
    urlParams: trackingId ? `?rid=${trackingId}` : '',
    locale,
    fetcherConfig: {
      method: 'POST',
      stringifyPayload: true,
    },
    apiOptions: {
      onSuccess: (response) => {
        // Callback
        if (successCallback) successCallback(response);
      },
      onError: (errors) => {
        // Callback
        if (errorCallback) errorCallback(errors);
      },
    },
    toastsOptions: {
      showErrorToast: true,
      ...toastsOptions,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    isMutating,
    reset,
    trigger,
  };
};
