/**
 * @description It displays in the left of the input
 */

const defaultClasses = 'w-4 h-4';

export const IconCalendar = ({ className = defaultClasses }: { className?: string }) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg">
    <path d="M13 2H11.5V0.375C11.5 0.1875 11.3125 0 11.125 0H10.875C10.6562 0 10.5 0.1875 10.5 0.375V2H4.5V0.375C4.5 0.1875 4.3125 0 4.125 0H3.875C3.65625 0 3.5 0.1875 3.5 0.375V2H2C1.15625 2 0.5 2.6875 0.5 3.5V14.5C0.5 15.3438 1.15625 16 2 16H13C13.8125 16 14.5 15.3438 14.5 14.5V3.5C14.5 2.6875 13.8125 2 13 2ZM2 3H13C13.25 3 13.5 3.25 13.5 3.5V5H1.5V3.5C1.5 3.25 1.71875 3 2 3ZM13 15H2C1.71875 15 1.5 14.7812 1.5 14.5V6H13.5V14.5C13.5 14.7812 13.25 15 13 15ZM5.125 10C5.3125 10 5.5 9.84375 5.5 9.625V8.375C5.5 8.1875 5.3125 8 5.125 8H3.875C3.65625 8 3.5 8.1875 3.5 8.375V9.625C3.5 9.84375 3.65625 10 3.875 10H5.125ZM8.125 10C8.3125 10 8.5 9.84375 8.5 9.625V8.375C8.5 8.1875 8.3125 8 8.125 8H6.875C6.65625 8 6.5 8.1875 6.5 8.375V9.625C6.5 9.84375 6.65625 10 6.875 10H8.125ZM11.125 10C11.3125 10 11.5 9.84375 11.5 9.625V8.375C11.5 8.1875 11.3125 8 11.125 8H9.875C9.65625 8 9.5 8.1875 9.5 8.375V9.625C9.5 9.84375 9.65625 10 9.875 10H11.125ZM8.125 13C8.3125 13 8.5 12.8438 8.5 12.625V11.375C8.5 11.1875 8.3125 11 8.125 11H6.875C6.65625 11 6.5 11.1875 6.5 11.375V12.625C6.5 12.8438 6.65625 13 6.875 13H8.125ZM5.125 13C5.3125 13 5.5 12.8438 5.5 12.625V11.375C5.5 11.1875 5.3125 11 5.125 11H3.875C3.65625 11 3.5 11.1875 3.5 11.375V12.625C3.5 12.8438 3.65625 13 3.875 13H5.125ZM11.125 13C11.3125 13 11.5 12.8438 11.5 12.625V11.375C11.5 11.1875 11.3125 11 11.125 11H9.875C9.65625 11 9.5 11.1875 9.5 11.375V12.625C9.5 12.8438 9.65625 13 9.875 13H11.125Z" />
  </svg>
);

/**
 * @description It displays in the right of the input
 */
export const IconSort = ({ className = defaultClasses }: { className?: string }) => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="currentColor" className={className} xmlns="http://www.w3.org/2000/svg">
    <path d="M1.74609 7.125C1.14453 7.125 0.84375 7.83594 1.28125 8.24609L4.53516 11.5C4.78125 11.7734 5.19141 11.7734 5.46484 11.5L8.71875 8.24609C9.12891 7.83594 8.82812 7.125 8.25391 7.125H1.74609ZM8.71875 4.25391L5.46484 1C5.19141 0.753906 4.78125 0.753906 4.53516 1L1.28125 4.25391C0.84375 4.69141 1.14453 5.375 1.74609 5.375H8.25391C8.82812 5.375 9.12891 4.69141 8.71875 4.25391Z" fill="#1E2022" />
  </svg>
);
