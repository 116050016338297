'use client';

import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Portal } from 'src/components/common/Portal/Portal';

// react-hot-toast headless hooks
// https://react-hot-toast.com/docs/use-toaster
import toast, { useToaster, useToasterStore, Renderable } from 'react-hot-toast/headless';

// hero icons
import {
  CheckCircleIcon, ExclamationCircleIcon, XMarkIcon,
} from '@heroicons/react/24/outline';

/**
 * @description Custom built toast using the react-hot-toast hooks (useToaster())
 * Read the react-hot-toast {@link https://react-hot-toast.com/docs documentation}.
 *
 * @description You can use this instead of ToastsContainer, but it will not use React portal.
 */
const Toast = () => {
  // react-hot toast hook
  const { toasts, handlers } = useToaster({
    duration: 7000,
    // success styling
    success: {
      icon: <CheckCircleIcon className="size-5 text-secondary" />,
    },
    // error styling
    error: {
      icon: <ExclamationCircleIcon className="size-5 text-error" />,
    },
  });

  // Translate
  const { t } = useTranslation('common');

  // react-hot-toast handlers
  const { startPause, endPause } = handlers;

  // base classes for toasts
  const toastBaseClasses = 'transform pointer-events-auto w-full max-w-sm bg-surface-dropdown p-4 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 flex gap-x-3 items-start';

  // success classes for toasts
  const successClasses = (type: string) => (type === 'success' ? ' border-l-3 border-secondary' : '');

  // error classes for toasts
  const errorClasses = (type: string) => (type === 'error' ? ' border-l-3 border-error' : '');


  // Toasts container & card **********************
  return (
    <div onMouseEnter={startPause} onMouseLeave={endPause} className="pointer-events-none fixed inset-x-4 bottom-4 z-50 flex flex-col-reverse gap-y-4">
      {toasts.map((toastCard) => (
        <Transition
          appear
          show={toastCard.visible}
          className={`${toastBaseClasses}${successClasses(toastCard.type)}${errorClasses(toastCard.type)}${toastCard.className ? ` ${toastCard.className}` : ''}`}
          enter="transition-all ease-in-out duration-300"
          enterFrom="opacity-0 -translate-x-8"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all ease-in-out duration-300"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 -translate-x-8"
          key={toastCard.id}
          id={toastCard.id}
          {...toastCard.ariaProps}
        >
          { // icon, if we have one
            toastCard.icon && <div className="shrink-0">{toastCard.icon}</div>
          }
          <div className="grow leading-snug">
            {toastCard.message as Renderable}
          </div>
          <button
            type="button"
            className="-mx-3 -mb-3 -mt-2 shrink-0 cursor-pointer p-3 text-sm leading-none text-ink-light transition-colors duration-200 hover:text-ink"
            onClick={() => toast.dismiss(toastCard.id)}
            aria-label={t('global.btn.close')}
          >
            <XMarkIcon className="-mt-px size-4" />
          </button>
        </Transition>
      ))}
    </div>
  );
};


/**
 * @description Toasts container for Toast component (notifications).
 * Read the react-hot-toast {@link https://react-hot-toast.com/docs documentation}.
 *
 * @description Use the **bjToast()** alias instead of **toast()**, so you can import it from
 * '@components/common'. Otherwise, you need to import the toast() from 'react-hot-toast/headless'.
 *
 * @example
 * // import ToastsContainer in _app or, if not, in page
 * import { ToastsContainer } from '@components/common';
 *
 * // import the bjToast function in places you want to use it
 * import { bjToast } from '@components/common';
 *
 * // simple toast
 * bjToast('Here is your simple toast.');
 *
 * // success toast
 * bjToast.success('This is a very nice success toast.');
 *
 * // error toast
 * bjToast.error('Boohoo, we have an error toast.');
 *
 * // custom icon & border toast
 * bjToast('Wow, a custom icon and color toast', {
 *    icon: <RocketLaunchIcon className="text-primary-light w-5 h-5" />,
 *    className: 'border-l-3 border-primary-light',
 * });
 *
 * // JSX content toast
 * bjToast(jsxContent(), {
 *    icon: <ImageBj className="rounded-full w-14 h-14" src={avatar_winnie} alt="Gigi Ping" />,
 * });
 */
export const ToastsContainer = () => {
  // state for active toasts
  const toasts = useToasterStore();
  // state to see if we have visible toasts
  const [toastsVisible, setVisible] = useState(false);

  // show hide toasts container
  useEffect(() => {
    setVisible(!!toasts.toasts.length);
  }, [toasts, toastsVisible]);

  return (
    <>
      {
        toastsVisible
        && <Portal wrapperId="toasts-root"><Toast /></Portal>
      }
    </>
  );
};
