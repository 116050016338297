// Modal dialog (base) classes
const modalBase = 'w-full transform overflow-hidden bg-surface transition-all';

// Modal dialog (base) sizes
const modalSizes = {
  xs: 'max-w-xs rounded',
  sm: 'max-w-xl rounded',
  md: 'max-w-screen-md rounded',
  lg: 'max-w-screen-lg rounded',
  xl: 'max-w-screen-xl rounded',
  '2xl': 'max-w-screen-2xl',
  full: 'min-h-screen',
};

// Modal backdrop styles
const backdrop = 'fixed inset-0 bg-black bg-opacity-50 backdrop-blur-xs';

// Modal header styles
const header = 'flex justify-between items-center border-b py-4 px-6 font-bold';

// Modal body styles
const body = 'p-6';

// Modal footer classes
const footer = 'border-t py-4 px-6';

export const ModalStyles = {
  modalSizes,
  modalBase,
  backdrop,
  header,
  body,
  footer,
};
