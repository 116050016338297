import {
  ApiResumeSavePreview,
  ApiResumeSavePreviewOk,
  ApiResumeUploadPreviewChanges,
  ApiResumeUploadPreviewChangesOk,
  ApiUploadResumeNew,
  ApiUploadResumeNewOk,
  ApiUploadResumePreview,
  ApiUploadResumePreviewOk,
} from '@type/v1-api-types';


// RESUME PREVIEW CHANGES  *****************************************************************************
// pathname: /v1/resume/upload/preview-changes
// ************************************************************************************************
export const isUserResumePreviewChangesOk = (res: ApiResumeUploadPreviewChanges | void)
: res is ApiResumeUploadPreviewChangesOk => res !== undefined && res !== null && (res as ApiResumeUploadPreviewChangesOk).personalInfoChanges !== undefined;

// RESUME UPLOAD PREVIEW  *****************************************************************************
// pathname: /v1/resume/upload/save-preview
// ************************************************************************************************
export const isUserResumeSavePreviewOk = (res: ApiResumeSavePreview | void)
: res is ApiResumeSavePreviewOk => res !== undefined && res !== null && Object.keys((res as ApiResumeSavePreviewOk)).length === 0;

// UPLOAD RESUME PREVIEW ***************************************************************************
// pathname: /v1/resume/upload/upload-preview
// ************************************************************************************************
export const isUploadResumePreviewOk = (res: ApiUploadResumePreview | void)
: res is ApiUploadResumePreviewOk => res !== undefined && res !== null && (res as ApiUploadResumePreviewOk).slug !== undefined;


// UPLOAD FIRST RESUME *****************************************************************************
// pathname: /v1/resume/upload/upload-new
// ************************************************************************************************
export const isUploadResumeNewOk = (res: ApiUploadResumeNew | void)
: res is ApiUploadResumeNewOk => res !== undefined && res !== null && Object.keys((res as ApiUploadResumeNewOk)).length === 0;
