// USER AVAILABLE RESUME OPERATIONS ***************************************************************************
// pathname: /v1/available-resume-operations/{userSlug}
// ************************************************************************************************


import { useSwrApi } from '@hooks/useSwrApi';
import { buildSearchParams } from '@utils/data-fetching';
import { CvDetailAvailableOperationsParent } from '@components/templates/cv/interface/CvDetailInterface';

interface IProps {
  locale: string,
  slug: string,
  shouldFetch?: boolean,
  onSuccess?: (data: CvDetailAvailableOperationsParent) => void,
  queryParams?: {
    jobSlug?: string,
    rid?: string | string[],
  }
}

/**
 * @description SWR: fetch user available resume operations.
 * * **slug**: string - the user slug to get the available operations for.
 * * **locale**: string - the locale to use for the request.
 * * **shouldFetch**: boolean - if true, the request will be sent. Default false.
 * * **onSuccess**: function - the callback to execute on success. Default undefined.
 * * **queryParams**: object - the query params to send with the request. Default undefined.
 */
export const useSwrAvailableResumeOperations = (props: IProps) => {
  // Props destructuring
  const {
    slug, locale, queryParams, shouldFetch = false, onSuccess,
  } = props;

  const withQueryParams = buildSearchParams({ ...queryParams });

  // SWR: fetch data
  // ********************************************
  const {
    data, error, isLoading, isValidating, mutate,
  } = useSwrApi<CvDetailAvailableOperationsParent>({
    url: `/v1/available-resume-operations/${slug}${withQueryParams}`,
    locale,
    shouldFetch,
    apiOptions: {
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 60 * 5, // 5 minutes
      onSuccess: (response) => {
        // Callback
        if (onSuccess) onSuccess(response);
      },
    },
    toastsOptions: {
      showErrorToast: true,
    },
  });

  // Return data
  // ********************************************
  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
  };
};
