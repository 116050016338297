import { AnchorProps, AnchorRef } from 'src/types/anchors-buttons';
import { forwardRef } from 'react';
import { route } from '@services/symfony';
import { composeButtonClasses } from './anchorButtonStyles';


/**
 * @description Styled anchor (<a>) component. **To be used for external links.** In addition to
 * the normal properties of the HTML Anchor, for styling you need to pass the following props:
 *
 * * **styling** - the styling of the button: text, outline, solid, none; default: solid
 * * **color** - the color of the button; default: primary
 * * **size** - the size of the button: xs, sm, md, lg; default: md
 * * **rounding** - the rounding of the button: none, normal, full; default: normal
 * * **symfonyRoute** - if the path is a route to symfony (true), it will be processed as such with the route() function; default: false
 * * **symfonyParams** - the params for the symfony route; default: {}
 * * **hash** - the hash for the link; default: ''
 *
 * -----------------
 * @description If styling is **none**, no classes will be pre-applied, the component will have no
 * styling applied to it.
 *
 * -----------------
 * @param props
 *
 * @example
 * <Anchor href="https://www.google.com/" styling="outline" color="secondary" size="sm" rounding="full">
 *   Link text
 * </Anchor>
 *
 * // example for symfony route
 * <Anchor href="app_order_list" symfonyRoute symfonyParams={{ param1: 'value1', param2: 'value2' }}>
 *   Link text
 * </Anchor>
 */
export const Anchor = forwardRef<AnchorRef, AnchorProps>((props, ref) => {
  const {
    href = '',
    color = 'primary',
    styling = 'solid',
    size = 'md',
    rounding = 'normal',
    className = '',
    children = 'Button text',
    symfonyRoute = false,
    symfonyParams = {},
    hash = '',
    ...restProps
  } = props;

  const finalClasses = styling === 'none' ? className : composeButtonClasses(color, size, styling, rounding, className);

  return (
    <a href={`${symfonyRoute ? route(href, symfonyParams) : href}${hash}`} ref={ref} className={finalClasses} {...restProps}>
      { children }
    </a>
  );
});
