import { Avatar, BadgeNotifications } from '@components/common';
import { ApiHeaderInfoOk } from '@type/v1-api-types';
import { UserCircleIcon } from '@heroicons/react/20/solid';

interface ProfileAvatarProps {
  headerInfo?: ApiHeaderInfoOk,
  className?: string,
}

/**
 * ProfileAvatar Component
 *
 * Displays a user's avatar with an optional premium badge if they are a company with an active subscription.
 *
 * @example
 * <ProfileAvatar headerInfo={headerData} />
 */
export const ProfileAvatar = (props: ProfileAvatarProps) => {
  // Destructure props
  const { headerInfo, className = '' } = props;

  // Helper variables
  // ******************************************
  const isCompany = headerInfo?.hasBusinessWallet;
  const hasPremiumBadge = isCompany && headerInfo?.hasSubscription;

  return (
    <span className={`relative shrink-0 rounded-full ${className}`}>
      {/* If the user is a company, render a badge with a medal icon */
        hasPremiumBadge && <Avatar.Badge iconName="medal" size="sm" withRing />
      }
      <BadgeNotifications count={headerInfo?.nrUnseenMessages || 0} showCount={false} className="absolute -right-1 top-0 -translate-x-1/2" />
      {headerInfo?.profileImg ? (
        <Avatar
          size="xs"
          name={headerInfo?.name}
          imgPath={headerInfo?.profileImg}
          extractImageName
          imgFolder="resume_image"
          aspectRatio="square-uncertain"
          imgPriority
          id={headerInfo?.userId || 1}
          withRing
        />
      )
        : (
          <UserCircleIcon className="size-6 md:size-7" />
        )}
    </span>
  );
};

