import { useRouter as usePagesRouter } from 'next/compat/router';
import { useParams } from 'next/navigation';
import { getLocaleFromQuery } from '@utils/gssp/gsspGetLocale';

/**
 * Hook: useRouterLocale
 *
 * This hook get the locale information from the router's query parameters.
 * It is compatible with both the App Router (`useParams` from `next/navigation`)
 * and the Pages Router (`useRouter` from `next/compat/router`).
 *
 * @returns {string | undefined} - The locale from the router's query parameters.
 *
 * @example
 * // Using in a component
 * const locale = useRouterLocale();
 * console.log(locale); // Outputs the locale ("ro", "en", "hu")
 */
export const useRouterLocale = () => {
  // Get query parameters from the Pages Router (legacy)
  const pagesRouterParams = usePagesRouter()?.query;

  // Get parameters from the App Router
  const appRouterParams = useParams();

  // Get and return the locale
  return getLocaleFromQuery(appRouterParams || pagesRouterParams);
};
