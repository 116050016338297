import { useTranslation } from 'next-i18next';
import { Anchor } from '@components/common';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import { dataTestId } from '@services/data-test-id';


/**
 * @description Renders the login button in the navbar. If toEmployerLogin is true, the button will redirect to the
 * employer login page.
 */
export const LoginButton = ({ toEmployerLogin = false, className = '' }: { toEmployerLogin?: boolean, className?: string }) => {
  // translate
  const { t } = useTranslation('common');

  return (
    <Anchor
      href={toEmployerLogin ? 'app_login_recruiter' : 'login'}
      symfonyRoute
      rounding="full"
      styling="outline"
      size="xs"
      color="ink"
      className={`min-w-fit !px-6 !py-3 md:ml-6 md:!px-4.5 md:!py-2 md:!text-tiny lg:order-last ${className}`}
      {...dataTestId('navbar.loginButton')}
    >
      <LockClosedIcon className="-mt-px mr-1.5 size-3.5" />
      {t('menu.sign_in-register')}
    </Anchor>
  );
};
