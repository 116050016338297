import {
  ApiCompanyCheckIdentifier,
  ApiCompanyCheckIdentifierOk, ApiCvListCards,
  ApiCvListCardsOk,
  ICompanyCheckIdentifier,
} from '@type/v1-api-types';


// Company Check Identifier *******************************************************************
// pathname: /v1/company/check-identifier/{value}
// ************************************************************************************************
export const isCompanyCheckIdentifierOk = (res: ApiCompanyCheckIdentifier | void)
: res is ApiCompanyCheckIdentifierOk => res !== undefined && res !== null
  && ((res as ICompanyCheckIdentifier)?.name !== undefined || (Object.keys(res)?.length === 0));

export const isCompanyCheckIdentifierWithNameOk = (res: ApiCompanyCheckIdentifier | void)
: res is ICompanyCheckIdentifier => res !== undefined && res !== null && (res as ICompanyCheckIdentifier)?.name !== undefined;

// Cv-list cards   *******************************************************************************
// pathname: /v1/company/landing
// ************************************************************************************************
export const isCvListCardsOk = (res: ApiCvListCards | void)
: res is ApiCvListCardsOk => res !== undefined && res !== null
    && (res as ApiCvListCardsOk).talents !== undefined && (res as ApiCvListCardsOk).jobs !== undefined;

