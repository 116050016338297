// Imports
// ********************************************
import {
  useSwrApi, useOnTabRefocus, ApiHeaderInfoOk,
} from '@hooks/useSwrApi';
import { useAppDispatch, useAppSelector } from 'src/app';
import { getUserHeaderInfo } from 'src/app/reducers/userSelector';
import { useState } from 'react';
import { useAuthClient } from '@hooks/index';


/**
 * @description Hook that fetches header info data and also saves it in Redux state for other uses,
 * even though a subscription to the same key (that means the fetch url) would be the same thing (you
 * access the swr cache). We directly check for the cookie here instead of relying on hooks în order
 * to avoid stale data on tab refocus checks.
 *
 * How it works:
 * 1. We check if the user is authenticated (has the rm_pbl cookie)
 * 2. If yes, we subscribe (fetch) to the header info data. When not authenticated, the swr cache is
 * emptied (mutated) and the data is cleared from Redux state.
 * 3. We also repeat the process on tab refocus (visibility change) and revalidate (re-fetch) the
 * swr cache if the conditions inside the 'revalidateHeaderInfo' function are met. This guarantees that
 * the data is always fresh, no matter if the user is authenticated or not and how many browser tabs
 * are open.
 * 4. We can force check for the auth cookie. If the authentication cookie is valid, then it will re-fetch the
 * swr data.
 *
 * Conditions for headerInfo revalidation:
 * 1. Check if we have the rm_pbl cookie. If not either fetch or clear the swr cache.
 * 2. Check if the cookie auth date is different from the redux auth date. If not, re-fetch data.
 * 3. Check if we already have the header info in redux state. If not, re-fetch data.
 */
export const useHeaderInfoData = (locale = 'ro', fetchOnMount = true) => {
  // redux dispatch
  const appDispatch = useAppDispatch();
  const headerInfo = useAppSelector(getUserHeaderInfo);

  // Auth client helper functions
  const { checkAuthCookie, getAuthCookieDate } = useAuthClient();

  // State for loading; checks for rm_pbl cookie. We directly check for the cookie here to avoid stale data.
  const [userAuthenticated, setUserAuthenticated] = useState(fetchOnMount ? checkAuthCookie() : false);

  // SWR: fetch data conditionally
  // ********************************************
  const {
    error, isLoading, isValidating, mutate,
  } = useSwrApi<ApiHeaderInfoOk>({
    url: '/v1/header-info',
    shouldFetch: userAuthenticated,
    locale,
    apiOptions: {
      revalidateOnFocus: false,
      // On success, save the data in Redux state if it's valid
      onSuccess: (newData) => {
        // save header info in Redux state
        appDispatch({ type: 'user/saveHeaderInfo', payload: newData });
        // save auth date in Redux state => use it for comparison with current date from cookie
        appDispatch({ type: 'user/saveAuthDate', payload: getAuthCookieDate() });
      },
      // On error, clear the data from Redux state
      onError: () => {
        appDispatch({ type: 'user/clearHeaderInfo' });
      },
    },
  });

  // Run the callback on tab refocus. We directly check for the auth cookie to avoid stale data.
  // If there's no cookie, 'shouldFetch' will trigger and the navbar will be in the un-logged mode.
  // ********************************************
  useOnTabRefocus(() => setUserAuthenticated(checkAuthCookie()));

  // Force check for the auth cookie.
  // ********************************************
  const forceCheckAuthCookie = () => {
    setUserAuthenticated(checkAuthCookie());
  };

  // Return data
  // ********************************************
  return {
    headerInfoData: headerInfo,
    headerInfoError: error,
    headerInfoLoading: isLoading,
    headerInfoValidating: isValidating,
    mutateHeaderInfo: mutate,
    forceCheckAuthCookie,
  };
};
