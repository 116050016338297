import {
  PopoverStyled, Button, HamburgerButton, ErrorBoundary,
} from '@components/common';
import { ProfileCenterInterface } from '@type/header-types';
import { BurgerMenuLinks } from '@components/layout/Header/MainNavbar/links';
import { LanguageList } from '@components/layout/LanguageSwitch/LanguageList';
import { ProfileInNavbar } from './ProfileInNavbar';


/**
 * @description The profile section in the navbar.
 * * **headerInfo** The header info data
 * * **locale** The current app locale
 * * **className** Extra classes
 */
export const ProfileSection = (props: ProfileCenterInterface) => {
  // Component props
  const { headerInfo, className } = props;

  // Render component
  // *****************************************
  return (
    <PopoverStyled className={className}>
      {({ open, close }) => (
        <>
          { /* Navbar profile */ }
          <PopoverStyled.Button as={Button} styling="none" className="flex items-center py-1.5 outline-none">
            <ProfileInNavbar headerInfo={headerInfo} />
            <HamburgerButton asButton={false} menuOpened={open} className="ml-3.5 scale-80" />
          </PopoverStyled.Button>

          { /* Dropdown */ }
          <PopoverStyled.Panel position="right" className="top-full mt-1 max-h-[75vh] min-w-87.5 overflow-y-auto md:max-w-80">
            <div className="bg-surface-50">

              { /* Burger menu items */ }
              <BurgerMenuLinks className="py-4" closePopoverDesktop={close} />

              {/* Language switcher */}
              <ErrorBoundary fallback={<div className="ml-4 mr-2 size-4.5 rounded-full bg-error md:mr-4" />}>
                <div className="border-t py-4">
                  <LanguageList withCurrentLocaleName />
                </div>
              </ErrorBoundary>

            </div>
          </PopoverStyled.Panel>
        </>
      )}
    </PopoverStyled>
  );
};
